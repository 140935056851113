/**
 * @generated SignedSource<<d9458663dc6de45ac7a4376a22ee51ab>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type projectTypeCell_projectsDirectoryV3$data = {
  readonly projectStyle: JiraProjectStyle | null | undefined;
  readonly projectType: JiraProjectType | null | undefined;
  readonly " $fragmentType": "projectTypeCell_projectsDirectoryV3";
};
export type projectTypeCell_projectsDirectoryV3$key = {
  readonly " $data"?: projectTypeCell_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectTypeCell_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "projectTypeCell_projectsDirectoryV3",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "projectStyle"
    },
    {
      "kind": "ScalarField",
      "name": "projectType"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "070d0af17c4bb3ec3f4ea5c84d8914ff";

export default node;
