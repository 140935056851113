/**
 * @generated SignedSource<<49b4670d3a84ed70a8d64a8fc967a301>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectCategoryCell_projectsDirectoryV3$data = {
  readonly category: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "projectCategoryCell_projectsDirectoryV3";
};
export type projectCategoryCell_projectsDirectoryV3$key = {
  readonly " $data"?: projectCategoryCell_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectCategoryCell_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "projectCategoryCell_projectsDirectoryV3",
  "selections": [
    {
      "concreteType": "JiraProjectCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "8895769fe267fb6dd387e5807f5f7fe1";

export default node;
