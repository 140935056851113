/**
 * @generated SignedSource<<cf601ef97dd8c80990689dbf69ca6155>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectCategoryPicker_projectsDirectoryV3$data = {
  readonly allJiraProjectCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "projectCategoryPicker_projectsDirectoryV3";
};
export type projectCategoryPicker_projectsDirectoryV3$key = {
  readonly " $data"?: projectCategoryPicker_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectCategoryPicker_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "projectCategoryPicker_projectsDirectoryV3",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraProjectCategoryConnection",
      "kind": "LinkedField",
      "name": "allJiraProjectCategories",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProjectCategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraProjectCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "9e121ef0e1f18f545400a0c2ac559c3f";

export default node;
