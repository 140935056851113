/**
 * @generated SignedSource<<66b9c8f5cdf7d37c300b3e6d4ae9594d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type keyCell_projectsDirectoryV3$data = {
  readonly key: string;
  readonly " $fragmentType": "keyCell_projectsDirectoryV3";
};
export type keyCell_projectsDirectoryV3$key = {
  readonly " $data"?: keyCell_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"keyCell_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "keyCell_projectsDirectoryV3",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "8366f681ad04b41cb180bc7a28c738a4";

export default node;
