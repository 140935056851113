import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'projects-directory-v3.page-layout.error-state.header',
		defaultMessage: 'An error occurred',
		description: '',
	},
	description: {
		id: 'projects-directory-v3.page-layout.error-state.description',
		defaultMessage:
			'There was a problem loading your projects. Try again or contact your administrator for support.',
		description: '',
	},
});
