import { useState, useCallback, useRef } from 'react';
import { useRelayEnvironment, type RefetchFn } from 'react-relay';
import {
	fetchQuery,
	type GraphQLTaggedNode,
	type FetchQueryFetchPolicy,
	type Subscription,
} from 'relay-runtime';

interface UseDirectoryRefetchProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	refetch: RefetchFn<any>;
	query: GraphQLTaggedNode;
}

interface UseDirectoryRefetchData {
	isFetching: boolean;
	errors: Error | null;
}

interface RefetchDataConfig {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	variables: any;
	options?: {
		fetchPolicy?: FetchQueryFetchPolicy;
		onComplete?: () => void;
		onError?: () => void;
	};
}

interface UseDirectoryRefetchActions {
	refetchData: (config: RefetchDataConfig) => void;
}

type UseDirectoryRefetch = (
	props: UseDirectoryRefetchProps,
) => [UseDirectoryRefetchData, UseDirectoryRefetchActions];

export const useDirectoryRefetch: UseDirectoryRefetch = ({ refetch, query }) => {
	const environment = useRelayEnvironment();
	const requestInFlight = useRef<Subscription>();
	const [isFetching, setIsFetching] = useState(false);
	const [errors, setErrors] = useState<Error | null>(null);

	const refetchData = useCallback(
		({ variables, options }: RefetchDataConfig) => {
			requestInFlight.current?.unsubscribe();

			setIsFetching(true);
			setErrors(null);

			requestInFlight.current = fetchQuery(environment, query, variables, {
				fetchPolicy: options?.fetchPolicy || 'store-or-network',
			}).subscribe({
				complete: () => {
					refetch(variables, {
						fetchPolicy: 'store-only',
						onComplete: () => {
							setIsFetching(false);
							options?.onComplete && options.onComplete();
						},
					});
				},
				error: (error: Error) => {
					setIsFetching(false);
					setErrors(error);
					options?.onError && options.onError();
				},
			});
		},
		[environment, query, refetch],
	);

	return [
		{
			isFetching,
			errors,
		},
		{ refetchData },
	];
};
