import parameters from '@atlassian/jira-relay/src/__generated__/uiArchiveProjectModal_getProjectQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { projectId: string };

export const archiveProjectModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-archive-project-modal" */ './src/ui'),
	),
	getPreloadProps: ({ projectId }: EntryPointParams) => ({
		queries: {
			project: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters,
				variables: {
					projectId,
				},
			},
		},
	}),
});
