// values which are passed into the tracking callback by atlaskit
export const analyticsLoadedEvent = 'profile-card.loaded';
export const analyticsViewEvent = 'profile-card.view';
export const analyticsReloadEvent = 'profile-card.reload';
export const analyticsLoadErrorEvent = 'profile-card.error';
export const analyticsClickEvent = 'profile-card.click';

// constants that end up in tracking events
export const profileCardActionSubject = 'profileCardNext';
export const profileCardNextSourceName = 'profileCardNext';
export const profileCardLegacyActionSubject = 'profileCardNextLegacy';
export const profileCardNextLegacySourceName = 'profileCardNextLegacy';
export const teamProfileCardNextLegacySourceName = 'teamProfileCardNextLegacy';
export const loadErrorEventAction = 'loadError';

// constants for possible actions as prop
export const VIEW_PROFILE_ACTION = 'viewProfile';
export const ASSIGNED_ISSUE_ACTION = 'assignedIssues';
export const REPORTED_ISSUE_ACTION = 'reportedIssues';
export const VIEW_FILTERS_ACTION = 'viewFilters';
