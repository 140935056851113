import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import type { projectsList_projectsDirectoryV3_projectsRef$key } from '@atlassian/jira-relay/src/__generated__/projectsList_projectsDirectoryV3_projectsRef.graphql';
import type { projectsList_projectsDirectoryV3_rootQueryRef$key } from '@atlassian/jira-relay/src/__generated__/projectsList_projectsDirectoryV3_rootQueryRef.graphql';
import { Pagination } from './pagination/index.tsx';
import { ResultsTable } from './results-table/index.tsx';

type Props = {
	projectsRef: projectsList_projectsDirectoryV3_projectsRef$key;
	rootQueryRef: projectsList_projectsDirectoryV3_rootQueryRef$key;
	isLoading?: boolean;
	isAdminSettingsContext: boolean;
};

export const ProjectsList = ({
	projectsRef,
	rootQueryRef,
	isLoading,
	isAdminSettingsContext,
}: Props) => {
	const dataRef = useFragment<projectsList_projectsDirectoryV3_projectsRef$key>(
		graphql`
			fragment projectsList_projectsDirectoryV3_projectsRef on JiraProjectConnection
			@argumentDefinitions(isAdminSettingsContext: { type: "Boolean!" }) {
				...resultsTable_projectsDirectoryV3
					@arguments(isAdminSettingsContext: $isAdminSettingsContext)
				...pagination_projectsDirectoryV3
			}
		`,
		projectsRef,
	);

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const categoriesData = useFragment<projectsList_projectsDirectoryV3_rootQueryRef$key>(
		graphql`
			fragment projectsList_projectsDirectoryV3_rootQueryRef on Query
			@argumentDefinitions(isAnonymous: { type: "Boolean!" }) {
				jira {
					allJiraProjectCategories(cloudId: $cloudId) @skip(if: $isAnonymous) {
						edges {
							__typename
						}
					}
					...actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef
						@arguments(cloudId: $cloudId)
				}
			}
		`,
		rootQueryRef,
	);

	const shouldShowCategories = useMemo(
		() => Boolean(categoriesData.jira?.allJiraProjectCategories?.edges?.length),
		[categoriesData],
	);

	return (
		<Container
			isLoading={isLoading}
			data-testid="global-pages.directories.projects-directory-v3.ui.page-layout.projects-list.container"
		>
			<ResultsTable
				shouldShowCategories={shouldShowCategories}
				projectsRef={dataRef}
				jiraQueryRef={categoriesData.jira || null}
				isAdminSettingsContext={isAdminSettingsContext}
			/>
			<Pagination projectsRef={dataRef} />
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	isLoading?: boolean;
}>({
	position: 'relative',
	paddingBottom: token('space.200', '16px'),

	'&::after': {
		content: '',
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: token('elevation.surface.overlay', 'rgba(255, 255, 255, 0.8)'),
		top: 0,
		left: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: (props) => (props.isLoading ? 'block' : 'none'),
	},
});
