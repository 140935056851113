/**
 * @generated SignedSource<<1a1fd0fb256e69d343384e4492a60188>>
 * @relayHash bedfc53abda2b09909778822a77ae8e6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b970039aa2992a80e17db04733801c9fc94a870f85ad01abf94a227b9b0a28de

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiDeleteProjectModal_getProjectQuery } from './uiDeleteProjectModal_getProjectQuery.graphql';

const node: PreloadableConcreteRequest<uiDeleteProjectModal_getProjectQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b970039aa2992a80e17db04733801c9fc94a870f85ad01abf94a227b9b0a28de",
    "metadata": {},
    "name": "uiDeleteProjectModal_getProjectQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
