/**
 * @generated SignedSource<<8ed08c6672a1eaf3b9b82bab3a381f5a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraWorkManagementUserLicenseSeatEdition = "FREE" | "PREMIUM" | "STANDARD" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$data = {
  readonly jwmLicensing: {
    readonly currentUserSeatEdition: JiraWorkManagementUserLicenseSeatEdition | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef";
};
export type actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key = {
  readonly " $data"?: actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraWorkManagementLicensing",
      "kind": "LinkedField",
      "name": "jwmLicensing",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "currentUserSeatEdition"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "5b00115542e25c7594cc5725fc674963";

export default node;
