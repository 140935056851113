import React from 'react';
import { graphql, useFragment } from 'react-relay';
import Tooltip from '@atlaskit/tooltip';
import { CellWrapper } from '@atlassian/jira-directory-base-v3/src/ui/table-cells/styled.tsx';
import type { lastIssueUpdateCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/lastIssueUpdateCell_projectsDirectoryV3.graphql';

type Props = {
	project: lastIssueUpdateCell_projectsDirectoryV3$key;
};

export const LastIssueUpdateCell = ({ project }: Props) => {
	const { lastUpdatedToolTipDate, lastUpdatedDisplayDate } = useFragment(
		graphql`
			fragment lastIssueUpdateCell_projectsDirectoryV3 on JiraProject {
				lastUpdatedToolTipDate: lastUpdatedFormatted(format: COMPLETE_DATETIME_FORMAT)
				lastUpdatedDisplayDate: lastUpdatedFormatted(format: RELATIVE)
			}
		`,
		project,
	);

	if (!lastUpdatedDisplayDate) {
		return null;
	}

	return (
		<CellWrapper>
			<Tooltip content={lastUpdatedToolTipDate} position="top">
				<span>{lastUpdatedDisplayDate}</span>
			</Tooltip>
		</CellWrapper>
	);
};
