import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async.tsx';
import type { userProfile_directoryBaseV3$key } from '@atlassian/jira-relay/src/__generated__/userProfile_directoryBaseV3.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

interface Props {
	dataRef: userProfile_directoryBaseV3$key;
}

const UserProfile = ({ dataRef }: Props) => {
	const {
		accountId,
		name: displayName,
		picture: avatarUrl,
	} = useFragment<userProfile_directoryBaseV3$key>(
		graphql`
			fragment userProfile_directoryBaseV3 on User {
				accountId
				name
				picture
			}
		`,
		dataRef,
	);

	return isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2') ? (
		<AsyncProfileCardNext accountId={accountId}>
			<Box xcss={containerStyles} data-testId={`directory-user-profile-${accountId}`}>
				<Box xcss={avatarContainerStyles}>
					<Avatar src={avatarUrl} size="small" appearance="circle" />
				</Box>
				{/* Do NOT render a link FG is enabled for A11y reasons */}
				{accountId && !fg('blu-4174-enghealth-11799-a11y-fix') ? (
					<Link href={`/jira/people/${accountId}`}>
						<Box xcss={textStyles}>{displayName}</Box>
					</Link>
				) : (
					<Box xcss={textStyles}>{displayName}</Box>
				)}
			</Box>
		</AsyncProfileCardNext>
	) : (
		<AsyncProfileCardNext accountId={accountId}>
			<ContentContainer data-testId={`directory-user-profile-${accountId}`}>
				<AvatarContainer>
					<Avatar src={avatarUrl} size="small" appearance="circle" />
				</AvatarContainer>
				{/* Do NOT render a link FG is enabled for A11y reasons */}
				{accountId && !fg('blu-4174-enghealth-11799-a11y-fix') ? (
					<Link href={`/jira/people/${accountId}`}>
						<TextContainer>{displayName}</TextContainer>
					</Link>
				) : (
					<TextContainer>{displayName}</TextContainer>
				)}
			</ContentContainer>
		</AsyncProfileCardNext>
	);
};

const UserProfileMemoized = memo(UserProfile);

export { UserProfileMemoized as UserProfile };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	paddingRight: token('space.100', '8px'),
	lineHeight: 0,
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.div({
	wordWrap: 'break-word',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	width: '100%',
});

const avatarContainerStyles = xcss({
	paddingRight: 'space.100',
	lineHeight: 0,
	flex: '0 0 auto',
});

const textStyles = xcss({
	display: 'contents',
	wordWrap: 'break-word',
	overflow: 'hidden',
	color: 'color.text.subtle',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});
