import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import Close from '@atlaskit/icon/core/close';
import { Box, media, xcss } from '@atlaskit/primitives';

type Props = {
	clearIconLabel: string;
	onClear: () => void;
};

export const ClearFieldIcon = ({ onClear, clearIconLabel }: Props) => (
	<Box xcss={[wrapperStylesCloseIcon]}>
		<IconButton
			appearance="subtle"
			testId="searchfield.ui.clearfield-icon.clear-button"
			onClick={onClear}
			label={clearIconLabel}
			spacing="compact"
			icon={Close}
		/>
	</Box>
);

const wrapperStylesCloseIcon = xcss({
	display: 'flex',
	marginRight: 'space.025',
	[media.above.md]: {
		marginRight: 'space.050',
	},
});
