/**
 * @generated SignedSource<<78595a404e5a2998fb1503dd6011f88a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type refinementBar_projectsDirectoryV3_Filters$data = {
  readonly jira: {
    readonly allJiraProjectCategories?: {
      readonly edges: ReadonlyArray<{
        readonly __typename: "JiraProjectCategoryEdge";
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"projectCategoryPicker_projectsDirectoryV3" | "projectTypePicker_projectsDirectoryV3">;
  } | null | undefined;
  readonly " $fragmentType": "refinementBar_projectsDirectoryV3_Filters";
};
export type refinementBar_projectsDirectoryV3_Filters$key = {
  readonly " $data"?: refinementBar_projectsDirectoryV3_Filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"refinementBar_projectsDirectoryV3_Filters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAnonymous"
    }
  ],
  "kind": "Fragment",
  "name": "refinementBar_projectsDirectoryV3_Filters",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "condition": "isAnonymous",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "cloudId",
                  "variableName": "cloudId"
                }
              ],
              "concreteType": "JiraProjectCategoryConnection",
              "kind": "LinkedField",
              "name": "allJiraProjectCategories",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraProjectCategoryEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ]
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "projectCategoryPicker_projectsDirectoryV3"
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "projectTypePicker_projectsDirectoryV3"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "44ad103870f84d678e5203337cb09f3c";

export default node;
