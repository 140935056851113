/**
 * @generated SignedSource<<a59e34681b681f5451cb425b4f17ea24>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type nameCell_projectsDirectoryV3$data = {
  readonly avatar: {
    readonly medium: string | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly name: string;
  readonly navigationMetadata: {
    readonly __typename: "JiraServiceManagementProjectNavigationMetadata";
    readonly queueId: string;
  } | {
    readonly __typename: "JiraSoftwareProjectNavigationMetadata";
    readonly boardId: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly projectStyle: JiraProjectStyle | null | undefined;
  readonly " $fragmentType": "nameCell_projectsDirectoryV3";
};
export type nameCell_projectsDirectoryV3$key = {
  readonly " $data"?: nameCell_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"nameCell_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "nameCell_projectsDirectoryV3",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "concreteType": "JiraAvatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "medium"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "projectStyle"
    },
    {
      "kind": "LinkedField",
      "name": "navigationMetadata",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "boardId"
            }
          ],
          "type": "JiraSoftwareProjectNavigationMetadata"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "queueId"
            }
          ],
          "type": "JiraServiceManagementProjectNavigationMetadata"
        }
      ]
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "ba733526a1242d306b4f0b4e62066653";

export default node;
