/**
 * @generated SignedSource<<17a702f64ead8e9c0f6992bf4974db90>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type userProfile_directoryBaseV3$data = {
  readonly accountId: string;
  readonly name: string;
  readonly picture: AGG$URL;
  readonly " $fragmentType": "userProfile_directoryBaseV3";
};
export type userProfile_directoryBaseV3$key = {
  readonly " $data"?: userProfile_directoryBaseV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"userProfile_directoryBaseV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "userProfile_directoryBaseV3",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "accountId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "picture"
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};

(node as any).hash = "32feb6e507f926d7932dcef23d9427a7";

export default node;
