import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	next: {
		id: 'pagination.next',
		defaultMessage: 'Next page',
		description: 'Label for the next page button',
	},
	previous: {
		id: 'pagination.previous',
		defaultMessage: 'Previous page',
		description: 'Label for the previous page button',
	},
});
