import React from 'react';
import type { PreloadedQuery } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	PROJECTS_DIRECTORY_EXPERIENCE,
	MANAGE_PROJECTS_DIRECTORY_EXPERIENCE,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryLayout_RootQuery.graphql';
import { projectsDirectoryPageResource } from '@atlassian/jira-router-resources-project-directories/src/services/index.tsx';
import { ProjectDirectoryLayout } from './page-layout/index.tsx';

type Queries = {
	projectsDirectoryPage: PreloadedQuery<pageLayout_projectsDirectoryLayout_RootQuery>;
};

type Props = {
	isAdminSettingsContext: boolean;
	queries?: Queries;
};

const ProjectsDirectoryContainer = ({ isAdminSettingsContext, queries }: Props) => {
	const queryReference = queries?.projectsDirectoryPage
		? queries.projectsDirectoryPage
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useRelayResource(projectsDirectoryPageResource, {
				suspendWhenLoading: false,
			}).queryReference;

	const experienceKey = isAdminSettingsContext
		? MANAGE_PROJECTS_DIRECTORY_EXPERIENCE
		: PROJECTS_DIRECTORY_EXPERIENCE;

	const onFail = useExperienceFail({
		experience: experienceKey,
	});
	const onSuccess = useExperienceSuccess({
		experience: experienceKey,
	});

	return (
		<JSErrorBoundary
			id="app-container"
			packageName="jiraProjectsDirectoryV3"
			onError={(...args) => {
				const [, error] = args;
				isHttpClientErrorResponse(error)
					? onSuccess({ view: 'fallback-error-page' })
					: onFail(...args);
			}}
		>
			<Placeholder name="project-directory-layout" fallback={null}>
				{queryReference && (
					<ProjectDirectoryLayout
						queryRef={queryReference}
						isAdminSettingsContext={isAdminSettingsContext}
					/>
				)}
			</Placeholder>
		</JSErrorBoundary>
	);
};

export default ProjectsDirectoryContainer;
