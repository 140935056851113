import React from 'react';
import { ErrorBoundaryFlag as CommonErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/index.tsx';
import type { ErrorBoundaryFlagProps } from '@atlassian/jira-error-boundary-flag-renderer/src/types.tsx';
import SpaStateTransitionStateSubscriber from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';

const ErrorBoundaryWithExtraEventData = ({ children, id }: ErrorBoundaryFlagProps) => (
	<CommonErrorBoundary id="spa-apps.error-boundary">
		<SpaStateTransitionStateSubscriber>
			{({ transitionsCount = 0, lastTransition = '', navigationStart = '' }) => {
				const extraEventData = {
					transitionsCount,
					lastTransition,
					navigationStart,
				};

				return (
					<CommonErrorBoundary id={id} extraEventData={extraEventData}>
						{children}
					</CommonErrorBoundary>
				);
			}}
		</SpaStateTransitionStateSubscriber>
	</CommonErrorBoundary>
);

const SpaErrorBoundary = ErrorBoundaryWithExtraEventData;

export default SpaErrorBoundary;
