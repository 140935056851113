/**
 * @generated SignedSource<<9c2e64beddc9c311d09d56ae5eaf9bcb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type pagination_directoryBaseV3$data = {
  readonly totalCount: number | null | undefined;
  readonly " $fragmentType": "pagination_directoryBaseV3";
};
export type pagination_directoryBaseV3$key = {
  readonly " $data"?: pagination_directoryBaseV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"pagination_directoryBaseV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "pagination_directoryBaseV3",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "totalCount"
    }
  ],
  "type": "HasTotal",
  "abstractKey": "__isHasTotal"
};

(node as any).hash = "38583de0cf0e75e654e4970a8d336719";

export default node;
