/**
 * @generated SignedSource<<278a452adea7689a9e2551bcd4ee9f68>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type pagination_projectsDirectoryV3$data = {
  readonly " $fragmentSpreads": FragmentRefs<"pagination_directoryBaseV3">;
  readonly " $fragmentType": "pagination_projectsDirectoryV3";
};
export type pagination_projectsDirectoryV3$key = {
  readonly " $data"?: pagination_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"pagination_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "pagination_projectsDirectoryV3",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "pagination_directoryBaseV3"
    }
  ],
  "type": "JiraProjectConnection"
};

(node as any).hash = "ab05e4ef735cb6b0a98b29d30007a5ef";

export default node;
