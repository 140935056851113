/**
 * @generated SignedSource<<2559e9ee4fe799ba020998c3c1d63439>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type leadCell_projectsDirectoryV3$data = {
  readonly lead: {
    readonly " $fragmentSpreads": FragmentRefs<"userProfile_directoryBaseV3">;
  } | null | undefined;
  readonly " $fragmentType": "leadCell_projectsDirectoryV3";
};
export type leadCell_projectsDirectoryV3$key = {
  readonly " $data"?: leadCell_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadCell_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "leadCell_projectsDirectoryV3",
  "selections": [
    {
      "kind": "LinkedField",
      "name": "lead",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "userProfile_directoryBaseV3"
        }
      ]
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "900649656e1e2128dc46e511bce9897f";

export default node;
