/**
 * @generated SignedSource<<364beda65bbbc75d8787e30400e03a32>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type header_projectsDirectoryV3$data = {
  readonly jira: {
    readonly " $fragmentSpreads": FragmentRefs<"createProjectAction_projectsDirectoryV3_CreateButton" | "openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton">;
  } | null | undefined;
  readonly " $fragmentType": "header_projectsDirectoryV3";
};
export type header_projectsDirectoryV3$key = {
  readonly " $data"?: header_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"header_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAnonymous"
    }
  ],
  "kind": "Fragment",
  "name": "header_projectsDirectoryV3",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "createProjectAction_projectsDirectoryV3_CreateButton"
        },
        {
          "condition": "isAnonymous",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "22f237392203128d7ea8a45840fcb1ff";

export default node;
