import React, { type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

export type ScrollablePageProps = PropsWithChildren<{ 'data-testid'?: string }>;

export const ScrollablePage = ({ children, ...rest }: ScrollablePageProps) => (
	<Box
		testId={rest['data-testid'] || 'spa-apps-page-layout.page.scrollable-page'}
		xcss={scrollablePageStyles}
		paddingBlock="space.0"
		paddingInline="space.500"
	>
		{children}
	</Box>
);

export type PageLayoutProps = PropsWithChildren<{ hideMargin?: boolean; 'data-testid'?: string }>;

export const PageLayout = ({ children, hideMargin, ...rest }: PageLayoutProps) => (
	<Box
		testId={rest['data-testid'] || 'spa-apps-page-layout.page.page-layout'}
		xcss={hideMargin ? pageLayoutWithoutMarginStyles : pageLayoutWithMarginStyles}
	>
		{children}
	</Box>
);

const scrollablePageStyles = xcss({
	minWidth: '800px', // (gridSize * 90) + ((gridSize * 5) * 2)
	margin: 'space.0',
});

const pageLayoutWithoutMarginStyles = xcss({
	margin: 'space.0',
});

const pageLayoutWithMarginStyles = xcss({
	marginBlock: '0',
	marginInline: 'space.500',
});
