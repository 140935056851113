import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-category-picker.placeholder',
		defaultMessage: 'All categories',
		description: 'Placeholder that is showed inside the ProjectCategoryPicker',
	},
	ariaLabel: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-category-picker.aria-label',
		defaultMessage: 'Select a project category',
		description: 'Aria label for the ProjectCategoryPicker',
	},
});
