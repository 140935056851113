/**
 * @generated SignedSource<<b4357c3565f68835dbde8f943f6d1b0e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectListRightPanelState = "CLOSED" | "OPEN" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type rightPanel_projectsDirectoryV3_ShouldShowRightSidebar$data = {
  readonly jira: {
    readonly canCreateProject: boolean | null | undefined;
    readonly sidebarExperimentTemplates: {
      readonly totalCount: number | null | undefined;
    } | null | undefined;
    readonly userPreferences: {
      readonly projectListRightPanelState: JiraProjectListRightPanelState | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "rightPanel_projectsDirectoryV3_ShouldShowRightSidebar";
};
export type rightPanel_projectsDirectoryV3_ShouldShowRightSidebar$key = {
  readonly " $data"?: rightPanel_projectsDirectoryV3_ShouldShowRightSidebar$data;
  readonly " $fragmentSpreads": FragmentRefs<"rightPanel_projectsDirectoryV3_ShouldShowRightSidebar">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "rightPanel_projectsDirectoryV3_ShouldShowRightSidebar",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v0/*: any*/)
            ],
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "projectListRightPanelState"
              }
            ]
          },
          {
            "alias": "sidebarExperimentTemplates",
            "args": [
              (v0/*: any*/),
              {
                "kind": "Literal",
                "name": "experimentKey",
                "value": "sidebarExperiment"
              }
            ],
            "concreteType": "JiraProjectListViewTemplateConnection",
            "kind": "LinkedField",
            "name": "projectListViewTemplates",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "totalCount"
              }
            ]
          },
          {
            "alias": "canCreateProject",
            "args": [
              (v0/*: any*/),
              {
                "kind": "Literal",
                "name": "type",
                "value": "CREATE_PROJECT"
              }
            ],
            "kind": "ScalarField",
            "name": "canPerform"
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "41b719258d7b3108422166c24c418e1c";

export default node;
