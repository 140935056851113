import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	srOnlyCaption: {
		id: 'simple-table.sr-only-caption',
		defaultMessage: 'Use column header buttons to sort',
		description: 'Advises Screen only users to use header buttons for sorting',
	},
	srOnlySortingAlertAsc: {
		id: 'simple-table.sr-only-sorting-alert-asc',
		defaultMessage: '{sortKey} column is sorted by ascending order',
		description: 'SR only alert message on sort order change ascending',
	},
	srOnlySortingAlertDesc: {
		id: 'simple-table.sr-only-sorting-alert-desc',
		defaultMessage: '{sortKey} column is sorted by descending order',
		description: 'SR only alert message on sort order change descending',
	},
});
