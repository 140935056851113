import React, { useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import {
	PANEL_ID,
	SIDEBAR_WIDTH,
} from '@atlassian/jira-growth-recommendations-in-project-list/src/constants.tsx';
import { useRightSidebarController } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { rightPanel_projectsDirectoryV3_ShouldShowRightSidebar$key } from '@atlassian/jira-relay/src/__generated__/rightPanel_projectsDirectoryV3_ShouldShowRightSidebar.graphql';

type Props = {
	dataRef: rightPanel_projectsDirectoryV3_ShouldShowRightSidebar$key;
};

const ShouldShowRightSidebar = ({ dataRef }: Props) => {
	const { openRightSidebar, closeRightSidebar } = useRightSidebarController();
	const {
		jira: { userPreferences, sidebarExperimentTemplates, canCreateProject },
	} = useFragment<rightPanel_projectsDirectoryV3_ShouldShowRightSidebar$key>(
		graphql`
			fragment rightPanel_projectsDirectoryV3_ShouldShowRightSidebar on Query {
				jira @required(action: THROW) {
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						projectListRightPanelState @optIn(to: "JiraProjectListRightPanelState")
					}
					sidebarExperimentTemplates: projectListViewTemplates(
						cloudId: $cloudId
						experimentKey: "sidebarExperiment"
					) @optIn(to: "JiraProjectListViewTemplate") {
						totalCount
					}
					canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
						@optIn(to: "JiraAction")
				}
			}
		`,
		dataRef,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (
			!(typeof sidebarExperimentTemplates?.totalCount === 'number') ||
			!(sidebarExperimentTemplates.totalCount > 0) ||
			!userPreferences?.projectListRightPanelState ||
			!canCreateProject
		) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'sidebarEligibility failed');
			return;
		}

		if (userPreferences.projectListRightPanelState === 'OPEN') {
			openRightSidebar(PANEL_ID, SIDEBAR_WIDTH, false, true);
		}

		if (userPreferences.projectListRightPanelState === 'CLOSED') {
			closeRightSidebar(PANEL_ID);
		}
	}, [
		openRightSidebar,
		closeRightSidebar,
		userPreferences?.projectListRightPanelState,
		sidebarExperimentTemplates?.totalCount,
		canCreateProject,
		createAnalyticsEvent,
	]);

	return null;
};

const ShouldShowRightSidebarContainer = (props: Props) => (
	<JSErrorBoundary id="right-side-panel" packageName="jiraProjectsDirectoryV3" fallback="unmount">
		<ShouldShowRightSidebar {...props} />
	</JSErrorBoundary>
);

export { ShouldShowRightSidebarContainer as ShouldShowRightSidebar };
