import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyEmptyView = lazy(
	() =>
		import(/* webpackChunkName: "async-project-directory-empty-view" */ './index').then(
			({ EmptyView }) => EmptyView,
		),
	{ ssr: false },
);

export const AsyncEmptyView = (props: Props) => (
	<Placeholder name="empty-view" fallback={null}>
		<LazyEmptyView {...props} />
	</Placeholder>
);
