import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	headerCaption: {
		id: 'projects-directory-v3.page-layout.header.header-caption',
		defaultMessage: 'Projects',
		description: 'Page title for Projects Directory page',
	},
	adminHeaderCaption: {
		id: 'projects-directory-v3.page-layout.header.admin-header-caption',
		defaultMessage: 'Manage Projects',
		description: 'Page title for Admin Projects Directory page',
	},
});
