import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';

const shouldShowTrashTriggerForSoftwareType = (
	isSimplified: boolean,
	isAdmin: boolean,
	canAdministerProject: boolean,
) => {
	if (isSimplified) {
		return isAdmin || canAdministerProject; // Either Jira admins or Project admins can trash the simplified/next-gen project
	}
	return isAdmin; // Only Jira admins can trash the classic software project
};

// There is no simplified/next-gen version of core project type. Only Jira admins can trash the core project
const shouldShowTrashTriggerForCoreType = (isAdmin: boolean) => isAdmin;

// Same behaviour as in Software:
// Either Jira admins or Project admins can trash the simplified/next-gen JSM project
// Only Jira admins can trash the classic JSM project
const shouldShowTrashTriggerForJSMType = (
	isSimplified: boolean,
	isAdmin: boolean,
	canAdministerProject: boolean,
) => shouldShowTrashTriggerForSoftwareType(isSimplified, isAdmin, canAdministerProject);

type Args = {
	projectType: ProjectType;
	isSimplified: boolean;
	isAdmin: boolean;
	canAdministerProject: boolean;
};

export const shouldShowTrashProjectTrigger = ({
	projectType,
	isSimplified,
	isAdmin,
	canAdministerProject,
}: Args) => {
	switch (projectType) {
		case PRODUCT_DISCOVERY_PROJECT:
		case SOFTWARE_PROJECT:
			return shouldShowTrashTriggerForSoftwareType(isSimplified, isAdmin, canAdministerProject);
		case CORE_PROJECT:
			return shouldShowTrashTriggerForCoreType(isAdmin);
		case SERVICE_DESK_PROJECT:
			return shouldShowTrashTriggerForJSMType(isSimplified, isAdmin, canAdministerProject);
		default:
			return false;
	}
};
