import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholderSpork: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-type-picker.placeholder-spork',
		defaultMessage: 'Filter by product',
		description: 'Placeholder that is showed inside the project type picker',
	},
	productDiscovery: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-type-picker.product-discovery',
		defaultMessage: 'Jira Product Discovery',
		description: 'Placeholder that is showed inside the project type picker',
	},
	softwareSpork: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-type-picker.software-spork',
		defaultMessage: 'Jira - software projects',
		description: 'Placeholder that is showed inside the project type picker',
	},
	serviceDesk: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-type-picker.service-desk',
		defaultMessage: 'Jira Service Management',
		description: 'Placeholder that is showed inside the project type picker',
	},
	customerService: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-type-picker.customer-service',
		defaultMessage: 'Jira Customer Service',
		description: 'Placeholder that is showed inside the project type picker',
	},
	businessSpork: {
		id: 'projects-directory-v3.page-layout.refinement-bar.project-type-picker.business-spork',
		defaultMessage: 'Jira - business projects',
		description: 'Placeholder that is showed inside the project type picker',
	},
});
