/**
 * @generated SignedSource<<f6003fff2598016aad9b7bcfa4be569a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type pageLayout_projectsDirectoryV3_ProjectDirectoryLayout$data = {
  readonly jira: {
    readonly allJiraProjects: {
      readonly totalCount: number | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"projectsList_projectsDirectoryV3_projectsRef">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "pageLayout_projectsDirectoryV3_ProjectDirectoryLayout";
};
export type pageLayout_projectsDirectoryV3_ProjectDirectoryLayout$key = {
  readonly " $data"?: pageLayout_projectsDirectoryV3_ProjectDirectoryLayout$data;
  readonly " $fragmentSpreads": FragmentRefs<"pageLayout_projectsDirectoryV3_ProjectDirectoryLayout">;
};

import ProjectDirectoryRefetchQuery_graphql from './ProjectDirectoryRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAdminSettingsContext"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": ProjectDirectoryRefetchQuery_graphql
    }
  },
  "name": "pageLayout_projectsDirectoryV3_ProjectDirectoryLayout",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Variable",
              "name": "filter",
              "variableName": "filter"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            },
            {
              "kind": "Variable",
              "name": "last",
              "variableName": "last"
            }
          ],
          "concreteType": "JiraProjectConnection",
          "kind": "LinkedField",
          "name": "allJiraProjects",
          "plural": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "isAdminSettingsContext",
                  "variableName": "isAdminSettingsContext"
                }
              ],
              "kind": "FragmentSpread",
              "name": "projectsList_projectsDirectoryV3_projectsRef"
            },
            {
              "kind": "ScalarField",
              "name": "totalCount"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "eb5983a7159e6add64de193b1e14a2b6";

export default node;
