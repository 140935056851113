/**
 * @generated SignedSource<<c09f58b52b9291556cc0880556a04178>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectListRightPanelState = "CLOSED" | "OPEN" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton$data = {
  readonly canCreateProject: boolean | null | undefined;
  readonly sidebarExperimentTemplates: {
    readonly totalCount: number;
  };
  readonly userPreferences: {
    readonly __id: string;
    readonly projectListRightPanelState: JiraProjectListRightPanelState | null | undefined;
  };
  readonly " $fragmentType": "openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton";
};
export type openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton$key = {
  readonly " $data"?: openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton$data;
  readonly " $fragmentSpreads": FragmentRefs<"openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton",
  "selections": [
    {
      "alias": "canCreateProject",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "type",
          "value": "CREATE_PROJECT"
        }
      ],
      "kind": "ScalarField",
      "name": "canPerform"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          (v0/*: any*/)
        ],
        "concreteType": "JiraUserPreferences",
        "kind": "LinkedField",
        "name": "userPreferences",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "projectListRightPanelState"
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__id"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "userPreferences"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "sidebarExperimentTemplates",
        "args": [
          (v0/*: any*/),
          {
            "kind": "Literal",
            "name": "experimentKey",
            "value": "sidebarExperiment"
          }
        ],
        "concreteType": "JiraProjectListViewTemplateConnection",
        "kind": "LinkedField",
        "name": "projectListViewTemplates",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "totalCount"
            },
            "action": "THROW",
            "path": "sidebarExperimentTemplates.totalCount"
          }
        ]
      },
      "action": "THROW",
      "path": "sidebarExperimentTemplates"
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "57f9e2bfda0bc31526f57df7eb39fcad";

export default node;
