import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	settings: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.actions-cell.settings',
		defaultMessage: 'Project settings',
		description: 'Dropdown menu item. Goes to Project settings page',
	},
	moreActions: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.actions-cell.more-actions',
		defaultMessage: 'More actions for {name}',
		description: 'Button label. Button opens dropdown',
	},
	more: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.actions-cell.more',
		defaultMessage: 'More',
		description: 'Button label. Button opens dropdown',
	},
});
