function $parcel$interopDefault(a) {
    return a && a.__esModule ? a.default : a;
}
function $parcel$defineInteropFlag(a) {
    Object.defineProperty(a, '__esModule', {
        value: true,
        configurable: true
    });
}
function $parcel$export(e, n, v, s) {
    Object.defineProperty(e, n, {
        get: v,
        set: s,
        enumerable: true,
        configurable: true
    });
}
var $parcel$global = globalThis;
var parcelRequire = $parcel$global["parcelRequire94c2"];
var parcelRegister = parcelRequire.register;
parcelRegister("j3Lmi", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "default", ()=>$7b4ac42d40f409f5$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $kaHBi;
    var $iXVV6;
    var $5oFIy;
    var $fWqHN;
    var $5qVrc;
    var $4dRjY;
    var $l8ruj;
    var $hQFKP;
    var $7b4ac42d40f409f5$export$2e2bcd8739ae039 = ({ queries: queries, extraProps: { isAdminProjectDirectoryRoute: isAdminProjectDirectoryRoute } })=>{
        const metricsConfig = (0, $5uXOq.useMemo)(()=>isAdminProjectDirectoryRoute ? (0, (0, parcelRequire("hQFKP")).METRICS_CONFIG).admin : (0, (0, parcelRequire("hQFKP")).METRICS_CONFIG).default, [
            isAdminProjectDirectoryRoute
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: metricsConfig.sourceName,
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4dRjY")).MarkProductStart), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kaHBi")).ExperienceStart), {
            experience: metricsConfig.experienceKey,
            analyticsSource: metricsConfig.experienceSource
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5qVrc")).default), {
            id: metricsConfig.errorBoundary
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "projects-directory-v3"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fWqHN")).default), {
            isAdminSettingsContext: isAdminProjectDirectoryRoute,
            queries: queries
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), null))));
    };
});
const $4d5e0871c06cee03$export$f636b5b56f1d624a = 'Screen';
const $4d5e0871c06cee03$export$9d2de6eebf7462b5 = 'Drawer';
const $4d5e0871c06cee03$export$1171103ea9083ffa = 'Modal';
const $4d5e0871c06cee03$export$cae2783c9279926 = 'InlineDialog';
const $4d5e0871c06cee03$export$d1123261ca8831d2 = 'Dropdown';
const $4d5e0871c06cee03$export$238d30785c4bca84 = 'Banner';
const $4d5e0871c06cee03$export$a1562d08625f5d7e = 'UI';
const $4d5e0871c06cee03$export$947dac6c72b26f81 = 'TRACK';
const $4d5e0871c06cee03$export$f1d537d508d2945a = 'SCREEN';
const $4d5e0871c06cee03$export$12346a674644c156 = 'OPERATIONAL';
parcelRegister("fWqHN", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2e2a7ebf5ca2a77a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $93jKl;
    var $3bQtK;
    var $fZneA;
    var $lfTZh;
    var $gkT9B;
    var $ao2h1;
    var $hW253;
    var $dpo6O;
    const $2e2a7ebf5ca2a77a$var$ProjectsDirectoryContainer = ({ isAdminSettingsContext: isAdminSettingsContext, queries: queries })=>{
        const queryReference = queries?.projectsDirectoryPage ? queries.projectsDirectoryPage : (0, (0, parcelRequire("ao2h1")).useRelayResource)((0, (0, parcelRequire("hW253")).projectsDirectoryPageResource), {
            suspendWhenLoading: false
        }).queryReference;
        const experienceKey = isAdminSettingsContext ? (0, (0, parcelRequire("gkT9B")).MANAGE_PROJECTS_DIRECTORY_EXPERIENCE) : (0, (0, parcelRequire("gkT9B")).PROJECTS_DIRECTORY_EXPERIENCE);
        const onFail = (0, (0, parcelRequire("93jKl")).useExperienceFail)({
            experience: experienceKey
        });
        const onSuccess = (0, (0, parcelRequire("3bQtK")).useExperienceSuccess)({
            experience: experienceKey
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "app-container",
            packageName: "jiraProjectsDirectoryV3",
            onError: (...args)=>{
                const [, error] = args;
                (0, (0, parcelRequire("fZneA")).isHttpClientErrorResponse)(error) ? onSuccess({
                    view: 'fallback-error-page'
                }) : onFail(...args);
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "project-directory-layout",
            fallback: null
        }, queryReference && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dpo6O")).ProjectDirectoryLayout), {
            queryRef: queryReference,
            isAdminSettingsContext: isAdminSettingsContext
        })));
    };
    var $2e2a7ebf5ca2a77a$export$2e2bcd8739ae039 = $2e2a7ebf5ca2a77a$var$ProjectsDirectoryContainer;
});
parcelRegister("dpo6O", function(module, exports) {
    $parcel$export(module.exports, "ProjectDirectoryLayout", ()=>$c6eccca01817d954$export$9fc4c60d00e1220);
    var $jJiDF;
    var $lVQ0r;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $fraBZ;
    var $fc2Kp;
    var $rq7Hc;
    var $39xOx;
    var $93jKl;
    var $3bQtK;
    var $4R6GH;
    var $ew1Lk;
    var $koVbs;
    var $1XJ20;
    var $gkT9B;
    var $8pIiT;
    var $88JI2;
    var $5rqex;
    var $6VrSE;
    var $9dbF6;
    var $fhVq7;
    var $dZw8H;
    var $koeKL;
    var $itmJb;
    var $cZpsT;
    var $cNd4s;
    var $dPvGX;
    var $he80v;
    var $hP4DQ;
    var $jNWQj;
    var $jBriI;
    var $4pNZM;
    var $9BDgo;
    var $qB72M;
    var $857tP;
    const $c6eccca01817d954$export$9fc4c60d00e1220 = ({ queryRef: queryRef, isAdminSettingsContext: isAdminSettingsContext })=>{
        const experienceKey = isAdminSettingsContext ? (0, (0, parcelRequire("gkT9B")).MANAGE_PROJECTS_DIRECTORY_EXPERIENCE) : (0, (0, parcelRequire("gkT9B")).PROJECTS_DIRECTORY_EXPERIENCE);
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const syncQueryFilter = (0, (0, parcelRequire("rq7Hc")).useQueryFilter)();
        const onFail = (0, (0, parcelRequire("93jKl")).useExperienceFail)({
            experience: experienceKey
        });
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const rootQueryRef = (0, $8Uumt.usePreloadedQuery)((0, (0, parcelRequire("lVQ0r")).default), queryRef);
        const [dataRef, refetch] = (0, $8Uumt.useRefetchableFragment)((0, (0, parcelRequire("jJiDF")).default), rootQueryRef);
        const prevDataRef = (0, (0, parcelRequire("1XJ20")).usePrevious)(dataRef);
        const [{ isFetching: isFetching, errors: errors }, { refetchData: refetchData }] = (0, (0, parcelRequire("fc2Kp")).useDirectoryRefetch)({
            refetch: refetch,
            query: (0, (0, parcelRequire("6VrSE")).default)
        });
        const handleFilterUpdate = (0, $5uXOq.useCallback)((filter)=>{
            const { [(0, (0, parcelRequire("gkT9B")).FilterFields).Page]: currentPage } = filter;
            refetchData({
                variables: {
                    cloudId: cloudId,
                    filter: (0, (0, parcelRequire("8pIiT")).mapQueryToFilter)(filter, {
                        cloudId: cloudId
                    }),
                    first: (0, (0, parcelRequire("gkT9B")).DEFAULT_LIMIT) * parseInt(currentPage, 10),
                    last: (0, (0, parcelRequire("gkT9B")).DEFAULT_LIMIT),
                    isAdminSettingsContext: isAdminSettingsContext
                },
                options: {
                    fetchPolicy: 'network-only'
                }
            });
            syncQueryFilter(filter);
        }, [
            cloudId,
            refetchData,
            syncQueryFilter,
            isAdminSettingsContext
        ]);
        const isSiteAdmin = (0, (0, parcelRequire("cNd4s")).useIsSiteAdmin)();
        const isJiraAdmin = (0, (0, parcelRequire("itmJb")).useIsAdmin)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const canBrowseProjects = isSiteAdmin || isJiraAdmin || rootQueryRef?.jira?.canBrowseProjects?.hasPermission;
        if (!canBrowseProjects) return (0, ($parcel$interopDefault($5uXOq))).createElement($c6eccca01817d954$var$HeaderlessContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4pNZM")).AsyncNoPermissionViews), {
            dataRef: rootQueryRef
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bQtK")).ExperienceSuccess), {
            experience: experienceKey,
            attributes: {
                view: 'no-permission'
            }
        }));
        if (errors) {
            onFail('projects-directory-v3.page', errors);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($c6eccca01817d954$var$HeaderlessContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hP4DQ")).PageErrorState), null));
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "page-layout",
            packageName: "jiraProjectsDirectoryV3",
            onError: onFail
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fhVq7")).ScrollablePage), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dPvGX")).FilterContainer), {
            isGlobal: true,
            onUpdate: handleFilterUpdate
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ew1Lk")).DocumentTitle), {
            title: isAdminSettingsContext ? formatMessage((0, (0, parcelRequire("jBriI")).default).adminDocumentTitle) : formatMessage((0, (0, parcelRequire("jBriI")).default).documentTitle)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jNWQj")).Header), {
            dataRef: rootQueryRef,
            isAdminSettingsContext: isAdminSettingsContext
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("qB72M")).RefinementBar), {
            dataRef: rootQueryRef
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fraBZ")).AnnouncerV2), {
            message: formatMessage((0, (0, parcelRequire("jBriI")).default).filterResultsAnnouncement, {
                itemCount: dataRef?.jira?.allJiraProjects?.totalCount || 0
            }),
            shouldAnnounce: !isFetching && prevDataRef !== dataRef
        }), dataRef?.jira?.allJiraProjects && dataRef?.jira?.allJiraProjects?.totalCount ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9BDgo")).ProjectsList), {
            isLoading: isFetching,
            isAdminSettingsContext: isAdminSettingsContext,
            projectsRef: dataRef?.jira?.allJiraProjects,
            rootQueryRef: rootQueryRef
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bQtK")).ExperienceSuccess), {
            experience: experienceKey,
            attributes: {
                view: 'projects-list'
            }
        })) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("he80v")).AsyncEmptyView), {
            dataRef: rootQueryRef
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bQtK")).ExperienceSuccess), {
            experience: experienceKey,
            attributes: {
                view: 'empty'
            }
        })))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dZw8H")).SpaStatePageReady), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5rqex")).default), {
            appName: "projects-directory-v3",
            metricKey: "jira.directories.projects-v3",
            isExpectedToHaveSsr: true,
            metric: (0, (0, parcelRequire("88JI2")).pageLoad)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9dbF6")).SubProductUpdater), {
            subProduct: "platform"
        }), !isAnonymous && !(0, (0, parcelRequire("4R6GH")).fg)('server_render_projects_directory_sidebar') && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("857tP")).ShouldShowRightSidebar), {
            dataRef: rootQueryRef
        }));
    };
    const $c6eccca01817d954$var$HeaderlessContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_ca0qvbgk",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("jJiDF", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bbf9a293684a471b$export$2e2bcd8739ae039);
    var $6VrSE;
    const $bbf9a293684a471b$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "cloudId"
            },
            {
                "kind": "RootArgument",
                "name": "filter"
            },
            {
                "kind": "RootArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAdminSettingsContext"
            },
            {
                "kind": "RootArgument",
                "name": "last"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "fragmentPathInResult": [],
                "operation": (0, (0, parcelRequire("6VrSE")).default)
            }
        },
        "name": "pageLayout_projectsDirectoryV3_ProjectDirectoryLayout",
        "selections": [
            {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "cloudId",
                                "variableName": "cloudId"
                            },
                            {
                                "kind": "Variable",
                                "name": "filter",
                                "variableName": "filter"
                            },
                            {
                                "kind": "Variable",
                                "name": "first",
                                "variableName": "first"
                            },
                            {
                                "kind": "Variable",
                                "name": "last",
                                "variableName": "last"
                            }
                        ],
                        "concreteType": "JiraProjectConnection",
                        "kind": "LinkedField",
                        "name": "allJiraProjects",
                        "plural": false,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "kind": "Variable",
                                        "name": "isAdminSettingsContext",
                                        "variableName": "isAdminSettingsContext"
                                    }
                                ],
                                "kind": "FragmentSpread",
                                "name": "projectsList_projectsDirectoryV3_projectsRef"
                            },
                            {
                                "kind": "ScalarField",
                                "name": "totalCount"
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "Query"
    };
    $bbf9a293684a471b$var$node.hash = "eb5983a7159e6add64de193b1e14a2b6";
    var $bbf9a293684a471b$export$2e2bcd8739ae039 = $bbf9a293684a471b$var$node;
});
parcelRegister("6VrSE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$146e9aaab261bbcb$export$2e2bcd8739ae039);
    const $146e9aaab261bbcb$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "filter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAdminSettingsContext"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "last"
            }
        ], v1 = [
            {
                "kind": "ScalarField",
                "name": "canPerform"
            }
        ], v2 = {
            "kind": "ScalarField",
            "name": "id"
        }, v3 = {
            "kind": "ScalarField",
            "name": "name"
        }, v4 = {
            "kind": "ScalarField",
            "name": "isFavourite"
        }, v5 = {
            "kind": "ScalarField",
            "name": "__typename"
        };
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "ProjectDirectoryRefetchQuery",
                "selections": [
                    {
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "isAdminSettingsContext",
                                "variableName": "isAdminSettingsContext"
                            }
                        ],
                        "kind": "FragmentSpread",
                        "name": "pageLayout_projectsDirectoryV3_ProjectDirectoryLayout"
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "ProjectDirectoryRefetchQuery",
                "selections": [
                    {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "kind": "Variable",
                                        "name": "cloudId",
                                        "variableName": "cloudId"
                                    },
                                    {
                                        "kind": "Variable",
                                        "name": "filter",
                                        "variableName": "filter"
                                    },
                                    {
                                        "kind": "Variable",
                                        "name": "first",
                                        "variableName": "first"
                                    },
                                    {
                                        "kind": "Variable",
                                        "name": "last",
                                        "variableName": "last"
                                    }
                                ],
                                "concreteType": "JiraProjectConnection",
                                "kind": "LinkedField",
                                "name": "allJiraProjects",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraProjectEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "concreteType": "JiraProject",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": "canViewIssue",
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "type",
                                                                "value": "VIEW_ISSUES"
                                                            }
                                                        ],
                                                        "concreteType": "JiraProjectAction",
                                                        "kind": "LinkedField",
                                                        "name": "action",
                                                        "plural": false,
                                                        "selections": v1,
                                                        "storageKey": "action(type:\"VIEW_ISSUES\")"
                                                    },
                                                    v2,
                                                    v3,
                                                    v4,
                                                    {
                                                        "concreteType": "JiraFavouriteValue",
                                                        "kind": "LinkedField",
                                                        "name": "favouriteValue",
                                                        "plural": false,
                                                        "selections": [
                                                            v4,
                                                            {
                                                                "kind": "ClientExtension",
                                                                "selections": [
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "__id"
                                                                    }
                                                                ]
                                                            },
                                                            v2
                                                        ]
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "key"
                                                    },
                                                    {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "medium"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "projectStyle"
                                                    },
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "navigationMetadata",
                                                        "plural": false,
                                                        "selections": [
                                                            v5,
                                                            {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "boardId"
                                                                    },
                                                                    v2
                                                                ],
                                                                "type": "JiraSoftwareProjectNavigationMetadata"
                                                            },
                                                            {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "queueId"
                                                                    }
                                                                ],
                                                                "type": "JiraServiceManagementProjectNavigationMetadata"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "projectType"
                                                    },
                                                    {
                                                        "concreteType": "JiraProjectCategory",
                                                        "kind": "LinkedField",
                                                        "name": "category",
                                                        "plural": false,
                                                        "selections": [
                                                            v3,
                                                            v2
                                                        ]
                                                    },
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "lead",
                                                        "plural": false,
                                                        "selections": [
                                                            v5,
                                                            {
                                                                "kind": "TypeDiscriminator",
                                                                "abstractKey": "__isUser"
                                                            },
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "accountId"
                                                            },
                                                            v3,
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "picture"
                                                            },
                                                            v2
                                                        ]
                                                    },
                                                    {
                                                        "condition": "isAdminSettingsContext",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                            {
                                                                "alias": "lastUpdatedToolTipDate",
                                                                "args": [
                                                                    {
                                                                        "kind": "Literal",
                                                                        "name": "format",
                                                                        "value": "COMPLETE_DATETIME_FORMAT"
                                                                    }
                                                                ],
                                                                "kind": "ScalarField",
                                                                "name": "lastUpdatedFormatted",
                                                                "storageKey": "lastUpdatedFormatted(format:\"COMPLETE_DATETIME_FORMAT\")"
                                                            },
                                                            {
                                                                "alias": "lastUpdatedDisplayDate",
                                                                "args": [
                                                                    {
                                                                        "kind": "Literal",
                                                                        "name": "format",
                                                                        "value": "RELATIVE"
                                                                    }
                                                                ],
                                                                "kind": "ScalarField",
                                                                "name": "lastUpdatedFormatted",
                                                                "storageKey": "lastUpdatedFormatted(format:\"RELATIVE\")"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "projectUrl"
                                                    },
                                                    {
                                                        "alias": "canEdit",
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "type",
                                                                "value": "EDIT_PROJECT_CONFIG"
                                                            }
                                                        ],
                                                        "concreteType": "JiraProjectAction",
                                                        "kind": "LinkedField",
                                                        "name": "action",
                                                        "plural": false,
                                                        "selections": v1,
                                                        "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                                                    },
                                                    {
                                                        "alias": "canViewProjectConfig",
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "type",
                                                                "value": "VIEW_PROJECT_CONFIG"
                                                            }
                                                        ],
                                                        "concreteType": "JiraProjectAction",
                                                        "kind": "LinkedField",
                                                        "name": "action",
                                                        "plural": false,
                                                        "selections": v1,
                                                        "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
                                                    },
                                                    {
                                                        "alias": "jiraProjectType",
                                                        "kind": "ScalarField",
                                                        "name": "projectType"
                                                    },
                                                    {
                                                        "alias": "jiraProjectName",
                                                        "kind": "ScalarField",
                                                        "name": "name"
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "totalCount"
                                    },
                                    {
                                        "concreteType": "PageInfo",
                                        "kind": "LinkedField",
                                        "name": "pageInfo",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "hasNextPage"
                                            }
                                        ]
                                    },
                                    {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHasTotal"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "a30e2ea29e13322533e9aa9312e9d23c3c5eca25d82dc9aa20c67cd71bc18e4a",
                "metadata": {},
                "name": "ProjectDirectoryRefetchQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $146e9aaab261bbcb$var$node.hash = "eb5983a7159e6add64de193b1e14a2b6";
    var $146e9aaab261bbcb$export$2e2bcd8739ae039 = $146e9aaab261bbcb$var$node;
});
parcelRegister("fraBZ", function(module, exports) {
    $parcel$export(module.exports, "AnnouncerV2", ()=>$f73a83ae5cba96ea$export$a43c8a2f4981e0e);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $dqoiX;
    const $f73a83ae5cba96ea$var$NON_BREAKING_SPACE = '\u00A0';
    const $f73a83ae5cba96ea$export$a43c8a2f4981e0e = (0, $5uXOq.memo)(({ message: originalMessage, liveMode: liveMode = 'polite', shouldAnnounce: shouldAnnounce = true, testId: testId })=>{
        const previousMessage = (0, $5uXOq.useRef)('');
        const setAlternatePolite = (0, $5uXOq.useRef)(false);
        const addSuffix = (0, $5uXOq.useRef)(false);
        const [oldMessage, setOldMessage] = (0, $5uXOq.useState)('');
        const [newMessage, setNewMessage] = (0, $5uXOq.useState)('');
        (0, $5uXOq.useEffect)(()=>{
            let message = shouldAnnounce ? originalMessage : $f73a83ae5cba96ea$var$NON_BREAKING_SPACE;
            if (previousMessage.current === message) {
                addSuffix.current = !addSuffix.current;
                if (addSuffix.current) message += $f73a83ae5cba96ea$var$NON_BREAKING_SPACE;
            }
            setOldMessage(setAlternatePolite.current ? '' : message);
            setNewMessage(setAlternatePolite.current ? message : '');
            if (shouldAnnounce) setAlternatePolite.current = !setAlternatePolite.current;
            previousMessage.current = originalMessage;
        }, [
            originalMessage,
            shouldAnnounce
        ]);
        return $5uXOq.createElement("div", {
            "data-testid": testId,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm"
            ])
        }, $5uXOq.createElement((0, (0, parcelRequire("dqoiX")).ScreenReaderText), {
            role: "status",
            "aria-live": liveMode,
            "aria-relevant": "additions text",
            "aria-atomic": "true"
        }, oldMessage), $5uXOq.createElement((0, (0, parcelRequire("dqoiX")).ScreenReaderText), {
            role: "status",
            "aria-live": liveMode,
            "aria-relevant": "additions text",
            "aria-atomic": "true"
        }, newMessage));
    });
    const $f73a83ae5cba96ea$var$positionRelative = null;
});
parcelRegister("dqoiX", function(module, exports) {
    $parcel$export(module.exports, "visuallyHiddenStyles", ()=>$11827e0613b04108$export$8dd524ceb40e3d16);
    $parcel$export(module.exports, "ScreenReaderText", ()=>$11827e0613b04108$export$88fad6aa294f111c);
    $parcel$export(module.exports, "ScreenReaderCaption", ()=>$11827e0613b04108$export$200c8e5d652aa9af);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $11827e0613b04108$export$8dd524ceb40e3d16 = {
        clip: 'rect(1px, 1px, 1px, 1px)',
        clipPath: 'inset(50%)',
        height: '1px',
        width: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute'
    };
    const $11827e0613b04108$export$88fad6aa294f111c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_ogto7mnp _mkrz1wmp _4t3it94y _1bsbt94y _19pk1n1a _2hwx1n1a _otyr1n1a _18u01n1a _1reo15vq _18m915vq _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf _kqswstnw",
                __cmplp.className
            ])
        });
    });
    const $11827e0613b04108$export$200c8e5d652aa9af = (0, $5uXOq.forwardRef)(({ as: C = "caption", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_ogto7mnp _mkrz1wmp _4t3it94y _1bsbt94y _19pk1n1a _2hwx1n1a _otyr1n1a _18u01n1a _1reo15vq _18m915vq _ca0qidpf _u5f3idpf _n3tdidpf _19bvidpf _kqswstnw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("fc2Kp", function(module, exports) {
    $parcel$export(module.exports, "useDirectoryRefetch", ()=>$21bc8d8317f54c7d$export$6e338326a9da0df2);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $eiowN = parcelRequire("eiowN");
    const $21bc8d8317f54c7d$export$6e338326a9da0df2 = ({ refetch: refetch, query: query })=>{
        const environment = (0, $8Uumt.useRelayEnvironment)();
        const requestInFlight = (0, $5uXOq.useRef)();
        const [isFetching, setIsFetching] = (0, $5uXOq.useState)(false);
        const [errors, setErrors] = (0, $5uXOq.useState)(null);
        const refetchData = (0, $5uXOq.useCallback)(({ variables: variables, options: options })=>{
            requestInFlight.current?.unsubscribe();
            setIsFetching(true);
            setErrors(null);
            requestInFlight.current = (0, $eiowN.fetchQuery)(environment, query, variables, {
                fetchPolicy: options?.fetchPolicy || 'store-or-network'
            }).subscribe({
                complete: ()=>{
                    refetch(variables, {
                        fetchPolicy: 'store-only',
                        onComplete: ()=>{
                            setIsFetching(false);
                            options?.onComplete && options.onComplete();
                        }
                    });
                },
                error: (error)=>{
                    setIsFetching(false);
                    setErrors(error);
                    options?.onError && options.onError();
                }
            });
        }, [
            environment,
            query,
            refetch
        ]);
        return [
            {
                isFetching: isFetching,
                errors: errors
            },
            {
                refetchData: refetchData
            }
        ];
    };
});
parcelRegister("rq7Hc", function(module, exports) {
    $parcel$export(module.exports, "useQueryFilter", ()=>$4a107a6f2b74163c$export$d0b8acaa70818f20);
    var $5uXOq = parcelRequire("5uXOq");
    var $ajpes = parcelRequire("ajpes");
    var $ebyGx = parcelRequire("ebyGx");
    var $doZTA;
    const $4a107a6f2b74163c$export$d0b8acaa70818f20 = ({ override: override = true } = {})=>{
        const [{ query: query, match: match }, routerActions] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const syncQueryFilter = (0, $5uXOq.useCallback)((filter)=>{
            const normalizedFilter = Object.entries(filter).reduce((acc, [key, val])=>{
                if (val) acc[key] = val;
                return acc;
            }, {});
            !(0, ($parcel$interopDefault($ajpes)))(normalizedFilter, query) && routerActions.replace(`${match.path}?${(0, ($parcel$interopDefault($ebyGx))).stringify(override ? normalizedFilter : {
                ...query,
                ...normalizedFilter
            })}`);
        }, [
            override,
            match.path,
            query,
            routerActions
        ]);
        return syncQueryFilter;
    };
});
parcelRegister("ew1Lk", function(module, exports) {
    $parcel$export(module.exports, "DocumentTitle", ()=>$41b549fa18234081$export$dd5ad0b65cf665da);
    var $5uXOq = parcelRequire("5uXOq");
    var $6nkVm;
    class $41b549fa18234081$export$dd5ad0b65cf665da extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                TitleSubscriber: (0, (0, parcelRequire("6nkVm")).DocumentTitleSubscriber)
            };
        }
        shouldComponentUpdate(nextProps) {
            return nextProps.title !== this.props.title;
        }
        render() {
            const { title: title, TitleSubscriber: TitleSubscriber } = this.props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement(TitleSubscriber, null, ({ contextArray: contextArray }, { setTitle: setTitle, addDocumentTitle: addDocumentTitle, removeDocumentTitle: removeDocumentTitle })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6nkVm")).DocumentTitleHelper), {
                    title: title,
                    setTitle: setTitle,
                    addDocumentTitle: addDocumentTitle,
                    contextArray: contextArray,
                    removeDocumentTitle: removeDocumentTitle,
                    TitleSubscriber: TitleSubscriber
                }));
        }
    }
});
parcelRegister("88JI2", function(module, exports) {
    $parcel$export(module.exports, "pageLoad", ()=>$29e6f514c98d2ae6$export$791d520f7e8058a5);
    var $dGrdR;
    const $29e6f514c98d2ae6$export$791d520f7e8058a5 = (0, (0, parcelRequire("dGrdR")).metrics).pageLoad({
        key: 'projects-directory-v3',
        ssr: {
            doneAsFmp: true
        },
        featureFlags: [
            'projects-directory-entrypoint_sn3p0',
            'opsgenie-consolidation-transitioning-state_d7vm9'
        ]
    });
});
parcelRegister("5rqex", function(module, exports) {
    $parcel$export(module.exports, "checkAndEmitApdexSignal", ()=>$ab527e70454bab7e$export$3082c88b693a5230);
    $parcel$export(module.exports, "default", ()=>$ab527e70454bab7e$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "baseDefaultProps", ()=>$ab527e70454bab7e$export$9b3029ee6188a41f);
    var $5uXOq = parcelRequire("5uXOq");
    var $99gkn;
    var $aOr3t;
    var $1u4q7;
    var $co1wz;
    var $1AZST;
    var $6G6w0;
    var $3wLoG;
    var $gtdbK;
    var $zbAs4;
    var $3B7oW;
    const $ab527e70454bab7e$export$3082c88b693a5230 = (appName, metricKey)=>{
        var emitSignal;
    };
    class $ab527e70454bab7e$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isExpectedToHaveSsr: false,
                withMarks: [],
                extra: {},
                emitOnAnimationFrame: false
            };
        }
        render() {
            const { appName: appName, metricKey: metricKey, extra: extra, metric: metric, emitOnAnimationFrame: emitOnAnimationFrame } = this.props;
            $ab527e70454bab7e$export$3082c88b693a5230(appName, metricKey);
            const isUFODefined = (0, (0, parcelRequire("co1wz")).ff)('uip.ufo') && (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).loadingPageLoadId !== 'UNKNOWN';
            if (isUFODefined) (0, (0, parcelRequire("zbAs4")).addUFOSSRDoneAsFMPToInteraction)();
            if (metric) {
                (0, (0, parcelRequire("gtdbK")).addBM3SSRDoneAsFMPToInteraction)(metric);
                (0, (0, parcelRequire("3B7oW")).trackBM3FeatureFlagsAccessed)(metric);
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("99gkn")).default), {
                data: extra
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aOr3t")).UFOBM3TimingsToUFO), {
                marks: metric?.getData()?.marks,
                timings: metric?.getData()?.config?.timings
            }), isUFODefined ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3wLoG")).StopUfo), {
                appName: appName,
                customData: extra,
                key: (0, (0, parcelRequire("1u4q7")).getInteractionId)().current
            }) : metric && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6G6w0")).StopBrowserMetrics3), {
                metric: metric,
                customData: extra,
                emitOnAnimationFrame: emitOnAnimationFrame
            }));
        }
    }
    const $ab527e70454bab7e$export$9b3029ee6188a41f = $ab527e70454bab7e$export$2e2bcd8739ae039.defaultProps;
});
parcelRegister("aOr3t", function(module, exports) {
    $parcel$export(module.exports, "getBm3Timings", ()=>$a3e67858f0c2f8da$export$f02972da195a43bb);
    $parcel$export(module.exports, "UFOBM3TimingsToUFO", ()=>$a3e67858f0c2f8da$export$30f0b5e5acd268bf);
    $parcel$export(module.exports, "addBM3TimingsToUFO", ()=>$a3e67858f0c2f8da$export$d93d677ef2f33c5c);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $1u4q7;
    var $bFU4G;
    function $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timingConfigs) {
        const bm3Timings = {};
        if (!marks || !timingConfigs) return bm3Timings;
        timingConfigs.forEach((item)=>{
            if (!item.startMark || !item.endMark) return;
            const startTime = marks[item.startMark];
            if (!startTime) return;
            const endTime = marks[item.endMark];
            if (!endTime) return;
            bm3Timings[item.key] = {
                startTime: startTime,
                endTime: endTime
            };
        });
        return bm3Timings;
    }
    function $a3e67858f0c2f8da$export$30f0b5e5acd268bf({ marks: marks, timings: timings }) {
        const interactionContext = (0, $5uXOq.useContext)((0, (0, parcelRequire("1tcYS")).default));
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)().current;
        (0, $5uXOq.useMemo)(()=>{
            if (interactionContext != null && interactionId != null && marks != null && timings != null) {
                const interactionType = (0, (0, parcelRequire("bFU4G")).getCurrentInteractionType)(interactionId);
                if (interactionType === 'press') return;
                const bm3Timings = $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timings);
                interactionContext.addCustomTimings(bm3Timings);
            }
        }, [
            interactionContext,
            interactionId,
            marks,
            timings
        ]);
        return null;
    }
    function $a3e67858f0c2f8da$export$d93d677ef2f33c5c(marks, timingsConfig) {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)().current;
        if (interactionId) {
            const interactionType = (0, (0, parcelRequire("bFU4G")).getCurrentInteractionType)(interactionId);
            if (interactionType === 'press') return;
            const bm3Timings = $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timingsConfig);
            (0, (0, parcelRequire("bFU4G")).addCustomTiming)(interactionId, [], bm3Timings);
        }
    }
});
parcelRegister("6G6w0", function(module, exports) {
    $parcel$export(module.exports, "StopBrowserMetrics3", ()=>$84d0d168ebb35c8a$export$575e93c0f9905999);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $21pfT;
    var $x9xyg;
    var $7N30e;
    var $co1wz;
    var $4dRjY;
    var $2SVPM;
    var $6b6Tk;
    const $84d0d168ebb35c8a$var$emitMetrics = (metric, customData, stopTime)=>{
        !(0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
        (0, (0, parcelRequire("2SVPM")).setInitialPageLoadTimingFromPerformanceMarks)('batch-js-heritage.eval', 'jira.heritage_batch_file_eval:start', 'jira.heritage_batch_file_eval:end');
        if (stopTime !== undefined) {
            if (!(0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) {
                metric.mark((0, $ce556f125dee8174$export$99c08ec92759c71c));
                (0, (0, parcelRequire("x9xyg")).setMark)((0, $ce556f125dee8174$export$99c08ec92759c71c));
                (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', (0, $ce556f125dee8174$export$99c08ec92759c71c));
            }
            metric.stop({
                customData: customData,
                stopTime: stopTime
            });
        } else metric.stop({
            customData: customData
        });
        if ((0, (0, parcelRequire("6b6Tk")).isAutomaticExposureCollectionEnabled)()) (0, (0, parcelRequire("7N30e")).stopLowPriorityEventDelay)();
    };
    class $84d0d168ebb35c8a$export$575e93c0f9905999 extends (0, $5uXOq.Component) {
        componentDidMount() {
            const { metric: metric, customData: customData, emitOnAnimationFrame: emitOnAnimationFrame } = this.props;
            if (!metric) return;
            const start = (0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task') ? (0, (0, parcelRequire("21pfT")).browserMetrics).getPageLoadMetric().getData()?.start || 0 : 0;
            (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_START', {
                startTime: start
            });
            (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_END');
            (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_END');
            const bm3Data = metric.getData();
            if (emitOnAnimationFrame) {
                const stopTime = performance.now();
                (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
                if (bm3Data) this.context?.addApdex({
                    key: bm3Data.key,
                    startTime: bm3Data.start,
                    stopTime: stopTime
                });
                if (typeof queueMicrotask !== 'undefined') queueMicrotask(()=>{
                    (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_TASK_END');
                    (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_TASK', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_TASK_END');
                });
                requestAnimationFrame(()=>{
                    if ((0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) {
                        metric.mark((0, $ce556f125dee8174$export$99c08ec92759c71c));
                        (0, (0, parcelRequire("x9xyg")).setMark)((0, $ce556f125dee8174$export$99c08ec92759c71c));
                        (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', (0, $ce556f125dee8174$export$99c08ec92759c71c));
                    }
                    if ((0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) setTimeout(()=>{
                        $84d0d168ebb35c8a$var$emitMetrics(metric, customData, stopTime);
                    }, 500);
                    else $84d0d168ebb35c8a$var$emitMetrics(metric, customData, stopTime);
                });
            } else {
                if (bm3Data) this.context?.addApdex({
                    key: bm3Data.key,
                    startTime: bm3Data.start,
                    stopTime: performance.now()
                });
                (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
                $84d0d168ebb35c8a$var$emitMetrics(metric, customData);
            }
        }
        static{
            this.contextType = (0, (0, parcelRequire("1tcYS")).default);
        }
        render() {
            return null;
        }
    }
});
parcelRegister("4dRjY", function(module, exports) {
    $parcel$export(module.exports, "PRODUCT_START_MARK", ()=>$9953c23a4f71adac$export$8e54fca0a680fdfd);
    $parcel$export(module.exports, "MarkProductStart", ()=>$9953c23a4f71adac$export$2a999c5f84501025);
    var $x9xyg;
    const $9953c23a4f71adac$export$8e54fca0a680fdfd = 'PRODUCT_START';
    const $9953c23a4f71adac$export$2a999c5f84501025 = ()=>{
        if (!false) {
            performance.clearMarks($9953c23a4f71adac$export$8e54fca0a680fdfd);
            (0, (0, parcelRequire("x9xyg")).setMark)($9953c23a4f71adac$export$8e54fca0a680fdfd);
        }
        return null;
    };
});
const $ce556f125dee8174$export$56940a9488e85199 = 'jira.fe.spa-';
const $ce556f125dee8174$export$45c4b6bf7935c69a = `${$ce556f125dee8174$export$56940a9488e85199}start`;
const $ce556f125dee8174$export$135c96ab5afee429 = 'ssr.spa.rendered';
const $ce556f125dee8174$export$ff834eccc35b9089 = 'appBundleReady';
const $ce556f125dee8174$export$86b05e34d462ead8 = 'prefetchApiReady';
const $ce556f125dee8174$export$d6a604a7d9a390fb = 'prefetchApiStart';
const $ce556f125dee8174$export$1211adfff27a31fe = 'issueViewInitialDataReady';
const $ce556f125dee8174$export$3ae43cc1782d058f = 'jswRenderStart';
const $ce556f125dee8174$export$363f6703ffa600be = 'jswCreateReduxStoreStart';
const $ce556f125dee8174$export$e87b74567b79c8 = 'jswCreateReduxStoreEnd';
const $ce556f125dee8174$export$99fe642459a8b02 = 'jswTransformCriticalDataStart';
const $ce556f125dee8174$export$ab1f5705fc8e0571 = 'jswTransformCriticalDataEnd';
const $ce556f125dee8174$export$8c3a9046da692c35 = 'jswReduxSoftwareAppLoadedStart';
const $ce556f125dee8174$export$5aa497d1efdcc5bf = 'jswReduxSoftwareAppLoadedEnd';
const $ce556f125dee8174$export$99c08ec92759c71c = 'tti-raf-mark';
const $ce556f125dee8174$export$48552baa9db8ce1d = 'tti-raf';
parcelRegister("3wLoG", function(module, exports) {
    $parcel$export(module.exports, "StopUfo", ()=>$bc9c50f4292c4530$export$d91154f099475c5);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $1AZST;
    class $bc9c50f4292c4530$export$d91154f099475c5 extends (0, $5uXOq.Component) {
        componentDidMount() {
            const { customData: customData, appName: appName } = this.props;
            (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).success({
                force: true,
                metadata: customData
            });
            const name = appName || (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).loadingPageLoadId;
            if (name) this.context?.addApdex({
                key: name,
                stopTime: performance.now()
            });
        }
        static{
            this.contextType = (0, (0, parcelRequire("1tcYS")).default);
        }
        render() {
            return null;
        }
    }
});
parcelRegister("gtdbK", function(module, exports) {
    $parcel$export(module.exports, "addBM3SSRDoneAsFMPToInteraction", ()=>$9088c71f7c9189f4$export$bf239ac2bfb04967);
    var $1u4q7;
    var $bFU4G;
    function $9088c71f7c9189f4$export$bf239ac2bfb04967(metric) {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = metric.getData();
        const config = data?.config;
        if (config && data?.isInitial) (0, (0, parcelRequire("bFU4G")).addMetadata)(currentInteractionId, {
            __legacy__bm3ConfigSSRDoneAsFmp: config.ssr?.doneAsFmp || false
        });
    }
});
parcelRegister("zbAs4", function(module, exports) {
    $parcel$export(module.exports, "addUFOSSRDoneAsFMPToInteraction", ()=>$30d390cd1767d57f$export$e63862cd888fe05a);
    var $1u4q7;
    var $bFU4G;
    var $1AZST;
    var $49z18;
    async function $30d390cd1767d57f$export$e63862cd888fe05a() {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = await (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).exportData();
        const fmpMark = data.metrics.marks.find((mark)=>mark.name === 'fmp') || (0, (0, parcelRequire("49z18")).payloadPublisher).ssr?.getDoneMark();
        if (data.initial && fmpMark) (0, (0, parcelRequire("bFU4G")).addMetadata)(currentInteractionId, {
            __legacy__ufoConfigSSRDoneAsFmp: true
        });
    }
});
parcelRegister("3B7oW", function(module, exports) {
    $parcel$export(module.exports, "trackBM3FeatureFlagsAccessed", ()=>$afa764d83f81f55e$export$f00f1e874ce869e9);
    var $1u4q7;
    var $y7eb2;
    const $afa764d83f81f55e$var$startsAsObject = (value)=>{
        try {
            if (value.charAt(0) === '{') return true;
        } catch (e) {
            return false;
        }
        return false;
    };
    const $afa764d83f81f55e$var$isObject = (value)=>value && typeof value === 'object' && value.constructor === Object;
    const $afa764d83f81f55e$var$isString = (input)=>{
        let result;
        try {
            result = typeof input === 'string' || input instanceof String;
        } catch (err) {
            result = false;
        }
        return result;
    };
    const $afa764d83f81f55e$export$7b4abba432e57989 = (value)=>$afa764d83f81f55e$var$isString(value) && $afa764d83f81f55e$var$startsAsObject(value) || $afa764d83f81f55e$var$isObject(value);
    const $afa764d83f81f55e$export$fcc03ee4c944962f = (featureFlagValue)=>$afa764d83f81f55e$export$7b4abba432e57989(featureFlagValue) ? 'non_boolean' : featureFlagValue;
    const $afa764d83f81f55e$export$d2818866b4acbea4 = new Map();
    const $afa764d83f81f55e$export$f00f1e874ce869e9 = (metric)=>{
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = metric.getData();
        const config = data?.config;
        if (config && config?.featureFlags?.length) {
            const allFeatureFlags = (0, (0, parcelRequire("y7eb2")).default)();
            config.featureFlags.forEach((featureFlagName)=>{
                const value = allFeatureFlags[featureFlagName];
                if (value === undefined) return;
                const featureFlagValue = $afa764d83f81f55e$export$fcc03ee4c944962f(value);
                $afa764d83f81f55e$export$d2818866b4acbea4.set(featureFlagName, featureFlagValue);
            });
        }
    };
});
parcelRegister("9dbF6", function(module, exports) {
    $parcel$export(module.exports, "SubProductUpdater", ()=>$5979c081afa32bfa$export$eb40d3df56aa5c53);
    var $5uXOq = parcelRequire("5uXOq");
    var $bNvU3;
    var $3iTiR;
    var $ero6m;
    var $54I24;
    var $8OHH4;
    const $5979c081afa32bfa$export$eb40d3df56aa5c53 = ({ allowMonolithDeferral: allowMonolithDeferral = false, ...props })=>{
        const subProduct = (0, (0, parcelRequire("8OHH4")).isSubProductProps)(props) ? props.subProduct : (0, (0, parcelRequire("ero6m")).getSubProductFromProjectType)(props.projectType);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bNvU3")).default), {
            op: (0, (0, parcelRequire("54I24")).default),
            args: [
                subProduct,
                allowMonolithDeferral
            ]
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3iTiR")).default), {
            subProduct: subProduct
        }));
    };
});
parcelRegister("3iTiR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1214b0731559b377$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $1u3Jv;
    var $hFChZ;
    var $1214b0731559b377$export$2e2bcd8739ae039 = ({ subProduct: subProduct })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1u3Jv")).default), {
            op: (0, (0, parcelRequire("hFChZ")).default),
            args: [
                subProduct
            ]
        });
});
parcelRegister("hFChZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0e606430ead76cd8$export$2e2bcd8739ae039);
    var $dJl1T;
    var $eusud;
    var $0e606430ead76cd8$export$2e2bcd8739ae039 = (subProduct)=>{
        (0, (0, parcelRequire("eusud")).performGetRequest)(`/rest/internal/2/mauTag/${subProduct}`).catch((err)=>{
            (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.tag-mau.tag-mau-event', 'Error connecting to monolith mau tag endpoint', err);
        });
    };
});
parcelRegister("ero6m", function(module, exports) {
    $parcel$export(module.exports, "getSubProductFromProjectType", ()=>$0997694844c8a9e7$export$b1fe420ed15dff7b);
    var $16v4H;
    const $0997694844c8a9e7$export$b1fe420ed15dff7b = (projectType)=>{
        if (projectType === 'product_discovery') return 'polaris';
        return (0, (0, parcelRequire("16v4H")).subProductFromProjectType)(projectType);
    };
});
parcelRegister("54I24", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bcbbcd48ca398ed4$export$2e2bcd8739ae039);
    var $7T84i;
    var $9B86Q;
    var $cQZXC;
    var $dJl1T;
    var $fjcgS;
    const $bcbbcd48ca398ed4$var$maybeDelayAndRace = (maybeWaitPromise, getDefaultPromise, getRacePromise)=>maybeWaitPromise ? Promise.race([
            maybeWaitPromise.then(getDefaultPromise),
            getRacePromise()
        ]) : getDefaultPromise();
    var $bcbbcd48ca398ed4$export$2e2bcd8739ae039 = async (subProduct, allowMonolithDeferral)=>{
        (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
            client.setSubProduct(subProduct);
            if (subProduct === 'serviceDesk') client.setUIViewedAttributes({
                solutions: [
                    (0, $86344d58e71e925a$export$c8b406ad8ee0dab9)
                ]
            });
            else client.removeUIViewedAttribute('solutions');
        });
        $bcbbcd48ca398ed4$var$maybeDelayAndRace(allowMonolithDeferral ? (0, (0, parcelRequire("cQZXC")).waitForHeritage)() : null, ()=>(0, (0, parcelRequire("7T84i")).loadBridgeStrict)({
                name: 'jira/analytics/sub-product-resolver',
                wrmKeys: [
                    'wrc!com.atlassian.jira.jira-client-analytics-plugin:analytics-web-client'
                ]
            }), ()=>(0, (0, parcelRequire("9B86Q")).loadBridgeWeak)({
                name: 'jira/analytics/sub-product-resolver'
            })).then((subProductResolver)=>{
            subProductResolver.setSubProduct(subProduct);
        }).catch((e)=>(0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.analytics-sub-product.sub-product-updater', 'Error connecting to monolith sub product resolver', e));
    };
});
const $86344d58e71e925a$export$c8b406ad8ee0dab9 = 'jiraServiceManagement';
parcelRegister("8OHH4", function(module, exports) {
    $parcel$export(module.exports, "isSubProductProps", ()=>$701d07b56c3e3574$export$c3063d6d7a8c075f);
    const $701d07b56c3e3574$export$c3063d6d7a8c075f = (props)=>'subProduct' in props && typeof props.subProduct === 'string';
    const $701d07b56c3e3574$export$81da4a7e1ba1dcee = (props)=>'projectType' in props && typeof props.projectType === 'string';
});
parcelRegister("fhVq7", function(module, exports) {
    $parcel$export(module.exports, "ScrollablePage", ()=>$501f0ba909d41168$export$8d7a30196b47fe34);
    $parcel$export(module.exports, "PageLayout", ()=>$501f0ba909d41168$export$6d1402fb58384e4f);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    const $501f0ba909d41168$export$8d7a30196b47fe34 = ({ children: children, ...rest })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            testId: rest['data-testid'] || 'spa-apps-page-layout.page.scrollable-page',
            xcss: $501f0ba909d41168$var$scrollablePageStyles,
            paddingBlock: "space.0",
            paddingInline: "space.500"
        }, children);
    const $501f0ba909d41168$export$6d1402fb58384e4f = ({ children: children, hideMargin: hideMargin, ...rest })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            testId: rest['data-testid'] || 'spa-apps-page-layout.page.page-layout',
            xcss: hideMargin ? $501f0ba909d41168$var$pageLayoutWithoutMarginStyles : $501f0ba909d41168$var$pageLayoutWithMarginStyles
        }, children);
    const $501f0ba909d41168$var$scrollablePageStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        minWidth: '800px',
        margin: 'space.0'
    });
    const $501f0ba909d41168$var$pageLayoutWithoutMarginStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        margin: 'space.0'
    });
    const $501f0ba909d41168$var$pageLayoutWithMarginStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlock: '0',
        marginInline: 'space.500'
    });
});
parcelRegister("dPvGX", function(module, exports) {
    $parcel$export(module.exports, "useScopedFilter", ()=>$3f2dd45a7998991e$export$c0c3d622c43d72e);
    $parcel$export(module.exports, "useFilter", ()=>$3f2dd45a7998991e$export$3274cf84b703fff);
    $parcel$export(module.exports, "FilterContainer", ()=>$3f2dd45a7998991e$export$2f96f6f204f87534);
    var $7OwSg;
    var $dZhZb;
    var $gkT9B;
    var $8pIiT;
    const { useScopedFilter: $3f2dd45a7998991e$export$c0c3d622c43d72e, useFilter: $3f2dd45a7998991e$export$3274cf84b703fff, FilterContainer: $3f2dd45a7998991e$export$2f96f6f204f87534 } = (0, (0, parcelRequire("7OwSg")).createUseFilter)('ProjectsDirectoryFilterState', {
        getInitialState: ({ routeQuery: routeQuery })=>(0, (0, parcelRequire("dZhZb")).matchAliases)((0, (0, parcelRequire("gkT9B")).filterInitialState), (0, (0, parcelRequire("8pIiT")).sanitizeLegacyQuery)(routeQuery), (0, (0, parcelRequire("gkT9B")).filterAliasMap))
    });
});
parcelRegister("he80v", function(module, exports) {
    $parcel$export(module.exports, "AsyncEmptyView", ()=>$723cb9a2d72bf4b7$export$548ce7324e0de587);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    const $723cb9a2d72bf4b7$var$LazyEmptyView = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("60iUS")).then(({ EmptyView: EmptyView })=>EmptyView), {
        ssr: false,
        moduleId: "./src/packages/global-pages/directories/projects-directory-v3/src/ui/page-layout/empty-view/index.tsx"
    });
    const $723cb9a2d72bf4b7$export$548ce7324e0de587 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "empty-view",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($723cb9a2d72bf4b7$var$LazyEmptyView, props));
});
parcelRegister("60iUS", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("asLLC").then(()=>parcelRequire('f8bcI'));
});
parcelRegister("hP4DQ", function(module, exports) {
    $parcel$export(module.exports, "PageErrorState", ()=>$4c4c6215a1d8edd1$export$93e16ad79580c69);
    var $5uXOq = parcelRequire("5uXOq");
    var $eae50;
    var $koVbs;
    var $7cGZT;
    const $4c4c6215a1d8edd1$export$93e16ad79580c69 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const onRetry = (0, $5uXOq.useCallback)(()=>window.location.reload(), []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eae50")).default), {
            header: formatMessage((0, (0, parcelRequire("7cGZT")).default).header),
            description: formatMessage((0, (0, parcelRequire("7cGZT")).default).description),
            onRetry: onRetry
        });
    };
});
parcelRegister("eae50", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$da809c0c454ce05f$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $hTEpF;
    var $co1wz;
    var $4R6GH;
    var $az4zD;
    var $lB1Xg = parcelRequire("lB1Xg");
    var $9Le4I = parcelRequire("9Le4I");
    var $63TJV;
    function $da809c0c454ce05f$export$edf27be85e5f6da0(props) {
        const { header: header, description: description, size: size, onRetry: onRetry, isLoading: isLoading, intl: { formatMessage: formatMessage }, errorImage: errorImage, isSubtle: isSubtle, imageWidth: imageWidth, maxImageHeight: maxImageHeight, retryMessage: retryMessage, renderErrorImage: renderErrorImage, headingLevel: headingLevel } = props;
        const buttonAppearance = isSubtle ? 'default' : 'primary';
        const defaultImageUrl = isSubtle ? (0, ($parcel$interopDefault($lB1Xg))) : (0, ($parcel$interopDefault($9Le4I)));
        const fallbackImage = errorImage || defaultImageUrl;
        let imageUrl;
        if ((0, (0, parcelRequire("co1wz")).ff)('jfe-asset-consolidation-itsm-agni_po996')) {
            if (renderErrorImage) imageUrl = undefined;
            else imageUrl = fallbackImage;
        } else imageUrl = fallbackImage;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            testId: "common-components-error-state.empty-state",
            size: size,
            imageWidth: imageWidth,
            imageUrl: imageUrl,
            header: header,
            description: description,
            isLoading: isLoading,
            maxImageHeight: maxImageHeight,
            primaryAction: !onRetry ? null : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: buttonAppearance,
                isDisabled: isLoading,
                onClick: onRetry
            }, formatMessage(retryMessage)),
            renderImage: renderErrorImage,
            headingLevel: (0, (0, parcelRequire("4R6GH")).fg)('blu-4174-enghealth-13917-filters-dir-actions-title') ? headingLevel : undefined
        });
    }
    $da809c0c454ce05f$export$edf27be85e5f6da0.defaultProps = {
        header: '',
        size: 'wide',
        isLoading: false,
        isSubtle: false,
        retryMessage: (0, (0, parcelRequire("63TJV")).default).retry
    };
    var $da809c0c454ce05f$export$2e2bcd8739ae039 = (0, (0, parcelRequire("az4zD")).injectIntlV2)($da809c0c454ce05f$export$edf27be85e5f6da0);
});
parcelRegister("lB1Xg", function(module, exports) {
    module.exports = new URL("error-subtle.580d9941.svg", import.meta.url).toString();
});
parcelRegister("9Le4I", function(module, exports) {
    module.exports = new URL("error.4fa1b24b.svg", import.meta.url).toString();
});
parcelRegister("63TJV", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$cbeb04399739f055$export$2e2bcd8739ae039);
    var $7VHMR;
    var $cbeb04399739f055$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        retry: {
            "id": "common.components.error-state.retry-action",
            "defaultMessage": "Try again"
        }
    });
});
parcelRegister("7cGZT", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2c2b48fa67913631$export$2e2bcd8739ae039);
    var $7VHMR;
    var $2c2b48fa67913631$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        header: {
            "id": "projects-directory-v3.page-layout.error-state.header",
            "defaultMessage": "An error occurred"
        },
        description: {
            "id": "projects-directory-v3.page-layout.error-state.description",
            "defaultMessage": "There was a problem loading your projects. Try again or contact your administrator for support."
        }
    });
});
parcelRegister("jNWQj", function(module, exports) {
    $parcel$export(module.exports, "Header", ()=>$d000ed073d174a52$export$8b251419efc915eb);
    var $90eJM;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $blYup;
    var $koVbs;
    var $cZpsT;
    var $8hZvl;
    var $4OVbb;
    var $hgRkd;
    const $d000ed073d174a52$export$8b251419efc915eb = ({ dataRef: dataRef, isAdminSettingsContext: isAdminSettingsContext })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { jira: jira } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("90eJM")).default), dataRef);
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("blYup")).Header), {
            title: isAdminSettingsContext ? formatMessage((0, (0, parcelRequire("4OVbb")).default).adminHeaderCaption) : formatMessage((0, (0, parcelRequire("4OVbb")).default).headerCaption),
            actions: jira ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8hZvl")).CreateButton), {
                dataRef: jira
            }), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hgRkd")).OpenTemplatesPanelButton), {
                dataRef: jira
            })) : undefined,
            isAdminSettingsContext: isAdminSettingsContext
        });
    };
});
parcelRegister("90eJM", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6efc2c6f7c108657$export$2e2bcd8739ae039);
    const $6efc2c6f7c108657$var$node = {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAnonymous"
            }
        ],
        "kind": "Fragment",
        "name": "header_projectsDirectoryV3",
        "selections": [
            {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "createProjectAction_projectsDirectoryV3_CreateButton"
                    },
                    {
                        "condition": "isAnonymous",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                            {
                                "kind": "FragmentSpread",
                                "name": "openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton"
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "Query"
    };
    $6efc2c6f7c108657$var$node.hash = "22f237392203128d7ea8a45840fcb1ff";
    var $6efc2c6f7c108657$export$2e2bcd8739ae039 = $6efc2c6f7c108657$var$node;
});
parcelRegister("blYup", function(module, exports) {
    $parcel$export(module.exports, "Header", ()=>$63df1a58614137f3$export$8b251419efc915eb);
    var $5uXOq = parcelRequire("5uXOq");
    var $ldJSL;
    var $fVnbM;
    var $tvuR8;
    var $57MwZ;
    var $d8lE2;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $ca01T;
    const $63df1a58614137f3$export$8b251419efc915eb = ({ title: title, actions: actions, children: children, isAdminSettingsContext: isAdminSettingsContext })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const searchActionLabel = formatMessage((0, (0, parcelRequire("ca01T")).default).searchJiraAdmin);
        const { open: open } = (0, (0, parcelRequire("d8lE2")).useActionsCommandPalette)();
        const onClick = (0, $5uXOq.useCallback)(()=>{
            open();
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'searchActionButton'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'dotDialog');
        }, [
            createAnalyticsEvent,
            open
        ]);
        const shifterAction = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ldJSL")).default), null, actions, isAdminSettingsContext && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("tvuR8"))))), {
                label: "",
                size: "small"
            }),
            onClick: onClick
        }, searchActionLabel));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("57MwZ")).default), {
            actions: shifterAction
        }, title), children);
    };
});
parcelRegister("57MwZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9694e728fd1ea060$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $eeGIy;
    const $9694e728fd1ea060$var$PageHeader = ({ innerRef: innerRef, breadcrumbs: breadcrumbs, actions: actions, bottomBar: bottomBar, children: children, id: id, disableTitleStyles: disableTitleStyles = false, truncateTitle: truncateTitle = false })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).OuterWrapper), null, breadcrumbs, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).TitleWrapper), {
            truncateTitle: truncateTitle
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).TitleContainer), {
            truncateTitle: truncateTitle
        }, disableTitleStyles ? children : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).StyledTitleWrapper), {
            ref: innerRef,
            truncateTitle: truncateTitle,
            id: id
        }, children)), actions && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).ActionsWrapper), null, actions)), bottomBar && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).BottomBarWrapper), null, " ", bottomBar, " "));
    };
    var $9694e728fd1ea060$export$2e2bcd8739ae039 = $9694e728fd1ea060$var$PageHeader;
});
parcelRegister("eeGIy", function(module, exports) {
    $parcel$export(module.exports, "OuterWrapper", ()=>$0c196d37a4e8266c$export$edaaad985a878983);
    $parcel$export(module.exports, "StyledTitleWrapper", ()=>$0c196d37a4e8266c$export$284be1ee64a3465c);
    $parcel$export(module.exports, "TitleWrapper", ()=>$0c196d37a4e8266c$export$585a3b23e887288b);
    $parcel$export(module.exports, "TitleContainer", ()=>$0c196d37a4e8266c$export$35e09ba17cc51c69);
    $parcel$export(module.exports, "ActionsWrapper", ()=>$0c196d37a4e8266c$export$c8223871eecc8f3a);
    $parcel$export(module.exports, "BottomBarWrapper", ()=>$0c196d37a4e8266c$export$6330225854c30dc);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $dC5iT;
    var $8CUq3;
    function $0c196d37a4e8266c$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $0c196d37a4e8266c$var$truncateStyles = {
        name: "13oenf9",
        styles: "overflow-x:hidden;overflow-y:hidden;text-overflow:ellipsis;white-space:nowrap"
    };
    const $0c196d37a4e8266c$var$outerStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        margin: `${"var(--ds-space-300, 24px)"} 0 ${"var(--ds-space-200, 16px)"} 0`
    });
    const $0c196d37a4e8266c$var$titleStyles = {
        name: "1o56te0",
        styles: "color:var(--ds-text, #172B4D);font:var(--ds-font-heading-large, normal 500 24px/28px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif);letter-spacing:normal;margin-block-start:0;outline:none"
    };
    const $0c196d37a4e8266c$var$titleWrapperStyles = {
        name: "1wb76vg",
        styles: "display:flex;align-items:flex-start;flex-wrap:wrap"
    };
    const $0c196d37a4e8266c$var$titleWrapperTruncateStyles = {
        name: "14gl6v0",
        styles: "flex-wrap:nowrap"
    };
    const $0c196d37a4e8266c$var$titleContainerStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        minWidth: 0,
        maxWidth: '100%',
        flex: '1 0 auto',
        flexShrink: undefined,
        marginBlockEnd: "var(--ds-space-100, 8px)"
    });
    const $0c196d37a4e8266c$var$actionStyles = {
        name: "1ianfu6",
        styles: "max-width:100%;flex:0 0 auto;margin-block-end:var(--ds-space-100, 8px);margin-inline-start:auto;padding-inline-start:var(--ds-space-400, 32px);white-space:nowrap;>{text-align:right;}"
    };
    const $0c196d37a4e8266c$var$titleContainerTruncateStyles = {
        name: "s2a4s9",
        styles: "flex-shrink:1"
    };
    const $0c196d37a4e8266c$var$bottomBarStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlockStart: 'space.200'
    });
    const $0c196d37a4e8266c$export$edaaad985a878983 = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: $0c196d37a4e8266c$var$outerStyles
        }, children);
    };
    const $0c196d37a4e8266c$export$284be1ee64a3465c = (0, ($parcel$interopDefault($5uXOq))).forwardRef(({ children: children, id: id, truncateTitle: truncateTitle }, ref)=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("h1", {
            css: [
                $0c196d37a4e8266c$var$titleStyles,
                truncateTitle && $0c196d37a4e8266c$var$truncateStyles
            ],
            ref: ref,
            tabIndex: -1,
            id: id
        }, children);
    });
    const $0c196d37a4e8266c$export$585a3b23e887288b = ({ children: children, truncateTitle: truncateTitle })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $0c196d37a4e8266c$var$titleWrapperStyles,
                truncateTitle && $0c196d37a4e8266c$var$titleWrapperTruncateStyles
            ]
        }, children);
    };
    const $0c196d37a4e8266c$export$35e09ba17cc51c69 = ({ children: children, truncateTitle: truncateTitle })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $0c196d37a4e8266c$var$titleContainerStyles,
                truncateTitle && $0c196d37a4e8266c$var$titleContainerTruncateStyles
            ]
        }, children);
    };
    const $0c196d37a4e8266c$export$c8223871eecc8f3a = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: $0c196d37a4e8266c$var$actionStyles
        }, children);
    };
    const $0c196d37a4e8266c$export$6330225854c30dc = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $0c196d37a4e8266c$var$bottomBarStyles
        }, children);
    };
});
parcelRegister("d8lE2", function(module, exports) {
    $parcel$export(module.exports, "useCommandPalette", ()=>$f75514d959bfcca8$export$6b58f9610017fbb7);
    $parcel$export(module.exports, "useActionsCommandPalette", ()=>$f75514d959bfcca8$export$72aa7bc7c6a83c36);
    $parcel$export(module.exports, "useCommandPaletteAnalyticsAttributes", ()=>$f75514d959bfcca8$export$1f90c8f89f98110e);
    $parcel$export(module.exports, "useCommandPaletteIsOpen", ()=>$f75514d959bfcca8$export$cc979be9b69236df);
    $parcel$export(module.exports, "useCommandPaletteHasChildPopup", ()=>$f75514d959bfcca8$export$a18633b655403b12);
    $parcel$export(module.exports, "useCommandPaletteIsLoaded", ()=>$f75514d959bfcca8$export$af8fd8cd4b048315);
    $parcel$export(module.exports, "useCommandPaletteSessionId", ()=>$f75514d959bfcca8$export$7422170a403e909);
    $parcel$export(module.exports, "useCommandPaletteExpandedCommand", ()=>$f75514d959bfcca8$export$282aa48e4a3afada);
    $parcel$export(module.exports, "useCommandPaletteSearch", ()=>$f75514d959bfcca8$export$96819dfb22ad05d3);
    $parcel$export(module.exports, "useCommandPaletteRegistryCurrentAttribution", ()=>$f75514d959bfcca8$export$f893891deedab3c6);
    $parcel$export(module.exports, "useCommandPaletteUsePerformModeState", ()=>$f75514d959bfcca8$export$bbc05723eb144e03);
    var $5uXOq = parcelRequire("5uXOq");
    var $3sQ5x;
    var $6dDSs;
    var $iXqE5;
    var $gM5ou;
    var $2sRNe;
    const $f75514d959bfcca8$var$initialState = {
        commandPaletteSessionId: '',
        isOpen: false,
        isLoaded: false,
        search: '',
        activeIndex: 0,
        isSearchFieldFocused: false,
        commands: [],
        parentCommand: [],
        sections: [],
        isDisabled: false,
        registry: {},
        activeRegistry: {
            commandRegistry: {},
            registryIds: []
        },
        onClosePendingCommandAction: undefined,
        isLoadChildrenError: false
    };
    const $f75514d959bfcca8$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: $f75514d959bfcca8$var$initialState,
        actions: (0, parcelRequire("2sRNe")).actions,
        name: 'commandPalette'
    });
    const $f75514d959bfcca8$export$6b58f9610017fbb7 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store);
    const $f75514d959bfcca8$export$72aa7bc7c6a83c36 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($f75514d959bfcca8$var$store);
    const $f75514d959bfcca8$export$e144a44b650635f4 = (ComponentToRender)=>(props)=>{
            const commandPalette = $f75514d959bfcca8$export$6b58f9610017fbb7();
            return (0, ($parcel$interopDefault($5uXOq))).createElement(ComponentToRender, {
                ...props,
                commandPalette: commandPalette
            });
        };
    const $f75514d959bfcca8$export$56f06313122d0aac = ({ search: search, commandPaletteSessionId: commandPaletteSessionId, expandedCommand: expandedCommand, registry: registry, activeRegistry: activeRegistry })=>({
            wordCount: (0, (0, parcelRequire("gM5ou")).getWordCount)(search),
            queryLength: search.length,
            queryHash: (0, (0, parcelRequire("gM5ou")).getQueryHash)(search),
            commandPaletteSessionId: commandPaletteSessionId,
            cpMenu: expandedCommand?.id || 'rootMenu',
            ...(0, (0, parcelRequire("gM5ou")).getExpandedCommandRegistryAnalytics)(registry, activeRegistry, expandedCommand?.id) || {},
            activeRegistryIds: activeRegistry.registryIds,
            activeRegistryIdsLength: activeRegistry.registryIds.length,
            activeRegistryCommandsLength: Object.keys(activeRegistry.commandRegistry).length,
            relevancyModel: 'v2-string-similarity'
        });
    const $f75514d959bfcca8$export$1f90c8f89f98110e = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: $f75514d959bfcca8$export$56f06313122d0aac
    });
    const $f75514d959bfcca8$export$cc979be9b69236df = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isOpen: isOpen })=>({
                isOpen: isOpen
            })
    });
    const $f75514d959bfcca8$export$a18633b655403b12 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($f75514d959bfcca8$var$store, {
        selector: (state)=>state.hasPopupOverlay
    });
    const $f75514d959bfcca8$export$af8fd8cd4b048315 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isLoaded: isLoaded })=>({
                isLoaded: isLoaded
            })
    });
    const $f75514d959bfcca8$export$4372697329bd9cbe = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ isDisabled: isDisabled })=>({
                isDisabled: isDisabled
            })
    });
    const $f75514d959bfcca8$export$7422170a403e909 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ commandPaletteSessionId: commandPaletteSessionId })=>({
                commandPaletteSessionId: commandPaletteSessionId
            })
    });
    const $f75514d959bfcca8$export$282aa48e4a3afada = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ expandedCommand: expandedCommand, isOpen: isOpen })=>({
                expandedCommand: expandedCommand,
                isOpen: isOpen
            })
    });
    const $f75514d959bfcca8$export$96819dfb22ad05d3 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ search: search })=>({
                search: search
            })
    });
    const $f75514d959bfcca8$export$a40e8ae3e53feab0 = (0, (0, parcelRequire("6dDSs")).createSelector)(({ registry: registry, activeRegistry: activeRegistry, expandedCommand: expandedCommand })=>({
            registry: registry,
            activeRegistry: activeRegistry,
            expandedCommand: expandedCommand
        }), ()=>undefined, ({ registry: registry, activeRegistry: activeRegistry, expandedCommand: expandedCommand })=>{
        if (!expandedCommand) return undefined;
        const registryData = (0, (0, parcelRequire("gM5ou")).getRegistryData)(registry, activeRegistry, expandedCommand.id);
        return {
            registryData: registryData,
            analyticsAttributes: registryData
        };
    });
    const $f75514d959bfcca8$export$f893891deedab3c6 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: $f75514d959bfcca8$export$a40e8ae3e53feab0
    });
    const $f75514d959bfcca8$export$bbc05723eb144e03 = (0, (0, parcelRequire("3sQ5x")).createHook)($f75514d959bfcca8$var$store, {
        selector: ({ commands: commands, search: search, expandedCommand: expandedCommand, childResult: childResult, registry: registry, activeRegistry: activeRegistry })=>({
                commands: commands,
                search: search,
                expandedCommand: expandedCommand,
                childResult: childResult,
                registry: registry,
                activeRegistry: activeRegistry
            })
    });
});
parcelRegister("gM5ou", function(module, exports) {
    $parcel$export(module.exports, "getRegistryData", ()=>$cf289af0c139712c$export$6a1ed481c04a3ae6);
    $parcel$export(module.exports, "getExpandedCommandRegistryAnalytics", ()=>$cf289af0c139712c$export$eb055284806da6e5);
    $parcel$export(module.exports, "getShortcutSpecialCharacters", ()=>$cf289af0c139712c$export$e6994b845b967732);
    $parcel$export(module.exports, "removeShortcutSpecialCharacters", ()=>$cf289af0c139712c$export$cd2a5827eae5f4cf);
    $parcel$export(module.exports, "mergeSections", ()=>$cf289af0c139712c$export$389b0273703d5c48);
    $parcel$export(module.exports, "mergeChildResults", ()=>$cf289af0c139712c$export$60a91e1b94ab4fef);
    $parcel$export(module.exports, "getAnalyticsAttributesFromState", ()=>$cf289af0c139712c$export$4023cd53e5e5fcb8);
    $parcel$export(module.exports, "isSecondaryTrigger", ()=>$cf289af0c139712c$export$daa5beabdf8d9fc3);
    $parcel$export(module.exports, "hasChildren", ()=>$cf289af0c139712c$export$bd5ff50f0e53d41c);
    $parcel$export(module.exports, "getAnalyticsAttributesFromResult", ()=>$cf289af0c139712c$export$bc9cd28438b3ea4b);
    $parcel$export(module.exports, "getQueryHash", ()=>$cf289af0c139712c$export$fa1c95a5ffa92036);
    $parcel$export(module.exports, "getWordCount", ()=>$cf289af0c139712c$export$dba3e2bcdbd49da4);
    $parcel$export(module.exports, "getCommandAction", ()=>$cf289af0c139712c$export$fb6dafa07e60c21a);
    $parcel$export(module.exports, "hasAction", ()=>$cf289af0c139712c$export$e217e69099d082f5);
    $parcel$export(module.exports, "useSetTimeoutAutoClear", ()=>$cf289af0c139712c$export$c3d20e8c1e93a572);
    var $5uXOq = parcelRequire("5uXOq");
    var $bdDiz = parcelRequire("bdDiz");
    var $2f2jJ;
    var $bcTvL;
    var $jh7L9;
    const $cf289af0c139712c$export$6a1ed481c04a3ae6 = (registry, activeRegistry, commandId)=>{
        if (!commandId) return undefined;
        const activeCommandRegistryData = activeRegistry?.commandRegistry?.[commandId];
        const registryData = activeCommandRegistryData?.registryId ? registry[activeCommandRegistryData?.registryId] : undefined;
        return registryData;
    };
    const $cf289af0c139712c$export$eb055284806da6e5 = (registry, activeRegistry, commandId)=>$cf289af0c139712c$export$6a1ed481c04a3ae6(registry, activeRegistry, commandId);
    const $cf289af0c139712c$export$8af60a6897157f84 = (registry, activeRegistry, parentCommands)=>{
        const parentCommandIds = parentCommands?.map((command)=>command.id);
        while(parentCommandIds?.length){
            const nextParentCommandId = parentCommandIds?.pop();
            const registryData = $cf289af0c139712c$export$6a1ed481c04a3ae6(registry, activeRegistry, nextParentCommandId);
            if (registryData) return registryData;
        }
        return undefined;
    };
    const $cf289af0c139712c$export$e6994b845b967732 = ()=>[
            {
                char: ' ',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).THEN
            },
            {
                char: '+',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).PLUS
            },
            {
                char: '|',
                replacement: (0, (0, parcelRequire("bcTvL")).SHORTCUT_ELEMENT_TYPES).OR
            }
        ];
    const $cf289af0c139712c$export$cd2a5827eae5f4cf = (shortcut)=>{
        const specialChars = $cf289af0c139712c$export$e6994b845b967732();
        let strippedShortcut = shortcut;
        specialChars.forEach(({ char: char })=>{
            strippedShortcut = strippedShortcut.split(char).join('');
        });
        return strippedShortcut.toLowerCase();
    };
    const $cf289af0c139712c$export$389b0273703d5c48 = (sections)=>sections.reduce((acc, curr)=>{
            Object.entries(curr).forEach(([key, entry])=>{
                acc[key] = entry;
            });
            return acc;
        }, {});
    const $cf289af0c139712c$export$60a91e1b94ab4fef = (childResults)=>{
        const commands = [];
        const sections = [];
        childResults.forEach((childResult)=>{
            commands.push(...childResult.commands);
            if (childResult.sections) sections.push(childResult.sections);
        });
        return {
            commands: commands,
            sections: $cf289af0c139712c$export$389b0273703d5c48(sections)
        };
    };
    const $cf289af0c139712c$export$ae8f55d2b67cb8d6 = (text, commonWordsToBeRemoved)=>{
        const commonWords = commonWordsToBeRemoved.toLowerCase().replace(/\s{2,}/g, ' ').trim().split(' ');
        const regexString = commonWords.map((word)=>`\\b${word}\\b`).join('|');
        const regex = new RegExp(regexString, 'g');
        return text.replace(/[.,]/g, '').replace(regex, '').replace(/\s{2,}/g, ' ').trim();
    };
    const $cf289af0c139712c$export$4023cd53e5e5fcb8 = (state, command, method = 'undefined', wasSecondaryTriggered, attributes)=>{
        const analyticsAttributes = {
            commandPaletteId: command.id,
            commandPaletteSessionId: state.commandPaletteSessionId,
            searchTaken: !!state.search,
            method: method,
            cpMenu: 'rootMenu',
            action: command.id,
            actionCategory: 'none',
            wasSecondaryTriggered: wasSecondaryTriggered,
            ...$cf289af0c139712c$export$8af60a6897157f84(state.registry, state.activeRegistry, command.parentCommands) || {},
            ...$cf289af0c139712c$export$6a1ed481c04a3ae6(state.registry, state.activeRegistry, command.id) || {},
            ...$cf289af0c139712c$export$eb055284806da6e5(state.registry, state.activeRegistry, state.expandedCommand?.id) || {},
            ...attributes,
            ...!!command.analytics && command.analytics
        };
        if (state.expandedCommand?.analytics) analyticsAttributes.cpMenu = state.expandedCommand.analytics.action;
        else if (state.expandedCommand?.id) analyticsAttributes.cpMenu = state.expandedCommand.id;
        const sections = state.expandedCommand && state.childResult?.sections ? $cf289af0c139712c$export$389b0273703d5c48([
            ...state.sections,
            state.childResult?.sections
        ]) : $cf289af0c139712c$export$389b0273703d5c48(state.sections);
        const rootCommand = state.parentCommand?.[0] || state.expandedCommand || command;
        const rootSection = rootCommand.section ? sections?.[rootCommand.section] : undefined;
        analyticsAttributes.actionCategory = rootSection?.actionCategory || rootCommand?.section || rootCommand?.analytics?.actionCategory || (command?.section ? sections?.[command?.section]?.actionCategory || command?.section : undefined) || analyticsAttributes.actionCategory;
        return analyticsAttributes;
    };
    const $cf289af0c139712c$export$daa5beabdf8d9fc3 = (event)=>(0, (0, parcelRequire("2f2jJ")).isMacOs)() ? event.metaKey : event.ctrlKey;
    const $cf289af0c139712c$export$bd5ff50f0e53d41c = (command)=>{
        if (command?.wasSecondaryTriggered) {
            if (command?.secondaryAction?.type) {
                const hasSecondaryChildren = (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.secondaryAction?.type);
                return hasSecondaryChildren;
            }
            return (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.primaryAction?.type);
        }
        return (0, (0, parcelRequire("jh7L9")).COMMAND_WITH_CHILDREN).some((commandType)=>commandType === command?.primaryAction?.type);
    };
    const $cf289af0c139712c$export$bc9cd28438b3ea4b = (result)=>{
        if (result) return {
            globalIndex: result.index,
            sectionIndex: result.sectionIndex,
            indexWithinSection: result.indexWithinSection,
            sectionId: result.sectionId
        };
        return {};
    };
    const $cf289af0c139712c$export$fa1c95a5ffa92036 = (str)=>(0, $bdDiz.createHash)().update(str).digest('hex');
    const $cf289af0c139712c$export$dba3e2bcdbd49da4 = (str)=>str.length > 0 ? str.split(/\s+/).length : 0;
    const $cf289af0c139712c$export$fb6dafa07e60c21a = (command, wasSecondaryTriggered)=>{
        if (!command) return null;
        const { secondaryAction: secondaryAction, primaryAction: primaryAction } = command;
        if (!primaryAction) return null;
        const commandAction = wasSecondaryTriggered && secondaryAction ? secondaryAction : primaryAction;
        return {
            ...commandAction
        };
    };
    const $cf289af0c139712c$export$e217e69099d082f5 = (command)=>Boolean(command.primaryAction || command.secondaryAction);
    const $cf289af0c139712c$export$c3d20e8c1e93a572 = ()=>{
        const timeoutRef = (0, $5uXOq.useRef)();
        (0, $5uXOq.useEffect)(()=>()=>{
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
            }, []);
        const setTimeoutAutoClear = (...args)=>{
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(...args);
        };
        return {
            setTimeoutAutoClear: setTimeoutAutoClear
        };
    };
});
parcelRegister("2f2jJ", function(module, exports) {
    $parcel$export(module.exports, "isMacOs", ()=>$6846128323867ebd$export$c5e71f489eb3970b);
    $parcel$export(module.exports, "getShortcutModifierKey", ()=>$6846128323867ebd$export$fe66dae91c68619b);
    const $6846128323867ebd$export$c5e71f489eb3970b = ()=>/Mac/i.test(window?.navigator?.platform);
    const $6846128323867ebd$export$fe66dae91c68619b = ()=>$6846128323867ebd$export$c5e71f489eb3970b() ? 'meta' : 'ctrl';
});
parcelRegister("bcTvL", function(module, exports) {
    $parcel$export(module.exports, "UI_EVENT_TYPE", ()=>$3996a4302dca83b1$export$a1562d08625f5d7e);
    $parcel$export(module.exports, "TRACK_EVENT_TYPE", ()=>$3996a4302dca83b1$export$947dac6c72b26f81);
    $parcel$export(module.exports, "SHORTCUT_ELEMENT_TYPES", ()=>$3996a4302dca83b1$export$1ee9d153576afd13);
    $parcel$export(module.exports, "COMMAND_PALETTE_DEBOUNCE_DEFAULT", ()=>$3996a4302dca83b1$export$894e19fc96687503);
    const $3996a4302dca83b1$export$a1562d08625f5d7e = 'UI';
    const $3996a4302dca83b1$export$947dac6c72b26f81 = 'TRACK';
    const $3996a4302dca83b1$export$f1d537d508d2945a = 'SCREEN';
    const $3996a4302dca83b1$export$12346a674644c156 = 'OPERATIONAL';
    var $3996a4302dca83b1$export$1ee9d153576afd13;
    (function(SHORTCUT_ELEMENT_TYPES) {
        SHORTCUT_ELEMENT_TYPES["THEN"] = "then";
        SHORTCUT_ELEMENT_TYPES["OR"] = "or";
        SHORTCUT_ELEMENT_TYPES["PLUS"] = "plus";
        SHORTCUT_ELEMENT_TYPES["KEY"] = "key";
    })($3996a4302dca83b1$export$1ee9d153576afd13 || ($3996a4302dca83b1$export$1ee9d153576afd13 = {}));
    const $3996a4302dca83b1$export$894e19fc96687503 = 250;
});
parcelRegister("jh7L9", function(module, exports) {
    $parcel$export(module.exports, "CommandActionType", ()=>$b1e48ebd4c8d5e4e$export$f041b1b117ab382f);
    $parcel$export(module.exports, "COMMAND_WITH_CHILDREN", ()=>$b1e48ebd4c8d5e4e$export$904b2a0e38a1bef3);
    var $b1e48ebd4c8d5e4e$export$f041b1b117ab382f;
    (function(CommandActionType) {
        CommandActionType["PERFORM"] = "perform";
        CommandActionType["COMMAND_LIST"] = "command-list";
    })($b1e48ebd4c8d5e4e$export$f041b1b117ab382f || ($b1e48ebd4c8d5e4e$export$f041b1b117ab382f = {}));
    const $b1e48ebd4c8d5e4e$export$3e448fd53031e13c = Object.values($b1e48ebd4c8d5e4e$export$f041b1b117ab382f);
    const $b1e48ebd4c8d5e4e$export$904b2a0e38a1bef3 = [
        "command-list"
    ];
});
parcelRegister("2sRNe", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$4f4c9e92d50d4f24$export$e324594224ef24da);
    var $kGsr8 = parcelRequire("kGsr8");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $e7WVN;
    var $eTXmK;
    var $2f2jJ;
    var $jcw0u;
    var $bcTvL;
    var $jh7L9;
    var $gM5ou;
    var $4DwnT;
    var $h3eZO;
    const $4f4c9e92d50d4f24$var$rootCommands = {
        parentCommand: [],
        expandedCommand: undefined,
        childCommands: undefined,
        childResult: undefined
    };
    const $4f4c9e92d50d4f24$var$open = ()=>({ setState: setState, getState: getState })=>{
            if (getState().isDisabled) return;
            setState({
                commandPaletteSessionId: (0, ($parcel$interopDefault($7Jxgt))).v4(),
                isOpen: true,
                activeIndex: 0,
                search: ''
            });
        };
    const $4f4c9e92d50d4f24$var$close = ()=>({ setState: setState })=>{
            setState({
                isOpen: false,
                commandPaletteSessionId: '',
                search: '',
                ...$4f4c9e92d50d4f24$var$rootCommands
            });
        };
    const $4f4c9e92d50d4f24$var$toggle = ()=>({ setState: setState, getState: getState })=>{
            const isOpen = !getState().isOpen;
            setState({
                isOpen: isOpen,
                activeIndex: 0,
                search: '',
                commandPaletteSessionId: isOpen ? (0, ($parcel$interopDefault($7Jxgt))).v4() : '',
                ...!isOpen ? $4f4c9e92d50d4f24$var$rootCommands : {}
            });
        };
    const $4f4c9e92d50d4f24$var$addCommands = (commands)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const newCommands = currentState.commands.concat(commands);
            const newState = {
                commands: newCommands
            };
            if (currentState.expandedCommand) {
                const matchedExpandedCommand = commands.filter((command)=>command.id === currentState.expandedCommand?.id);
                if (matchedExpandedCommand.length === 1) {
                    const [updatedExpandedCommand] = matchedExpandedCommand;
                    newState.expandedCommand = updatedExpandedCommand;
                }
            }
            setState(newState);
        };
    const $4f4c9e92d50d4f24$var$removeCommands = (commands)=>({ setState: setState, getState: getState })=>{
            const newCommands = getState().commands.filter((command)=>!commands.includes(command));
            setState({
                commands: newCommands
            });
        };
    const $4f4c9e92d50d4f24$var$removeCommandsById = (commandIds)=>({ setState: setState, getState: getState })=>{
            const newCommands = getState().commands.filter((command)=>!commandIds.includes(command.id));
            setState({
                commands: newCommands
            });
        };
    const $4f4c9e92d50d4f24$var$handleSearch = (search)=>({ setState: setState })=>{
            setState({
                search: search,
                activeIndex: 0
            });
        };
    const $4f4c9e92d50d4f24$var$forceSearchValue = (search)=>({ setState: setState })=>{
            setState({
                search: search,
                shouldForceSearchValue: true
            });
        };
    const $4f4c9e92d50d4f24$var$resetShouldForceSearchValue = ()=>({ setState: setState })=>{
            setState({
                shouldForceSearchValue: false
            });
        };
    const $4f4c9e92d50d4f24$var$goBackLevel = (goToRoot, analytics)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            if (!currentState.expandedCommand) return;
            const newState = {
                search: '',
                activeIndex: 0,
                childResult: undefined,
                forceSearchFocus: true
            };
            if (currentState.parentCommand.length && !goToRoot) {
                newState.expandedCommand = currentState.parentCommand[currentState.parentCommand.length - 1];
                newState.parentCommand = currentState.parentCommand.slice(0, currentState.parentCommand.length - 1);
                if (newState.expandedCommand?.primaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && newState.expandedCommand?.primaryAction?.commands) newState.childResult = {
                    commands: newState.expandedCommand?.primaryAction?.commands,
                    sections: newState.expandedCommand?.primaryAction?.sections
                };
            } else {
                newState.loadingList = false;
                newState.parentCommand = [];
                newState.expandedCommand = undefined;
                newState.childResult = undefined;
            }
            if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) currentState.onAnalytics(analytics.createAnalyticsEvent({
                action: 'navigation',
                actionSubject: 'commandPalette'
            }), (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                attributes: {
                    cpMenu: currentState.expandedCommand?.id || 'rootMenu',
                    searchSessionId: currentState.commandPaletteSessionId,
                    action: goToRoot ? 'backToRoot' : 'backToParent',
                    actionCategory: 'back',
                    method: analytics?.method
                }
            });
            setState(newState);
        };
    const $4f4c9e92d50d4f24$var$forceFocusSearch = (forceSearchFocus = false)=>({ setState: setState })=>{
            setState({
                forceSearchFocus: forceSearchFocus
            });
        };
    const $4f4c9e92d50d4f24$var$setActiveIndex = (index)=>({ setState: setState, getState: getState })=>{
            const { isSearchFieldFocused: isSearchFieldFocused } = getState();
            setState({
                activeIndex: index,
                ...!isSearchFieldFocused ? {
                    forceSearchFocus: true
                } : {}
            });
        };
    const $4f4c9e92d50d4f24$var$getAnalyticsAttributes = (command, method, wasSecondaryTriggered = false, attributes = {})=>({ getState: getState })=>(0, (0, parcelRequire("gM5ou")).getAnalyticsAttributesFromState)(getState(), command, method, wasSecondaryTriggered, attributes);
    const $4f4c9e92d50d4f24$var$performDialogCloseCommand = ()=>({ setState: setState, getState: getState })=>{
            setTimeout(()=>{
                const { onClosePendingCommandAction: onClosePendingCommandAction, onAnalytics: onAnalytics } = getState();
                if (onClosePendingCommandAction !== undefined) {
                    try {
                        (0, (0, parcelRequire("eTXmK")).default)('command-palette-executed');
                        onClosePendingCommandAction.perform();
                        if (onAnalytics && onClosePendingCommandAction?.createAnalyticsEvent) {
                            const performAnalyticsEvent = onClosePendingCommandAction?.createAnalyticsEvent({
                                action: 'taskSucceeded',
                                actionSubject: 'commandPalette'
                            });
                            onAnalytics(performAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                                attributes: onClosePendingCommandAction?.analyticsArgs
                            });
                        }
                    } catch (err) {
                        const error = err instanceof Error ? err : new Error('Command palette perform error');
                        (0, (0, parcelRequire("jcw0u")).default)({
                            error: error,
                            meta: {
                                id: 'compal.performDialogCloseCommand',
                                packageName: 'jiraCommandPalette',
                                teamName: typeof onClosePendingCommandAction?.analyticsArgs?.teamName === 'string' ? onClosePendingCommandAction?.analyticsArgs.teamName : 'deliveroo'
                            },
                            attributes: onClosePendingCommandAction.analyticsArgs
                        });
                        (0, (0, parcelRequire("e7WVN")).setInteractionError)('command-palette-executed', {
                            errorMessage: error.message,
                            name: 'performDialogCloseCommandPerformError'
                        });
                    }
                    setState({
                        onClosePendingCommandAction: undefined
                    });
                }
            }, 0);
        };
    const $4f4c9e92d50d4f24$var$executeCommand = (command, analytics, wasSecondaryTriggered = false)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const newState = {
                ...!(0, (0, parcelRequire("4DwnT")).getActiveCommandList)(command, wasSecondaryTriggered)?.keepSearch && {
                    search: ''
                },
                activeIndex: 0
            };
            const analyticsAttributes = (0, (0, parcelRequire("gM5ou")).getAnalyticsAttributesFromState)(currentState, command, analytics?.method || 'undefined', wasSecondaryTriggered, analytics?.attributes);
            const currentCommandAction = (0, (0, parcelRequire("gM5ou")).getCommandAction)(command, wasSecondaryTriggered);
            if (!currentCommandAction) return;
            if (currentState?.onAnalytics && analytics?.createAnalyticsEvent) {
                const analyticsEvent = analytics.createAnalyticsEvent({
                    action: 'navigation',
                    actionSubject: 'commandPalette'
                });
                currentState.onAnalytics(analyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                    attributes: analyticsAttributes
                });
            }
            const shouldLoadChildren = (0, (0, parcelRequire("gM5ou")).hasChildren)({
                ...command,
                wasSecondaryTriggered: wasSecondaryTriggered
            });
            const shouldExecutePerform = currentCommandAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).PERFORM;
            if (shouldLoadChildren) {
                (0, (0, parcelRequire("eTXmK")).default)('command-palette-load-child-command-list');
                if (currentState.expandedCommand) newState.parentCommand = [
                    ...currentState.parentCommand,
                    currentState.expandedCommand
                ];
                newState.expandedCommand = {
                    ...command,
                    wasSecondaryTriggered: wasSecondaryTriggered
                };
                newState.childResult = undefined;
                newState.forceSearchFocus = true;
                if ((0, (0, parcelRequire("h3eZO")).isSearchableChildrenAction)(currentCommandAction)) {
                    newState.childResult = {
                        commands: currentCommandAction.commands,
                        sections: currentCommandAction.sections
                    };
                    if (command.parentCommands?.length) newState.parentCommand = [
                        ...newState.parentCommand || [],
                        ...command.parentCommands
                    ];
                } else newState.childResult = undefined;
                setState(newState);
                if (currentCommandAction.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && currentCommandAction.onClick) currentCommandAction.onClick();
            } else if (shouldExecutePerform) {
                if (!currentCommandAction.preventModalClose) {
                    newState.commandPaletteSessionId = '';
                    newState.isOpen = false;
                    newState.expandedCommand = undefined;
                    newState.parentCommand = [];
                    newState.childResult = undefined;
                    setState(newState);
                    if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                        const closeAnalyticsEvent = analytics.createAnalyticsEvent({
                            action: 'closed',
                            actionSubject: 'commandPalette'
                        });
                        currentState.onAnalytics(closeAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).UI_EVENT_TYPE), {
                            attributes: analyticsAttributes
                        });
                    }
                }
                if (wasSecondaryTriggered && currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                    const closeAnalyticsEvent = analytics.createAnalyticsEvent({
                        action: 'secondaryAction',
                        actionSubject: 'commandPalette'
                    });
                    currentState.onAnalytics(closeAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                        attributes: {
                            ...analyticsAttributes,
                            keyPressed: (0, (0, parcelRequire("2f2jJ")).isMacOs)() ? 'cmdEnter' : 'ctrlEnter',
                            ...currentCommandAction.analyticsArgs
                        }
                    });
                }
                if (currentCommandAction.preventModalClose) try {
                    (0, (0, parcelRequire("eTXmK")).default)('command-palette-executed');
                    currentCommandAction.perform();
                    if (currentState.onAnalytics && analytics?.createAnalyticsEvent) {
                        const performAnalyticsEvent = analytics.createAnalyticsEvent({
                            action: 'taskSucceeded',
                            actionSubject: 'commandPalette'
                        });
                        currentState.onAnalytics(performAnalyticsEvent, (0, (0, parcelRequire("bcTvL")).TRACK_EVENT_TYPE), {
                            attributes: analyticsAttributes
                        });
                    }
                } catch (err) {
                    const error = err instanceof Error ? err : new Error('Command palette perform error');
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: error,
                        meta: {
                            id: 'compal.executeCommand',
                            packageName: 'jiraCommandPalette',
                            teamName: typeof analytics?.attributes?.teamName === 'string' ? analytics?.attributes.teamName : 'deliveroo'
                        },
                        attributes: analytics?.attributes
                    });
                    (0, (0, parcelRequire("e7WVN")).setInteractionError)('command-palette-executed', {
                        errorMessage: error.message,
                        name: 'executeCommandPerformError'
                    });
                }
                else setState({
                    onClosePendingCommandAction: {
                        ...currentCommandAction,
                        analyticsArgs: analyticsAttributes,
                        createAnalyticsEvent: analytics?.createAnalyticsEvent
                    }
                });
            }
        };
    const $4f4c9e92d50d4f24$var$setChildCommands = (childResult)=>({ setState: setState })=>{
            setState({
                childResult: childResult
            });
        };
    const $4f4c9e92d50d4f24$var$setLoadingList = (loadingList)=>({ setState: setState })=>{
            setState({
                loadingList: loadingList
            });
        };
    const $4f4c9e92d50d4f24$var$setFocusInput = (isSearchFieldFocused)=>({ setState: setState })=>setState({
                isSearchFieldFocused: isSearchFieldFocused
            });
    const $4f4c9e92d50d4f24$var$registerSections = (newSections)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const sections = currentState.sections.slice(0);
            sections.push(newSections);
            setState({
                sections: sections
            });
        };
    const $4f4c9e92d50d4f24$var$unregisterSections = (newSections)=>({ setState: setState, getState: getState })=>{
            const currentState = getState();
            const sections = currentState.sections.filter((section)=>section !== newSections);
            setState({
                sections: sections
            });
        };
    const $4f4c9e92d50d4f24$var$markAsLoaded = ()=>({ setState: setState })=>{
            setState({
                isLoaded: true
            });
        };
    const $4f4c9e92d50d4f24$var$setIsDisabled = (isDisabled)=>({ setState: setState })=>{
            setState({
                isDisabled: isDisabled,
                ...isDisabled ? {
                    isOpen: false
                } : {}
            });
        };
    const $4f4c9e92d50d4f24$var$setRegistry = (registry)=>({ setState: setState })=>{
            setState({
                registry: registry
            });
        };
    const $4f4c9e92d50d4f24$var$updateActiveRegistry = (registryId, commands)=>({ setState: setState, getState: getState })=>{
            const currentActiveRegistry = getState().activeRegistry;
            const activeRegistry = {
                commandRegistry: {
                    ...currentActiveRegistry.commandRegistry,
                    ...commands.reduce((acc, command)=>{
                        acc[command.id] = {
                            registryId: registryId
                        };
                        return acc;
                    }, {})
                },
                registryIds: (0, ($parcel$interopDefault($kGsr8)))([
                    ...currentActiveRegistry.registryIds,
                    registryId
                ])
            };
            setState({
                activeRegistry: activeRegistry
            });
        };
    const $4f4c9e92d50d4f24$var$removeFromActiveRegistry = (registryId, commands)=>({ setState: setState, getState: getState })=>{
            const currentActiveRegistry = getState().activeRegistry;
            const commandRegistry = {
                ...currentActiveRegistry.commandRegistry
            };
            commands.forEach((command)=>{
                delete commandRegistry[command.id];
            });
            setState({
                activeRegistry: {
                    commandRegistry: commandRegistry,
                    registryIds: currentActiveRegistry.registryIds.filter((id)=>id !== registryId)
                }
            });
        };
    const $4f4c9e92d50d4f24$var$setAnalytics = (onAnalytics, onErrorAnalytics)=>({ setState: setState })=>{
            setState({
                onAnalytics: onAnalytics,
                onErrorAnalytics: onErrorAnalytics
            });
        };
    const $4f4c9e92d50d4f24$var$setFallbackOverride = (fallbackOverride)=>({ setState: setState })=>{
            setState({
                fallbackOverride: fallbackOverride
            });
        };
    const $4f4c9e92d50d4f24$var$setHasPopupOverlay = (hasPopupOverlay)=>({ setState: setState })=>{
            setState({
                hasPopupOverlay: hasPopupOverlay
            });
        };
    const $4f4c9e92d50d4f24$export$e324594224ef24da = {
        open: $4f4c9e92d50d4f24$var$open,
        toggle: $4f4c9e92d50d4f24$var$toggle,
        close: $4f4c9e92d50d4f24$var$close,
        addCommands: $4f4c9e92d50d4f24$var$addCommands,
        removeCommands: $4f4c9e92d50d4f24$var$removeCommands,
        handleSearch: $4f4c9e92d50d4f24$var$handleSearch,
        forceFocusSearch: $4f4c9e92d50d4f24$var$forceFocusSearch,
        setActiveIndex: $4f4c9e92d50d4f24$var$setActiveIndex,
        executeCommand: $4f4c9e92d50d4f24$var$executeCommand,
        goBackLevel: $4f4c9e92d50d4f24$var$goBackLevel,
        setChildCommands: $4f4c9e92d50d4f24$var$setChildCommands,
        setLoadingList: $4f4c9e92d50d4f24$var$setLoadingList,
        setFocusInput: $4f4c9e92d50d4f24$var$setFocusInput,
        registerSections: $4f4c9e92d50d4f24$var$registerSections,
        unregisterSections: $4f4c9e92d50d4f24$var$unregisterSections,
        markAsLoaded: $4f4c9e92d50d4f24$var$markAsLoaded,
        getAnalyticsAttributes: $4f4c9e92d50d4f24$var$getAnalyticsAttributes,
        setIsDisabled: $4f4c9e92d50d4f24$var$setIsDisabled,
        setRegistry: $4f4c9e92d50d4f24$var$setRegistry,
        updateActiveRegistry: $4f4c9e92d50d4f24$var$updateActiveRegistry,
        removeCommandsById: $4f4c9e92d50d4f24$var$removeCommandsById,
        removeFromActiveRegistry: $4f4c9e92d50d4f24$var$removeFromActiveRegistry,
        performDialogCloseCommand: $4f4c9e92d50d4f24$var$performDialogCloseCommand,
        setAnalytics: $4f4c9e92d50d4f24$var$setAnalytics,
        setFallbackOverride: $4f4c9e92d50d4f24$var$setFallbackOverride,
        setHasPopupOverlay: $4f4c9e92d50d4f24$var$setHasPopupOverlay,
        forceSearchValue: $4f4c9e92d50d4f24$var$forceSearchValue,
        resetShouldForceSearchValue: $4f4c9e92d50d4f24$var$resetShouldForceSearchValue
    };
});
parcelRegister("4DwnT", function(module, exports) {
    $parcel$export(module.exports, "getActiveCommandList", ()=>$b4088022dc3f5c54$export$639b7f41b0b47a01);
    var $jh7L9;
    const $b4088022dc3f5c54$export$639b7f41b0b47a01 = (expandedCommand, wasSecondaryTriggered)=>{
        if (wasSecondaryTriggered && expandedCommand?.secondaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST) return expandedCommand.secondaryAction;
        if (!wasSecondaryTriggered && expandedCommand?.primaryAction?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST) return expandedCommand.primaryAction;
        return null;
    };
});
parcelRegister("h3eZO", function(module, exports) {
    $parcel$export(module.exports, "isSearchableChildrenAction", ()=>$dd5db8363c7eeda7$export$d75616d77443fe4e);
    var $jh7L9;
    const $dd5db8363c7eeda7$export$d75616d77443fe4e = (action)=>action?.type === (0, (0, parcelRequire("jh7L9")).CommandActionType).COMMAND_LIST && action.commands !== undefined;
});
parcelRegister("ca01T", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2a1835286195bcc1$export$2e2bcd8739ae039);
    var $7VHMR;
    var $2a1835286195bcc1$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        searchJiraAdmin: {
            "id": "directory-base-v3.header.search-jira-admin",
            "defaultMessage": "Search Jira admin"
        }
    });
});
parcelRegister("8hZvl", function(module, exports) {
    $parcel$export(module.exports, "CreateButton", ()=>$5970b2021bc0982f$export$6d3c345ce7c38fd9);
    var $2TaVE;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $Mp6qH;
    var $39xOx;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $e9QkH;
    var $eZZL8;
    const $5970b2021bc0982f$var$CreateButtonComponent = (props)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "primary",
            ...props
        }, formatMessage((0, (0, parcelRequire("eZZL8")).default).createProject));
    };
    const $5970b2021bc0982f$var$CreateButton = ({ dataRef: dataRef })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'clicked',
            actionSubject: 'directoryHeader'
        });
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("2TaVE")).default), dataRef);
        const [, { on: openProjectCreateDrawer }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("Mp6qH")).MODAL_ID).PROJECT_CREATE);
        const handleCreate = (0, $5uXOq.useCallback)(()=>{
            openProjectCreateDrawer();
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'CreateProjectButton');
        }, [
            analyticsEvent,
            openProjectCreateDrawer
        ]);
        if (!data?.canCreateProject) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($5970b2021bc0982f$var$CreateButtonComponent, {
            "data-testId": "global-pages.directories.projects-directory-v3.create-projects-button",
            onClick: handleCreate
        });
    };
    const $5970b2021bc0982f$export$6d3c345ce7c38fd9 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "create-project-button",
            packageName: "jira-project-directory-page-v3",
            fallback: "flag"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($5970b2021bc0982f$var$CreateButton, props));
});
parcelRegister("2TaVE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$54793390d1738743$export$2e2bcd8739ae039);
    const $54793390d1738743$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "cloudId"
            }
        ],
        "kind": "Fragment",
        "name": "createProjectAction_projectsDirectoryV3_CreateButton",
        "selections": [
            {
                "alias": "canCreateProject",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "cloudId",
                        "variableName": "cloudId"
                    },
                    {
                        "kind": "Literal",
                        "name": "type",
                        "value": "CREATE_PROJECT"
                    }
                ],
                "kind": "ScalarField",
                "name": "canPerform"
            }
        ],
        "type": "JiraQuery"
    };
    $54793390d1738743$var$node.hash = "df6f22d032f8d63a59ac7ad12bb1116f";
    var $54793390d1738743$export$2e2bcd8739ae039 = $54793390d1738743$var$node;
});
parcelRegister("e9QkH", function(module, exports) {
    $parcel$export(module.exports, "useSwitchModals", ()=>$bbe85969245206f0$export$5ed4b99cbad4ebd6);
    var $2qauD;
    const { useSwitch: $bbe85969245206f0$var$useSwitch } = (0, (0, parcelRequire("2qauD")).createSwitchController)('jira-switch-modals');
    const $bbe85969245206f0$export$5ed4b99cbad4ebd6 = $bbe85969245206f0$var$useSwitch;
});
parcelRegister("2qauD", function(module, exports) {
    $parcel$export(module.exports, "createSwitchController", ()=>$a539fc33301c42ee$export$1856548326159ec8);
    var $5uXOq = parcelRequire("5uXOq");
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $a539fc33301c42ee$export$1856548326159ec8 = (name, activeByDefault)=>{
        const actions = {
            on: (active)=>({ setState: setState })=>setState({
                        active: active
                    }),
            off: (active)=>({ setState: setState, getState: getState })=>{
                    if (getState().active === active || active === undefined) setState({
                        active: undefined
                    });
                },
            toggle: (active)=>({ getState: getState, dispatch: dispatch })=>{
                    if (getState().active === active) dispatch(actions.off(active));
                    else dispatch(actions.on(active));
                }
        };
        const initialState = {
            active: activeByDefault
        };
        const store = (0, (0, parcelRequire("iXqE5")).createStore)({
            name: name,
            initialState: initialState,
            actions: actions
        });
        const SwitchContainer = (0, (0, parcelRequire("gg7kZ")).createContainer)(store);
        const selector = (state, key)=>state.active === key;
        const useSwitchInternal = (0, (0, parcelRequire("3sQ5x")).createHook)(store, {
            selector: selector
        });
        const useSwitch = (active)=>{
            const [isActive, { on: onSwitch, off: offSwitch, toggle: toggleSwitch }] = useSwitchInternal(active);
            const on = (0, $5uXOq.useCallback)(()=>onSwitch(active), [
                onSwitch,
                active
            ]);
            const off = (0, $5uXOq.useCallback)(()=>offSwitch(active), [
                active,
                offSwitch
            ]);
            const toggle = (0, $5uXOq.useCallback)(()=>toggleSwitch(active), [
                toggleSwitch,
                active
            ]);
            return [
                isActive,
                {
                    on: on,
                    off: off,
                    toggle: toggle
                }
            ];
        };
        return {
            SwitchContainer: SwitchContainer,
            useSwitch: useSwitch
        };
    };
});
parcelRegister("eZZL8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a1c4919d55f32747$export$2e2bcd8739ae039);
    var $7VHMR;
    var $a1c4919d55f32747$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        createProject: {
            "id": "projects-directory-v3.page-layout.header.create-project-action.create-project",
            "defaultMessage": "Create project"
        }
    });
});
parcelRegister("4OVbb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$145fd049ccb4af48$export$2e2bcd8739ae039);
    var $7VHMR;
    var $145fd049ccb4af48$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        headerCaption: {
            "id": "projects-directory-v3.page-layout.header.header-caption",
            "defaultMessage": "Projects"
        },
        adminHeaderCaption: {
            "id": "projects-directory-v3.page-layout.header.admin-header-caption",
            "defaultMessage": "Manage Projects"
        }
    });
});
parcelRegister("hgRkd", function(module, exports) {
    $parcel$export(module.exports, "OpenTemplatesPanelButton", ()=>$f7017a5cc5dfe719$export$6ac7df8c3bce770);
    var $cSfZu;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $39xOx;
    var $7Hq8b;
    var $dW6Zn;
    var $koVbs;
    var $hsmeR;
    var $iXVV6;
    var $5oFIy;
    var $8zOmE;
    var $2NMNM;
    var $a49ag;
    const $f7017a5cc5dfe719$var$OpenTemplatesPanelButton = ({ dataRef: dataRef })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'clicked',
            actionSubject: 'directoryHeader'
        });
        const rightSidebarCurrentState = (0, (0, parcelRequire("hsmeR")).useActiveRightSidebarState)();
        const { openRightSidebar: openRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { canCreateProject: canCreateProject, userPreferences: userPreferences, sidebarExperimentTemplates: sidebarExperimentTemplates } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("cSfZu")).default), dataRef);
        const setProjectListRightPanelState = (0, (0, parcelRequire("dW6Zn")).useSetProjectListRightPanelState)(userPreferences.__id);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'openTemplatesPanelButton');
            if (userPreferences.projectListRightPanelState === 'OPEN' && rightSidebarCurrentState?.panelId !== (0, (0, parcelRequire("7Hq8b")).PANEL_ID)) {
                openRightSidebar((0, (0, parcelRequire("7Hq8b")).PANEL_ID), (0, (0, parcelRequire("7Hq8b")).SIDEBAR_WIDTH), false, true);
                return;
            }
            setProjectListRightPanelState(userPreferences.projectListRightPanelState === 'OPEN' ? 'CLOSED' : 'OPEN');
        }, [
            analyticsEvent,
            openRightSidebar,
            rightSidebarCurrentState?.panelId,
            setProjectListRightPanelState,
            userPreferences.projectListRightPanelState
        ]);
        if (!canCreateProject || typeof sidebarExperimentTemplates?.totalCount !== 'number' || sidebarExperimentTemplates.totalCount === 0) {
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({}), 'openTemplatesPanelButton skipped', {
                reason: 'sidebarEligibility failed',
                canCreateProject: canCreateProject,
                totalCount: sidebarExperimentTemplates.totalCount
            });
            return null;
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            isSelected: rightSidebarCurrentState?.panelId === (0, (0, parcelRequire("7Hq8b")).PANEL_ID),
            onClick: onClick
        }, formatMessage((0, (0, parcelRequire("a49ag")).default).templatesButton)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), {
            attributes: {
                experiment: 'recommendationsinProjectsDirectoryWithHoverPreview',
                totalCount: sidebarExperimentTemplates.totalCount,
                canCreateProject: canCreateProject
            }
        }));
    };
    const $f7017a5cc5dfe719$export$6ac7df8c3bce770 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "open-templates-panel-button",
            packageName: "jiraProjectsDirectoryV3",
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "projectsV3",
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f7017a5cc5dfe719$var$OpenTemplatesPanelButton, props)));
});
parcelRegister("cSfZu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4df339b0a0a1d4f4$export$2e2bcd8739ae039);
    const $4df339b0a0a1d4f4$var$node = function() {
        var v0 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        };
        return {
            "argumentDefinitions": [
                {
                    "kind": "RootArgument",
                    "name": "cloudId"
                }
            ],
            "kind": "Fragment",
            "name": "openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton",
            "selections": [
                {
                    "alias": "canCreateProject",
                    "args": [
                        v0,
                        {
                            "kind": "Literal",
                            "name": "type",
                            "value": "CREATE_PROJECT"
                        }
                    ],
                    "kind": "ScalarField",
                    "name": "canPerform"
                },
                {
                    "kind": "RequiredField",
                    "field": {
                        "args": [
                            v0
                        ],
                        "concreteType": "JiraUserPreferences",
                        "kind": "LinkedField",
                        "name": "userPreferences",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "projectListRightPanelState"
                            },
                            {
                                "kind": "ClientExtension",
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "__id"
                                    }
                                ]
                            }
                        ]
                    },
                    "action": "THROW",
                    "path": "userPreferences"
                },
                {
                    "kind": "RequiredField",
                    "field": {
                        "alias": "sidebarExperimentTemplates",
                        "args": [
                            v0,
                            {
                                "kind": "Literal",
                                "name": "experimentKey",
                                "value": "sidebarExperiment"
                            }
                        ],
                        "concreteType": "JiraProjectListViewTemplateConnection",
                        "kind": "LinkedField",
                        "name": "projectListViewTemplates",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "RequiredField",
                                "field": {
                                    "kind": "ScalarField",
                                    "name": "totalCount"
                                },
                                "action": "THROW",
                                "path": "sidebarExperimentTemplates.totalCount"
                            }
                        ]
                    },
                    "action": "THROW",
                    "path": "sidebarExperimentTemplates"
                }
            ],
            "type": "JiraQuery"
        };
    }();
    $4df339b0a0a1d4f4$var$node.hash = "57f9e2bfda0bc31526f57df7eb39fcad";
    var $4df339b0a0a1d4f4$export$2e2bcd8739ae039 = $4df339b0a0a1d4f4$var$node;
});
parcelRegister("dW6Zn", function(module, exports) {
    $parcel$export(module.exports, "useSetProjectListRightPanelState", ()=>$0cab5e96cb0d59c5$export$f7872413c3847449);
    var $5pqbm;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $jcw0u;
    var $koeKL;
    const $0cab5e96cb0d59c5$var$PROJECT_LIST_RIGHT_PANEL_STATE = 'projectListRightPanelState';
    const $0cab5e96cb0d59c5$var$relayStoreUpdater = (store, userPreferencesRefId, state)=>{
        if (typeof userPreferencesRefId === 'string') {
            const record = store.get(userPreferencesRefId);
            record?.setValue(state, $0cab5e96cb0d59c5$var$PROJECT_LIST_RIGHT_PANEL_STATE);
        }
    };
    const $0cab5e96cb0d59c5$export$f7872413c3847449 = (userPreferencesRefId)=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const [commit] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("5pqbm")).default));
        const setRightPanelState = (0, $5uXOq.useCallback)((state)=>{
            commit({
                variables: {
                    cloudId: cloudId,
                    state: state
                },
                optimisticUpdater: (store)=>$0cab5e96cb0d59c5$var$relayStoreUpdater(store, userPreferencesRefId, state),
                updater: (store, { jira: { userPreferences: { setProjectListRightPanelState: { projectListRightPanelState: projectListRightPanelState } } } })=>$0cab5e96cb0d59c5$var$relayStoreUpdater(store, userPreferencesRefId, projectListRightPanelState ?? state),
                onCompleted: ({ jira: { userPreferences: { setProjectListRightPanelState: { success: success, errors: errors } } } })=>{
                    const error = (errors ?? [])[0];
                    if (!success || error) {
                        const errorMessage = `error on set project list right panel state: ${error.message}`;
                        const statusCode = error.extensions?.statusCode;
                        (0, (0, parcelRequire("jcw0u")).default)({
                            error: new Error(`status code: ${statusCode ?? 'unknown'}, ${errorMessage}`),
                            meta: {
                                id: 'jiraSetProjectListRightPanelStateError',
                                packageName: 'jiraGrowthRecommendationsInProjectList'
                            }
                        });
                    }
                },
                onError: (error)=>{
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: new Error(`jiraSetProjectListRightPanelStateMutationError with ${error.message}`),
                        meta: {
                            id: 'jiraSetProjectListRightPanelStateMutationError',
                            packageName: 'jiraGrowthRecommendationsInProjectList'
                        }
                    });
                }
            });
        }, [
            cloudId,
            commit,
            userPreferencesRefId
        ]);
        return setRightPanelState;
    };
});
parcelRegister("5pqbm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$faa907682b1711b4$export$2e2bcd8739ae039);
    const $faa907682b1711b4$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "state"
            }
        ], v1 = [
            {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
            }
        ], v2 = [
            {
                "kind": "Variable",
                "name": "state",
                "variableName": "state"
            }
        ], v3 = {
            "kind": "ScalarField",
            "name": "success"
        }, v4 = {
            "kind": "ScalarField",
            "name": "statusCode"
        }, v5 = {
            "kind": "ScalarField",
            "name": "message"
        }, v6 = {
            "kind": "ScalarField",
            "name": "projectListRightPanelState"
        };
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
                "selections": [
                    {
                        "concreteType": "JiraMutation",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v1,
                                "concreteType": "JiraUserPreferencesMutation",
                                "kind": "LinkedField",
                                "name": "userPreferences",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v2,
                                        "concreteType": "JiraProjectListRightPanelStateMutationPayload",
                                        "kind": "LinkedField",
                                        "name": "setProjectListRightPanelState",
                                        "plural": false,
                                        "selections": [
                                            v3,
                                            {
                                                "concreteType": "MutationError",
                                                "kind": "LinkedField",
                                                "name": "errors",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "extensions",
                                                        "plural": false,
                                                        "selections": [
                                                            v4
                                                        ]
                                                    },
                                                    v5
                                                ]
                                            },
                                            v6
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
                "selections": [
                    {
                        "concreteType": "JiraMutation",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v1,
                                "concreteType": "JiraUserPreferencesMutation",
                                "kind": "LinkedField",
                                "name": "userPreferences",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v2,
                                        "concreteType": "JiraProjectListRightPanelStateMutationPayload",
                                        "kind": "LinkedField",
                                        "name": "setProjectListRightPanelState",
                                        "plural": false,
                                        "selections": [
                                            v3,
                                            {
                                                "concreteType": "MutationError",
                                                "kind": "LinkedField",
                                                "name": "errors",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "extensions",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "__typename"
                                                            },
                                                            v4,
                                                            {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "id"
                                                                    }
                                                                ],
                                                                "type": "BulkMutationErrorExtension"
                                                            }
                                                        ]
                                                    },
                                                    v5
                                                ]
                                            },
                                            v6
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "c9fc45f1ccb390d27ba078874be435f11bb5799b236a50a6fc32b19b5b705f8b",
                "metadata": {},
                "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
                "operationKind": "mutation",
                "text": null
            }
        };
    }();
    $faa907682b1711b4$var$node.hash = "1cbcdd010c06eb6470327729729fba37";
    var $faa907682b1711b4$export$2e2bcd8739ae039 = $faa907682b1711b4$var$node;
});
parcelRegister("a49ag", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ca80a8d0ed79712d$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ca80a8d0ed79712d$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        templatesButton: {
            "id": "projects-directory-v3.page-layout.header.open-templates-panel.templates-button",
            "defaultMessage": "Templates"
        }
    });
});
parcelRegister("jBriI", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$23ed3a75ab32d9bb$export$2e2bcd8739ae039);
    var $7VHMR;
    var $23ed3a75ab32d9bb$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        documentTitle: {
            "id": "projects-directory-v3.page-layout.document-title",
            "defaultMessage": "Projects"
        },
        adminDocumentTitle: {
            "id": "projects-directory-v3.page-layout.admin-document-title",
            "defaultMessage": "Manage Projects"
        },
        filterResultsAnnouncement: {
            "id": "projects-directory-v3.page-layout.filter-results-announcement",
            "defaultMessage": "{itemCount, plural, =0 {No projects were found that match your search} one {{itemCount} project found} other {{itemCount} projects found}}"
        }
    });
});
parcelRegister("4pNZM", function(module, exports) {
    $parcel$export(module.exports, "AsyncNoPermissionViews", ()=>$2aa9ba258e3b4c1b$export$a2f35d77f8a7c2df);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    const $2aa9ba258e3b4c1b$var$LazyNoPermissionViews = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("3x2Dh")).then(({ NoPermissionViews: NoPermissionViews })=>NoPermissionViews), {
        ssr: false,
        moduleId: "./src/packages/global-pages/directories/projects-directory-v3/src/ui/page-layout/no-permission-views/index.tsx"
    });
    const $2aa9ba258e3b4c1b$export$a2f35d77f8a7c2df = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "no-permission-views",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($2aa9ba258e3b4c1b$var$LazyNoPermissionViews, props));
});
parcelRegister("3x2Dh", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("4g5mM").then(()=>parcelRequire('cwinS'));
});
parcelRegister("9BDgo", function(module, exports) {
    $parcel$export(module.exports, "ProjectsList", ()=>$2f30cecb5e68f7b0$export$99bffacbdbcb5195);
    var $10mZH;
    var $012oV;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $23EKX;
    var $jCHzE;
    const $2f30cecb5e68f7b0$export$99bffacbdbcb5195 = ({ projectsRef: projectsRef, rootQueryRef: rootQueryRef, isLoading: isLoading, isAdminSettingsContext: isAdminSettingsContext })=>{
        const dataRef = (0, $8Uumt.useFragment)((0, (0, parcelRequire("012oV")).default), projectsRef);
        const categoriesData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("10mZH")).default), rootQueryRef);
        const shouldShowCategories = (0, $5uXOq.useMemo)(()=>Boolean(categoriesData.jira?.allJiraProjectCategories?.edges?.length), [
            categoriesData
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($2f30cecb5e68f7b0$var$Container, {
            isLoading: isLoading,
            "data-testid": "global-pages.directories.projects-directory-v3.ui.page-layout.projects-list.container"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jCHzE")).ResultsTable), {
            shouldShowCategories: shouldShowCategories,
            projectsRef: dataRef,
            jiraQueryRef: categoriesData.jira || null,
            isAdminSettingsContext: isAdminSettingsContext
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("23EKX")).Pagination), {
            projectsRef: dataRef
        }));
    };
    const $2f30cecb5e68f7b0$var$Container = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isLoading: isLoading, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _n3tdpxbi _aetrb3bt _18postnw _z0ai1osq _1qdg1osq _1hfk37p7 _15l2idpf _19doidpf",
                __cmplp.isLoading ? "_1ywu1ule" : "_1ywuglyw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("10mZH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d0f03a9149749c57$export$2e2bcd8739ae039);
    const $d0f03a9149749c57$var$node = function() {
        var v0 = [
            {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
            }
        ];
        return {
            "argumentDefinitions": [
                {
                    "kind": "RootArgument",
                    "name": "cloudId"
                },
                {
                    "defaultValue": null,
                    "kind": "LocalArgument",
                    "name": "isAnonymous"
                }
            ],
            "kind": "Fragment",
            "name": "projectsList_projectsDirectoryV3_rootQueryRef",
            "selections": [
                {
                    "concreteType": "JiraQuery",
                    "kind": "LinkedField",
                    "name": "jira",
                    "plural": false,
                    "selections": [
                        {
                            "condition": "isAnonymous",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                                {
                                    "args": v0,
                                    "concreteType": "JiraProjectCategoryConnection",
                                    "kind": "LinkedField",
                                    "name": "allJiraProjectCategories",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "concreteType": "JiraProjectCategoryEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "name": "__typename"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "args": v0,
                            "kind": "FragmentSpread",
                            "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef"
                        }
                    ]
                }
            ],
            "type": "Query"
        };
    }();
    $d0f03a9149749c57$var$node.hash = "17861eb1411077bef688cc2747a1321b";
    var $d0f03a9149749c57$export$2e2bcd8739ae039 = $d0f03a9149749c57$var$node;
});
parcelRegister("012oV", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d3bc3d78dad75f9b$export$2e2bcd8739ae039);
    const $d3bc3d78dad75f9b$var$node = {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAdminSettingsContext"
            }
        ],
        "kind": "Fragment",
        "name": "projectsList_projectsDirectoryV3_projectsRef",
        "selections": [
            {
                "args": [
                    {
                        "kind": "Variable",
                        "name": "isAdminSettingsContext",
                        "variableName": "isAdminSettingsContext"
                    }
                ],
                "kind": "FragmentSpread",
                "name": "resultsTable_projectsDirectoryV3"
            },
            {
                "kind": "FragmentSpread",
                "name": "pagination_projectsDirectoryV3"
            }
        ],
        "type": "JiraProjectConnection"
    };
    $d3bc3d78dad75f9b$var$node.hash = "a2ac4e53b4aeb7f1a30eaf9175bfdeae";
    var $d3bc3d78dad75f9b$export$2e2bcd8739ae039 = $d3bc3d78dad75f9b$var$node;
});
parcelRegister("23EKX", function(module, exports) {
    $parcel$export(module.exports, "Pagination", ()=>$d7b0791bcfaedb14$export$68f5e1453c188a82);
    var $29id7;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $kgoHi;
    var $l7W0s;
    var $4R6GH;
    var $8zOmE;
    var $2NMNM;
    var $gkT9B;
    var $dCm1o;
    var $dPvGX;
    const $d7b0791bcfaedb14$export$68f5e1453c188a82 = ({ projectsRef: projectsRef })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'changed',
            actionSubject: 'resultsTable'
        });
        const [value, { updateFilter: updateFilter }] = (0, (0, parcelRequire("dPvGX")).useScopedFilter)((0, (0, parcelRequire("gkT9B")).FilterFields).Page);
        const currentPage = parseInt(value, 10);
        const dataRef = (0, $8Uumt.useFragment)((0, (0, parcelRequire("29id7")).default), projectsRef);
        const handleChange = (0, $5uXOq.useCallback)((page)=>{
            updateFilter((0, (0, parcelRequire("gkT9B")).FilterFields).Page, page.toString());
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'PageNumber');
        }, [
            analyticsEvent,
            updateFilter
        ]);
        if ((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_beta_drop_1')) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
            alignItems: "center",
            justifyContent: "center"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l7W0s")).Pagination), {
            dataRef: dataRef,
            itemsPerPage: (0, (0, parcelRequire("gkT9B")).DEFAULT_LIMIT),
            currentPage: currentPage,
            onChange: handleChange
        }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l7W0s")).Pagination), {
            dataRef: dataRef,
            itemsPerPage: (0, (0, parcelRequire("gkT9B")).DEFAULT_LIMIT),
            currentPage: currentPage,
            onChange: handleChange
        });
    };
});
parcelRegister("29id7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fc77703b4d9ebb06$export$2e2bcd8739ae039);
    const $fc77703b4d9ebb06$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "pagination_projectsDirectoryV3",
        "selections": [
            {
                "kind": "FragmentSpread",
                "name": "pagination_directoryBaseV3"
            }
        ],
        "type": "JiraProjectConnection"
    };
    $fc77703b4d9ebb06$var$node.hash = "ab05e4ef735cb6b0a98b29d30007a5ef";
    var $fc77703b4d9ebb06$export$2e2bcd8739ae039 = $fc77703b4d9ebb06$var$node;
});
parcelRegister("l7W0s", function(module, exports) {
    $parcel$export(module.exports, "Pagination", ()=>$479573a11f152c22$export$68f5e1453c188a82);
    var $6f0MA;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $xBgAj;
    var $koVbs;
    var $7VJSW;
    const $479573a11f152c22$var$PAGES_TO_DISPLAY = 10;
    const $479573a11f152c22$export$68f5e1453c188a82 = ({ dataRef: dataRef, itemsPerPage: itemsPerPage, currentPage: currentPage, onChange: onChange })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { totalCount: totalCount } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("6f0MA")).default), dataRef);
        const handlePageChange = (0, $5uXOq.useCallback)((_, page)=>{
            onChange(page);
        }, [
            onChange
        ]);
        const pages = (0, $5uXOq.useMemo)(()=>{
            if (!totalCount) return [];
            const totalPages = Math.ceil(totalCount / itemsPerPage);
            const middle = [];
            const end = Math.min(currentPage + $479573a11f152c22$var$PAGES_TO_DISPLAY, totalPages);
            const start = Math.max(currentPage - $479573a11f152c22$var$PAGES_TO_DISPLAY, 1);
            for(let i = start; i < end; i += 1)middle.push(i);
            return Array.from(new Set([
                1,
                ...middle,
                totalPages
            ]));
        }, [
            totalCount,
            itemsPerPage,
            currentPage
        ]);
        const selectedIndex = (0, $5uXOq.useMemo)(()=>pages.findIndex((p)=>p === currentPage), [
            pages,
            currentPage
        ]);
        if (!totalCount) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("xBgAj")).default), {
            pages: pages,
            onChange: handlePageChange,
            selectedIndex: selectedIndex,
            nextLabel: formatMessage((0, (0, parcelRequire("7VJSW")).default).next),
            previousLabel: formatMessage((0, (0, parcelRequire("7VJSW")).default).previous)
        });
    };
});
parcelRegister("6f0MA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8ed9309e8fbf8b95$export$2e2bcd8739ae039);
    const $8ed9309e8fbf8b95$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "pagination_directoryBaseV3",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "totalCount"
            }
        ],
        "type": "HasTotal",
        "abstractKey": "__isHasTotal"
    };
    $8ed9309e8fbf8b95$var$node.hash = "38583de0cf0e75e654e4970a8d336719";
    var $8ed9309e8fbf8b95$export$2e2bcd8739ae039 = $8ed9309e8fbf8b95$var$node;
});
parcelRegister("xBgAj", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8aaadfaafd170b3c$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $6QvkM;
    var $eGmKJ;
    var $33etG;
    var $fmJTX;
    var $6s76W;
    var $dC5iT;
    var $f5TWP;
    var $8CUq3;
    var $5HBW9;
    var $15F6T;
    var $lmPue;
    var $fc5SA;
    var $gRWrA;
    const $8aaadfaafd170b3c$var$analyticsAttributes = {
        componentName: 'pagination',
        packageName: "platform-pkg",
        packageVersion: "0.0.0-use.local"
    };
    const $8aaadfaafd170b3c$var$paginationMenuStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        padding: 'space.0',
        margin: 'space.0'
    });
    const $8aaadfaafd170b3c$var$paginationMenuItemStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlockStart: 'space.0'
    });
    const $8aaadfaafd170b3c$var$navigatorIconWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingInline: 'space.075'
    });
    function $8aaadfaafd170b3c$var$NavigatorIcon({ chevronDirection: chevronDirection }) {
        const Chevron = chevronDirection === 'left' ? (0, ($parcel$interopDefault((0, parcelRequire("fmJTX"))))) : (0, ($parcel$interopDefault((0, parcelRequire("6s76W")))));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $8aaadfaafd170b3c$var$navigatorIconWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement(Chevron, {
            label: "",
            LEGACY_margin: `0 ${"var(--ds-space-negative-075, -6px)"}`,
            color: "currentColor"
        }));
    }
    function $8aaadfaafd170b3c$var$InnerPagination({ components: components = (0, (0, parcelRequire("fc5SA")).emptyObject), defaultSelectedIndex: defaultSelectedIndex = 0, selectedIndex: selectedIndex, label: label = 'pagination', pageLabel: pageLabel = 'page', previousLabel: previousLabel = 'previous', nextLabel: nextLabel = 'next', style: style = (0, (0, parcelRequire("fc5SA")).emptyObject), max: max = 7, onChange: onChange = (0, (0, parcelRequire("eGmKJ")).default), pages: pages, getPageLabel: getPageLabel, renderEllipsis: renderEllipsis = (0, (0, parcelRequire("lmPue")).default), analyticsContext: analyticsContext, testId: testId, isDisabled: isDisabled }, ref) {
        const [selectedIndexValue, setSelectedIndexValue] = (0, (0, parcelRequire("33etG")).default)(selectedIndex, ()=>defaultSelectedIndex || 0);
        const onChangeWithAnalytics = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: (value, analyticsEvent)=>{
                const { event: event, selectedPageIndex: selectedPageIndex } = value;
                if (selectedIndex === undefined) setSelectedIndexValue(selectedPageIndex);
                if (onChange) onChange(event, pages[selectedPageIndex], analyticsEvent);
            },
            action: 'changed',
            actionSubject: 'pageNumber',
            analyticsData: analyticsContext,
            ...$8aaadfaafd170b3c$var$analyticsAttributes
        });
        const transform = (page, currPageIndex, testId)=>{
            const selectedPage = pages[selectedIndexValue];
            const pageIndexLabel = `${pageLabel} ${getPageLabel ? getPageLabel(page, currPageIndex) : page}`;
            const isCurrentPage = page === selectedPage;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
                as: "li",
                xcss: $8aaadfaafd170b3c$var$paginationMenuItemStyles,
                key: `page-${getPageLabel ? getPageLabel(page, currPageIndex) : currPageIndex}`
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("15F6T")).default), {
                component: components.Page,
                onClick: (event)=>onChangeWithAnalytics({
                        event: event,
                        selectedPageIndex: currPageIndex
                    }),
                "aria-current": isCurrentPage ? 'page' : undefined,
                "aria-label": pageIndexLabel,
                isSelected: isCurrentPage,
                isDisabled: isDisabled,
                page: page,
                testId: testId && `${testId}--${isCurrentPage ? 'current-' : ''}page-${currPageIndex}`
            }, getPageLabel ? getPageLabel(page, currPageIndex) : page));
        };
        return ((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            testId: testId,
            style: style,
            ref: ref,
            "aria-label": label,
            as: "nav"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.0",
            alignBlock: "center"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5HBW9")).default), {
            key: "left-navigator",
            component: components.Previous,
            onClick: (event)=>onChangeWithAnalytics({
                    event: event,
                    selectedPageIndex: selectedIndexValue - 1
                }),
            isDisabled: isDisabled || selectedIndexValue === 0,
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement($8aaadfaafd170b3c$var$NavigatorIcon, {
                chevronDirection: "left"
            }),
            "aria-label": previousLabel,
            testId: testId && `${testId}--left-navigator`
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.0",
            alignBlock: "baseline",
            as: "ul",
            xcss: $8aaadfaafd170b3c$var$paginationMenuStyles
        }, (0, (0, parcelRequire("gRWrA")).default)(pages, selectedIndexValue, {
            max: max,
            ellipsis: renderEllipsis,
            transform: transform
        }, testId)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5HBW9")).default), {
            key: "right-navigator",
            component: components.Next,
            onClick: (event)=>onChangeWithAnalytics({
                    event: event,
                    selectedPageIndex: selectedIndexValue + 1
                }),
            isDisabled: isDisabled || selectedIndexValue === pages.length - 1,
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement($8aaadfaafd170b3c$var$NavigatorIcon, {
                chevronDirection: "right"
            }),
            "aria-label": nextLabel,
            testId: testId && `${testId}--right-navigator`
        }))));
    }
    const $8aaadfaafd170b3c$var$Pagination = (0, $5uXOq.memo)((0, $5uXOq.forwardRef)($8aaadfaafd170b3c$var$InnerPagination));
    var $8aaadfaafd170b3c$export$2e2bcd8739ae039 = $8aaadfaafd170b3c$var$Pagination;
});
parcelRegister("fmJTX", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $b2fc843461eabf96$var$_react = $b2fc843461eabf96$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $b2fc843461eabf96$var$_chevronLeft = $b2fc843461eabf96$var$_interopRequireDefault((parcelRequire("lRheX")));
    var $b2fc843461eabf96$var$_chevronLeftLarge = $b2fc843461eabf96$var$_interopRequireDefault((parcelRequire("a88oa")));
    function $b2fc843461eabf96$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $b2fc843461eabf96$var$ChevronLeftIcon = (props)=>$b2fc843461eabf96$var$_react.default.createElement($b2fc843461eabf96$var$_chevronLeft.default, Object.assign({
            LEGACY_fallbackIcon: $b2fc843461eabf96$var$_chevronLeftLarge.default
        }, props));
    $b2fc843461eabf96$var$ChevronLeftIcon.Name = 'ChevronLeftIconMigration';
    var $b2fc843461eabf96$var$_default = module.exports.default = $b2fc843461eabf96$var$ChevronLeftIcon;
});
parcelRegister("lRheX", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $fe9bebc953515e63$var$_react = $fe9bebc953515e63$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $fe9bebc953515e63$var$_UNSAFE_baseNew = $fe9bebc953515e63$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $fe9bebc953515e63$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $fe9bebc953515e63$var$ChevronLeftIcon = (props)=>$fe9bebc953515e63$var$_react.default.createElement($fe9bebc953515e63$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M7.5 10.5 3 6l4.5-4.5"/>`,
            type: 'utility'
        }, props));
    $fe9bebc953515e63$var$ChevronLeftIcon.displayName = 'ChevronLeftIcon';
    var $fe9bebc953515e63$var$_default = module.exports.default = $fe9bebc953515e63$var$ChevronLeftIcon;
});
parcelRegister("a88oa", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $7601289634e055f6$var$_react = $7601289634e055f6$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $7601289634e055f6$var$_chevronLeft = $7601289634e055f6$var$_interopRequireDefault((parcelRequire("lRheX")));
    function $7601289634e055f6$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $7601289634e055f6$var$ChevronLeftLargeIcon = (props)=>$7601289634e055f6$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="m9.005 10.995 4.593-4.593a.99.99 0 1 1 1.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 0 1-1.4 1.4L9.005 12.41a1 1 0 0 1 0-1.414"/></svg>`
        }, props, {
            newIcon: $7601289634e055f6$var$_chevronLeft.default,
            iconType: "utility"
        }));
    $7601289634e055f6$var$ChevronLeftLargeIcon.displayName = 'ChevronLeftLargeIcon';
    var $7601289634e055f6$var$_default = module.exports.default = $7601289634e055f6$var$ChevronLeftLargeIcon;
});
parcelRegister("6s76W", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $4b2adfad98efb792$var$_react = $4b2adfad98efb792$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $4b2adfad98efb792$var$_chevronRight = $4b2adfad98efb792$var$_interopRequireDefault((parcelRequire("3dGN0")));
    var $4b2adfad98efb792$var$_chevronRightLarge = $4b2adfad98efb792$var$_interopRequireDefault((parcelRequire("h1toL")));
    function $4b2adfad98efb792$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $4b2adfad98efb792$var$ChevronRightIcon = (props)=>$4b2adfad98efb792$var$_react.default.createElement($4b2adfad98efb792$var$_chevronRight.default, Object.assign({
            LEGACY_fallbackIcon: $4b2adfad98efb792$var$_chevronRightLarge.default
        }, props));
    $4b2adfad98efb792$var$ChevronRightIcon.Name = 'ChevronRightIconMigration';
    var $4b2adfad98efb792$var$_default = module.exports.default = $4b2adfad98efb792$var$ChevronRightIcon;
});
parcelRegister("h1toL", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $c6495598d3d7027d$var$_react = $c6495598d3d7027d$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $c6495598d3d7027d$var$_chevronRight = $c6495598d3d7027d$var$_interopRequireDefault((parcelRequire("3dGN0")));
    function $c6495598d3d7027d$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $c6495598d3d7027d$var$ChevronRightLargeIcon = (props)=>$c6495598d3d7027d$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M14.995 10.995a1 1 0 0 1 0 1.414l-4.593 4.593a.99.99 0 0 1-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 0 1 1.4-1.4z"/></svg>`
        }, props, {
            newIcon: $c6495598d3d7027d$var$_chevronRight.default,
            iconType: "utility"
        }));
    $c6495598d3d7027d$var$ChevronRightLargeIcon.displayName = 'ChevronRightLargeIcon';
    var $c6495598d3d7027d$var$_default = module.exports.default = $c6495598d3d7027d$var$ChevronRightLargeIcon;
});
parcelRegister("5HBW9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$63acbbc2953d9b5a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    function $63acbbc2953d9b5a$export$2e2bcd8739ae039(props) {
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            "aria-label": props['aria-label'],
            isDisabled: props.isDisabled,
            iconBefore: props.iconBefore,
            testId: props.testId,
            onClick: props.onClick,
            component: props.component,
            appearance: "subtle"
        });
    }
});
parcelRegister("15F6T", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$097dd42f19f41562$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    function $097dd42f19f41562$export$2e2bcd8739ae039(props) {
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            ...props,
            appearance: "subtle"
        });
    }
});
parcelRegister("lmPue", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3d144cd0d91a90ab$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $cgL5K;
    var $8CUq3;
    var $jldZL;
    const $3d144cd0d91a90ab$var$containerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        position: 'relative'
    });
    function $3d144cd0d91a90ab$export$2e2bcd8739ae039({ key: key, testId: testId, from: from, to: to }) {
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "li",
            testId: testId,
            key: key,
            xcss: $3d144cd0d91a90ab$var$containerStyles,
            paddingInline: "space.100"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            testId: testId && `${testId}-text`
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jldZL")).default), null, "Skipped pages from ", from, " to ", to), "\u2026"));
    }
});
parcelRegister("fc5SA", function(module, exports) {
    $parcel$export(module.exports, "emptyObject", ()=>$cbef1c2cfb373aca$export$3f860eba1218f8d3);
    const $cbef1c2cfb373aca$export$3f860eba1218f8d3 = {};
});
parcelRegister("gRWrA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$48fbac64848b775a$export$2e2bcd8739ae039);
    var $8QpSo;
    const $48fbac64848b775a$var$collapseRange = (pages, current, { max: max, ellipsis: ellipsis, transform: transform }, testId)=>{
        const total = pages.length;
        const needEllipsis = total > max;
        const hasStartEllipsis = needEllipsis && max - 4 < current;
        const hasEndEllipsis = needEllipsis && current < total - max + 3;
        const getPageComponents = (0, (0, parcelRequire("8QpSo")).default)((startIndex = 0, lastIndex = total)=>{
            return pages.slice(startIndex, lastIndex).map((page, index)=>transform(page, startIndex + index, testId));
        });
        if (!needEllipsis) return getPageComponents(0, total);
        if (hasStartEllipsis && !hasEndEllipsis) {
            const pageCount = max - 2;
            return [
                ...getPageComponents(0, 1),
                ellipsis({
                    key: 'ellipsis-1',
                    testId: testId && `${testId}-ellipsis`,
                    from: 2,
                    to: total - pageCount
                }),
                ...getPageComponents(total - pageCount)
            ];
        }
        if (!hasStartEllipsis && hasEndEllipsis) {
            const pageCount = max - 2;
            return [
                ...getPageComponents(0, pageCount),
                ellipsis({
                    key: 'ellipsis-1',
                    testId: testId && `${testId}-ellipsis`,
                    from: pageCount + 1,
                    to: total - 1
                }),
                ...getPageComponents(total - 1)
            ];
        }
        const pageCount = max - 4;
        return [
            ...getPageComponents(0, 1),
            ellipsis({
                key: 'ellipsis-1',
                testId: testId && `${testId}-ellipsis`,
                from: 2,
                to: current - Math.floor(pageCount / 2)
            }),
            ...getPageComponents(current - Math.floor(pageCount / 2), current + pageCount - 1),
            ellipsis({
                key: 'ellipsis-2',
                testId: testId && `${testId}-ellipsis`,
                from: current + 3,
                to: total - 1
            }),
            ...getPageComponents(total - 1)
        ];
    };
    var $48fbac64848b775a$export$2e2bcd8739ae039 = $48fbac64848b775a$var$collapseRange;
});
parcelRegister("7VJSW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f76bb650b37806f0$export$2e2bcd8739ae039);
    var $7VHMR;
    var $f76bb650b37806f0$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        next: {
            "id": "pagination.next",
            "defaultMessage": "Next page"
        },
        previous: {
            "id": "pagination.previous",
            "defaultMessage": "Previous page"
        }
    });
});
parcelRegister("jCHzE", function(module, exports) {
    $parcel$export(module.exports, "ResultsTable", ()=>$373842dac8dd0b01$export$cd4354c9a18aa0b5);
    var $bo0Ax;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $4R6GH;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $gkT9B;
    var $3ixDM;
    var $cZpsT;
    var $dPvGX;
    var $gxECQ;
    var $1Mh8b;
    var $6KK5t;
    const $373842dac8dd0b01$export$cd4354c9a18aa0b5 = ({ shouldShowCategories: shouldShowCategories, projectsRef: projectsRef, jiraQueryRef: jiraQueryRef, isAdminSettingsContext: isAdminSettingsContext })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const isAnonymous = (0, (0, parcelRequire("cZpsT")).useIsAnonymous)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'changed',
            actionSubject: 'resultsTable'
        });
        const [filter, { updateFilter: updateFilter }] = (0, (0, parcelRequire("dPvGX")).useFilter)();
        const projects = (0, $8Uumt.useFragment)((0, (0, parcelRequire("bo0Ax")).default), projectsRef);
        const head = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("1Mh8b")).getTableHeader)({
                formatMessage: formatMessage,
                isAnonymous: isAnonymous,
                shouldShowCategories: shouldShowCategories,
                isAdminSettingsContext: isAdminSettingsContext
            }), [
            formatMessage,
            isAnonymous,
            shouldShowCategories,
            isAdminSettingsContext
        ]);
        const projectsItems = (0, $5uXOq.useMemo)(()=>{
            const totalCount = projects?.totalCount || 0;
            return projects?.pageInfo?.hasNextPage ? projects?.edges : projects?.edges?.slice(totalCount % (0, (0, parcelRequire("gkT9B")).DEFAULT_LIMIT) * -1);
        }, [
            projects?.edges,
            projects?.pageInfo?.hasNextPage,
            projects?.totalCount
        ]);
        const rows = (0, $5uXOq.useMemo)(()=>projectsItems?.map((project)=>project && (0, (0, parcelRequire("6KK5t")).getTableRow)(project.node, isAdminSettingsContext, jiraQueryRef)).filter(Boolean), [
            projectsItems,
            isAdminSettingsContext,
            jiraQueryRef
        ]);
        const handleSort = (0, $5uXOq.useCallback)(({ key: key, sortOrder: sortOrder })=>{
            updateFilter({
                [(0, (0, parcelRequire("gkT9B")).FilterFields).SortOrder]: sortOrder,
                [(0, (0, parcelRequire("gkT9B")).FilterFields).SortKey]: key
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'SortOrder', {
                sortOrder: sortOrder,
                sortKey: key
            });
        }, [
            analyticsEvent,
            updateFilter
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if ((0, (0, parcelRequire("4R6GH")).fg)('blu-4931-jracloud-76302-use-url-sort-key')) handleSort({
                key: filter[(0, (0, parcelRequire("gkT9B")).FilterFields).SortKey],
                sortOrder: filter[(0, (0, parcelRequire("gkT9B")).FilterFields).SortOrder]
            });
        }, []);
        const getCaptionText = ()=>{
            const selectedProductsKeys = filter[(0, (0, parcelRequire("gkT9B")).FilterFields).ProjectType]?.split(',').filter(Boolean) || [];
            const selectedProjectCategory = filter[(0, (0, parcelRequire("gkT9B")).FilterFields).ProjectCategoryLabel];
            const selectedProductsLabels = filter[(0, (0, parcelRequire("gkT9B")).FilterFields).ProjectTypeLabel];
            if (!selectedProductsKeys.length) {
                if (!shouldShowCategories) return formatMessage((0, (0, parcelRequire("gxECQ")).default).tableCaptionAllProjects);
                if (!selectedProjectCategory) return formatMessage((0, (0, parcelRequire("gxECQ")).default).tableCaptionAllProjectsAndAllCategories);
                return formatMessage((0, (0, parcelRequire("gxECQ")).default).tableCaptionSpecificCategory, {
                    category: selectedProjectCategory
                });
            }
            if (!shouldShowCategories) return formatMessage((0, (0, parcelRequire("gxECQ")).default).tableCaptionSpecificProduct, {
                product: selectedProductsLabels
            });
            if (!selectedProjectCategory) return formatMessage((0, (0, parcelRequire("gxECQ")).default).tableCaptionSpecificProductAllCategories, {
                product: selectedProductsLabels
            });
            return formatMessage((0, (0, parcelRequire("gxECQ")).default).tableCaptionSpecificProductSpecificCategories, {
                product: selectedProductsLabels,
                category: selectedProjectCategory
            });
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3ixDM")).SimpleTable), {
            captionText: getCaptionText(),
            head: head,
            rows: rows,
            sortKey: filter[(0, (0, parcelRequire("gkT9B")).FilterFields).SortKey],
            sortOrder: filter[(0, (0, parcelRequire("gkT9B")).FilterFields).SortOrder],
            onSort: handleSort
        });
    };
});
parcelRegister("bo0Ax", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$965de2b3474cfb1d$export$2e2bcd8739ae039);
    const $965de2b3474cfb1d$var$node = {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAdminSettingsContext"
            }
        ],
        "kind": "Fragment",
        "name": "resultsTable_projectsDirectoryV3",
        "selections": [
            {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "FragmentSpread",
                                "name": "favouriteCell_projectsDirectoryV3_FavoriteCell"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "nameCell_projectsDirectoryV3"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "keyCell_projectsDirectoryV3"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "projectTypeCell_projectsDirectoryV3"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "projectCategoryCell_projectsDirectoryV3"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "leadCell_projectsDirectoryV3"
                            },
                            {
                                "condition": "isAdminSettingsContext",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "lastIssueUpdateCell_projectsDirectoryV3"
                                    }
                                ]
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "urlCell_projectsDirectoryV3"
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "actionsCell_projectsDirectoryV3_project"
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "name": "totalCount"
            },
            {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                    }
                ]
            }
        ],
        "type": "JiraProjectConnection"
    };
    $965de2b3474cfb1d$var$node.hash = "9ed13593445dfde5eabe8be75addbd2e";
    var $965de2b3474cfb1d$export$2e2bcd8739ae039 = $965de2b3474cfb1d$var$node;
});
parcelRegister("3ixDM", function(module, exports) {
    $parcel$export(module.exports, "SimpleTable", ()=>$d3d0fb3942243d15$export$3fe48f9151d0ecc2);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $koVbs;
    var $lITuv;
    var $6i8tl;
    var $1y0Lu;
    var $aK8yz;
    const $d3d0fb3942243d15$export$3fe48f9151d0ecc2 = ({ head: head, rows: rows, sortKey: sortKey, sortOrder: sortOrder, onSort: onSort, captionText: captionText })=>{
        const columnKeys = (0, $5uXOq.useMemo)(()=>head.cells.map((cell)=>cell.key), [
            head
        ]);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const alertMessage = sortOrder === (0, (0, parcelRequire("6i8tl")).SortOrder).ASC ? (0, (0, parcelRequire("lITuv")).default).srOnlySortingAlertAsc : (0, (0, parcelRequire("lITuv")).default).srOnlySortingAlertDesc;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($d3d0fb3942243d15$var$Table, null, captionText ? (0, ($parcel$interopDefault($5uXOq))).createElement($d3d0fb3942243d15$var$SrOnlyCaption, null, captionText) : (0, ($parcel$interopDefault($5uXOq))).createElement($d3d0fb3942243d15$var$SrOnlyCaption, null, formatMessage((0, (0, parcelRequire("lITuv")).default).srOnlyCaption), sortOrder && (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            "aria-live": "polite",
            "aria-relevant": "text"
        }, formatMessage(alertMessage, {
            sortKey: sortKey
        }))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1y0Lu")).TableHead), {
            onSort: onSort,
            ...head,
            sortKey: sortKey,
            sortOrder: sortOrder
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("tbody", null, rows.map((rowProps, index)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aK8yz")).TableRow), {
                columnKeys: columnKeys,
                ...rowProps,
                key: `table-row-${index}`
            }))));
    };
    const $d3d0fb3942243d15$var$Table = (0, $5uXOq.forwardRef)(({ as: C = "table", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_yq5hcfaq _1bsb1osq _1kqm1n9t _18s81km1",
                __cmplp.className
            ])
        });
    });
    const $d3d0fb3942243d15$var$srOnlyStyles = null;
    const $d3d0fb3942243d15$var$SrOnlyCaption = (0, $5uXOq.forwardRef)(({ as: C = "caption", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswstnw _154i1wug _1reo15vq _18m915vq _ogto7mnp _1bsbt94y _4t3it94y _o5721q9c",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("lITuv", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$52289dd44420c4f5$export$2e2bcd8739ae039);
    var $7VHMR;
    var $52289dd44420c4f5$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        srOnlyCaption: {
            "id": "simple-table.sr-only-caption",
            "defaultMessage": "Use column header buttons to sort"
        },
        srOnlySortingAlertAsc: {
            "id": "simple-table.sr-only-sorting-alert-asc",
            "defaultMessage": "{sortKey} column is sorted by ascending order"
        },
        srOnlySortingAlertDesc: {
            "id": "simple-table.sr-only-sorting-alert-desc",
            "defaultMessage": "{sortKey} column is sorted by descending order"
        }
    });
});
parcelRegister("6i8tl", function(module, exports) {
    $parcel$export(module.exports, "SortOrder", ()=>$826aa536310f15bc$export$7ae723045d45777a);
    $parcel$export(module.exports, "SortOrderLabel", ()=>$826aa536310f15bc$export$6123155563a1925);
    const $826aa536310f15bc$export$7ae723045d45777a = {
        ASC: 'ASC',
        DESC: 'DESC'
    };
    const $826aa536310f15bc$export$6123155563a1925 = {
        ASC: 'ascending',
        DESC: 'descending'
    };
});
parcelRegister("1y0Lu", function(module, exports) {
    $parcel$export(module.exports, "TableHead", ()=>$3109ad13e80c3b31$export$2f4a7be4f0dcc2);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $dkGRB;
    const $3109ad13e80c3b31$export$2f4a7be4f0dcc2 = ({ cells: cells, sortOrder: sortOrder, sortKey: sortKey, onSort: onSort })=>(0, ($parcel$interopDefault($5uXOq))).createElement($3109ad13e80c3b31$var$TableHeadContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement($3109ad13e80c3b31$var$TableHeadRowContainer, null, cells.map((headCell)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dkGRB")).HeadCell), {
                ...headCell,
                key: headCell.key,
                onSort: onSort,
                sortOrder: sortOrder,
                sortKey: sortKey,
                cellKey: headCell.key
            }))));
    const $3109ad13e80c3b31$var$SortIconContainerSelector = 'simple-table-sort-icon-container';
    const $3109ad13e80c3b31$var$SortableIconCellContentWrapperSelector = 'simple-table-sortable-icon-cell-content-wrapper';
    const $3109ad13e80c3b31$var$TableHeadContainer = (0, $5uXOq.forwardRef)(({ as: C = "thead", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_s7n4nkob _19itglyw _1r04idpf _kqswh2mm",
                __cmplp.className
            ])
        });
    });
    const $3109ad13e80c3b31$var$TableHeadRowContainer = (0, $5uXOq.forwardRef)(({ as: C = "tr", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i14no _189e18uv _1dqoglyw _1h6d18uv _10dk105o _bd0s105o _aswu1b66 _d1ad1b66 _174iidpf _1thxidpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("dkGRB", function(module, exports) {
    $parcel$export(module.exports, "HeadCell", ()=>$cb5bbd562c0ca574$export$4e8024263ace498b);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $ls8Y7;
    var $3bDjY;
    var $koVbs;
    var $dCm1o;
    var $6i8tl;
    var $2XBKO;
    const $cb5bbd562c0ca574$var$TooltipContent = ({ children: children, sortOrder: sortOrder })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($cb5bbd562c0ca574$var$TooltipContentWrapper, null, children, " \u2022", ' ', formatMessage(sortOrder === (0, (0, parcelRequire("6i8tl")).SortOrder).ASC ? (0, (0, parcelRequire("2XBKO")).default).tooltipAscSortingDirectionContent : (0, (0, parcelRequire("2XBKO")).default).tooltipDescSortingDirectionContent));
    };
    const $cb5bbd562c0ca574$var$TooltipWrapper = ({ tooltipContent: tooltipContent, content: content, sortOrder: sortOrder, children: children })=>{
        if (false) return children;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: tooltipContent || (0, ($parcel$interopDefault($5uXOq))).createElement($cb5bbd562c0ca574$var$TooltipContent, {
                sortOrder: sortOrder
            }, content),
            position: "top"
        }, children);
    };
    const $cb5bbd562c0ca574$export$1c3e7abe01fad210 = 'simple-table-sortable-icon-cell-content-wrapper';
    const $cb5bbd562c0ca574$export$4e8024263ace498b = ({ isSortable: isSortable, cellKey: cellKey, content: content, sortKey: sortKey, sortOrder: sortOrder, tooltipContent: tooltipContent, onSort: onSort, width: width, textAlign: textAlign, isIconColumn: isIconColumn, element: element })=>{
        const isActive = cellKey === sortKey;
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const handleClick = (0, $5uXOq.useCallback)(()=>onSort({
                key: cellKey,
                sortOrder: isActive && sortOrder === (0, (0, parcelRequire("6i8tl")).SortOrder).ASC ? (0, (0, parcelRequire("6i8tl")).SortOrder).DESC : (0, (0, parcelRequire("6i8tl")).SortOrder).ASC
            }), [
            cellKey,
            sortOrder,
            onSort,
            isActive
        ]);
        let sortableValue;
        if (isSortable) {
            sortableValue = 'none';
            if (isActive && sortOrder) sortableValue = (0, (0, parcelRequire("6i8tl")).SortOrderLabel)[sortOrder];
        }
        const buttonProperties = {
            'aria-roledescription': isSortable ? formatMessage((0, (0, parcelRequire("2XBKO")).default).a11YSortButtonDescriptionLabel) : undefined
        };
        const wrappedContent = isIconColumn ? (0, ($parcel$interopDefault($5uXOq))).createElement($cb5bbd562c0ca574$var$IconCellContentWrapper, {
            "data-component-selector": isSortable ? $cb5bbd562c0ca574$export$1c3e7abe01fad210 : '',
            isSortable: isSortable,
            isActive: isActive
        }, content) : content;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($cb5bbd562c0ca574$var$HeadCellContainer, {
            as: element,
            isSortable: isSortable,
            width: width,
            "aria-sort": sortableValue,
            textAlign: textAlign,
            isIconColumn: isIconColumn
        }, isSortable ? (0, ($parcel$interopDefault($5uXOq))).createElement($cb5bbd562c0ca574$var$TooltipWrapper, {
            tooltipContent: tooltipContent,
            content: content,
            sortOrder: sortOrder
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($cb5bbd562c0ca574$var$SortHeadCellContainer, {
            width: width,
            isActive: isActive,
            onClick: handleClick,
            isIconColumn: isIconColumn,
            isVisualRefresh: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)(),
            ...buttonProperties
        }, wrappedContent, (0, ($parcel$interopDefault($5uXOq))).createElement($cb5bbd562c0ca574$var$SortIconContainer, {
            isActive: isActive,
            sortOrder: sortOrder,
            isIconColumn: isIconColumn
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("ls8Y7"))))), {
            size: "small",
            label: ""
        })))) : wrappedContent);
    };
    const $cb5bbd562c0ca574$var$SortIconContainerSelector = 'simple-table-sort-icon-container';
    const $cb5bbd562c0ca574$var$SortIconContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isActive: isActive, isIconColumn: isIconColumn, sortOrder: sortOrder, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_v5641b4w",
                __cmplp.isActive ? "_tzy4zg3v" : "_tzy4idpf",
                __cmplp.isActive || __cmplp.isIconColumn ? "_18u01b66" : "_18u0idpf",
                __cmplp.isIconColumn ? "_2hwx1b66" : "_2hwxidpf",
                __cmplp.sortOrder === (0, (0, parcelRequire("6i8tl")).SortOrder).DESC ? "_t9ec1sub" : "_t9ecglyw",
                __cmplp.className
            ])
        });
    });
    $cb5bbd562c0ca574$var$SortIconContainer.defaultProps = {
        'data-component-selector': $cb5bbd562c0ca574$var$SortIconContainerSelector
    };
    const $cb5bbd562c0ca574$var$IconCellContentWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSortable: isSortable, isActive: isActive, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_154i1ssb _1ltv1ssb _v564wz4i",
                __cmplp.isSortable ? "_kqswstnw" : "_kqswn7od",
                __cmplp.isActive ? "_tzy4grf3" : "_tzy4kb7n",
                __cmplp.isSortable ? "_t9ec1ooe" : "_t9ecglyw",
                __cmplp.className
            ])
        });
    });
    const $cb5bbd562c0ca574$var$HeadCellContainer = (0, $5uXOq.forwardRef)(({ as: C = "th", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSortable: isSortable, isIconColumn: isIconColumn, textAlign: textAlign, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1vcp0mh": (0, (0, parcelRequire("lD23V")).default)(__cmplp.width, "%"),
                "--_1tpx2gk": (0, (0, parcelRequire("lD23V")).default)(__cmplp.textAlign)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0cdav6 _kqswh2mm _r06hglyw _s7n4nkob _5pio18uv _1h6d18uv _1dqoglyw _189e18uv _vchhusvi _k48pbfng _1wybdlk8 _syaz10s3 _1cs8stnw _txdo8fd9 _1kt9b3bt _1tesidpf _dsq4glyw _19woidpf _1aukidpf",
                __cmplp.isSortable || __cmplp.isIconColumn && __cmplp.textAlign === 'center' ? "_19bvidpf" : "_19bvu2gc",
                __cmplp.isIconColumn && __cmplp.textAlign === 'center' ? "_u5f3idpf" : "_u5f3u2gc",
                __cmplp.width ? "_1bsb1efm" : "_1bsb1osq",
                __cmplp.textAlign && "_y3gnp1rm",
                __cmplp.isSortable || __cmplp.isIconColumn && __cmplp.textAlign === 'center' ? "_1lhcidpf" : "_1lhcu2gc",
                __cmplp.isIconColumn && __cmplp.textAlign === 'center' ? "_1o59idpf" : "_1o59u2gc",
                __cmplp.className
            ])
        });
    });
    const $cb5bbd562c0ca574$var$SortHeadCellContainer = (0, $5uXOq.forwardRef)(({ as: C = "button", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isIconColumn: isIconColumn, isVisualRefresh: isVisualRefresh, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_tsntec": (0, (0, parcelRequire("lD23V")).default)(`${"var(--ds-space-050, 4px)"} ${__cmplp.isIconColumn && __cmplp.isVisualRefresh ? "var(--ds-space-0, 0px)" : "var(--ds-space-100, 8px)"}`)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19itidpf _11q7glyw _k48pbfng _1wybdlk8 _syaz10s3 _16qsidpf _1e0c116y _1yt46tij _2rko1l7b _80omtlke _j6xthri9 _19lchri9",
                __cmplp.isIconColumn ? "_kqswh2mm" : "_kqswn7od",
                __cmplp.className
            ])
        });
    });
    const $cb5bbd562c0ca574$var$TooltipContentWrapper = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1p1d1dk0",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("ls8Y7", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $f9e32b81d01f795b$var$_react = $f9e32b81d01f795b$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $f9e32b81d01f795b$var$_arrowDown = $f9e32b81d01f795b$var$_interopRequireDefault((parcelRequire("cC5ib")));
    function $f9e32b81d01f795b$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $f9e32b81d01f795b$var$ArrowDownIcon = (props)=>$f9e32b81d01f795b$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M11 6v9.586l-3.793-3.793a1 1 0 0 0-1.414 0c-.39.39-.39 1.024 0 1.415l5.5 5.499A1 1 0 0 0 12 19a1 1 0 0 0 .707-.293l5.5-5.499a1 1 0 1 0-1.414-1.415L13 15.586V6a1 1 0 0 0-2 0"/></svg>`
        }, props, {
            newIcon: $f9e32b81d01f795b$var$_arrowDown.default
        }));
    $f9e32b81d01f795b$var$ArrowDownIcon.displayName = 'ArrowDownIcon';
    var $f9e32b81d01f795b$var$_default = module.exports.default = $f9e32b81d01f795b$var$ArrowDownIcon;
});
parcelRegister("cC5ib", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $92ed238f23140736$var$_react = $92ed238f23140736$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $92ed238f23140736$var$_UNSAFE_baseNew = $92ed238f23140736$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $92ed238f23140736$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $92ed238f23140736$var$ArrowDownIcon = (props)=>$92ed238f23140736$var$_react.default.createElement($92ed238f23140736$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M8 1v12.75m-5-4.5 5 5 5-5"/>`
        }, props));
    $92ed238f23140736$var$ArrowDownIcon.displayName = 'ArrowDownIcon';
    var $92ed238f23140736$var$_default = module.exports.default = $92ed238f23140736$var$ArrowDownIcon;
});
parcelRegister("2XBKO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$04126d925cf2d546$export$2e2bcd8739ae039);
    var $7VHMR;
    var $04126d925cf2d546$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        tooltipAscSortingDirectionContent: {
            "id": "simple-table.table-head.table-head-cell.tooltip-asc-sorting-direction-content",
            "defaultMessage": "A \u279E Z"
        },
        tooltipDescSortingDirectionContent: {
            "id": "simple-table.table-head.table-head-cell.tooltip-desc-sorting-direction-content",
            "defaultMessage": "Z \u279E A"
        },
        a11YSortButtonDescriptionLabel: {
            "id": "simple-table.table-head.table-head-cell.a-11-y-sort-button-description-label",
            "defaultMessage": "column sort button"
        }
    });
});
parcelRegister("aK8yz", function(module, exports) {
    $parcel$export(module.exports, "TableRow", ()=>$a4795e7a0830d9f7$export$b05581f4e764e162);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $3fed9;
    const $a4795e7a0830d9f7$export$b05581f4e764e162 = ({ columnKeys: columnKeys, cells: cells })=>{
        const Cells = (0, $5uXOq.useMemo)(()=>cells.filter((cell)=>columnKeys.includes(cell.key)).map(({ key: key, content: content })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3fed9")).CellContainer), {
                    key: `table-row-cell-${key}`
                }, content)), [
            columnKeys,
            cells
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($a4795e7a0830d9f7$var$TableRowContainer, null, Cells);
    };
    const $a4795e7a0830d9f7$var$TableRowContainer = (0, $5uXOq.forwardRef)(({ as: C = "tr", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3ickbl _kqswh2mm _189e18uv _1dqoglyw _1h6d18uv _1bsb1osq _irr3k6hx",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("3fed9", function(module, exports) {
    $parcel$export(module.exports, "CellContainer", ()=>$5df3abcdfbf91a1b$export$287da707e3318210);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $5df3abcdfbf91a1b$export$287da707e3318210 = (0, $5uXOq.forwardRef)(({ as: C = "td", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_r06hglyw _5pio18uv _1h6d18uv _1dqoglyw _189e18uv _vchhusvi _1e0cdav6 _19bvu2gc _u5f3u2gc _kqswh2mm _s7n4nkob _1bsbzwfg _1lhcu2gc _1o59u2gc",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("gxECQ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1839cdb82b552cc6$export$2e2bcd8739ae039);
    var $7VHMR;
    var $1839cdb82b552cc6$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        tableCaptionAllProjects: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-caption-all-projects",
            "defaultMessage": "Table showing all projects"
        },
        tableCaptionAllProjectsAndAllCategories: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-caption-all-projects-and-all-categories",
            "defaultMessage": "Table showing all projects for all categories."
        },
        tableCaptionSpecificProduct: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-product",
            "defaultMessage": "Table showing {product} projects."
        },
        tableCaptionSpecificCategory: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-category",
            "defaultMessage": "Table showing all projects for {category} category"
        },
        tableCaptionSpecificProductAllCategories: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-product-all-categories",
            "defaultMessage": "Table showing {product} projects for all categories"
        },
        tableCaptionSpecificProductSpecificCategories: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-product-specific-categories",
            "defaultMessage": "Table showing {product} projects for {category} category"
        }
    });
});
parcelRegister("1Mh8b", function(module, exports) {
    $parcel$export(module.exports, "getTableHeader", ()=>$bdb65469ab756b5d$export$ee524713fdf0677d);
    var $5uXOq = parcelRequire("5uXOq");
    var $6s1PB;
    var $gkT9B;
    var $eAfBM;
    var $eErHx;
    const $bdb65469ab756b5d$export$ee524713fdf0677d = ({ formatMessage: formatMessage, isAnonymous: isAnonymous, shouldShowCategories: shouldShowCategories, isAdminSettingsContext: isAdminSettingsContext })=>({
            cells: [
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Favourites,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eAfBM")).StarredHeader), {
                        label: formatMessage((0, (0, parcelRequire("eErHx")).messages).starred)
                    }),
                    width: 2.85,
                    testId: 'jira-project-directory.results-table.header.favourites',
                    disabled: isAnonymous,
                    isIconColumn: true,
                    textAlign: 'center'
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Name,
                    content: formatMessage((0, (0, parcelRequire("eErHx")).messages).name),
                    width: 22,
                    testId: 'jira-project-directory.results-table.header.name'
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Key,
                    content: formatMessage((0, (0, parcelRequire("eErHx")).messages).key),
                    width: 12,
                    testId: 'jira-project-directory.results-table.header.key'
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Type,
                    content: formatMessage((0, (0, parcelRequire("eErHx")).messages).type),
                    width: 20,
                    testId: 'jira-project-directory.results-table.header.type'
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Lead,
                    content: formatMessage((0, (0, parcelRequire("eErHx")).messages).owner),
                    width: shouldShowCategories ? 20 : 36,
                    testId: 'jira-project-directory.results-table.header.owner'
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Category,
                    content: formatMessage((0, (0, parcelRequire("eErHx")).messages).category),
                    width: 16,
                    testId: 'jira-project-directory.results-table.header.category',
                    disabled: !shouldShowCategories
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).LastIssueUpdate,
                    content: formatMessage((0, (0, parcelRequire("6s1PB")).expVal)('issue-terminology-refresh-m2-replace', 'isEnabled', false) ? (0, (0, parcelRequire("eErHx")).messages).lastIssueUpdateIssueTermRefresh : (0, (0, parcelRequire("eErHx")).messages).lastIssueUpdate),
                    width: 16,
                    testId: 'jira-project-directory.results-table.header.last-issue-update',
                    disabled: !isAdminSettingsContext
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Url,
                    content: formatMessage((0, (0, parcelRequire("eErHx")).messages).projectUrl),
                    width: 7,
                    testId: 'jira-project-directory.results-table.header.url'
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Action,
                    content: formatMessage((0, (0, parcelRequire("eErHx")).messages).action),
                    width: 8,
                    textAlign: 'right',
                    testId: 'jira-project-directory.results-table.header.action'
                }
            ].filter((cell)=>!cell.disabled).map((cell)=>({
                    ...cell,
                    isSortable: (0, (0, parcelRequire("gkT9B")).SortableColumns).includes(cell.key)
                }))
        });
});
parcelRegister("eAfBM", function(module, exports) {
    $parcel$export(module.exports, "StarredHeader", ()=>$3b5b4e0536886ce1$export$808dc759b0b23f14);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $5IcUR;
    const $3b5b4e0536886ce1$export$808dc759b0b23f14 = ({ label: label })=>(0, ($parcel$interopDefault($5uXOq))).createElement($3b5b4e0536886ce1$var$ContentWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("5IcUR"))))), {
            color: "currentColor",
            label: label,
            LEGACY_size: "small"
        }));
    const $3b5b4e0536886ce1$var$ContentWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bah1h6o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("eErHx", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$2c4015d2e43a75d8$export$defe85febe8b728c);
    var $7VHMR;
    const $2c4015d2e43a75d8$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        starred: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.starred",
            "defaultMessage": "Starred"
        },
        name: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.name",
            "defaultMessage": "Name"
        },
        key: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.key",
            "defaultMessage": "Key"
        },
        type: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.type",
            "defaultMessage": "Type"
        },
        owner: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.owner",
            "defaultMessage": "Lead"
        },
        category: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.category",
            "defaultMessage": "Category"
        },
        lastIssueUpdate: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.last-issue-update",
            "defaultMessage": "Last issue update"
        },
        projectUrl: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.project-url",
            "defaultMessage": "Project URL"
        },
        action: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.action",
            "defaultMessage": "More actions"
        },
        lastIssueUpdateIssueTermRefresh: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-head.last-issue-update-issue-term-refresh",
            "defaultMessage": "Last work update"
        }
    });
});
parcelRegister("6KK5t", function(module, exports) {
    $parcel$export(module.exports, "getTableRow", ()=>$378b92d264a55d83$export$8a668e19c44a2b13);
    var $5uXOq = parcelRequire("5uXOq");
    var $gkT9B;
    var $1xM33;
    var $5Huo8;
    var $cKtQP;
    var $dpBMT;
    var $9g60v;
    var $1Hnw6;
    var $kf2VM;
    var $65Pfm;
    var $3TDIg;
    const $378b92d264a55d83$export$8a668e19c44a2b13 = (projectRef, isAdminSettingsContext = false, jiraQueryRef)=>({
            cells: [
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Favourites,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Huo8")).FavoriteCell), {
                        project: projectRef
                    })
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Name,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1Hnw6")).NameCell), {
                        project: projectRef
                    })
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Key,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cKtQP")).KeyCell), {
                        project: projectRef
                    })
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Type,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("65Pfm")).ProjectTypeCell), {
                        project: projectRef
                    })
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Lead,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9g60v")).LeadCell), {
                        project: projectRef
                    })
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Category,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kf2VM")).ProjectCategoryCell), {
                        project: projectRef
                    })
                },
                ...isAdminSettingsContext ? [
                    {
                        key: (0, (0, parcelRequire("gkT9B")).TableColumns).LastIssueUpdate,
                        content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dpBMT")).LastIssueUpdateCell), {
                            project: projectRef
                        })
                    }
                ] : [],
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Url,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3TDIg")).UrlCell), {
                        project: projectRef
                    })
                },
                {
                    key: (0, (0, parcelRequire("gkT9B")).TableColumns).Action,
                    content: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1xM33")).ActionsCell), {
                        project: projectRef,
                        jiraQueryRef: jiraQueryRef
                    })
                }
            ]
        });
});
parcelRegister("1xM33", function(module, exports) {
    $parcel$export(module.exports, "ActionsCell", ()=>$953b1735b9f944fe$export$eee95bbb96e96f7b);
    var $ldTWx;
    var $6lRt3;
    var $k8cj1;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $BLPHT;
    var $6B7KL;
    var $aRoVz;
    var $3oUqw;
    var $i8Qvm;
    var $2vSU6;
    var $47gW9;
    var $ivDCs;
    var $4mO32;
    var $co1wz;
    var $koVbs;
    var $7mPT9;
    var $8zOmE;
    var $2NMNM;
    var $iviep;
    var $4pdD4;
    var $ibPPY;
    var $kRt9N;
    var $iVNLr;
    var $lu5k0;
    var $k9JTq;
    var $itmJb;
    var $hDzhb;
    var $dPvGX;
    var $6wfdY;
    var $biPXN;
    var $3rWFI;
    var $x3l4g;
    const $953b1735b9f944fe$var$PREMIUM = 'PREMIUM';
    function $953b1735b9f944fe$var$useModalReturnFocusTo({ projectToRestore: projectToRestore }) {
        const { setReturnFocusTo: setReturnFocusTo } = (0, (0, parcelRequire("lu5k0")).useModalDialogActions)();
        const renderButtonRef = (0, $5uXOq.useRef)(null);
        const setModalReturnFocusTo = (0, $5uXOq.useCallback)(()=>{
            setReturnFocusTo(renderButtonRef);
        }, [
            setReturnFocusTo,
            renderButtonRef
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (projectToRestore) setModalReturnFocusTo();
        }, [
            projectToRestore,
            setModalReturnFocusTo,
            setReturnFocusTo
        ]);
        return {
            setModalReturnFocusTo: setModalReturnFocusTo,
            renderButtonRef: renderButtonRef
        };
    }
    const $953b1735b9f944fe$var$ActionsCellDropdown = ({ project: project, jiraQueryRef: jiraQueryRef })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'clicked',
            actionSubject: 'resultsTable'
        });
        const isAdmin = (0, (0, parcelRequire("itmJb")).useIsAdmin)();
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        const [, { forceUpdate: forceUpdate }] = (0, (0, parcelRequire("dPvGX")).useFilter)();
        const [isDropdownOpen, setIsDropdownOpen] = (0, $5uXOq.useState)(false);
        const [projectToRestore, setProjectToRestore] = (0, $5uXOq.useState)(null);
        const [activeEntryPoint, setActiveEntryPoint] = (0, $5uXOq.useState)(null);
        const { jiraProjectType: jiraProjectType, jiraProjectName: jiraProjectName, projectStyle: projectStyle, key: key, id: id } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("k8cj1")).default), project);
        const jwmNavigationData = (0, $8Uumt.useFragment)((0, (0, parcelRequire("6lRt3")).default), jiraQueryRef);
        const { setModalReturnFocusTo: setModalReturnFocusTo, renderButtonRef: renderButtonRef } = $953b1735b9f944fe$var$useModalReturnFocusTo({
            projectToRestore: projectToRestore
        });
        const isCorePremiumUserSeat = Boolean(jwmNavigationData?.jwmLicensing?.currentUserSeatEdition === $953b1735b9f944fe$var$PREMIUM);
        const entryPointParams = (0, $5uXOq.useMemo)(()=>({
                projectId: id
            }), [
            id
        ]);
        const { entryPointReferenceSubject: deleteRefSubject, entryPointActions: deleteActions } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("iVNLr")).deleteProjectModalEntryPoint), entryPointParams, undefined, {
            timeToIntent: 50
        });
        const deleteEntryPointRuntimeProps = (0, $5uXOq.useMemo)(()=>({
                onClose: ()=>{},
                onDeleteProjectSuccess: ()=>{
                    forceUpdate();
                    deleteActions.unload();
                },
                onProjectRestore: setProjectToRestore
            }), [
            deleteActions,
            forceUpdate
        ]);
        const deleteTriggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(deleteActions);
        const { entryPointReferenceSubject: archiveRefSubject, entryPointActions: archiveActions } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("kRt9N")).archiveProjectModalEntryPoint), entryPointParams, undefined, {
            timeToIntent: 50
        });
        const archiveEntryPointRuntimeProps = (0, $5uXOq.useMemo)(()=>({
                onClose: ()=>{},
                onArchiveProjectSuccess: ()=>{
                    forceUpdate();
                    archiveActions.unload();
                },
                onProjectRestore: setProjectToRestore
            }), [
            archiveActions,
            forceUpdate
        ]);
        const archiveTriggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(archiveActions);
        const { entryPointReferenceSubject: archiveProjectFeatureGateRefSubject, entryPointActions: archiveProjectFeatureGateActions } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("ibPPY")).archiveProjectFeatureGateModalEntryPoint), {});
        const archiveFeatureGateEntryPointRuntimeProps = (0, $5uXOq.useMemo)(()=>({
                onClose: ()=>{
                    archiveProjectFeatureGateActions.unload();
                }
            }), [
            archiveProjectFeatureGateActions
        ]);
        const archiveFeatureGateTriggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(archiveProjectFeatureGateActions);
        const handleTrashClick = (0, $5uXOq.useCallback)(()=>{
            setModalReturnFocusTo();
            setActiveEntryPoint({
                entryPointReferenceSubject: deleteRefSubject,
                runtimeProps: deleteEntryPointRuntimeProps,
                entryPointActions: deleteActions,
                id: 'project-delete-modal'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'trashDropdownItem');
        }, [
            setModalReturnFocusTo,
            deleteRefSubject,
            deleteEntryPointRuntimeProps,
            deleteActions,
            analyticsEvent
        ]);
        const isSoftwareAppStandardEdition = (0, (0, parcelRequire("iviep")).isStandardEdition)((0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT), appEditions);
        const isSoftwareAppFreeEdition = (0, (0, parcelRequire("iviep")).isFreeEdition)((0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT), appEditions);
        const handleArchiveClick = (0, $5uXOq.useCallback)(()=>{
            setModalReturnFocusTo();
            if ((isSoftwareAppStandardEdition || isSoftwareAppFreeEdition) && (jiraProjectType === (0, (0, parcelRequire("2vSU6")).JIRA_PROJECT_TYPE_SOFTWARE_PROJECT) || jiraProjectType === (0, (0, parcelRequire("2vSU6")).JIRA_PROJECT_TYPE_CORE_PROJECT))) {
                setActiveEntryPoint({
                    entryPointReferenceSubject: archiveProjectFeatureGateRefSubject,
                    runtimeProps: archiveFeatureGateEntryPointRuntimeProps,
                    entryPointActions: archiveProjectFeatureGateActions,
                    id: 'project-archive-feature-gate-modal'
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'projectArchivingFeatureGateItem');
            } else {
                setActiveEntryPoint({
                    entryPointReferenceSubject: archiveRefSubject,
                    runtimeProps: archiveEntryPointRuntimeProps,
                    entryPointActions: archiveActions,
                    id: 'project-archive-modal'
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'archiveDropdownItem');
            }
        }, [
            setModalReturnFocusTo,
            isSoftwareAppStandardEdition,
            isSoftwareAppFreeEdition,
            jiraProjectType,
            archiveProjectFeatureGateRefSubject,
            archiveFeatureGateEntryPointRuntimeProps,
            archiveProjectFeatureGateActions,
            analyticsEvent,
            archiveRefSubject,
            archiveEntryPointRuntimeProps,
            archiveActions
        ]);
        const handleSettingsClick = (0, $5uXOq.useCallback)(()=>{
            setIsDropdownOpen(false);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'settingsDropdownItem');
        }, [
            analyticsEvent
        ]);
        const handleDropdownClick = (0, $5uXOq.useCallback)(({ isOpen: isOpen })=>{
            setIsDropdownOpen(isOpen);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'dropdownMenu');
        }, [
            analyticsEvent
        ]);
        const isSimplified = projectStyle === 'TEAM_MANAGED_PROJECT';
        const projectType = jiraProjectType?.toLowerCase();
        const archiveTriggerRefToUse = (jiraProjectType === (0, (0, parcelRequire("2vSU6")).JIRA_PROJECT_TYPE_SOFTWARE_PROJECT) || jiraProjectType === (0, (0, parcelRequire("2vSU6")).JIRA_PROJECT_TYPE_CORE_PROJECT)) && (isSoftwareAppStandardEdition || isSoftwareAppFreeEdition) ? archiveFeatureGateTriggerRef : archiveTriggerRef;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($953b1735b9f944fe$var$ActionCellWrapper, null, activeEntryPoint !== null && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ivDCs")).ModalEntryPointContainer), {
            ...activeEntryPoint,
            id: id,
            packageName: `jira-projects-directory-v3-delete-${id}`
        }), projectToRestore !== null && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i8Qvm")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4pdD4")).default), {
            ...projectToRestore,
            onProjectRestoreSuccess: ()=>{
                setProjectToRestore(null);
                forceUpdate();
            },
            onClose: ()=>setProjectToRestore(null),
            onProjectRestoreFailure: ()=>setProjectToRestore(null)
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
            placement: "bottom-end",
            isOpen: isDropdownOpen,
            onOpenChange: handleDropdownClick,
            shouldRenderToParent: true,
            trigger: ({ triggerRef: triggerRef, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    ...props,
                    appearance: "subtle",
                    "aria-label": formatMessage((0, (0, parcelRequire("3rWFI")).default).moreActions, {
                        name: jiraProjectName
                    }),
                    iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3oUqw"))))), {
                        label: ""
                    }),
                    ref: (0, (0, parcelRequire("7mPT9")).mergeRefs)(triggerRef, renderButtonRef)
                })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aRoVz")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            onClick: handleSettingsClick,
            component: ({ onClick: onClick, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hDzhb")).default), {
                    ...props,
                    onClick: onClick,
                    to: (0, (0, parcelRequire("x3l4g")).getSettingsUrl)(projectType, isSimplified, key)
                })
        }, formatMessage((0, (0, parcelRequire("3rWFI")).default).settings)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("biPXN")).TrashProjectDropdownItem), {
            projectType: projectType,
            isAdmin: isAdmin,
            canAdministerProject: true,
            isSimplified: isSimplified,
            ref: deleteTriggerRef,
            onClick: handleTrashClick
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6wfdY")).ArchiveProjectDropdownItem), {
            projectType: projectType,
            isAdmin: isAdmin,
            canAdministerProject: true,
            isSimplified: isSimplified,
            ref: archiveTriggerRefToUse,
            onClick: handleArchiveClick,
            isCorePremiumUserSeat: isCorePremiumUserSeat
        }))));
    };
    const $953b1735b9f944fe$export$eee95bbb96e96f7b = ({ project: project, jiraQueryRef: jiraQueryRef })=>{
        const dataRef = (0, $8Uumt.useFragment)((0, (0, parcelRequire("ldTWx")).default), project);
        if ((0, (0, parcelRequire("co1wz")).ff)('olympus-da-157-enable-project-settings-navigation_zxaq5')) {
            if (!dataRef?.canViewProjectConfig?.canPerform) return null;
        } else if (!dataRef?.canEdit?.canPerform) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($953b1735b9f944fe$var$ActionsCellDropdown, {
            project: dataRef,
            jiraQueryRef: jiraQueryRef
        });
    };
    const $953b1735b9f944fe$var$ActionCellWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bahesu3",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("ldTWx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$555da9684e607856$export$2e2bcd8739ae039);
    const $555da9684e607856$var$node = function() {
        var v0 = [
            {
                "kind": "ScalarField",
                "name": "canPerform"
            }
        ];
        return {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "name": "actionsCell_projectsDirectoryV3_project",
            "selections": [
                {
                    "alias": "canEdit",
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "type",
                            "value": "EDIT_PROJECT_CONFIG"
                        }
                    ],
                    "concreteType": "JiraProjectAction",
                    "kind": "LinkedField",
                    "name": "action",
                    "plural": false,
                    "selections": v0,
                    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                },
                {
                    "alias": "canViewProjectConfig",
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "type",
                            "value": "VIEW_PROJECT_CONFIG"
                        }
                    ],
                    "concreteType": "JiraProjectAction",
                    "kind": "LinkedField",
                    "name": "action",
                    "plural": false,
                    "selections": v0,
                    "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
                },
                {
                    "kind": "FragmentSpread",
                    "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_project"
                }
            ],
            "type": "JiraProject"
        };
    }();
    $555da9684e607856$var$node.hash = "9bbdd28e963158cad6040ea1b67b1dce";
    var $555da9684e607856$export$2e2bcd8739ae039 = $555da9684e607856$var$node;
});
parcelRegister("6lRt3", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$472128e18ca20072$export$2e2bcd8739ae039);
    const $472128e18ca20072$var$node = {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            }
        ],
        "kind": "Fragment",
        "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef",
        "selections": [
            {
                "args": [
                    {
                        "kind": "Variable",
                        "name": "cloudId",
                        "variableName": "cloudId"
                    }
                ],
                "concreteType": "JiraWorkManagementLicensing",
                "kind": "LinkedField",
                "name": "jwmLicensing",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "currentUserSeatEdition"
                    }
                ]
            }
        ],
        "type": "JiraQuery"
    };
    $472128e18ca20072$var$node.hash = "5b00115542e25c7594cc5725fc674963";
    var $472128e18ca20072$export$2e2bcd8739ae039 = $472128e18ca20072$var$node;
});
parcelRegister("k8cj1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$259ea62f8fda0b43$export$2e2bcd8739ae039);
    const $259ea62f8fda0b43$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_project",
        "selections": [
            {
                "alias": "jiraProjectType",
                "kind": "ScalarField",
                "name": "projectType"
            },
            {
                "kind": "ScalarField",
                "name": "projectStyle"
            },
            {
                "alias": "jiraProjectName",
                "kind": "ScalarField",
                "name": "name"
            },
            {
                "kind": "ScalarField",
                "name": "key"
            },
            {
                "kind": "ScalarField",
                "name": "id"
            }
        ],
        "type": "JiraProject"
    };
    $259ea62f8fda0b43$var$node.hash = "c940602017bcd3fd11260c66a06307d5";
    var $259ea62f8fda0b43$export$2e2bcd8739ae039 = $259ea62f8fda0b43$var$node;
});
parcelRegister("6B7KL", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9ff85f737833a5bc$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $5JjdN;
    var $kJpBP;
    var $1aogK;
    var $3Z96c;
    var $VSDnB;
    var $l7Q0F;
    const $9ff85f737833a5bc$var$DropdownMenuItem = (0, $5uXOq.forwardRef)(({ children: children, component: component, description: description, elemAfter: elemAfter, elemBefore: elemBefore, href: href, isDisabled: isDisabled, isSelected: isSelected, onClick: onClick, rel: rel, shouldDescriptionWrap: shouldDescriptionWrap = true, shouldTitleWrap: shouldTitleWrap = true, target: target, testId: testId, UNSAFE_shouldDisableRouterLink: UNSAFE_shouldDisableRouterLink, returnFocusRef: returnFocusRef, interactionName: interactionName, ...rest }, ref)=>{
        const itemRef = (0, (0, parcelRequire("l7Q0F")).default)();
        const handleItemClick = (0, $5uXOq.useCallback)((event)=>{
            if (returnFocusRef?.current) returnFocusRef.current.focus();
            if (onClick) onClick(event);
        }, [
            onClick,
            returnFocusRef
        ]);
        if (component) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1aogK")).default), {
            component: component,
            description: description,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            testId: testId,
            href: href,
            target: target,
            rel: rel,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
        else if (href) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Z96c")).default), {
            description: description,
            href: href,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            rel: rel,
            role: "menuitem",
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            target: target,
            testId: testId,
            UNSAFE_shouldDisableRouterLink: UNSAFE_shouldDisableRouterLink,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
        else return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
            description: description,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            role: "menuitem",
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            testId: testId,
            target: target,
            rel: rel,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
    });
    var $9ff85f737833a5bc$export$2e2bcd8739ae039 = $9ff85f737833a5bc$var$DropdownMenuItem;
});
parcelRegister("aRoVz", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6c1f89f82839ba50$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $9KnKW;
    var $9UCCQ;
    var $k20lf;
    const $6c1f89f82839ba50$var$DropdownMenuItemGroup = (0, $5uXOq.forwardRef)(({ children: children, id: id, isList: isList, isScrollable: isScrollable, title: title, testId: testId, hasSeparator: hasSeparator, overrides: overrides, ...rest }, ref)=>{
        const uid = (0, (0, parcelRequire("9KnKW")).useId)();
        const titleId = `dropdown-menu-item-group-title-${uid}`;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            id: id,
            ref: ref,
            isList: isList,
            isScrollable: isScrollable,
            hasSeparator: hasSeparator,
            testId: testId,
            titleId: title ? titleId : undefined,
            overrides: overrides,
            ...rest
        }, title && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k20lf")).default), {
            id: titleId,
            title: title
        }), children);
    });
    var $6c1f89f82839ba50$export$2e2bcd8739ae039 = $6c1f89f82839ba50$var$DropdownMenuItemGroup;
});
parcelRegister("47gW9", function(module, exports) {
    $parcel$export(module.exports, "useEntryPointButtonTrigger", ()=>$1be876415a945060$export$25d2524a63315ed);
    $parcel$export(module.exports, "getDefaultEntrypointActionEventMap", ()=>$1be876415a945060$export$5f13515bd3a35325);
    var $5uXOq = parcelRequire("5uXOq");
    var $iRKpN;
    const $1be876415a945060$export$25d2524a63315ed = (actions, isActive = true, eventMap = $1be876415a945060$export$5f13515bd3a35325(actions))=>{
        const removePreviousListeners = (0, $5uXOq.useRef)(null);
        const isMounted = (0, $5uXOq.useRef)(true);
        (0, $5uXOq.useEffect)(()=>()=>{
                removePreviousListeners.current?.();
                removePreviousListeners.current = null;
                isMounted.current = false;
            }, []);
        return (0, $5uXOq.useCallback)((element)=>{
            if (!isMounted.current) return;
            removePreviousListeners.current?.();
            if (element && isActive) {
                (0, (0, parcelRequire("iRKpN")).modifyListeners)({
                    element: element,
                    operationName: 'add',
                    eventMap: eventMap
                });
                removePreviousListeners.current = ()=>{
                    (0, (0, parcelRequire("iRKpN")).modifyListeners)({
                        element: element,
                        operationName: 'remove',
                        eventMap: eventMap
                    });
                };
            } else removePreviousListeners.current = null;
        }, [
            eventMap,
            isActive
        ]);
    };
    const $1be876415a945060$export$5f13515bd3a35325 = (actions)=>({
            click: actions.load,
            mouseenter: actions.preload,
            mouseleave: actions.cancelPreload,
            focusin: actions.preload,
            focusout: actions.cancelPreload
        });
});
parcelRegister("iRKpN", function(module, exports) {
    $parcel$export(module.exports, "modifyListeners", ()=>$62bae43418420b8a$export$15257a36a7e3f9d6);
    function $62bae43418420b8a$export$15257a36a7e3f9d6(params) {
        const { element: element, operationName: operationName, eventMap: eventMap } = params;
        const functionName = operationName === 'add' ? 'addEventListener' : 'removeEventListener';
        if (element) Object.keys(eventMap).forEach((eventName)=>element[functionName](eventName, eventMap[eventName]));
    }
});
parcelRegister("ivDCs", function(module, exports) {
    $parcel$export(module.exports, "ModalEntryPointContainer", ()=>$832a9e21b9f9d7ff$export$4d67c6ee49f3d14d);
    var $5uXOq = parcelRequire("5uXOq");
    var $i8Qvm;
    var $8Bm0k;
    var $c5vfY;
    const $832a9e21b9f9d7ff$export$4d67c6ee49f3d14d = ({ entryPointActions: { unload: unload }, entryPointReferenceSubject: entryPointReferenceSubject, errorFallback: errorFallback, runtimeProps: runtimeProps, ...containerProps })=>{
        const entryPointReference = (0, (0, parcelRequire("8Bm0k")).useEntryPointSubscription)(entryPointReferenceSubject);
        const props = (0, $5uXOq.useMemo)(()=>({
                ...runtimeProps,
                onClose () {
                    runtimeProps.onClose?.();
                    unload();
                }
            }), [
            runtimeProps,
            unload
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i8Qvm")).default), null, entryPointReference && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c5vfY")).InternalEntryPointContainer), {
            ...containerProps,
            entryPointReference: entryPointReference,
            errorFallback: errorFallback || 'flag',
            placeholderName: "modal-entry-point-container",
            runtimeProps: props
        }));
    };
});
parcelRegister("7mPT9", function(module, exports) {
    $parcel$export(module.exports, "mergeRefs", ()=>$d1da29f25d5b5e0a$export$c9058316764c140e);
    $parcel$export(module.exports, "default", ()=>$d1da29f25d5b5e0a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    function $d1da29f25d5b5e0a$export$c9058316764c140e(...refs) {
        return (value)=>{
            refs.forEach((ref)=>{
                if (typeof ref === 'function') ref(value);
                else if (ref != null) ref.current = value;
            });
        };
    }
    function $d1da29f25d5b5e0a$export$2e2bcd8739ae039(...refs) {
        return (0, $5uXOq.useMemo)(()=>$d1da29f25d5b5e0a$export$c9058316764c140e(...refs), refs);
    }
});
parcelRegister("iviep", function(module, exports) {
    $parcel$export(module.exports, "hasPermissionGate", ()=>$39a9a56ffba74d2e$export$2d7869ada39bf0b);
    $parcel$export(module.exports, "isStandardEdition", ()=>$39a9a56ffba74d2e$export$4b1af5399a63f452);
    $parcel$export(module.exports, "isFreeEdition", ()=>$39a9a56ffba74d2e$export$a8e9f6df1deeb7d);
    $parcel$export(module.exports, "hasPermissionToArchive", ()=>$39a9a56ffba74d2e$export$70eef98d89c5d158);
    var $2vSU6;
    var $ldyPw;
    const $39a9a56ffba74d2e$export$2d7869ada39bf0b = (projectType, appEditions)=>(0, (0, parcelRequire("ldyPw")).getEditionForProject)(projectType, appEditions) === (0, (0, parcelRequire("ldyPw")).PREMIUM_EDITION);
    const $39a9a56ffba74d2e$export$4b1af5399a63f452 = (projectType, appEditions)=>(0, (0, parcelRequire("ldyPw")).getEditionForProject)(projectType, appEditions) === (0, (0, parcelRequire("ldyPw")).STANDARD_EDITION);
    const $39a9a56ffba74d2e$export$a8e9f6df1deeb7d = (projectType, appEditions)=>(0, (0, parcelRequire("ldyPw")).getEditionForProject)(projectType, appEditions) === (0, (0, parcelRequire("ldyPw")).FREE_EDITION);
    const $39a9a56ffba74d2e$var$hasPermissionToArchiveProject = (isSimplified, isAdmin, canAdministerProject)=>{
        if (isSimplified) return isAdmin || canAdministerProject;
        return isAdmin;
    };
    const $39a9a56ffba74d2e$export$70eef98d89c5d158 = ({ projectType: projectType, isSimplified: isSimplified, isAdmin: isAdmin, canAdministerProject: canAdministerProject })=>{
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
            case 0, (0, parcelRequire("2vSU6")).CORE_PROJECT:
                return $39a9a56ffba74d2e$var$hasPermissionToArchiveProject(isSimplified, isAdmin, canAdministerProject);
            default:
                return false;
        }
    };
});
parcelRegister("4pdD4", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3ad70d2ac8fd8e5c$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gAeCk;
    var $39xOx;
    var $lfTZh;
    var $kxZPA;
    const $3ad70d2ac8fd8e5c$var$LazyComponent = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("hpeJS")), {
        moduleId: "./src/packages/project-pages/project-restore/project-restore-modal/src/index.tsx"
    });
    const $3ad70d2ac8fd8e5c$var$Component = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            fallback: "flag",
            id: "bundle-async-project-restore-modal",
            packageName: "jira-project-restore-modal"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "project-restore-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gAeCk")).default), {
                metricKey: (0, $14d5c91aea6dd559$export$37312a67c3e314c6)
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($3ad70d2ac8fd8e5c$var$LazyComponent, props)));
    var $3ad70d2ac8fd8e5c$export$2e2bcd8739ae039 = $3ad70d2ac8fd8e5c$var$Component;
});
const $14d5c91aea6dd559$export$37312a67c3e314c6 = 'PROJECT_RESTORE_MODAL_LOAD_START_MARK_KEY';
const $14d5c91aea6dd559$export$367c841df4ac5730 = 'https://support.atlassian.com/jira-service-management-cloud/docs/trash-and-restore-a-project/';
parcelRegister("hpeJS", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("l8d9V")
    ]).then(()=>parcelRequire('8itfQ'));
});
parcelRegister("ibPPY", function(module, exports) {
    $parcel$export(module.exports, "archiveProjectFeatureGateModalEntryPoint", ()=>$efb56fa3ccd065b0$export$fcdeeeabe3fc5ecf);
    var $eufEc;
    var $fEDBv;
    const $efb56fa3ccd065b0$export$fcdeeeabe3fc5ecf = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("5HNBc")), {
            moduleId: "1b29af53"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("5HNBc", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("6DFtN")
    ]).then(()=>parcelRequire('awct0'));
});
parcelRegister("kRt9N", function(module, exports) {
    $parcel$export(module.exports, "archiveProjectModalEntryPoint", ()=>$de9347d67c4d5780$export$31eb70b07b11d874);
    var $6mG7r;
    var $eufEc;
    var $fEDBv;
    const $de9347d67c4d5780$export$31eb70b07b11d874 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("es7nE")), {
            moduleId: "a707d335"
        }),
        getPreloadProps: ({ projectId: projectId })=>({
                queries: {
                    project: {
                        options: {
                            fetchPolicy: 'store-or-network'
                        },
                        parameters: (0, parcelRequire("6mG7r")).default,
                        variables: {
                            projectId: projectId
                        }
                    }
                }
            })
    });
});
parcelRegister("6mG7r", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$42b91c010617dcca$export$2e2bcd8739ae039);
    const $42b91c010617dcca$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "a9daa742fffdffb3be75a50f4d433a30c07a6212c4a9d23a43b3664323d1faa0",
            "metadata": {},
            "name": "uiArchiveProjectModal_getProjectQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $42b91c010617dcca$export$2e2bcd8739ae039 = $42b91c010617dcca$var$node;
});
parcelRegister("es7nE", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("8WZsV")
    ]).then(()=>parcelRequire('enCHC'));
});
parcelRegister("iVNLr", function(module, exports) {
    $parcel$export(module.exports, "deleteProjectModalEntryPoint", ()=>$be5cb60b714e4982$export$71ab1db8fa3662de);
    var $7L4m7;
    var $eufEc;
    var $fEDBv;
    const $be5cb60b714e4982$export$71ab1db8fa3662de = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("df0Rb")), {
            moduleId: "0a83cef5"
        }),
        getPreloadProps: ({ projectId: projectId })=>({
                queries: {
                    project: {
                        options: {
                            fetchPolicy: 'store-or-network'
                        },
                        parameters: (0, parcelRequire("7L4m7")).default,
                        variables: {
                            projectId: projectId
                        }
                    }
                }
            })
    });
});
parcelRegister("7L4m7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ad982b59eb99c13b$export$2e2bcd8739ae039);
    const $ad982b59eb99c13b$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "b970039aa2992a80e17db04733801c9fc94a870f85ad01abf94a227b9b0a28de",
            "metadata": {},
            "name": "uiDeleteProjectModal_getProjectQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $ad982b59eb99c13b$export$2e2bcd8739ae039 = $ad982b59eb99c13b$var$node;
});
parcelRegister("df0Rb", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("2qoav")
    ]).then(()=>parcelRequire('9cip1'));
});
parcelRegister("lu5k0", function(module, exports) {
    $parcel$export(module.exports, "useModalDialogActions", ()=>$5f429bdcb9dbbc95$export$3cf2e4b6d3541465);
    $parcel$export(module.exports, "useReturnFocusTo", ()=>$5f429bdcb9dbbc95$export$217308a4ece853b2);
    var $3sQ5x;
    var $iXqE5;
    var $49R2H;
    var $a3Qjd;
    var $kIxMH;
    const $5f429bdcb9dbbc95$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'JiraSoftwareModalDialog',
        initialState: (0, parcelRequire("49R2H")).initialState,
        actions: (0, parcelRequire("a3Qjd")).actions
    });
    const $5f429bdcb9dbbc95$export$3cf2e4b6d3541465 = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($5f429bdcb9dbbc95$var$Store);
    const $5f429bdcb9dbbc95$export$217308a4ece853b2 = (0, (0, parcelRequire("3sQ5x")).createStateHook)($5f429bdcb9dbbc95$var$Store, {
        selector: (0, (0, parcelRequire("kIxMH")).getReturnFocusTo)
    });
});
parcelRegister("49R2H", function(module, exports) {
    $parcel$export(module.exports, "initialState", ()=>$1a3a816977643252$export$d4c72bab9d6cc13a);
    const $1a3a816977643252$export$d4c72bab9d6cc13a = {
        returnFocusTo: null
    };
});
parcelRegister("a3Qjd", function(module, exports) {
    $parcel$export(module.exports, "actions", ()=>$1886bc9c17d47b9b$export$e324594224ef24da);
    const $1886bc9c17d47b9b$export$b263d1e638b131c2 = (returnFocusTo)=>({ setState: setState })=>{
            setState({
                returnFocusTo: returnFocusTo
            });
        };
    const $1886bc9c17d47b9b$export$e324594224ef24da = {
        setReturnFocusTo: $1886bc9c17d47b9b$export$b263d1e638b131c2
    };
});
parcelRegister("kIxMH", function(module, exports) {
    $parcel$export(module.exports, "getReturnFocusTo", ()=>$cd4eb273a1fc9e15$export$d5f8692765ccd209);
    const $cd4eb273a1fc9e15$export$d5f8692765ccd209 = (state)=>state.returnFocusTo;
});
parcelRegister("6wfdY", function(module, exports) {
    $parcel$export(module.exports, "ArchiveProjectDropdownItem", ()=>$af9be29b8402bcf0$export$26fac2325408ae57);
    var $5uXOq = parcelRequire("5uXOq");
    var $6B7KL;
    var $j5csE;
    const $af9be29b8402bcf0$export$26fac2325408ae57 = (0, $5uXOq.forwardRef)((props, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j5csE")).ProjectArchiveTrigger), {
            ...props,
            Component: ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
                    ref: ref,
                    onClick: props.onClick
                }, children)
        }));
});
parcelRegister("j5csE", function(module, exports) {
    $parcel$export(module.exports, "useShouldShowArchiveProjectTrigger", ()=>$0115ce7a250913b5$export$b73fe2adeac2987c);
    $parcel$export(module.exports, "ProjectArchiveTrigger", ()=>$0115ce7a250913b5$export$8901cb385df588dc);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $6B7KL;
    var $lj8SI;
    var $2vSU6;
    var $koVbs;
    var $k9JTq;
    var $3hsHx;
    var $iviep;
    function $0115ce7a250913b5$export$b73fe2adeac2987c({ isAdmin: isAdmin, isSimplified: isSimplified, canAdministerProject: canAdministerProject, projectType: projectType, shouldHideFeatureGateTrigger: shouldHideFeatureGateTrigger, isCorePremiumUserSeat: isCorePremiumUserSeat }) {
        const appEditions = (0, (0, parcelRequire("k9JTq")).useAppEditions)();
        if (!(0, (0, parcelRequire("iviep")).hasPermissionToArchive)({
            projectType: projectType,
            isSimplified: isSimplified,
            isAdmin: isAdmin,
            canAdministerProject: canAdministerProject
        })) return false;
        const isJswPremiumEdition = (0, (0, parcelRequire("iviep")).hasPermissionGate)((0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT), appEditions);
        const isJswStandardEdition = (0, (0, parcelRequire("iviep")).isStandardEdition)((0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT), appEditions);
        const isJswFreeEdition = (0, (0, parcelRequire("iviep")).isFreeEdition)((0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT), appEditions);
        const isJsmPremiumEdition = (0, (0, parcelRequire("iviep")).hasPermissionGate)((0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT), appEditions);
        if (projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) && isJswPremiumEdition || projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) && isJsmPremiumEdition) return {
            showPremiumLozenge: false
        };
        if (isAdmin && !shouldHideFeatureGateTrigger && (projectType === (0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT) || projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT)) && (isJswStandardEdition || isJswFreeEdition)) return {
            showPremiumLozenge: true
        };
        if (projectType === (0, (0, parcelRequire("2vSU6")).CORE_PROJECT) && isCorePremiumUserSeat) return {
            showPremiumLozenge: false
        };
        return false;
    }
    const $0115ce7a250913b5$export$8901cb385df588dc = (props)=>{
        const { projectType: projectType, onClick: onClick, isAdmin: isAdmin, canAdministerProject: canAdministerProject, isSimplified: isSimplified, Component: Component, isCorePremiumUserSeat: isCorePremiumUserSeat, shouldHideFeatureGateTrigger: shouldHideFeatureGateTrigger, customLabelText: customLabelText } = props;
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const premiumLozenge = (0, $5uXOq.useMemo)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement($0115ce7a250913b5$var$LozengeWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
                appearance: "new"
            }, formatMessage((0, (0, parcelRequire("3hsHx")).default).premiumLozenge))), [
            formatMessage
        ]);
        const getDropdownItem = ({ showPremiumLozenge: showPremiumLozenge })=>{
            const dropdownChildren = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, customLabelText || formatMessage(projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) ? (0, (0, parcelRequire("3hsHx")).default).archiveTriggerServiceManagement : (0, (0, parcelRequire("3hsHx")).default).archiveTrigger), showPremiumLozenge && premiumLozenge);
            return Component ? (0, ($parcel$interopDefault($5uXOq))).createElement(Component, null, dropdownChildren) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
                onClick: onClick
            }, dropdownChildren);
        };
        const shouldShowResult = $0115ce7a250913b5$export$b73fe2adeac2987c({
            isAdmin: isAdmin,
            isSimplified: isSimplified,
            canAdministerProject: canAdministerProject,
            projectType: projectType,
            shouldHideFeatureGateTrigger: shouldHideFeatureGateTrigger,
            isCorePremiumUserSeat: isCorePremiumUserSeat
        });
        if (!shouldShowResult) return null;
        return getDropdownItem(shouldShowResult);
    };
    const $0115ce7a250913b5$var$LozengeWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1nu9 _18u01b66",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("3hsHx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bfe2f488870be757$export$2e2bcd8739ae039);
    var $7VHMR;
    var $bfe2f488870be757$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        archiveTrigger: {
            "id": "project-archive-trigger.archive-trigger",
            "defaultMessage": "Archive"
        },
        archiveTriggerServiceManagement: {
            "id": "project-archive-trigger.archive-trigger-service-management",
            "defaultMessage": "Move to archive"
        },
        premiumLozenge: {
            "id": "project-archive-trigger.premium-lozenge",
            "defaultMessage": "Premium"
        }
    });
});
parcelRegister("biPXN", function(module, exports) {
    $parcel$export(module.exports, "TrashProjectDropdownItem", ()=>$19f4d9de7fb80565$export$eb302d626a2eaf73);
    var $5uXOq = parcelRequire("5uXOq");
    var $6B7KL;
    var $koVbs;
    var $kDO0r;
    var $8JRs9;
    const $19f4d9de7fb80565$export$eb302d626a2eaf73 = (0, $5uXOq.forwardRef)(({ onClick: onClick, ...args }, ref)=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if (!(0, (0, parcelRequire("kDO0r")).shouldShowTrashProjectTrigger)(args)) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            ref: ref,
            onClick: onClick
        }, formatMessage((0, (0, parcelRequire("8JRs9")).default).delete));
    });
});
parcelRegister("kDO0r", function(module, exports) {
    $parcel$export(module.exports, "shouldShowTrashProjectTrigger", ()=>$ea1964a427a6c77f$export$efbe59f7e76b22d6);
    var $2vSU6;
    const $ea1964a427a6c77f$var$shouldShowTrashTriggerForSoftwareType = (isSimplified, isAdmin, canAdministerProject)=>{
        if (isSimplified) return isAdmin || canAdministerProject;
        return isAdmin;
    };
    const $ea1964a427a6c77f$var$shouldShowTrashTriggerForCoreType = (isAdmin)=>isAdmin;
    const $ea1964a427a6c77f$var$shouldShowTrashTriggerForJSMType = (isSimplified, isAdmin, canAdministerProject)=>$ea1964a427a6c77f$var$shouldShowTrashTriggerForSoftwareType(isSimplified, isAdmin, canAdministerProject);
    const $ea1964a427a6c77f$export$efbe59f7e76b22d6 = ({ projectType: projectType, isSimplified: isSimplified, isAdmin: isAdmin, canAdministerProject: canAdministerProject })=>{
        switch(projectType){
            case 0, (0, parcelRequire("2vSU6")).PRODUCT_DISCOVERY_PROJECT:
            case 0, (0, parcelRequire("2vSU6")).SOFTWARE_PROJECT:
                return $ea1964a427a6c77f$var$shouldShowTrashTriggerForSoftwareType(isSimplified, isAdmin, canAdministerProject);
            case 0, (0, parcelRequire("2vSU6")).CORE_PROJECT:
                return $ea1964a427a6c77f$var$shouldShowTrashTriggerForCoreType(isAdmin);
            case 0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT:
                return $ea1964a427a6c77f$var$shouldShowTrashTriggerForJSMType(isSimplified, isAdmin, canAdministerProject);
            default:
                return false;
        }
    };
});
parcelRegister("8JRs9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9d2d8f05f17607c1$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9d2d8f05f17607c1$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        delete: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.actions-cell.delete-project-menu-item.delete",
            "defaultMessage": "Move to trash"
        }
    });
});
parcelRegister("3rWFI", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8f888a411db236a8$export$2e2bcd8739ae039);
    var $7VHMR;
    var $8f888a411db236a8$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        settings: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.actions-cell.settings",
            "defaultMessage": "Project settings"
        },
        moreActions: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.actions-cell.more-actions",
            "defaultMessage": "More actions for {name}"
        },
        more: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.actions-cell.more",
            "defaultMessage": "More"
        }
    });
});
parcelRegister("x3l4g", function(module, exports) {
    $parcel$export(module.exports, "getSettingsUrl", ()=>$8a87a6fc4b868af5$export$3fe840d461122951);
    var $2vSU6;
    var $02Psx;
    const $8a87a6fc4b868af5$var$getClassicSettingsUrl = (projectType, projectKey)=>projectType === (0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT) ? `/jira/servicedesk/projects/${projectKey}/settings` : `/projects/${projectKey}/settings`;
    const $8a87a6fc4b868af5$export$3fe840d461122951 = (projectType, isSimplified, projectKey)=>!isSimplified || !projectType ? $8a87a6fc4b868af5$var$getClassicSettingsUrl(projectType, projectKey) : (0, (0, parcelRequire("02Psx")).getNextgenSettingsUrl)(projectType, projectKey);
});
parcelRegister("5Huo8", function(module, exports) {
    $parcel$export(module.exports, "FavoriteCell", ()=>$e3bdc0b0936b2c61$export$d67ed47628a04c08);
    var $3gMXQ;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $gd976;
    var $3HwlS;
    var $8zOmE;
    var $2NMNM;
    const $e3bdc0b0936b2c61$export$d67ed47628a04c08 = ({ project: project })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'clicked',
            actionSubject: 'resultsTable'
        });
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("3gMXQ")).default), project);
        const handleClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'FavoriteButton');
        }, [
            analyticsEvent
        ]);
        if ((0, (0, parcelRequire("3HwlS")).getWillShowNav4)()) return !data.canViewIssue?.canPerform ? null : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gd976")).FavoriteCell), {
            queryRef: data,
            onClick: handleClick
        });
        const { id: id, name: name, favouriteValue: favouriteValue, canViewIssue: canViewIssue } = data;
        if (!canViewIssue?.canPerform || !favouriteValue) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gd976")).FavoriteCellOld), {
            id: id,
            favoriteItemName: name,
            favouriteValueRef: favouriteValue,
            onClick: handleClick
        });
    };
});
parcelRegister("3gMXQ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9b7c68c8adef0634$export$2e2bcd8739ae039);
    const $9b7c68c8adef0634$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "favouriteCell_projectsDirectoryV3_FavoriteCell",
        "selections": [
            {
                "alias": "canViewIssue",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "type",
                        "value": "VIEW_ISSUES"
                    }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "canPerform"
                    }
                ],
                "storageKey": "action(type:\"VIEW_ISSUES\")"
            },
            {
                "kind": "FragmentSpread",
                "name": "favouriteCell_directoryBaseV3_FavoriteCell_queryRef"
            },
            {
                "kind": "ScalarField",
                "name": "id"
            },
            {
                "concreteType": "JiraFavouriteValue",
                "kind": "LinkedField",
                "name": "favouriteValue",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef"
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "name": "name"
            }
        ],
        "type": "JiraProject"
    };
    $9b7c68c8adef0634$var$node.hash = "ad54d41b3040372f85a46039e18e78ea";
    var $9b7c68c8adef0634$export$2e2bcd8739ae039 = $9b7c68c8adef0634$var$node;
});
parcelRegister("gd976", function(module, exports) {
    $parcel$export(module.exports, "FavoriteCell", ()=>$0fca16bce44cd801$export$d67ed47628a04c08);
    $parcel$export(module.exports, "FavoriteCellOld", ()=>$0fca16bce44cd801$export$fdcdbda2ebc451a1);
    var $1bDfu;
    var $ajR4C;
    var $i62GJ;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $hcObS;
    var $4041n;
    var $pa9q9;
    var $dXq4S;
    var $l3Aku;
    const $0fca16bce44cd801$export$d67ed47628a04c08 = ({ queryRef: queryRef, onClick: onClick })=>{
        const { changeFavoriteMutation: changeFavoriteMutation } = (0, (0, parcelRequire("4041n")).useChangeFavorite)();
        const { id: id, name: name, isFavourite: isFavourite } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("i62GJ")).default), queryRef);
        const handleClick = (0, $5uXOq.useCallback)(()=>{
            onClick?.({
                isFavourite: !isFavourite
            });
            changeFavoriteMutation({
                id: id,
                value: !isFavourite,
                typename: 'JiraProject'
            });
        }, [
            onClick,
            isFavourite,
            changeFavoriteMutation,
            id
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($0fca16bce44cd801$var$ContentWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hcObS")).default), {
            onClick: handleClick,
            isShownInList: true,
            isFavorite: isFavourite,
            favoriteItemName: name
        }));
    };
    const $0fca16bce44cd801$export$fdcdbda2ebc451a1 = ({ id: entityId, favoriteItemName: favoriteItemName, favouriteValueRef: favouriteValueRef, onClick: onClick })=>{
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const { updateFavoriteState: updateFavoriteState } = (0, (0, parcelRequire("4041n")).useChangeFavorite)();
        const { __id: recordId, isFavourite: isFavourite } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("ajR4C")).default), favouriteValueRef);
        const [commit] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("1bDfu")).default));
        const updateFavouriteRecord = (0, $5uXOq.useCallback)((store)=>{
            const { resourceId: resourceId, resourceType: resourceType } = (0, (0, parcelRequire("dXq4S")).getAriConfig)(entityId);
            const favouriteEntityRecord = store.get(recordId);
            if (resourceType === 'project') updateFavoriteState({
                id: resourceId,
                type: 'projects',
                value: !isFavourite
            });
            favouriteEntityRecord?.setValue(!isFavourite, 'isFavourite');
        }, [
            isFavourite,
            recordId,
            entityId,
            updateFavoriteState
        ]);
        const handleClick = (0, $5uXOq.useCallback)(()=>{
            onClick && onClick({
                isFavourite: !isFavourite
            });
            commit({
                variables: {
                    entityId: entityId,
                    isFavourite: !isFavourite
                },
                optimisticUpdater: updateFavouriteRecord,
                updater: updateFavouriteRecord,
                onCompleted: (data)=>{
                    if (!data?.jira?.setEntityIsFavourite?.success) showFlag({
                        type: 'error',
                        title: (0, (0, parcelRequire("l3Aku")).default).title,
                        description: (0, (0, parcelRequire("l3Aku")).default).description
                    });
                }
            });
        }, [
            onClick,
            isFavourite,
            commit,
            entityId,
            updateFavouriteRecord,
            showFlag
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($0fca16bce44cd801$var$ContentWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hcObS")).default), {
            onClick: handleClick,
            isShownInList: true,
            isFavorite: isFavourite,
            favoriteItemName: favoriteItemName
        }));
    };
    const $0fca16bce44cd801$var$ContentWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bah1h6o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1bDfu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$89b380f8d70db9a5$export$2e2bcd8739ae039);
    const $89b380f8d70db9a5$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "entityId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isFavourite"
            }
        ], v1 = [
            {
                "concreteType": "JiraMutation",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "args": [
                            {
                                "fields": [
                                    {
                                        "kind": "Variable",
                                        "name": "entityId",
                                        "variableName": "entityId"
                                    },
                                    {
                                        "kind": "Variable",
                                        "name": "isFavourite",
                                        "variableName": "isFavourite"
                                    }
                                ],
                                "kind": "ObjectValue",
                                "name": "input"
                            }
                        ],
                        "concreteType": "JiraSetIsFavouritePayload",
                        "kind": "LinkedField",
                        "name": "setEntityIsFavourite",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "success"
                            }
                        ]
                    }
                ]
            }
        ];
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "favouriteCell_directoryBaseV3_Mutation",
                "selections": v1,
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "favouriteCell_directoryBaseV3_Mutation",
                "selections": v1
            },
            "params": {
                "id": "677bbcd9c25049b9a4296d20d4a52bcaa99e82e0d0f12414fac1ed3dbbfa1e31",
                "metadata": {},
                "name": "favouriteCell_directoryBaseV3_Mutation",
                "operationKind": "mutation",
                "text": null
            }
        };
    }();
    $89b380f8d70db9a5$var$node.hash = "bd17a743d8447fa8141ce0ba8c8f1012";
    var $89b380f8d70db9a5$export$2e2bcd8739ae039 = $89b380f8d70db9a5$var$node;
});
parcelRegister("ajR4C", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$323ac085f29fc95e$export$2e2bcd8739ae039);
    const $323ac085f29fc95e$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "isFavourite"
            },
            {
                "kind": "ClientExtension",
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "__id"
                    }
                ]
            }
        ],
        "type": "JiraFavouriteValue"
    };
    $323ac085f29fc95e$var$node.hash = "b3217e19a577d125349014040cc34d96";
    var $323ac085f29fc95e$export$2e2bcd8739ae039 = $323ac085f29fc95e$var$node;
});
parcelRegister("i62GJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$dc684d17909d4244$export$2e2bcd8739ae039);
    const $dc684d17909d4244$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "favouriteCell_directoryBaseV3_FavoriteCell_queryRef",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "id"
            },
            {
                "kind": "ScalarField",
                "name": "name"
            },
            {
                "kind": "ScalarField",
                "name": "isFavourite"
            }
        ],
        "type": "JiraProject"
    };
    $dc684d17909d4244$var$node.hash = "600c66fd2f77bc9eec46edf5294a9a60";
    var $dc684d17909d4244$export$2e2bcd8739ae039 = $dc684d17909d4244$var$node;
});
parcelRegister("hcObS", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButtonWrapper", ()=>$0a68425ce3fc2fa8$export$bb1478fee6cd5f0c);
    $parcel$export(module.exports, "default", ()=>$0a68425ce3fc2fa8$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $basmW = parcelRequire("basmW");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $fVnbM;
    var $dm1qi;
    var $dAf5R;
    var $5oTeF;
    var $bH1hf;
    var $7FtaJ;
    var $1Cvrw;
    var $fNobW;
    var $3bDjY;
    var $iQv12;
    var $7XYc4;
    var $az4zD;
    var $dCm1o;
    var $6fQ6A;
    const $0a68425ce3fc2fa8$var$SKELETON_TEST_ID = 'favouriting.favorite-button-stateless.skeleton';
    const $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX = 'favouriting.favorite-button-stateless.icon-wrapper';
    class $0a68425ce3fc2fa8$export$f8ad2acf0ec4860f extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isFavorite: undefined,
                favoriteItemName: undefined,
                isShownInList: false,
                isSmall: false,
                hideTooltip: false,
                onClick: (0, ($parcel$interopDefault($8Rkzz))),
                tooltipPosition: 'bottom',
                skipAnimation: false
            };
        }
        componentDidUpdate(prevProps) {
            if (!this.props.skipAnimation && (!prevProps.pending && this.props.pending || !prevProps.isFavorite && this.props.isFavorite)) {
                this.onAnimationStart();
                this.onSettingAnimationEnd();
            }
        }
        onAnimationStart() {
            this.setState({
                isAnimating: !this.state.isAnimating
            });
        }
        onSettingAnimationEnd() {
            setTimeout(()=>{
                this.setState({
                    isAnimating: false
                });
            }, $0a68425ce3fc2fa8$var$animationTime * 2);
        }
        render() {
            const { isFavorite: isFavorite, favoriteItemName: favoriteItemName, isShownInList: isShownInList, isSmall: isSmall, hideTooltip: hideTooltip, tooltipPosition: tooltipPosition, pending: pending, intl: { formatMessage: formatMessage } } = this.props;
            const { isAnimating: isAnimating } = this.state;
            if (isFavorite === undefined) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
                name: "favourite-button"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$db65185b5889c83, {
                "data-testid": $0a68425ce3fc2fa8$var$SKELETON_TEST_ID,
                isSmall: isSmall
            }));
            const iconWrapperTestId = isShownInList ? `${$0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX}-list` : $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX;
            const buttonContentOld = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, isFavorite ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("bH1hf"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred)
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dAf5R"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("5oTeF"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred)
            }));
            const renderIcon = ()=>{
                const label = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
                let StarIconComponent;
                if (isSmall) StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("7FtaJ"))))) : (0, ($parcel$interopDefault((0, parcelRequire("1Cvrw")))));
                else StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))) : (0, ($parcel$interopDefault((0, parcelRequire("dAf5R")))));
                return (0, ($parcel$interopDefault($5uXOq))).createElement(StarIconComponent, {
                    color: "currentColor",
                    label: label
                });
            };
            const buttonContent = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, renderIcon());
            const tooltipContent = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
            const ariaLabel = favoriteItemName ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarredWithItemName, {
                itemName: favoriteItemName
            }) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarred);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$bb1478fee6cd5f0c, {
                role: "presentation",
                onClick: this.onBtnClickWrapper,
                onKeyPress: this.onBtnClickWrapper,
                "data-is-favorite": isFavorite
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: !hideTooltip && tooltipContent,
                hideTooltipOnClick: true,
                position: tooltipPosition
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: isShownInList ? 'subtle-link' : 'subtle',
                spacing: "none",
                "aria-label": ariaLabel,
                "aria-pressed": isFavorite,
                onClick: !pending ? this.onClick : (0, ($parcel$interopDefault($8Rkzz)))
            }, (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? buttonContent : buttonContentOld)));
        }
        constructor(...args){
            super(...args);
            this.state = {
                isAnimating: false
            };
            this.onBtnClickWrapper = (e)=>{
                e.stopPropagation();
                e.preventDefault();
            };
            this.onClick = (_, analyticsEvent)=>{
                this.props.onClick(analyticsEvent);
            };
        }
    }
    var $0a68425ce3fc2fa8$export$2e2bcd8739ae039 = (0, ($parcel$interopDefault($basmW)))((0, (0, parcelRequire("iQv12")).default)({
        onClick: ()=>({
                name: 'star'
            })
    }), (0, (0, parcelRequire("az4zD")).injectIntlV2))($0a68425ce3fc2fa8$export$f8ad2acf0ec4860f);
    const $0a68425ce3fc2fa8$var$animationTime = 500;
    const $0a68425ce3fc2fa8$export$bb1478fee6cd5f0c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1o8l",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_11vn1ssb _1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase = (0, (0, parcelRequire("7XYc4")).componentWithCondition)((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled), $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew, $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld);
    const $0a68425ce3fc2fa8$var$FavIconWrapper = (0, $5uXOq.forwardRef)(({ as: C = $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.isFavorite ? "_syaz2kyj" : "_syaz2u25",
                __cmplp.isFavorite ? "_30l31ebw" : "_30l3w9a8",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$export$db65185b5889c83 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iQv12", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$945500a9598c3ac2$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gvRPW;
    const $945500a9598c3ac2$var$fireWithUIAnalytics = (ConnectedProps)=>{
        const providedKeys = Object.keys(ConnectedProps).sort();
        const cachedMappedProps = {};
        const cachedConnectedProps = {};
        const getStateFromMappedProps = (mapped)=>{
            providedKeys.forEach((key, index)=>{
                const prop = mapped[index];
                if (key in cachedMappedProps && cachedMappedProps[key] === prop) return;
                cachedMappedProps[key] = prop;
                cachedConnectedProps[key] = (...attrs)=>{
                    const analyticsEvent = attrs[attrs.length - 1].clone();
                    const connectedProp = ConnectedProps[key];
                    if (typeof ConnectedProps[key] === 'string') analyticsEvent.update({
                        name: connectedProp,
                        analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                    }).fire();
                    else if (typeof connectedProp === 'function') {
                        const update = connectedProp(...attrs);
                        analyticsEvent.update({
                            ...update,
                            analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                        }).fire();
                    }
                    if (typeof prop === 'function') prop(...attrs);
                };
            });
            return cachedConnectedProps;
        };
        return (WrappedComponent)=>class WithAnalytics extends (0, $5uXOq.Component) {
                static{
                    this.displayName = `WithAnalytics(${WrappedComponent.displayName || WrappedComponent.name || 'UnnamedComponent'}`;
                }
                render() {
                    const newMappedProps = getStateFromMappedProps(providedKeys.map((key)=>this.props[key]));
                    return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, {
                        ...this.props,
                        ...newMappedProps
                    });
                }
            };
    };
    var $945500a9598c3ac2$export$2e2bcd8739ae039 = $945500a9598c3ac2$var$fireWithUIAnalytics;
});
parcelRegister("6fQ6A", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$10bc2ec5d9a72db1$export$2e2bcd8739ae039);
    var $7VHMR;
    var $10bc2ec5d9a72db1$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        addToStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.add-to-starred",
            "defaultMessage": "Add to Starred"
        },
        removeFromStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.remove-from-starred",
            "defaultMessage": "Remove from Starred"
        },
        toggleStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred",
            "defaultMessage": "Star"
        },
        toggleStarredWithItemName: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred-with-item-name",
            "defaultMessage": "Star {itemName}"
        }
    });
});
parcelRegister("l3Aku", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9e49bd54aff07a59$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9e49bd54aff07a59$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "directory-base-v3.table-cells.favourite-cell.title",
            "defaultMessage": "Something\u2019s gone wrong"
        },
        description: {
            "id": "directory-base-v3.table-cells.favourite-cell.description",
            "defaultMessage": "You cannot star items right now due to an error."
        }
    });
});
parcelRegister("cKtQP", function(module, exports) {
    $parcel$export(module.exports, "KeyCell", ()=>$a6c5ff1d3d963ca3$export$4d17c2f4d9bbf823);
    var $62jWf;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    const $a6c5ff1d3d963ca3$export$4d17c2f4d9bbf823 = ({ project: project })=>{
        const { key: key } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("62jWf")).default), project);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, key);
    };
});
parcelRegister("62jWf", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b9bca1dbeaf30902$export$2e2bcd8739ae039);
    const $b9bca1dbeaf30902$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "keyCell_projectsDirectoryV3",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "key"
            }
        ],
        "type": "JiraProject"
    };
    $b9bca1dbeaf30902$var$node.hash = "8366f681ad04b41cb180bc7a28c738a4";
    var $b9bca1dbeaf30902$export$2e2bcd8739ae039 = $b9bca1dbeaf30902$var$node;
});
parcelRegister("dpBMT", function(module, exports) {
    $parcel$export(module.exports, "LastIssueUpdateCell", ()=>$86c4b041a3cfb14e$export$c2180874b4ed0214);
    var $gcQJe;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $3bDjY;
    var $k71ZQ;
    const $86c4b041a3cfb14e$export$c2180874b4ed0214 = ({ project: project })=>{
        const { lastUpdatedToolTipDate: lastUpdatedToolTipDate, lastUpdatedDisplayDate: lastUpdatedDisplayDate } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("gcQJe")).default), project);
        if (!lastUpdatedDisplayDate) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k71ZQ")).CellWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: lastUpdatedToolTipDate,
            position: "top"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("span", null, lastUpdatedDisplayDate)));
    };
});
parcelRegister("gcQJe", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3dcc8f1aebe109db$export$2e2bcd8739ae039);
    const $3dcc8f1aebe109db$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "lastIssueUpdateCell_projectsDirectoryV3",
        "selections": [
            {
                "alias": "lastUpdatedToolTipDate",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "format",
                        "value": "COMPLETE_DATETIME_FORMAT"
                    }
                ],
                "kind": "ScalarField",
                "name": "lastUpdatedFormatted",
                "storageKey": "lastUpdatedFormatted(format:\"COMPLETE_DATETIME_FORMAT\")"
            },
            {
                "alias": "lastUpdatedDisplayDate",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "format",
                        "value": "RELATIVE"
                    }
                ],
                "kind": "ScalarField",
                "name": "lastUpdatedFormatted",
                "storageKey": "lastUpdatedFormatted(format:\"RELATIVE\")"
            }
        ],
        "type": "JiraProject"
    };
    $3dcc8f1aebe109db$var$node.hash = "be249fa705b1ccb8ba2a516f14f08647";
    var $3dcc8f1aebe109db$export$2e2bcd8739ae039 = $3dcc8f1aebe109db$var$node;
});
parcelRegister("k71ZQ", function(module, exports) {
    $parcel$export(module.exports, "CellWrapper", ()=>$5e2591e176309da1$export$4812fadef83edffe);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $5e2591e176309da1$export$4812fadef83edffe = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1reo15vq _18m915vq _o5721q9c _1bto1l2s",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("9g60v", function(module, exports) {
    $parcel$export(module.exports, "LeadCell", ()=>$8e208bc361a14491$export$53c185d8cac45d1d);
    var $cYWJt;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $3BX7N;
    const $8e208bc361a14491$export$53c185d8cac45d1d = ({ project: project })=>{
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("cYWJt")).default), project);
        return data?.lead ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3BX7N")).UserProfile), {
            dataRef: data.lead
        }) : null;
    };
});
parcelRegister("cYWJt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$580b0ea2b842b806$export$2e2bcd8739ae039);
    const $580b0ea2b842b806$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "leadCell_projectsDirectoryV3",
        "selections": [
            {
                "kind": "LinkedField",
                "name": "lead",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "userProfile_directoryBaseV3"
                    }
                ]
            }
        ],
        "type": "JiraProject"
    };
    $580b0ea2b842b806$var$node.hash = "900649656e1e2128dc46e511bce9897f";
    var $580b0ea2b842b806$export$2e2bcd8739ae039 = $580b0ea2b842b806$var$node;
});
parcelRegister("3BX7N", function(module, exports) {
    $parcel$export(module.exports, "UserProfile", ()=>$16c5a599774d5a20$export$b7b58f2e100445f7);
    var $eV9WL;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $ujWmd;
    var $dC5iT;
    var $8CUq3;
    var $41j30;
    var $4R6GH;
    var $kYYfm;
    var $dCm1o;
    const $16c5a599774d5a20$var$UserProfile = ({ dataRef: dataRef })=>{
        const { accountId: accountId, name: displayName, picture: avatarUrl } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("eV9WL")).default), dataRef);
        return (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_eap_drop_2') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kYYfm")).AsyncProfileCardNext), {
            accountId: accountId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $16c5a599774d5a20$var$containerStyles,
            "data-testId": `directory-user-profile-${accountId}`
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $16c5a599774d5a20$var$avatarContainerStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ujWmd")).default), {
            src: avatarUrl,
            size: "small",
            appearance: "circle"
        })), accountId && !(0, (0, parcelRequire("4R6GH")).fg)('blu-4174-enghealth-11799-a11y-fix') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("41j30")).default), {
            href: `/jira/people/${accountId}`
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $16c5a599774d5a20$var$textStyles
        }, displayName)) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $16c5a599774d5a20$var$textStyles
        }, displayName))) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kYYfm")).AsyncProfileCardNext), {
            accountId: accountId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($16c5a599774d5a20$var$ContentContainer, {
            "data-testId": `directory-user-profile-${accountId}`
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($16c5a599774d5a20$var$AvatarContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ujWmd")).default), {
            src: avatarUrl,
            size: "small",
            appearance: "circle"
        })), accountId && !(0, (0, parcelRequire("4R6GH")).fg)('blu-4174-enghealth-11799-a11y-fix') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("41j30")).default), {
            href: `/jira/people/${accountId}`
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($16c5a599774d5a20$var$TextContainer, null, displayName)) : (0, ($parcel$interopDefault($5uXOq))).createElement($16c5a599774d5a20$var$TextContainer, null, displayName)));
    };
    const $16c5a599774d5a20$export$b7b58f2e100445f7 = (0, $5uXOq.memo)($16c5a599774d5a20$var$UserProfile);
    const $16c5a599774d5a20$var$ContentContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _16jlkb7n _1o9zkb7n _i0dl1wug _1bsb1osq",
                __cmplp.className
            ])
        });
    });
    const $16c5a599774d5a20$var$AvatarContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_u5f3u2gc _vwz4idpf _16jlidpf _1o9zidpf _i0dl1wug",
                __cmplp.className
            ])
        });
    });
    const $16c5a599774d5a20$var$TextContainer = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_slp31hna _1reo15vq _18m915vq _o5721q9c _1bto1l2s",
                __cmplp.className
            ])
        });
    });
    const $16c5a599774d5a20$var$containerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 auto',
        width: '100%'
    });
    const $16c5a599774d5a20$var$avatarContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingRight: 'space.100',
        lineHeight: 0,
        flex: '0 0 auto'
    });
    const $16c5a599774d5a20$var$textStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'contents',
        wordWrap: 'break-word',
        overflow: 'hidden',
        color: 'color.text.subtle',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    });
});
parcelRegister("eV9WL", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$109194a127fc57c7$export$2e2bcd8739ae039);
    const $109194a127fc57c7$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "userProfile_directoryBaseV3",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "accountId"
            },
            {
                "kind": "ScalarField",
                "name": "name"
            },
            {
                "kind": "ScalarField",
                "name": "picture"
            }
        ],
        "type": "User",
        "abstractKey": "__isUser"
    };
    $109194a127fc57c7$var$node.hash = "32feb6e507f926d7932dcef23d9427a7";
    var $109194a127fc57c7$export$2e2bcd8739ae039 = $109194a127fc57c7$var$node;
});
parcelRegister("41j30", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7c2f3c33a6194375$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $8oe80;
    var $hDzhb;
    function $7c2f3c33a6194375$var$Link(props) {
        const { children: children, ...rest } = props;
        delete rest.createAnalyticsEvent;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hDzhb")).default), rest, children);
    }
    var $7c2f3c33a6194375$export$2e2bcd8739ae039 = (0, (0, parcelRequire("8oe80")).default)('link', {
        onClick: 'clicked'
    })($7c2f3c33a6194375$var$Link);
});
parcelRegister("kYYfm", function(module, exports) {
    $parcel$export(module.exports, "AsyncProfileCardNext", ()=>$15521008cc986085$export$a3c0845f856d4292);
    var $5uXOq = parcelRequire("5uXOq");
    var $39xOx;
    var $gcl1l;
    var $lfTZh;
    var $kxZPA;
    const $15521008cc986085$var$LazyComponent = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("beEVQ")).then((module)=>module.ProfileCard), {
        moduleId: "./src/packages/profilecard-next/src/main.tsx"
    });
    const $15521008cc986085$export$a3c0845f856d4292 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "AsyncProfileCardNext",
            packageName: "jiraProfilecardNext",
            render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, props.children),
            sendToPrivacyUnsafeSplunk: true,
            teamName: "empanada",
            attributes: (0, (0, parcelRequire("gcl1l")).isLoaderErrorAttributes)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-profile-card-next-view",
            fallback: props.children
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($15521008cc986085$var$LazyComponent, props)));
    var $15521008cc986085$export$2e2bcd8739ae039 = $15521008cc986085$export$a3c0845f856d4292;
});
parcelRegister("gcl1l", function(module, exports) {
    $parcel$export(module.exports, "isLoaderErrorAttributes", ()=>$ecfe970d67067a10$export$c7209ce2e0d060ea);
    var $bCf8q;
    const $ecfe970d67067a10$export$c7209ce2e0d060ea = (error)=>({
            isLoaderError: (0, (0, parcelRequire("bCf8q")).isLoaderError)(error)
        });
});
parcelRegister("beEVQ", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("ek7ue")
    ]).then(()=>parcelRequire('jO6eK'));
});
parcelRegister("1Hnw6", function(module, exports) {
    $parcel$export(module.exports, "NameCell", ()=>$86fdd751cdcb86f6$export$2e923ef80ad8608a);
    var $fZsDk;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $dC5iT;
    var $8CUq3;
    var $41j30;
    var $k71ZQ;
    var $4R6GH;
    const $86fdd751cdcb86f6$export$2d483fceb9cca990 = (key, navigationMetadata, projectStyle)=>{
        if (!navigationMetadata) return `/browse/${key}`;
        if (navigationMetadata.__typename === 'JiraSoftwareProjectNavigationMetadata') {
            if (projectStyle === 'TEAM_MANAGED_PROJECT') return `/jira/software/projects/${key}/boards/${navigationMetadata.boardId}`;
            if (projectStyle === 'COMPANY_MANAGED_PROJECT') return `/jira/software/c/projects/${key}/boards/${navigationMetadata.boardId}`;
        }
        if (navigationMetadata.__typename === 'JiraServiceManagementProjectNavigationMetadata') return (0, (0, parcelRequire("4R6GH")).fg)('fix_jsm_queue_redirect_issue_from_project_list') ? `/jira/servicedesk/projects/${key}` : `/jira/servicedesk/projects/${key}/queues/custom/${navigationMetadata.queueId}`;
        return `/browse/${key}`;
    };
    const $86fdd751cdcb86f6$export$2e923ef80ad8608a = ({ project: project })=>{
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("fZsDk")).default), project);
        const boardUrl = $86fdd751cdcb86f6$export$2d483fceb9cca990(data?.key, data?.navigationMetadata, data?.projectStyle);
        if (data?.avatar?.medium) return (0, ($parcel$interopDefault($5uXOq))).createElement($86fdd751cdcb86f6$var$Container, null, (0, ($parcel$interopDefault($5uXOq))).createElement($86fdd751cdcb86f6$var$Link, {
            href: boardUrl
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $86fdd751cdcb86f6$var$AvatarStyles,
            as: "img",
            src: data.avatar.medium,
            alt: ""
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($86fdd751cdcb86f6$var$WithAvatarLabel, null, data.name)));
        return (0, ($parcel$interopDefault($5uXOq))).createElement($86fdd751cdcb86f6$var$Container, null, (0, ($parcel$interopDefault($5uXOq))).createElement($86fdd751cdcb86f6$var$Link, {
            href: boardUrl
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($86fdd751cdcb86f6$var$NoAvatarLabel, null, data.name)));
    };
    const $86fdd751cdcb86f6$export$29a344cbda7670b7 = '24px';
    const $86fdd751cdcb86f6$var$NoAvatarLabel = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18u0xy5q _1reo15vq _18m915vq _1bto1l2s",
                __cmplp.className
            ])
        });
    });
    const $86fdd751cdcb86f6$var$WithAvatarLabel = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18u0u2gc _1reo15vq _18m915vq _1bto1l2s",
                __cmplp.className
            ])
        });
    });
    const $86fdd751cdcb86f6$var$AvatarStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        flexShrink: 0,
        width: "var(--ds-space-300, 24px)",
        height: "var(--ds-space-300, 24px)",
        borderRadius: 'border.radius.050',
        display: 'inline-block',
        verticalAlign: 'middle'
    });
    const $86fdd751cdcb86f6$var$Link = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("41j30")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule _1reo15vq",
                __cmplp.className
            ])
        });
    });
    const $86fdd751cdcb86f6$var$Container = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("k71ZQ")).CellWrapper), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4cvr1h6o _1reon7od _18m9n7od",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("fZsDk", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$acbcfd3f392f5769$export$2e2bcd8739ae039);
    const $acbcfd3f392f5769$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "nameCell_projectsDirectoryV3",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "name"
            },
            {
                "kind": "ScalarField",
                "name": "key"
            },
            {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "medium"
                    }
                ]
            },
            {
                "kind": "ScalarField",
                "name": "projectStyle"
            },
            {
                "kind": "LinkedField",
                "name": "navigationMetadata",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "__typename"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "boardId"
                            }
                        ],
                        "type": "JiraSoftwareProjectNavigationMetadata"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "name": "queueId"
                            }
                        ],
                        "type": "JiraServiceManagementProjectNavigationMetadata"
                    }
                ]
            }
        ],
        "type": "JiraProject"
    };
    $acbcfd3f392f5769$var$node.hash = "ba733526a1242d306b4f0b4e62066653";
    var $acbcfd3f392f5769$export$2e2bcd8739ae039 = $acbcfd3f392f5769$var$node;
});
parcelRegister("kf2VM", function(module, exports) {
    $parcel$export(module.exports, "ProjectCategoryCell", ()=>$8dd3a0b626ca709f$export$1bd789d7a92e69d5);
    var $iILWc;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $k71ZQ;
    const $8dd3a0b626ca709f$export$1bd789d7a92e69d5 = ({ project: project })=>{
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("iILWc")).default), project);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k71ZQ")).CellWrapper), null, data?.category?.name);
    };
});
parcelRegister("iILWc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c9bdd904e13889b1$export$2e2bcd8739ae039);
    const $c9bdd904e13889b1$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "projectCategoryCell_projectsDirectoryV3",
        "selections": [
            {
                "concreteType": "JiraProjectCategory",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "name": "name"
                    }
                ]
            }
        ],
        "type": "JiraProject"
    };
    $c9bdd904e13889b1$var$node.hash = "8895769fe267fb6dd387e5807f5f7fe1";
    var $c9bdd904e13889b1$export$2e2bcd8739ae039 = $c9bdd904e13889b1$var$node;
});
parcelRegister("65Pfm", function(module, exports) {
    $parcel$export(module.exports, "ProjectTypeCell", ()=>$b95a4f1456c2cb80$export$8052dbb4a8a7e741);
    var $bjQBi;
    var $5uXOq = parcelRequire("5uXOq");
    var $9XE6b = parcelRequire("9XE6b");
    var $8Uumt = parcelRequire("8Uumt");
    var $k71ZQ;
    var $koVbs;
    var $1mECc;
    const $b95a4f1456c2cb80$var$projectTypeLabels = {
        COMPANY_MANAGED_PROJECT: {
            BUSINESS: (0, (0, parcelRequire("1mECc")).default).companyManagedBusiness,
            SOFTWARE: (0, (0, parcelRequire("1mECc")).default).companyManagedSoftware,
            SERVICE_DESK: (0, (0, parcelRequire("1mECc")).default).companyManagedServiceProject,
            PRODUCT_DISCOVERY: (0, (0, parcelRequire("1mECc")).default).productDiscoveryProject,
            CUSTOMER_SERVICE: (0, (0, parcelRequire("1mECc")).default).customerServiceProjectNonFinal
        },
        TEAM_MANAGED_PROJECT: {
            BUSINESS: (0, (0, parcelRequire("1mECc")).default).teamManagedBusiness,
            SOFTWARE: (0, (0, parcelRequire("1mECc")).default).teamManagedSoftware,
            SERVICE_DESK: (0, (0, parcelRequire("1mECc")).default).teamManagedServiceProject,
            PRODUCT_DISCOVERY: (0, (0, parcelRequire("1mECc")).default).productDiscoveryProject,
            CUSTOMER_SERVICE: (0, (0, parcelRequire("1mECc")).default).customerServiceProjectNonFinal
        }
    };
    const $b95a4f1456c2cb80$export$8052dbb4a8a7e741 = ({ project: project })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { projectType: projectType, projectStyle: projectStyle } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("bjQBi")).default), project);
        const projectStyleKey = projectStyle || 'TEAM_MANAGED_PROJECT';
        const label = (0, ($parcel$interopDefault($9XE6b)))($b95a4f1456c2cb80$var$projectTypeLabels, [
            projectStyleKey,
            projectType || ''
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k71ZQ")).CellWrapper), null, label ? formatMessage(label) : projectType);
    };
});
parcelRegister("bjQBi", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b43a4434e331c6a8$export$2e2bcd8739ae039);
    const $b43a4434e331c6a8$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "projectTypeCell_projectsDirectoryV3",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "projectStyle"
            },
            {
                "kind": "ScalarField",
                "name": "projectType"
            }
        ],
        "type": "JiraProject"
    };
    $b43a4434e331c6a8$var$node.hash = "070d0af17c4bb3ec3f4ea5c84d8914ff";
    var $b43a4434e331c6a8$export$2e2bcd8739ae039 = $b43a4434e331c6a8$var$node;
});
parcelRegister("1mECc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9bc33c325b6b85b7$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9bc33c325b6b85b7$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        teamManagedBusiness: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.team-managed-business",
            "defaultMessage": "Team-managed business"
        },
        companyManagedBusiness: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.company-managed-business",
            "defaultMessage": "Company-managed business"
        },
        companyManagedServiceProject: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.company-managed-service-project",
            "defaultMessage": "Service management"
        },
        teamManagedServiceProject: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.team-managed-service-project",
            "defaultMessage": "Team-managed service management"
        },
        companyManagedSoftware: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.company-managed-software",
            "defaultMessage": "Company-managed software"
        },
        teamManagedSoftware: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.team-managed-software",
            "defaultMessage": "Team-managed software"
        },
        productDiscoveryProject: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.product-discovery-project",
            "defaultMessage": "Product Discovery"
        },
        customerServiceProjectNonFinal: {
            "id": "projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.customer-service-project-non-final",
            "defaultMessage": "Customer service"
        }
    });
});
parcelRegister("3TDIg", function(module, exports) {
    $parcel$export(module.exports, "UrlCell", ()=>$c616a251894259db$export$110272e30ac53e5);
    var $12DqC;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $458iJ;
    var $3bDjY;
    const $c616a251894259db$export$110272e30ac53e5 = ({ project: project })=>{
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("12DqC")).default), project);
        if (!data?.projectUrl) return null;
        const { projectUrl: projectUrl } = data;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($c616a251894259db$var$UrlCellContent, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: projectUrl
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "subtle-link",
            href: projectUrl,
            target: "_blank",
            spacing: "none",
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("458iJ"))))), {
                size: "medium",
                label: projectUrl
            })
        })));
    };
    const $c616a251894259db$var$UrlCellContent = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bah1h6o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("12DqC", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bdc5c3a790efcbf4$export$2e2bcd8739ae039);
    const $bdc5c3a790efcbf4$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "urlCell_projectsDirectoryV3",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "projectUrl"
            }
        ],
        "type": "JiraProject"
    };
    $bdc5c3a790efcbf4$var$node.hash = "bc3c770944d0003a95b4fc28334d594a";
    var $bdc5c3a790efcbf4$export$2e2bcd8739ae039 = $bdc5c3a790efcbf4$var$node;
});
parcelRegister("458iJ", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $2f8e117d74b4de46$var$_react = $2f8e117d74b4de46$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $2f8e117d74b4de46$var$_link = $2f8e117d74b4de46$var$_interopRequireDefault((parcelRequire("1f9bV")));
    function $2f8e117d74b4de46$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $2f8e117d74b4de46$var$LinkIcon = (props)=>$2f8e117d74b4de46$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd"><path d="m12.856 5.457-.937.92a1 1 0 0 0 0 1.437 1.047 1.047 0 0 0 1.463 0l.984-.966c.967-.95 2.542-1.135 3.602-.288a2.54 2.54 0 0 1 .203 3.81l-2.903 2.852a2.646 2.646 0 0 1-3.696 0l-1.11-1.09L9 13.57l1.108 1.089c1.822 1.788 4.802 1.788 6.622 0l2.905-2.852a4.558 4.558 0 0 0-.357-6.82c-1.893-1.517-4.695-1.226-6.422.47"/><path d="m11.144 19.543.937-.92a1 1 0 0 0 0-1.437 1.047 1.047 0 0 0-1.462 0l-.985.966c-.967.95-2.542 1.135-3.602.288a2.54 2.54 0 0 1-.203-3.81l2.903-2.852a2.646 2.646 0 0 1 3.696 0l1.11 1.09L15 11.43l-1.108-1.089c-1.822-1.788-4.802-1.788-6.622 0l-2.905 2.852a4.558 4.558 0 0 0 .357 6.82c1.893 1.517 4.695 1.226 6.422-.47"/></g></svg>`
        }, props, {
            newIcon: $2f8e117d74b4de46$var$_link.default
        }));
    $2f8e117d74b4de46$var$LinkIcon.displayName = 'LinkIcon';
    var $2f8e117d74b4de46$var$_default = module.exports.default = $2f8e117d74b4de46$var$LinkIcon;
});
parcelRegister("1f9bV", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $0e7e525cdad15340$var$_react = $0e7e525cdad15340$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $0e7e525cdad15340$var$_UNSAFE_baseNew = $0e7e525cdad15340$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $0e7e525cdad15340$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $0e7e525cdad15340$var$LinkIcon = (props)=>$0e7e525cdad15340$var$_react.default.createElement($0e7e525cdad15340$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-width="1.5" d="m5.25 10.75 5.5-5.5M5 6.5 2.75 8.75a3.182 3.182 0 0 0 4.5 4.5L9.5 11m-3-6 2.25-2.25a3.182 3.182 0 0 1 4.5 4.5L11 9.5"/>`
        }, props));
    $0e7e525cdad15340$var$LinkIcon.displayName = 'LinkIcon';
    var $0e7e525cdad15340$var$_default = module.exports.default = $0e7e525cdad15340$var$LinkIcon;
});
parcelRegister("qB72M", function(module, exports) {
    $parcel$export(module.exports, "RefinementBar", ()=>$de420aeb16fb521a$export$1772ce3730aa9a66);
    var $gHOaW;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $gsfYl;
    var $aKLxh;
    var $hJiWC;
    var $lOzQH;
    const $de420aeb16fb521a$var$Filters = ({ dataRef: dataRef })=>{
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("gHOaW")).default), dataRef);
        const shouldShowCategories = data.jira && Boolean(data.jira?.allJiraProjectCategories?.edges?.length);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lOzQH")).SearchControl), null), data?.jira && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hJiWC")).ProjectTypePicker), {
            dataRef: data.jira
        }), shouldShowCategories && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aKLxh")).ProjectCategoryPicker), {
            dataRef: data.jira
        }));
    };
    const $de420aeb16fb521a$export$1772ce3730aa9a66 = ({ dataRef: dataRef })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gsfYl")).RefinementBar), null, (0, ($parcel$interopDefault($5uXOq))).createElement($de420aeb16fb521a$var$Filters, {
            dataRef: dataRef
        }));
});
parcelRegister("gHOaW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$dc502ca7a6e9feac$export$2e2bcd8739ae039);
    const $dc502ca7a6e9feac$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAnonymous"
            }
        ],
        "kind": "Fragment",
        "name": "refinementBar_projectsDirectoryV3_Filters",
        "selections": [
            {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                    {
                        "condition": "isAnonymous",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                            {
                                "args": [
                                    {
                                        "kind": "Variable",
                                        "name": "cloudId",
                                        "variableName": "cloudId"
                                    }
                                ],
                                "concreteType": "JiraProjectCategoryConnection",
                                "kind": "LinkedField",
                                "name": "allJiraProjectCategories",
                                "plural": false,
                                "selections": [
                                    {
                                        "concreteType": "JiraProjectCategoryEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "kind": "ScalarField",
                                                "name": "__typename"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "kind": "FragmentSpread",
                                "name": "projectCategoryPicker_projectsDirectoryV3"
                            }
                        ]
                    },
                    {
                        "kind": "FragmentSpread",
                        "name": "projectTypePicker_projectsDirectoryV3"
                    }
                ]
            }
        ],
        "type": "Query"
    };
    $dc502ca7a6e9feac$var$node.hash = "44ad103870f84d678e5203337cb09f3c";
    var $dc502ca7a6e9feac$export$2e2bcd8739ae039 = $dc502ca7a6e9feac$var$node;
});
parcelRegister("gsfYl", function(module, exports) {
    $parcel$export(module.exports, "RefinementBar", ()=>$9847a28eabdb9f07$export$1772ce3730aa9a66);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $9847a28eabdb9f07$export$1772ce3730aa9a66 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement($9847a28eabdb9f07$var$Wrapper, null, children);
    const $9847a28eabdb9f07$var$Wrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _zulppxbi _16jlkb7n _1o9zkb7n _i0dlf1ug _otyrpxbi",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("aKLxh", function(module, exports) {
    $parcel$export(module.exports, "ProjectCategoryPicker", ()=>$1a698ceca317d045$export$307a32ce3626da26);
    var $lHEuD;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $7K5PP;
    var $dZhZb;
    var $koVbs;
    var $dXq4S;
    var $8zOmE;
    var $2NMNM;
    var $gkT9B;
    var $dPvGX;
    var $6psbp;
    const $1a698ceca317d045$export$307a32ce3626da26 = ({ dataRef: dataRef })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'changed',
            actionSubject: 'refinementBar'
        });
        const [value, { updateFilter: updateFilter }] = (0, (0, parcelRequire("dPvGX")).useScopedFilter)((0, (0, parcelRequire("gkT9B")).FilterFields).ProjectCategoryId);
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("lHEuD")).default), dataRef);
        const onChange = (0, $5uXOq.useCallback)((newValue)=>{
            updateFilter({
                [(0, (0, parcelRequire("gkT9B")).FilterFields).ProjectCategoryId]: newValue ? String(newValue.value) : '',
                [(0, (0, parcelRequire("gkT9B")).FilterFields).ProjectCategoryLabel]: newValue ? String(newValue.label) : ''
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'ProjectCategoryPicker');
        }, [
            updateFilter,
            analyticsEvent
        ]);
        const options = (0, $5uXOq.useMemo)(()=>data?.allJiraProjectCategories?.edges?.map((edge)=>({
                    value: edge?.node?.id ? (0, (0, parcelRequire("dXq4S")).getAriConfig)(edge?.node?.id).resourceId : '',
                    label: edge?.node?.name || ''
                })) || [], [
            data
        ]);
        const selectedValue = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dZhZb")).matchValueToSelectOptions)(value, options), [
            value,
            options
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1a698ceca317d045$var$SelectWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7K5PP")).default), {
            "aria-label": formatMessage((0, (0, parcelRequire("6psbp")).default).ariaLabel),
            placeholder: formatMessage((0, (0, parcelRequire("6psbp")).default).placeholder),
            isClearable: true,
            name: (0, (0, parcelRequire("gkT9B")).FilterFields).ProjectCategoryId,
            value: selectedValue,
            onChange: onChange,
            options: options
        }));
    };
    const $1a698ceca317d045$var$SelectWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1ul9uuw1",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("lHEuD", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$739203250c4d55bf$export$2e2bcd8739ae039);
    const $739203250c4d55bf$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "cloudId"
            }
        ],
        "kind": "Fragment",
        "name": "projectCategoryPicker_projectsDirectoryV3",
        "selections": [
            {
                "args": [
                    {
                        "kind": "Variable",
                        "name": "cloudId",
                        "variableName": "cloudId"
                    }
                ],
                "concreteType": "JiraProjectCategoryConnection",
                "kind": "LinkedField",
                "name": "allJiraProjectCategories",
                "plural": false,
                "selections": [
                    {
                        "concreteType": "JiraProjectCategoryEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "concreteType": "JiraProjectCategory",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "id"
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "name": "name"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraQuery"
    };
    $739203250c4d55bf$var$node.hash = "9e121ef0e1f18f545400a0c2ac559c3f";
    var $739203250c4d55bf$export$2e2bcd8739ae039 = $739203250c4d55bf$var$node;
});
parcelRegister("7K5PP", function(module, exports) {
    $parcel$export(module.exports, "SelectWithoutAnalytics", ()=>$c2d344e1481a2d3b$export$d1de3b251ccaa8a3);
    $parcel$export(module.exports, "default", ()=>$c2d344e1481a2d3b$export$2e2bcd8739ae039);
    var $gB4mS;
    var $994MI;
    var $cb25w;
    var $eAthk;
    var $3c98u;
    const $c2d344e1481a2d3b$var$packageName = "platform-pkg";
    const $c2d344e1481a2d3b$var$packageVersion = "0.0.0-use.local";
    const $c2d344e1481a2d3b$export$d1de3b251ccaa8a3 = (0, (0, parcelRequire("3c98u")).default)((0, (0, parcelRequire("eAthk")).default));
    const $c2d344e1481a2d3b$var$createAndFireEventOnAtlaskit = (0, (0, parcelRequire("gB4mS")).default)('atlaskit');
    const $c2d344e1481a2d3b$var$Select = (0, (0, parcelRequire("994MI")).default)({
        componentName: 'select',
        packageName: $c2d344e1481a2d3b$var$packageName,
        packageVersion: $c2d344e1481a2d3b$var$packageVersion
    })((0, (0, parcelRequire("cb25w")).default)({
        onChange: $c2d344e1481a2d3b$var$createAndFireEventOnAtlaskit({
            action: 'changed',
            actionSubject: 'option',
            attributes: {
                componentName: 'select',
                packageName: $c2d344e1481a2d3b$var$packageName,
                packageVersion: $c2d344e1481a2d3b$var$packageVersion
            }
        })
    })($c2d344e1481a2d3b$export$d1de3b251ccaa8a3));
    var $c2d344e1481a2d3b$export$2e2bcd8739ae039 = $c2d344e1481a2d3b$var$Select;
});
parcelRegister("eAthk", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6d0cf20527a3a891$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "useAsync", ()=>(parcelRequire("iaInK")).default);
    var $5uXOq = parcelRequire("5uXOq");
    var $2QhWf;
    var $iaInK;
    var $9uJ4X;
    const $6d0cf20527a3a891$var$AsyncSelect = (0, $5uXOq.forwardRef)((props, ref)=>{
        const isAsyncEnabledInBaseSelect = props.isAsync && !props.options && (!!props.loadOptions || !!props.defaultOptions);
        const stateManagedProps = (0, (0, parcelRequire("iaInK")).default)(props);
        let selectAsyncProps = !props.isAsync || isAsyncEnabledInBaseSelect ? stateManagedProps : props;
        const selectProps = (0, (0, parcelRequire("9uJ4X")).default)(selectAsyncProps);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2QhWf")).default), {
            ref: ref,
            ...selectProps
        });
    });
    var $6d0cf20527a3a891$export$2e2bcd8739ae039 = $6d0cf20527a3a891$var$AsyncSelect;
});
parcelRegister("2QhWf", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d8e863683b34816f$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $VSDnB;
    var $aCMCu;
    var $eEtcC;
    var $bChGW;
    var $jtVgE;
    var $9cRAr;
    var $galqx;
    var $81i5b;
    var $aA8TC;
    var $5JAlL;
    var $7VXAu;
    var $5HBcW;
    var $gyjU7;
    const $d8e863683b34816f$export$9c21a3269931e1e6 = {
        'aria-live': 'polite',
        backspaceRemovesValue: true,
        blurInputOnSelect: (0, (0, parcelRequire("gyjU7")).isTouchCapable)(),
        captureMenuScroll: !(0, (0, parcelRequire("gyjU7")).isTouchCapable)(),
        classNames: {},
        closeMenuOnSelect: true,
        closeMenuOnScroll: false,
        components: {},
        controlShouldRenderValue: true,
        escapeClearsValue: false,
        filterOption: (0, (0, parcelRequire("5JAlL")).createFilter)(),
        formatGroupLabel: (0, (0, parcelRequire("eEtcC")).formatGroupLabel),
        getOptionLabel: (0, (0, parcelRequire("eEtcC")).getOptionLabel),
        getOptionValue: (0, (0, parcelRequire("eEtcC")).getOptionValue),
        isDisabled: false,
        isLoading: false,
        isMulti: false,
        isRtl: false,
        isSearchable: true,
        isOptionDisabled: (0, (0, parcelRequire("eEtcC")).isOptionDisabled),
        loadingMessage: ()=>'Loading...',
        maxMenuHeight: 300,
        minMenuHeight: 140,
        menuIsOpen: false,
        menuPlacement: 'bottom',
        menuPosition: 'absolute',
        menuShouldBlockScroll: false,
        menuShouldScrollIntoView: !(0, (0, parcelRequire("gyjU7")).isMobileDevice)(),
        noOptionsMessage: ()=>'No options',
        openMenuOnFocus: false,
        openMenuOnClick: true,
        options: [],
        pageSize: 5,
        placeholder: 'Select...',
        screenReaderStatus: ({ count: count })=>`${count} result${count !== 1 ? 's' : ''} available`,
        styles: {},
        tabIndex: 0,
        tabSelectsValue: true,
        unstyled: false
    };
    function $d8e863683b34816f$var$toCategorizedOption(props, option, selectValue, index) {
        const isDisabled = $d8e863683b34816f$var$isOptionDisabled(props, option, selectValue);
        const isSelected = $d8e863683b34816f$var$isOptionSelected(props, option, selectValue);
        const label = $d8e863683b34816f$var$getOptionLabel(props, option);
        const value = $d8e863683b34816f$var$getOptionValue(props, option);
        return {
            type: 'option',
            data: option,
            isDisabled: isDisabled,
            isSelected: isSelected,
            label: label,
            value: value,
            index: index
        };
    }
    function $d8e863683b34816f$var$buildCategorizedOptions(props, selectValue) {
        return props.options.map((groupOrOption, groupOrOptionIndex)=>{
            if ('options' in groupOrOption) {
                const categorizedOptions = groupOrOption.options.map((option, optionIndex)=>$d8e863683b34816f$var$toCategorizedOption(props, option, selectValue, optionIndex)).filter((categorizedOption)=>$d8e863683b34816f$var$isFocusable(props, categorizedOption));
                return categorizedOptions.length > 0 ? {
                    type: 'group',
                    data: groupOrOption,
                    options: categorizedOptions,
                    index: groupOrOptionIndex
                } : undefined;
            }
            const categorizedOption = $d8e863683b34816f$var$toCategorizedOption(props, groupOrOption, selectValue, groupOrOptionIndex);
            return $d8e863683b34816f$var$isFocusable(props, categorizedOption) ? categorizedOption : undefined;
        }).filter((0, (0, parcelRequire("gyjU7")).notNullish));
    }
    function $d8e863683b34816f$var$buildFocusableOptionsFromCategorizedOptions(categorizedOptions) {
        return categorizedOptions.reduce((optionsAccumulator, categorizedOption)=>{
            if (categorizedOption.type === 'group') optionsAccumulator.push(...categorizedOption.options.map((option)=>option.data));
            else optionsAccumulator.push(categorizedOption.data);
            return optionsAccumulator;
        }, []);
    }
    function $d8e863683b34816f$var$buildFocusableOptionsWithIds(categorizedOptions, optionId) {
        return categorizedOptions.reduce((optionsAccumulator, categorizedOption)=>{
            if (categorizedOption.type === 'group') optionsAccumulator.push(...categorizedOption.options.map((option)=>({
                    data: option.data,
                    id: `${optionId}-${categorizedOption.index}-${option.index}`
                })));
            else optionsAccumulator.push({
                data: categorizedOption.data,
                id: `${optionId}-${categorizedOption.index}`
            });
            return optionsAccumulator;
        }, []);
    }
    function $d8e863683b34816f$var$buildFocusableOptions(props, selectValue) {
        return $d8e863683b34816f$var$buildFocusableOptionsFromCategorizedOptions($d8e863683b34816f$var$buildCategorizedOptions(props, selectValue));
    }
    function $d8e863683b34816f$var$isFocusable(props, categorizedOption) {
        const { inputValue: inputValue = '' } = props;
        const { data: data, isSelected: isSelected, label: label, value: value } = categorizedOption;
        return (!$d8e863683b34816f$var$shouldHideSelectedOptions(props) || !isSelected) && $d8e863683b34816f$var$filterOption(props, {
            label: label,
            value: value,
            data: data
        }, inputValue);
    }
    function $d8e863683b34816f$var$getNextFocusedValue(state, nextSelectValue) {
        const { focusedValue: focusedValue, selectValue: lastSelectValue } = state;
        const lastFocusedIndex = lastSelectValue.indexOf(focusedValue);
        if (lastFocusedIndex > -1) {
            const nextFocusedIndex = nextSelectValue.indexOf(focusedValue);
            if (nextFocusedIndex > -1) return focusedValue;
            else if (lastFocusedIndex < nextSelectValue.length) return nextSelectValue[lastFocusedIndex];
        }
        return null;
    }
    function $d8e863683b34816f$var$getNextFocusedOption(state, options) {
        const { focusedOption: lastFocusedOption } = state;
        return lastFocusedOption && options.indexOf(lastFocusedOption) > -1 ? lastFocusedOption : options[0];
    }
    const $d8e863683b34816f$var$getFocusedOptionId = (focusableOptionsWithIds, focusedOption)=>{
        const focusedOptionId = focusableOptionsWithIds.find((option)=>option.data === focusedOption)?.id;
        return focusedOptionId || null;
    };
    const $d8e863683b34816f$var$getOptionLabel = (props, data)=>{
        return props.getOptionLabel(data);
    };
    const $d8e863683b34816f$var$getOptionValue = (props, data)=>{
        return props.getOptionValue(data);
    };
    function $d8e863683b34816f$var$isOptionDisabled(props, option, selectValue) {
        return typeof props.isOptionDisabled === 'function' ? props.isOptionDisabled(option, selectValue) : false;
    }
    function $d8e863683b34816f$var$isOptionSelected(props, option, selectValue) {
        if (selectValue.indexOf(option) > -1) return true;
        if (typeof props.isOptionSelected === 'function') return props.isOptionSelected(option, selectValue);
        const candidate = $d8e863683b34816f$var$getOptionValue(props, option);
        return selectValue.some((i)=>$d8e863683b34816f$var$getOptionValue(props, i) === candidate);
    }
    function $d8e863683b34816f$var$filterOption(props, option, inputValue) {
        return props.filterOption ? props.filterOption(option, inputValue) : true;
    }
    const $d8e863683b34816f$var$shouldHideSelectedOptions = (props)=>{
        const { hideSelectedOptions: hideSelectedOptions, isMulti: isMulti } = props;
        if (hideSelectedOptions === undefined) return isMulti;
        return hideSelectedOptions;
    };
    let $d8e863683b34816f$var$instanceId = 1;
    class $d8e863683b34816f$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = $d8e863683b34816f$export$9c21a3269931e1e6;
        }
        constructor(props){
            super(props);
            this.state = {
                ariaSelection: null,
                focusedOption: null,
                focusedOptionId: null,
                focusableOptionsWithIds: [],
                focusedValue: null,
                inputIsHidden: false,
                isFocused: false,
                selectValue: [],
                clearFocusValueOnUpdate: false,
                prevWasFocused: false,
                inputIsHiddenAfterUpdate: undefined,
                prevProps: undefined,
                instancePrefix: ''
            };
            this.blockOptionHover = false;
            this.isComposing = false;
            this.initialTouchX = 0;
            this.initialTouchY = 0;
            this.openAfterFocus = false;
            this.scrollToFocusedOptionOnUpdate = false;
            this.isAppleDevice = (0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') ? (0, (0, parcelRequire("aCMCu")).isSafari)() : (0, (0, parcelRequire("aCMCu")).isAppleDevice)();
            this.controlRef = null;
            this.getControlRef = (ref)=>{
                this.controlRef = ref;
            };
            this.focusedOptionRef = null;
            this.getFocusedOptionRef = (ref)=>{
                this.focusedOptionRef = ref;
            };
            this.menuListRef = null;
            this.getMenuListRef = (ref)=>{
                this.menuListRef = ref;
            };
            this.inputRef = null;
            this.getInputRef = (ref)=>{
                this.inputRef = ref;
            };
            this.focus = this.focusInput;
            this.blur = this.blurInput;
            this.onChange = (newValue, actionMeta)=>{
                const { onChange: onChange, name: name } = this.props;
                actionMeta.name = name;
                this.ariaOnChange(newValue, actionMeta);
                onChange(newValue, actionMeta);
            };
            this.setValue = (newValue, action, option)=>{
                const { closeMenuOnSelect: closeMenuOnSelect, isMulti: isMulti, inputValue: inputValue } = this.props;
                this.onInputChange('', {
                    action: 'set-value',
                    prevInputValue: inputValue
                });
                if (closeMenuOnSelect) {
                    this.setState({
                        inputIsHiddenAfterUpdate: !isMulti
                    });
                    this.onMenuClose();
                }
                this.setState({
                    clearFocusValueOnUpdate: true
                });
                this.onChange(newValue, {
                    action: action,
                    option: option
                });
            };
            this.selectOption = (newValue)=>{
                const { blurInputOnSelect: blurInputOnSelect, isMulti: isMulti, name: name } = this.props;
                const { selectValue: selectValue } = this.state;
                const deselected = isMulti && this.isOptionSelected(newValue, selectValue);
                const isDisabled = this.isOptionDisabled(newValue, selectValue);
                if (deselected) {
                    const candidate = this.getOptionValue(newValue);
                    this.setValue((0, (0, parcelRequire("gyjU7")).multiValueAsValue)(selectValue.filter((i)=>this.getOptionValue(i) !== candidate)), 'deselect-option', newValue);
                } else if (!isDisabled) {
                    if (isMulti) this.setValue((0, (0, parcelRequire("gyjU7")).multiValueAsValue)([
                        ...selectValue,
                        newValue
                    ]), 'select-option', newValue);
                    else this.setValue((0, (0, parcelRequire("gyjU7")).singleValueAsValue)(newValue), 'select-option');
                } else {
                    this.ariaOnChange((0, (0, parcelRequire("gyjU7")).singleValueAsValue)(newValue), {
                        action: 'select-option',
                        option: newValue,
                        name: name
                    });
                    return;
                }
                if (blurInputOnSelect) this.blurInput();
            };
            this.removeValue = (removedValue)=>{
                const { isMulti: isMulti } = this.props;
                const { selectValue: selectValue } = this.state;
                const candidate = this.getOptionValue(removedValue);
                const newValueArray = selectValue.filter((i)=>this.getOptionValue(i) !== candidate);
                const newValue = (0, (0, parcelRequire("gyjU7")).valueTernary)(isMulti, newValueArray, newValueArray[0] || null);
                this.onChange(newValue, {
                    action: 'remove-value',
                    removedValue: removedValue
                });
                this.focusInput();
            };
            this.clearValue = ()=>{
                const { selectValue: selectValue } = this.state;
                this.onChange((0, (0, parcelRequire("gyjU7")).valueTernary)(this.props.isMulti, [], null), {
                    action: 'clear',
                    removedValues: selectValue
                });
            };
            this.popValue = ()=>{
                const { isMulti: isMulti } = this.props;
                const { selectValue: selectValue } = this.state;
                const lastSelectedValue = selectValue[selectValue.length - 1];
                const newValueArray = selectValue.slice(0, selectValue.length - 1);
                const newValue = (0, (0, parcelRequire("gyjU7")).valueTernary)(isMulti, newValueArray, newValueArray[0] || null);
                this.onChange(newValue, {
                    action: 'pop-value',
                    removedValue: lastSelectedValue
                });
            };
            this.getFocusedOptionId = (focusedOption)=>{
                return $d8e863683b34816f$var$getFocusedOptionId(this.state.focusableOptionsWithIds, focusedOption);
            };
            this.getFocusableOptionsWithIds = ()=>{
                return $d8e863683b34816f$var$buildFocusableOptionsWithIds($d8e863683b34816f$var$buildCategorizedOptions(this.props, this.state.selectValue), this.getElementId('option'));
            };
            this.getValue = ()=>this.state.selectValue;
            this.cx = (...args)=>(0, (0, parcelRequire("gyjU7")).classNames)(this.props.classNamePrefix, ...args);
            this.getOptionLabel = (data)=>{
                return $d8e863683b34816f$var$getOptionLabel(this.props, data);
            };
            this.getOptionValue = (data)=>{
                return $d8e863683b34816f$var$getOptionValue(this.props, data);
            };
            this.getStyles = (key, props)=>{
                const { unstyled: unstyled } = this.props;
                const base = (0, (0, parcelRequire("7VXAu")).defaultStyles)[key](props, unstyled);
                base.boxSizing = 'border-box';
                const custom = this.props.styles[key];
                return custom ? custom(base, props) : base;
            };
            this.getClassNames = (key, props)=>this.props.classNames[key]?.(props);
            this.getElementId = (element)=>{
                return `${this.state.instancePrefix}-${element}`;
            };
            this.getComponents = ()=>{
                return (0, (0, parcelRequire("bChGW")).defaultComponents)(this.props);
            };
            this.buildCategorizedOptions = ()=>$d8e863683b34816f$var$buildCategorizedOptions(this.props, this.state.selectValue);
            this.getCategorizedOptions = ()=>this.props.menuIsOpen ? this.buildCategorizedOptions() : [];
            this.buildFocusableOptions = ()=>$d8e863683b34816f$var$buildFocusableOptionsFromCategorizedOptions(this.buildCategorizedOptions());
            this.getFocusableOptions = ()=>this.props.menuIsOpen ? this.buildFocusableOptions() : [];
            this.ariaOnChange = (value, actionMeta)=>{
                this.setState({
                    ariaSelection: {
                        value: value,
                        ...actionMeta
                    }
                });
            };
            this.onMenuMouseDown = (event)=>{
                if (event.button !== 0) return;
                event.stopPropagation();
                event.preventDefault();
                this.focusInput();
            };
            this.onMenuMouseMove = (event)=>{
                this.blockOptionHover = false;
            };
            this.onControlMouseDown = (event)=>{
                if (event.defaultPrevented) {
                    if (!this.controlRef?.closest('[data-rbd-draggable-context-id]')) return;
                }
                const { openMenuOnClick: openMenuOnClick } = this.props;
                if (!this.state.isFocused) {
                    if (openMenuOnClick) this.openAfterFocus = true;
                    this.focusInput();
                } else if (!this.props.menuIsOpen) {
                    if (openMenuOnClick) this.openMenu('first');
                } else if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') this.onMenuClose();
                if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') event.preventDefault();
            };
            this.onDropdownIndicatorMouseDown = (event)=>{
                if (event && event.type === 'mousedown' && event.button !== 0) return;
                if (this.props.isDisabled) return;
                const { isMulti: isMulti, menuIsOpen: menuIsOpen } = this.props;
                this.focusInput();
                if (menuIsOpen) {
                    this.setState({
                        inputIsHiddenAfterUpdate: !isMulti
                    });
                    this.onMenuClose();
                } else this.openMenu('first');
                event.preventDefault();
            };
            this.onClearIndicatorMouseDown = (event)=>{
                if (event && event.type === 'mousedown' && event.button !== 0) return;
                this.clearValue();
                event.preventDefault();
                this.openAfterFocus = false;
                if (event.type === 'touchend') this.focusInput();
                else setTimeout(()=>this.focusInput());
            };
            this.onScroll = (event)=>{
                if (typeof this.props.closeMenuOnScroll === 'boolean') {
                    if (event.target instanceof HTMLElement && (0, (0, parcelRequire("gyjU7")).isDocumentElement)(event.target)) this.props.onMenuClose();
                } else if (typeof this.props.closeMenuOnScroll === 'function') {
                    if (this.props.closeMenuOnScroll(event)) this.props.onMenuClose();
                }
            };
            this.onCompositionStart = ()=>{
                this.isComposing = true;
            };
            this.onCompositionEnd = ()=>{
                this.isComposing = false;
            };
            this.onTouchStart = ({ touches: touches })=>{
                const touch = touches && touches.item(0);
                if (!touch) return;
                this.initialTouchX = touch.clientX;
                this.initialTouchY = touch.clientY;
                this.userIsDragging = false;
            };
            this.onTouchMove = ({ touches: touches })=>{
                const touch = touches && touches.item(0);
                if (!touch) return;
                const deltaX = Math.abs(touch.clientX - this.initialTouchX);
                const deltaY = Math.abs(touch.clientY - this.initialTouchY);
                const moveThreshold = 5;
                this.userIsDragging = deltaX > moveThreshold || deltaY > moveThreshold;
            };
            this.onTouchEnd = (event)=>{
                if (this.userIsDragging) return;
                if (this.controlRef && !this.controlRef.contains(event.target) && this.menuListRef && !this.menuListRef.contains(event.target)) this.blurInput();
                this.initialTouchX = 0;
                this.initialTouchY = 0;
            };
            this.onControlTouchEnd = (event)=>{
                if (this.userIsDragging) return;
                this.onControlMouseDown(event);
            };
            this.onClearIndicatorTouchEnd = (event)=>{
                if (this.userIsDragging) return;
                this.onClearIndicatorMouseDown(event);
            };
            this.onDropdownIndicatorTouchEnd = (event)=>{
                if (this.userIsDragging) return;
                this.onDropdownIndicatorMouseDown(event);
            };
            this.handleInputChange = (event)=>{
                const { inputValue: prevInputValue } = this.props;
                const inputValue = event.currentTarget.value;
                this.setState({
                    inputIsHiddenAfterUpdate: false
                });
                this.onInputChange(inputValue, {
                    action: 'input-change',
                    prevInputValue: prevInputValue
                });
                if (!this.props.menuIsOpen) this.onMenuOpen();
            };
            this.onInputFocus = (event)=>{
                if (this.props.onFocus) this.props.onFocus(event);
                this.setState({
                    inputIsHiddenAfterUpdate: false,
                    isFocused: true
                });
                if (this.openAfterFocus || this.props.openMenuOnFocus) this.openMenu('first');
                this.openAfterFocus = false;
            };
            this.onInputBlur = (event)=>{
                const { inputValue: prevInputValue } = this.props;
                if (this.menuListRef && this.menuListRef.contains(document.activeElement)) {
                    this.inputRef.focus();
                    return;
                }
                if (this.props.onBlur) this.props.onBlur(event);
                this.onInputChange('', {
                    action: 'input-blur',
                    prevInputValue: prevInputValue
                });
                this.onMenuClose();
                this.setState({
                    focusedValue: null,
                    isFocused: false
                });
            };
            this.onOptionHover = (focusedOption)=>{
                if (this.blockOptionHover || this.state.focusedOption === focusedOption) return;
                const options = this.getFocusableOptions();
                const focusedOptionIndex = options.indexOf(focusedOption);
                this.setState({
                    focusedOption: focusedOption,
                    focusedOptionId: focusedOptionIndex > -1 ? this.getFocusedOptionId(focusedOption) : null
                });
            };
            this.shouldHideSelectedOptions = ()=>{
                return $d8e863683b34816f$var$shouldHideSelectedOptions(this.props);
            };
            this.onValueInputFocus = (e)=>{
                e.preventDefault();
                e.stopPropagation();
                this.focus();
            };
            this.onKeyDown = (event)=>{
                const { isMulti: isMulti, backspaceRemovesValue: backspaceRemovesValue, escapeClearsValue: escapeClearsValue, inputValue: inputValue, isClearable: isClearable, isDisabled: isDisabled, menuIsOpen: menuIsOpen, onKeyDown: onKeyDown, tabSelectsValue: tabSelectsValue, openMenuOnFocus: openMenuOnFocus } = this.props;
                const { focusedOption: focusedOption, focusedValue: focusedValue, selectValue: selectValue } = this.state;
                if (isDisabled) return;
                if (typeof onKeyDown === 'function') {
                    onKeyDown(event);
                    if (event.defaultPrevented) return;
                }
                this.blockOptionHover = true;
                switch(event.key){
                    case 'ArrowLeft':
                        if (!isMulti || inputValue) return;
                        this.focusValue('previous');
                        break;
                    case 'ArrowRight':
                        if (!isMulti || inputValue) return;
                        this.focusValue('next');
                        break;
                    case 'Delete':
                    case 'Backspace':
                        if (inputValue) return;
                        if (focusedValue) this.removeValue(focusedValue);
                        else {
                            if (!backspaceRemovesValue) return;
                            if (isMulti) this.popValue();
                            else if (isClearable) this.clearValue();
                        }
                        break;
                    case 'Tab':
                        if (this.isComposing) return;
                        if (event.shiftKey || !menuIsOpen || !tabSelectsValue || !focusedOption || openMenuOnFocus && this.isOptionSelected(focusedOption, selectValue)) return;
                        this.selectOption(focusedOption);
                        break;
                    case 'Enter':
                        if (event.keyCode === 229) break;
                        if (menuIsOpen) {
                            if (!focusedOption) return;
                            if (this.isComposing) return;
                            this.selectOption(focusedOption);
                            break;
                        }
                        return;
                    case 'Escape':
                        if (menuIsOpen) {
                            this.setState({
                                inputIsHiddenAfterUpdate: false
                            });
                            this.onInputChange('', {
                                action: 'menu-close',
                                prevInputValue: inputValue
                            });
                            this.onMenuClose();
                        } else if (isClearable && escapeClearsValue) this.clearValue();
                        break;
                    case ' ':
                        if (inputValue) return;
                        if (!menuIsOpen) {
                            this.openMenu('first');
                            break;
                        }
                        if (!focusedOption) return;
                        this.selectOption(focusedOption);
                        break;
                    case 'ArrowUp':
                        if (menuIsOpen) this.focusOption('up');
                        else this.openMenu('last');
                        break;
                    case 'ArrowDown':
                        if (menuIsOpen) this.focusOption('down');
                        else this.openMenu('first');
                        break;
                    case 'PageUp':
                        if (!menuIsOpen) return;
                        this.focusOption('pageup');
                        break;
                    case 'PageDown':
                        if (!menuIsOpen) return;
                        this.focusOption('pagedown');
                        break;
                    case 'Home':
                        if (!menuIsOpen) return;
                        this.focusOption('first');
                        break;
                    case 'End':
                        if (!menuIsOpen) return;
                        this.focusOption('last');
                        break;
                    default:
                        return;
                }
                event.preventDefault();
            };
            this.state.instancePrefix = 'react-select-' + (this.props.instanceId || ++$d8e863683b34816f$var$instanceId);
            this.state.selectValue = (0, (0, parcelRequire("gyjU7")).cleanValue)(props.value);
            if (props.menuIsOpen && this.state.selectValue.length) {
                const focusableOptionsWithIds = this.getFocusableOptionsWithIds();
                const focusableOptions = this.buildFocusableOptions();
                const optionIndex = focusableOptions.indexOf(this.state.selectValue[0]);
                this.state.focusableOptionsWithIds = focusableOptionsWithIds;
                this.state.focusedOption = focusableOptions[optionIndex];
                this.state.focusedOptionId = $d8e863683b34816f$var$getFocusedOptionId(focusableOptionsWithIds, focusableOptions[optionIndex]);
            }
        }
        static getDerivedStateFromProps(props, state) {
            const { prevProps: prevProps, clearFocusValueOnUpdate: clearFocusValueOnUpdate, inputIsHiddenAfterUpdate: inputIsHiddenAfterUpdate, ariaSelection: ariaSelection, isFocused: isFocused, prevWasFocused: prevWasFocused, instancePrefix: instancePrefix } = state;
            const { options: options, value: value, menuIsOpen: menuIsOpen, inputValue: inputValue, isMulti: isMulti } = props;
            const selectValue = (0, (0, parcelRequire("gyjU7")).cleanValue)(value);
            let newMenuOptionsState = {};
            if (prevProps && (value !== prevProps.value || options !== prevProps.options || menuIsOpen !== prevProps.menuIsOpen || inputValue !== prevProps.inputValue)) {
                const focusableOptions = menuIsOpen ? $d8e863683b34816f$var$buildFocusableOptions(props, selectValue) : [];
                const focusableOptionsWithIds = menuIsOpen ? $d8e863683b34816f$var$buildFocusableOptionsWithIds($d8e863683b34816f$var$buildCategorizedOptions(props, selectValue), `${instancePrefix}-option`) : [];
                const focusedValue = clearFocusValueOnUpdate ? $d8e863683b34816f$var$getNextFocusedValue(state, selectValue) : null;
                const focusedOption = $d8e863683b34816f$var$getNextFocusedOption(state, focusableOptions);
                const focusedOptionId = $d8e863683b34816f$var$getFocusedOptionId(focusableOptionsWithIds, focusedOption);
                newMenuOptionsState = {
                    selectValue: selectValue,
                    focusedOption: focusedOption,
                    focusedOptionId: focusedOptionId,
                    focusableOptionsWithIds: focusableOptionsWithIds,
                    focusedValue: focusedValue,
                    clearFocusValueOnUpdate: false
                };
            }
            const newInputIsHiddenState = inputIsHiddenAfterUpdate != null && props !== prevProps ? {
                inputIsHidden: inputIsHiddenAfterUpdate,
                inputIsHiddenAfterUpdate: undefined
            } : {};
            let newAriaSelection = ariaSelection;
            let hasKeptFocus = isFocused && prevWasFocused;
            if (isFocused && !hasKeptFocus) {
                newAriaSelection = {
                    value: (0, (0, parcelRequire("gyjU7")).valueTernary)(isMulti, selectValue, selectValue[0] || null),
                    options: selectValue,
                    action: 'initial-input-focus'
                };
                hasKeptFocus = !prevWasFocused;
            }
            if (ariaSelection?.action === 'initial-input-focus') newAriaSelection = null;
            return {
                ...newMenuOptionsState,
                ...newInputIsHiddenState,
                prevProps: props,
                ariaSelection: newAriaSelection,
                prevWasFocused: hasKeptFocus
            };
        }
        componentDidMount() {
            this.startListeningComposition();
            this.startListeningToTouch();
            if (this.props.closeMenuOnScroll && document && document.addEventListener) document.addEventListener('scroll', this.onScroll, true);
            if (this.props.autoFocus) this.focusInput();
            if (this.props.menuIsOpen && this.state.focusedOption && this.menuListRef && this.focusedOptionRef) (0, (0, parcelRequire("gyjU7")).scrollIntoView)(this.menuListRef, this.focusedOptionRef);
        }
        componentDidUpdate(prevProps) {
            const { isDisabled: isDisabled, menuIsOpen: menuIsOpen } = this.props;
            const { isFocused: isFocused } = this.state;
            if (isFocused && !isDisabled && prevProps.isDisabled || isFocused && menuIsOpen && !prevProps.menuIsOpen) this.focusInput();
            if (isFocused && isDisabled && !prevProps.isDisabled) this.setState({
                isFocused: false
            }, this.onMenuClose);
            else if (!isFocused && !isDisabled && prevProps.isDisabled && this.inputRef === document.activeElement) this.setState({
                isFocused: true
            });
            if (this.menuListRef && this.focusedOptionRef && this.scrollToFocusedOptionOnUpdate) {
                (0, (0, parcelRequire("gyjU7")).scrollIntoView)(this.menuListRef, this.focusedOptionRef);
                this.scrollToFocusedOptionOnUpdate = false;
            }
        }
        componentWillUnmount() {
            this.stopListeningComposition();
            this.stopListeningToTouch();
            document.removeEventListener('scroll', this.onScroll, true);
        }
        onMenuOpen() {
            this.props.onMenuOpen();
        }
        onMenuClose() {
            this.onInputChange('', {
                action: 'menu-close',
                prevInputValue: this.props.inputValue
            });
            this.props.onMenuClose();
        }
        onInputChange(newValue, actionMeta) {
            this.props.onInputChange(newValue, actionMeta);
        }
        focusInput() {
            if (!this.inputRef) return;
            this.inputRef.focus();
        }
        blurInput() {
            if (!this.inputRef) return;
            this.inputRef.blur();
        }
        openMenu(focusOption) {
            const { selectValue: selectValue, isFocused: isFocused } = this.state;
            const focusableOptions = this.buildFocusableOptions();
            let openAtIndex = focusOption === 'first' ? 0 : focusableOptions.length - 1;
            if (!this.props.isMulti) {
                const selectedIndex = focusableOptions.indexOf(selectValue[0]);
                if (selectedIndex > -1) openAtIndex = selectedIndex;
            }
            this.scrollToFocusedOptionOnUpdate = !(isFocused && this.menuListRef);
            this.setState({
                inputIsHiddenAfterUpdate: false,
                focusedValue: null,
                focusedOption: focusableOptions[openAtIndex],
                focusedOptionId: this.getFocusedOptionId(focusableOptions[openAtIndex])
            }, ()=>this.onMenuOpen());
        }
        focusValue(direction) {
            const { selectValue: selectValue, focusedValue: focusedValue } = this.state;
            if (!this.props.isMulti) return;
            this.setState({
                focusedOption: null
            });
            let focusedIndex = selectValue.indexOf(focusedValue);
            if (!focusedValue) focusedIndex = -1;
            const lastIndex = selectValue.length - 1;
            let nextFocus = -1;
            if (!selectValue.length) return;
            switch(direction){
                case 'previous':
                    if (focusedIndex === 0) nextFocus = 0;
                    else if (focusedIndex === -1) nextFocus = lastIndex;
                    else nextFocus = focusedIndex - 1;
                    break;
                case 'next':
                    if (focusedIndex > -1 && focusedIndex < lastIndex) nextFocus = focusedIndex + 1;
                    break;
            }
            this.setState({
                inputIsHidden: nextFocus !== -1,
                focusedValue: selectValue[nextFocus]
            });
        }
        focusOption(direction = 'first') {
            const { pageSize: pageSize } = this.props;
            const { focusedOption: focusedOption } = this.state;
            const options = this.getFocusableOptions();
            if (!options.length) return;
            let nextFocus = 0;
            let focusedIndex = options.indexOf(focusedOption);
            if (!focusedOption) focusedIndex = -1;
            if (direction === 'up') nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options.length - 1;
            else if (direction === 'down') nextFocus = (focusedIndex + 1) % options.length;
            else if (direction === 'pageup') {
                nextFocus = focusedIndex - pageSize;
                if (nextFocus < 0) nextFocus = 0;
            } else if (direction === 'pagedown') {
                nextFocus = focusedIndex + pageSize;
                if (nextFocus > options.length - 1) nextFocus = options.length - 1;
            } else if (direction === 'last') nextFocus = options.length - 1;
            this.scrollToFocusedOptionOnUpdate = true;
            this.setState({
                focusedOption: options[nextFocus],
                focusedValue: null,
                focusedOptionId: this.getFocusedOptionId(options[nextFocus])
            });
        }
        getTheme() {
            if (!this.props.theme) return 0, (0, parcelRequire("5HBcW")).defaultTheme;
            if (typeof this.props.theme === 'function') return this.props.theme((0, (0, parcelRequire("5HBcW")).defaultTheme));
            return {
                ...(0, (0, parcelRequire("5HBcW")).defaultTheme),
                ...this.props.theme
            };
        }
        getCommonProps() {
            const { clearValue: clearValue, cx: cx, getStyles: getStyles, getClassNames: getClassNames, getValue: getValue, selectOption: selectOption, setValue: setValue, props: props } = this;
            const { isMulti: isMulti, isRtl: isRtl, options: options } = props;
            const hasValue = this.hasValue();
            return {
                clearValue: clearValue,
                cx: cx,
                getStyles: getStyles,
                getClassNames: getClassNames,
                getValue: getValue,
                hasValue: hasValue,
                isMulti: isMulti,
                isRtl: isRtl,
                options: options,
                selectOption: selectOption,
                selectProps: props,
                setValue: setValue,
                theme: this.getTheme()
            };
        }
        hasValue() {
            const { selectValue: selectValue } = this.state;
            return selectValue.length > 0;
        }
        hasOptions() {
            return !!this.getFocusableOptions().length;
        }
        isClearable() {
            const { isClearable: isClearable, isMulti: isMulti } = this.props;
            if (isClearable === undefined) return isMulti;
            return isClearable;
        }
        isOptionDisabled(option, selectValue) {
            return $d8e863683b34816f$var$isOptionDisabled(this.props, option, selectValue);
        }
        isOptionSelected(option, selectValue) {
            return $d8e863683b34816f$var$isOptionSelected(this.props, option, selectValue);
        }
        filterOption(option, inputValue) {
            return $d8e863683b34816f$var$filterOption(this.props, option, inputValue);
        }
        formatOptionLabel(data, context) {
            if (typeof this.props.formatOptionLabel === 'function') {
                const { inputValue: inputValue } = this.props;
                const { selectValue: selectValue } = this.state;
                return this.props.formatOptionLabel(data, {
                    context: context,
                    inputValue: inputValue,
                    selectValue: selectValue
                });
            } else return this.getOptionLabel(data);
        }
        formatGroupLabel(data) {
            return this.props.formatGroupLabel(data);
        }
        startListeningComposition() {
            if (document && document.addEventListener) {
                document.addEventListener('compositionstart', this.onCompositionStart, false);
                document.addEventListener('compositionend', this.onCompositionEnd, false);
            }
        }
        stopListeningComposition() {
            if (document && document.removeEventListener) {
                document.removeEventListener('compositionstart', this.onCompositionStart);
                document.removeEventListener('compositionend', this.onCompositionEnd);
            }
        }
        startListeningToTouch() {
            if (document && document.addEventListener) {
                document.addEventListener('touchstart', this.onTouchStart, false);
                document.addEventListener('touchmove', this.onTouchMove, false);
                document.addEventListener('touchend', this.onTouchEnd, false);
            }
        }
        stopListeningToTouch() {
            if (document && document.removeEventListener) {
                document.removeEventListener('touchstart', this.onTouchStart);
                document.removeEventListener('touchmove', this.onTouchMove);
                document.removeEventListener('touchend', this.onTouchEnd);
            }
        }
        renderInput() {
            const { descriptionId: descriptionId, form: form, inputId: inputId, inputValue: inputValue, isDisabled: isDisabled, isInvalid: isInvalid, isRequired: isRequired, isSearchable: isSearchable, label: label, labelId: labelId, menuIsOpen: menuIsOpen, required: required, tabIndex: tabIndex = 0 } = this.props;
            const { Input: Input } = this.getComponents();
            const { inputIsHidden: inputIsHidden, ariaSelection: ariaSelection } = this.state;
            const { commonProps: commonProps } = this;
            const id = inputId || this.getElementId('input');
            const description = this.props['aria-describedby'] || descriptionId;
            const ariaAttributes = {
                'aria-autocomplete': 'both',
                'aria-errormessage': this.props['aria-errormessage'],
                'aria-expanded': menuIsOpen,
                'aria-haspopup': 'listbox',
                'aria-describedby': description,
                'aria-invalid': this.props['aria-invalid'] || isInvalid,
                'aria-label': this.props['aria-label'] || label,
                'aria-labelledby': this.props['aria-labelledby'] || labelId,
                'aria-required': required || isRequired,
                role: 'combobox',
                'aria-activedescendant': this.state.focusedOptionId || undefined,
                ...menuIsOpen && {
                    'aria-controls': this.getElementId('listbox')
                },
                ...!isSearchable && {
                    'aria-readonly': true
                },
                ...this.hasValue() ? ariaSelection?.action === 'initial-input-focus' && {
                    'aria-describedby': description ? [
                        description,
                        this.getElementId('live-region')
                    ].join(' ') : this.getElementId('live-region')
                } : {
                    'aria-describedby': description ? [
                        description,
                        this.getElementId('placeholder')
                    ].join(' ') : this.getElementId('placeholder')
                }
            };
            if (!isSearchable) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jtVgE")).default), {
                id: id,
                innerRef: this.getInputRef,
                onBlur: this.onInputBlur,
                onChange: (0, (0, parcelRequire("gyjU7")).noop),
                onFocus: this.onInputFocus,
                disabled: isDisabled,
                tabIndex: tabIndex,
                inputMode: "none",
                form: form,
                value: "",
                ...ariaAttributes
            });
            return (0, ($parcel$interopDefault($5uXOq))).createElement(Input, {
                ...commonProps,
                autoCapitalize: "none",
                autoComplete: "off",
                autoCorrect: "off",
                id: id,
                innerRef: this.getInputRef,
                isDisabled: isDisabled,
                isHidden: inputIsHidden,
                onBlur: this.onInputBlur,
                onChange: this.handleInputChange,
                onFocus: this.onInputFocus,
                spellCheck: "false",
                tabIndex: tabIndex,
                form: form,
                type: "text",
                value: inputValue,
                ...ariaAttributes
            });
        }
        renderPlaceholderOrValue() {
            const { MultiValue: MultiValue, MultiValueContainer: MultiValueContainer, MultiValueLabel: MultiValueLabel, MultiValueRemove: MultiValueRemove, SingleValue: SingleValue, Placeholder: Placeholder } = this.getComponents();
            const { commonProps: commonProps } = this;
            const { controlShouldRenderValue: controlShouldRenderValue, isDisabled: isDisabled, isMulti: isMulti, inputValue: inputValue, placeholder: placeholder } = this.props;
            const { selectValue: selectValue, focusedValue: focusedValue, isFocused: isFocused } = this.state;
            if (!this.hasValue() || !controlShouldRenderValue) return inputValue ? null : (0, ($parcel$interopDefault($5uXOq))).createElement(Placeholder, {
                ...commonProps,
                key: "placeholder",
                isDisabled: isDisabled,
                isFocused: isFocused,
                innerProps: {
                    id: this.getElementId('placeholder')
                }
            }, placeholder);
            if (isMulti) return selectValue.map((opt, index)=>{
                const isOptionFocused = opt === focusedValue;
                const key = `${this.getOptionLabel(opt)}-${this.getOptionValue(opt)}`;
                return (0, ($parcel$interopDefault($5uXOq))).createElement(MultiValue, {
                    ...commonProps,
                    components: {
                        Container: MultiValueContainer,
                        Label: MultiValueLabel,
                        Remove: MultiValueRemove
                    },
                    isFocused: isOptionFocused,
                    isDisabled: isDisabled,
                    key: key,
                    index: index,
                    removeProps: {
                        onClick: ()=>this.removeValue(opt),
                        onTouchEnd: ()=>this.removeValue(opt),
                        onMouseDown: (e)=>{
                            e.preventDefault();
                        }
                    },
                    data: opt
                }, this.formatOptionLabel(opt, 'value'));
            });
            if (inputValue) return null;
            const singleValue = selectValue[0];
            return (0, ($parcel$interopDefault($5uXOq))).createElement(SingleValue, {
                ...commonProps,
                data: singleValue,
                isDisabled: isDisabled
            }, this.formatOptionLabel(singleValue, 'value'));
        }
        renderClearIndicator() {
            const { ClearIndicator: ClearIndicator } = this.getComponents();
            const { commonProps: commonProps } = this;
            const { clearControlLabel: clearControlLabel, isDisabled: isDisabled, isLoading: isLoading } = this.props;
            const { isFocused: isFocused } = this.state;
            if (!this.isClearable() || !ClearIndicator || isDisabled || !this.hasValue() || isLoading) return null;
            const innerProps = {
                onMouseDown: this.onClearIndicatorMouseDown,
                onTouchEnd: this.onClearIndicatorTouchEnd,
                'aria-hidden': 'true'
            };
            return (0, ($parcel$interopDefault($5uXOq))).createElement(ClearIndicator, {
                clearControlLabel: clearControlLabel,
                ...commonProps,
                innerProps: innerProps,
                isFocused: isFocused
            });
        }
        renderLoadingIndicator() {
            const { LoadingIndicator: LoadingIndicator } = this.getComponents();
            const { commonProps: commonProps } = this;
            const { isDisabled: isDisabled, isLoading: isLoading } = this.props;
            const { isFocused: isFocused } = this.state;
            if (!LoadingIndicator || !isLoading) return null;
            const innerProps = {
                'aria-hidden': 'true'
            };
            return (0, ($parcel$interopDefault($5uXOq))).createElement(LoadingIndicator, {
                ...commonProps,
                innerProps: innerProps,
                isDisabled: isDisabled,
                isFocused: isFocused
            });
        }
        renderIndicatorSeparator() {
            const { DropdownIndicator: DropdownIndicator, IndicatorSeparator: IndicatorSeparator } = this.getComponents();
            if (!DropdownIndicator || !IndicatorSeparator) return null;
            const { commonProps: commonProps } = this;
            const { isDisabled: isDisabled } = this.props;
            const { isFocused: isFocused } = this.state;
            return (0, ($parcel$interopDefault($5uXOq))).createElement(IndicatorSeparator, {
                ...commonProps,
                isDisabled: isDisabled,
                isFocused: isFocused
            });
        }
        renderDropdownIndicator() {
            const { DropdownIndicator: DropdownIndicator } = this.getComponents();
            if (!DropdownIndicator) return null;
            const { commonProps: commonProps } = this;
            const { isDisabled: isDisabled } = this.props;
            const { isFocused: isFocused } = this.state;
            const innerProps = {
                onMouseDown: this.onDropdownIndicatorMouseDown,
                onTouchEnd: this.onDropdownIndicatorTouchEnd,
                'aria-hidden': 'true'
            };
            return (0, ($parcel$interopDefault($5uXOq))).createElement(DropdownIndicator, {
                ...commonProps,
                innerProps: innerProps,
                isDisabled: isDisabled,
                isFocused: isFocused
            });
        }
        renderMenu() {
            const { Group: Group, GroupHeading: GroupHeading, Menu: Menu, MenuList: MenuList, MenuPortal: MenuPortal, LoadingMessage: LoadingMessage, NoOptionsMessage: NoOptionsMessage, Option: Option } = this.getComponents();
            const { commonProps: commonProps } = this;
            const { focusedOption: focusedOption } = this.state;
            const { captureMenuScroll: captureMenuScroll, inputValue: inputValue, isLoading: isLoading, loadingMessage: loadingMessage, minMenuHeight: minMenuHeight, maxMenuHeight: maxMenuHeight, menuIsOpen: menuIsOpen, menuPlacement: menuPlacement, menuPosition: menuPosition, menuPortalTarget: menuPortalTarget, menuShouldBlockScroll: menuShouldBlockScroll, menuShouldScrollIntoView: menuShouldScrollIntoView, noOptionsMessage: noOptionsMessage, onMenuScrollToTop: onMenuScrollToTop, onMenuScrollToBottom: onMenuScrollToBottom, labelId: labelId, label: label } = this.props;
            if (!menuIsOpen) return null;
            const render = (props, id, headingId)=>{
                const { type: type, data: data, isDisabled: isDisabled, isSelected: isSelected, label: label, value: value } = props;
                const isFocused = focusedOption === data;
                const onHover = isDisabled ? undefined : ()=>this.onOptionHover(data);
                const onSelect = isDisabled ? undefined : ()=>this.selectOption(data);
                const optionId = `${this.getElementId('option')}-${id}`;
                const innerProps = {
                    id: optionId,
                    onClick: onSelect,
                    onMouseMove: onHover,
                    onMouseOver: onHover,
                    role: 'option',
                    'aria-selected': !commonProps.isMulti && (0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') ? isSelected || undefined : isSelected,
                    'aria-describedby': (0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') ? headingId : undefined
                };
                return (0, ($parcel$interopDefault($5uXOq))).createElement(Option, {
                    ...commonProps,
                    innerProps: innerProps,
                    data: data,
                    isDisabled: isDisabled,
                    isSelected: isSelected,
                    key: optionId,
                    label: label,
                    type: type,
                    value: value,
                    isFocused: isFocused,
                    innerRef: isFocused ? this.getFocusedOptionRef : undefined
                }, this.formatOptionLabel(props.data, 'menu'));
            };
            let menuUI;
            if (this.hasOptions()) {
                const items = this.getCategorizedOptions();
                menuUI = items.map((item)=>{
                    if (item.type === 'group') {
                        const { data: data, options: options, index: groupIndex } = item;
                        const groupId = `${this.getElementId('group')}-${groupIndex}`;
                        const headingId = `${groupId}-heading`;
                        return (0, ($parcel$interopDefault($5uXOq))).createElement(Group, {
                            ...commonProps,
                            key: groupId,
                            data: data,
                            options: options,
                            Heading: GroupHeading,
                            headingProps: {
                                id: headingId,
                                data: item.data
                            },
                            label: this.formatGroupLabel(item.data)
                        }, item.options.map((option)=>render(option, `${groupIndex}-${option.index}`, headingId)));
                    } else if (item.type === 'option') return render(item, `${item.index}`);
                });
            } else if (isLoading) {
                const message = loadingMessage({
                    inputValue: inputValue
                });
                if (message === null) return null;
                menuUI = (0, ($parcel$interopDefault($5uXOq))).createElement(LoadingMessage, commonProps, message);
            } else {
                const message = noOptionsMessage({
                    inputValue: inputValue
                });
                if (message === null) return null;
                menuUI = (0, ($parcel$interopDefault($5uXOq))).createElement(NoOptionsMessage, commonProps, message);
            }
            const menuPlacementProps = {
                minMenuHeight: minMenuHeight,
                maxMenuHeight: maxMenuHeight,
                menuPlacement: menuPlacement,
                menuPosition: menuPosition,
                menuShouldScrollIntoView: menuShouldScrollIntoView
            };
            const menuElement = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aA8TC")).MenuPlacer), {
                ...commonProps,
                ...menuPlacementProps
            }, ({ ref: ref, placerProps: { placement: placement, maxHeight: maxHeight } })=>(0, ($parcel$interopDefault($5uXOq))).createElement(Menu, {
                    ...commonProps,
                    ...menuPlacementProps,
                    innerRef: ref,
                    innerProps: {
                        onMouseDown: this.onMenuMouseDown,
                        onMouseMove: this.onMenuMouseMove,
                        id: this.props.components.Menu ? this.getElementId('listbox') : undefined
                    },
                    isLoading: isLoading,
                    placement: placement
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("galqx")).default), {
                    captureEnabled: captureMenuScroll,
                    onTopArrive: onMenuScrollToTop,
                    onBottomArrive: onMenuScrollToBottom,
                    lockEnabled: menuShouldBlockScroll
                }, (scrollTargetRef)=>(0, ($parcel$interopDefault($5uXOq))).createElement(MenuList, {
                        ...commonProps,
                        innerRef: (instance)=>{
                            this.getMenuListRef(instance);
                            scrollTargetRef(instance);
                        },
                        innerProps: {
                            role: 'listbox',
                            'aria-multiselectable': (0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') ? commonProps.isMulti || undefined : commonProps.isMulti,
                            id: this.getElementId('listbox'),
                            ...(0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') && {
                                'aria-label': label,
                                'aria-labelledby': labelId
                            }
                        },
                        isLoading: isLoading,
                        maxHeight: maxHeight,
                        focusedOption: focusedOption
                    }, menuUI))));
            return menuPortalTarget || menuPosition === 'fixed' ? (0, ($parcel$interopDefault($5uXOq))).createElement(MenuPortal, {
                ...commonProps,
                appendTo: menuPortalTarget,
                controlElement: this.controlRef,
                menuPlacement: menuPlacement,
                menuPosition: menuPosition
            }, menuElement) : menuElement;
        }
        renderFormField() {
            const { delimiter: delimiter, isDisabled: isDisabled, isMulti: isMulti, required: required, name: name } = this.props;
            const { selectValue: selectValue } = this.state;
            if (required && !this.hasValue() && !isDisabled) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9cRAr")).default), {
                name: name,
                onFocus: this.onValueInputFocus
            });
            if (!name || isDisabled) return;
            if (isMulti) {
                if (delimiter) {
                    const value = selectValue.map((opt)=>this.getOptionValue(opt)).join(delimiter);
                    return (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
                        name: name,
                        type: "hidden",
                        value: value
                    });
                } else {
                    const input = selectValue.length > 0 ? selectValue.map((opt, i)=>(0, ($parcel$interopDefault($5uXOq))).createElement("input", {
                            key: `i-${i}`,
                            name: name,
                            type: "hidden",
                            value: this.getOptionValue(opt)
                        })) : (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
                        name: name,
                        type: "hidden",
                        value: ""
                    });
                    return (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, input);
                }
            } else {
                const value = selectValue[0] ? this.getOptionValue(selectValue[0]) : '';
                return (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
                    name: name,
                    type: "hidden",
                    value: value
                });
            }
        }
        renderLiveRegion() {
            const { commonProps: commonProps } = this;
            const { ariaSelection: ariaSelection, focusedOption: focusedOption, focusedValue: focusedValue, isFocused: isFocused, selectValue: selectValue } = this.state;
            const focusableOptions = this.getFocusableOptions();
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("81i5b")).default), {
                ...commonProps,
                id: this.getElementId('live-region'),
                ariaSelection: ariaSelection,
                focusedOption: focusedOption,
                focusedValue: focusedValue,
                isFocused: isFocused,
                selectValue: selectValue,
                focusableOptions: focusableOptions,
                isAppleDevice: this.isAppleDevice
            });
        }
        render() {
            const { Control: Control, IndicatorsContainer: IndicatorsContainer, SelectContainer: SelectContainer, ValueContainer: ValueContainer } = this.getComponents();
            const { className: className, id: id, isDisabled: isDisabled, menuIsOpen: menuIsOpen } = this.props;
            const { isFocused: isFocused } = this.state;
            const commonProps = this.commonProps = this.getCommonProps();
            return (0, ($parcel$interopDefault($5uXOq))).createElement(SelectContainer, {
                ...commonProps,
                className: className,
                innerProps: {
                    id: id,
                    onKeyDown: this.onKeyDown
                },
                isDisabled: isDisabled,
                isFocused: isFocused
            }, this.renderLiveRegion(), (0, ($parcel$interopDefault($5uXOq))).createElement(Control, {
                ...commonProps,
                innerRef: this.getControlRef,
                innerProps: {
                    onMouseDown: this.onControlMouseDown,
                    onTouchEnd: this.onControlTouchEnd
                },
                isDisabled: isDisabled,
                isFocused: isFocused,
                menuIsOpen: menuIsOpen
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(ValueContainer, {
                ...commonProps,
                isDisabled: isDisabled
            }, this.renderPlaceholderOrValue(), this.renderInput()), (0, ($parcel$interopDefault($5uXOq))).createElement(IndicatorsContainer, {
                ...commonProps,
                isDisabled: isDisabled
            }, this.renderClearIndicator(), this.renderLoadingIndicator(), this.renderIndicatorSeparator(), this.renderDropdownIndicator())), this.renderMenu(), this.renderFormField());
        }
    }
});
parcelRegister("aCMCu", function(module, exports) {
    $parcel$export(module.exports, "isSafari", ()=>$150018e79a44f2ed$export$95df08bae54cb4df);
    $parcel$export(module.exports, "isAppleDevice", ()=>$150018e79a44f2ed$export$e1865c3bedcd822b);
    function $150018e79a44f2ed$var$testPlatform(re) {
        return typeof window !== 'undefined' && window.navigator != null ? re.test(window.navigator['userAgentData']?.platform || window.navigator.platform) : false;
    }
    function $150018e79a44f2ed$export$186c6964ca17d99() {
        return $150018e79a44f2ed$var$testPlatform(/^iPhone/i);
    }
    function $150018e79a44f2ed$export$95df08bae54cb4df() {
        if (typeof window !== 'undefined' && window.navigator != null) {
            const ua = window.navigator.userAgent?.toLowerCase();
            return ua ? ua.includes('safari') && !ua.includes('chrome') : false;
        }
        return false;
    }
    function $150018e79a44f2ed$export$9ac100e40613ea10() {
        return $150018e79a44f2ed$var$testPlatform(/^Mac/i);
    }
    function $150018e79a44f2ed$export$7bef049ce92e4224() {
        return $150018e79a44f2ed$var$testPlatform(/^iPad/i) || $150018e79a44f2ed$export$9ac100e40613ea10() && navigator.maxTouchPoints > 1;
    }
    function $150018e79a44f2ed$export$fedb369cb70207f1() {
        return $150018e79a44f2ed$export$186c6964ca17d99() || $150018e79a44f2ed$export$7bef049ce92e4224();
    }
    function $150018e79a44f2ed$export$e1865c3bedcd822b() {
        return $150018e79a44f2ed$export$9ac100e40613ea10() || $150018e79a44f2ed$export$fedb369cb70207f1();
    }
});
parcelRegister("eEtcC", function(module, exports) {
    $parcel$export(module.exports, "formatGroupLabel", ()=>$1a3d0f2eb875e1af$export$f4da216a6ca54656);
    $parcel$export(module.exports, "getOptionLabel", ()=>$1a3d0f2eb875e1af$export$dac6ace3020563ca);
    $parcel$export(module.exports, "getOptionValue", ()=>$1a3d0f2eb875e1af$export$40be908210c5a79c);
    $parcel$export(module.exports, "isOptionDisabled", ()=>$1a3d0f2eb875e1af$export$56bd0c8d90db075);
    const $1a3d0f2eb875e1af$export$f4da216a6ca54656 = (group)=>group.label;
    const $1a3d0f2eb875e1af$export$dac6ace3020563ca = (option)=>option.label;
    const $1a3d0f2eb875e1af$export$40be908210c5a79c = (option)=>option.value;
    const $1a3d0f2eb875e1af$export$56bd0c8d90db075 = (option)=>!!option.isDisabled;
});
parcelRegister("bChGW", function(module, exports) {
    $parcel$export(module.exports, "components", ()=>$ea75c1778b4e3ad2$export$9b5ac10f59f76296);
    $parcel$export(module.exports, "defaultComponents", ()=>$ea75c1778b4e3ad2$export$71abc632aa29e6fe);
    var $h1QWG;
    var $eZFhY;
    var $i48X9;
    var $95iuW;
    var $dS5G5;
    var $aA8TC;
    var $hqcPo;
    var $8l2RA;
    var $kKm82;
    var $hpcbi;
    const $ea75c1778b4e3ad2$export$9b5ac10f59f76296 = {
        ClearIndicator: (0, (0, parcelRequire("95iuW")).ClearIndicator),
        Control: (0, (0, parcelRequire("eZFhY")).default),
        DropdownIndicator: (0, (0, parcelRequire("95iuW")).DropdownIndicator),
        DownChevron: (0, (0, parcelRequire("95iuW")).DownChevron),
        CrossIcon: (0, (0, parcelRequire("95iuW")).CrossIcon),
        Group: (0, (0, parcelRequire("i48X9")).default),
        GroupHeading: (0, (0, parcelRequire("i48X9")).GroupHeading),
        IndicatorsContainer: (0, (0, parcelRequire("h1QWG")).IndicatorsContainer),
        IndicatorSeparator: (0, (0, parcelRequire("95iuW")).IndicatorSeparator),
        Input: (0, (0, parcelRequire("dS5G5")).default),
        LoadingIndicator: (0, (0, parcelRequire("95iuW")).LoadingIndicator),
        Menu: (0, (0, parcelRequire("aA8TC")).default),
        MenuList: (0, (0, parcelRequire("aA8TC")).MenuList),
        MenuPortal: (0, (0, parcelRequire("aA8TC")).MenuPortal),
        LoadingMessage: (0, (0, parcelRequire("aA8TC")).LoadingMessage),
        NoOptionsMessage: (0, (0, parcelRequire("aA8TC")).NoOptionsMessage),
        MultiValue: (0, (0, parcelRequire("hqcPo")).default),
        MultiValueContainer: (0, (0, parcelRequire("hqcPo")).MultiValueContainer),
        MultiValueLabel: (0, (0, parcelRequire("hqcPo")).MultiValueLabel),
        MultiValueRemove: (0, (0, parcelRequire("hqcPo")).MultiValueRemove),
        Option: (0, (0, parcelRequire("8l2RA")).default),
        Placeholder: (0, (0, parcelRequire("kKm82")).default),
        SelectContainer: (0, (0, parcelRequire("h1QWG")).SelectContainer),
        SingleValue: (0, (0, parcelRequire("hpcbi")).default),
        ValueContainer: (0, (0, parcelRequire("h1QWG")).ValueContainer)
    };
    const $ea75c1778b4e3ad2$export$71abc632aa29e6fe = (props)=>({
            ...$ea75c1778b4e3ad2$export$9b5ac10f59f76296,
            ...props.components
        });
});
parcelRegister("h1QWG", function(module, exports) {
    $parcel$export(module.exports, "containerCSS", ()=>$78186faf13ea75d3$export$949392aeaf7d2e3a);
    $parcel$export(module.exports, "SelectContainer", ()=>$78186faf13ea75d3$export$446eebadefe1123b);
    $parcel$export(module.exports, "valueContainerCSS", ()=>$78186faf13ea75d3$export$47aec73c7aa6b9aa);
    $parcel$export(module.exports, "ValueContainer", ()=>$78186faf13ea75d3$export$4dc9f58ad9a1a000);
    $parcel$export(module.exports, "indicatorsContainerCSS", ()=>$78186faf13ea75d3$export$9d74e3c8b5868ad7);
    $parcel$export(module.exports, "IndicatorsContainer", ()=>$78186faf13ea75d3$export$d0ffd7e649cab6f);
    var $f8Sb3;
    var $gyjU7;
    const $78186faf13ea75d3$export$949392aeaf7d2e3a = ({ isDisabled: isDisabled, isRtl: isRtl })=>({
            label: 'container',
            direction: isRtl ? 'rtl' : undefined,
            pointerEvents: isDisabled ? 'none' : undefined,
            position: 'relative'
        });
    const $78186faf13ea75d3$export$446eebadefe1123b = (props)=>{
        const { children: children, innerProps: innerProps, isDisabled: isDisabled, isRtl: isRtl } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'container', {
                '--is-disabled': isDisabled,
                '--is-rtl': isRtl
            }),
            ...innerProps
        }, children);
    };
    const $78186faf13ea75d3$export$47aec73c7aa6b9aa = ({ theme: { spacing: spacing }, isMulti: isMulti, hasValue: hasValue, selectProps: { controlShouldRenderValue: controlShouldRenderValue } }, unstyled)=>({
            alignItems: 'center',
            display: isMulti && hasValue && controlShouldRenderValue ? 'flex' : 'grid',
            flex: 1,
            flexWrap: 'wrap',
            WebkitOverflowScrolling: 'touch',
            position: 'relative',
            overflow: 'hidden',
            ...unstyled ? {} : {
                padding: `${spacing.baseUnit / 2}px ${spacing.baseUnit * 2}px`
            }
        });
    const $78186faf13ea75d3$export$4dc9f58ad9a1a000 = (props)=>{
        const { children: children, innerProps: innerProps, isMulti: isMulti, hasValue: hasValue } = props;
        const styles = (0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'valueContainer', {
            'value-container': true,
            'value-container--is-multi': isMulti,
            'value-container--has-value': hasValue
        });
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: styles.css,
            className: styles.className || 'value-container',
            ...innerProps
        }, children);
    };
    const $78186faf13ea75d3$export$9d74e3c8b5868ad7 = ()=>({
            alignItems: 'center',
            alignSelf: 'stretch',
            display: 'flex',
            flexShrink: 0
        });
    const $78186faf13ea75d3$export$d0ffd7e649cab6f = (props)=>{
        const { children: children, innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'indicatorsContainer', {
                indicators: true
            }),
            ...innerProps
        }, children);
    };
});
parcelRegister("gyjU7", function(module, exports) {
    $parcel$export(module.exports, "noop", ()=>$109d3f9af5469308$export$8793edee2d425525);
    $parcel$export(module.exports, "classNames", ()=>$109d3f9af5469308$export$ce4ab0c55987d1ff);
    $parcel$export(module.exports, "cleanValue", ()=>$109d3f9af5469308$export$33f062f47f21a01);
    $parcel$export(module.exports, "cleanCommonProps", ()=>$109d3f9af5469308$export$8e9fd4287627890c);
    $parcel$export(module.exports, "getStyleProps", ()=>$109d3f9af5469308$export$26417cabd806a658);
    $parcel$export(module.exports, "handleInputChange", ()=>$109d3f9af5469308$export$2096bae78ecda911);
    $parcel$export(module.exports, "isDocumentElement", ()=>$109d3f9af5469308$export$d8a62a489b442872);
    $parcel$export(module.exports, "normalizedHeight", ()=>$109d3f9af5469308$export$2e0654fad740d2e4);
    $parcel$export(module.exports, "getScrollTop", ()=>$109d3f9af5469308$export$c4a223a8ba9e4ea5);
    $parcel$export(module.exports, "scrollTo", ()=>$109d3f9af5469308$export$3f99968195accfaf);
    $parcel$export(module.exports, "getScrollParent", ()=>$109d3f9af5469308$export$cfa2225e87938781);
    $parcel$export(module.exports, "animatedScrollTo", ()=>$109d3f9af5469308$export$858c30343199f3af);
    $parcel$export(module.exports, "scrollIntoView", ()=>$109d3f9af5469308$export$53a0910f038337bd);
    $parcel$export(module.exports, "getBoundingClientObj", ()=>$109d3f9af5469308$export$7807c2a424c2f6d1);
    $parcel$export(module.exports, "isTouchCapable", ()=>$109d3f9af5469308$export$56963d77dbffdde7);
    $parcel$export(module.exports, "isMobileDevice", ()=>$109d3f9af5469308$export$44f917bf91becd01);
    $parcel$export(module.exports, "supportsPassiveEvents", ()=>$109d3f9af5469308$export$b0622a6911dbeaf5);
    $parcel$export(module.exports, "notNullish", ()=>$109d3f9af5469308$export$aedb69ff3e31596c);
    $parcel$export(module.exports, "valueTernary", ()=>$109d3f9af5469308$export$40fb9515c784cc3b);
    $parcel$export(module.exports, "singleValueAsValue", ()=>$109d3f9af5469308$export$dea4763e29237f4c);
    $parcel$export(module.exports, "multiValueAsValue", ()=>$109d3f9af5469308$export$d1ca752ec761b95b);
    $parcel$export(module.exports, "removeProps", ()=>$109d3f9af5469308$export$4213b25a1c4e877);
    const $109d3f9af5469308$export$8793edee2d425525 = ()=>{};
    const $109d3f9af5469308$export$1643ccb46f49255c = ()=>'';
    function $109d3f9af5469308$var$applyPrefixToName(prefix, name) {
        if (!name) return prefix;
        else if (name[0] === '-') return prefix + name;
        else return prefix + '__' + name;
    }
    function $109d3f9af5469308$export$ce4ab0c55987d1ff(prefix, state, ...classNameList) {
        const arr = [
            ...classNameList
        ];
        if (state && prefix) {
            for(let key in state)if (state.hasOwnProperty(key) && state[key]) arr.push(`${$109d3f9af5469308$var$applyPrefixToName(prefix, key)}`);
        }
        return arr.filter((i)=>i).map((i)=>String(i).trim()).join(' ');
    }
    const $109d3f9af5469308$export$33f062f47f21a01 = (value)=>{
        if ($109d3f9af5469308$export$43bee75e5e14138e(value)) return value.filter(Boolean);
        if (typeof value === 'object' && value !== null) return [
            value
        ];
        return [];
    };
    const $109d3f9af5469308$export$8e9fd4287627890c = (props)=>{
        const { className: className, clearValue: clearValue, cx: cx, getStyles: getStyles, getClassNames: getClassNames, getValue: getValue, hasValue: hasValue, isMulti: isMulti, isRtl: isRtl, options: options, selectOption: selectOption, selectProps: selectProps, setValue: setValue, theme: theme, ...innerProps } = props;
        return {
            ...innerProps
        };
    };
    const $109d3f9af5469308$export$26417cabd806a658 = (props, name, classNamesState)=>{
        const { cx: cx, getStyles: getStyles, getClassNames: getClassNames, className: className } = props;
        return {
            css: getStyles(name, props),
            className: cx(classNamesState ?? {}, getClassNames(name, props), className)
        };
    };
    function $109d3f9af5469308$export$2096bae78ecda911(inputValue, actionMeta, onInputChange) {
        if (onInputChange) {
            const newValue = onInputChange(inputValue, actionMeta);
            if (typeof newValue === 'string') return newValue;
        }
        return inputValue;
    }
    function $109d3f9af5469308$export$d8a62a489b442872(el) {
        return [
            document.documentElement,
            document.body,
            window
        ].indexOf(el) > -1;
    }
    function $109d3f9af5469308$export$2e0654fad740d2e4(el) {
        if ($109d3f9af5469308$export$d8a62a489b442872(el)) return window.innerHeight;
        return el.clientHeight;
    }
    function $109d3f9af5469308$export$c4a223a8ba9e4ea5(el) {
        if ($109d3f9af5469308$export$d8a62a489b442872(el)) return window.pageYOffset;
        return el.scrollTop;
    }
    function $109d3f9af5469308$export$3f99968195accfaf(el, top) {
        if ($109d3f9af5469308$export$d8a62a489b442872(el)) {
            window.scrollTo(0, top);
            return;
        }
        el.scrollTop = top;
    }
    function $109d3f9af5469308$export$cfa2225e87938781(element) {
        let style = getComputedStyle(element);
        const excludeStaticParent = style.position === 'absolute';
        const overflowRx = /(auto|scroll)/;
        if (style.position === 'fixed') return document.documentElement;
        for(let parent = element; parent = parent.parentElement;){
            style = getComputedStyle(parent);
            if (excludeStaticParent && style.position === 'static') continue;
            if (overflowRx.test(style.overflow + style.overflowY + style.overflowX)) return parent;
        }
        return document.documentElement;
    }
    function $109d3f9af5469308$var$easeOutCubic(t, b, c, d) {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    }
    function $109d3f9af5469308$export$858c30343199f3af(element, to, duration = 200, callback = $109d3f9af5469308$export$8793edee2d425525) {
        const start = $109d3f9af5469308$export$c4a223a8ba9e4ea5(element);
        const change = to - start;
        const increment = 10;
        let currentTime = 0;
        function animateScroll() {
            currentTime += increment;
            const val = $109d3f9af5469308$var$easeOutCubic(currentTime, start, change, duration);
            $109d3f9af5469308$export$3f99968195accfaf(element, val);
            if (currentTime < duration) window.requestAnimationFrame(animateScroll);
            else callback(element);
        }
        animateScroll();
    }
    function $109d3f9af5469308$export$53a0910f038337bd(menuEl, focusedEl) {
        const menuRect = menuEl.getBoundingClientRect();
        const focusedRect = focusedEl.getBoundingClientRect();
        const overScroll = focusedEl.offsetHeight / 3;
        if (focusedRect.bottom + overScroll > menuRect.bottom) $109d3f9af5469308$export$3f99968195accfaf(menuEl, Math.min(focusedEl.offsetTop + focusedEl.clientHeight - menuEl.offsetHeight + overScroll, menuEl.scrollHeight));
        else if (focusedRect.top - overScroll < menuRect.top) $109d3f9af5469308$export$3f99968195accfaf(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0));
    }
    function $109d3f9af5469308$export$7807c2a424c2f6d1(element) {
        const rect = element.getBoundingClientRect();
        return {
            bottom: rect.bottom,
            height: rect.height,
            left: rect.left,
            right: rect.right,
            top: rect.top,
            width: rect.width
        };
    }
    function $109d3f9af5469308$export$c0d0115e766e14c3(str) {
        return str.replace(/\W/g, '-');
    }
    function $109d3f9af5469308$export$56963d77dbffdde7() {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    }
    function $109d3f9af5469308$export$44f917bf91becd01() {
        try {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        } catch (e) {
            return false;
        }
    }
    let $109d3f9af5469308$var$passiveOptionAccessed = false;
    const $109d3f9af5469308$var$options = {
        get passive () {
            return $109d3f9af5469308$var$passiveOptionAccessed = true;
        }
    };
    const $109d3f9af5469308$var$w = typeof window !== 'undefined' ? window : {};
    if ($109d3f9af5469308$var$w.addEventListener && $109d3f9af5469308$var$w.removeEventListener) {
        $109d3f9af5469308$var$w.addEventListener('p', $109d3f9af5469308$export$8793edee2d425525, $109d3f9af5469308$var$options);
        $109d3f9af5469308$var$w.removeEventListener('p', $109d3f9af5469308$export$8793edee2d425525, false);
    }
    const $109d3f9af5469308$export$b0622a6911dbeaf5 = $109d3f9af5469308$var$passiveOptionAccessed;
    function $109d3f9af5469308$export$aedb69ff3e31596c(item) {
        return item != null;
    }
    function $109d3f9af5469308$export$43bee75e5e14138e(arg) {
        return Array.isArray(arg);
    }
    function $109d3f9af5469308$export$40fb9515c784cc3b(isMulti, multiValue, singleValue) {
        return isMulti ? multiValue : singleValue;
    }
    function $109d3f9af5469308$export$dea4763e29237f4c(singleValue) {
        return singleValue;
    }
    function $109d3f9af5469308$export$d1ca752ec761b95b(multiValue) {
        return multiValue;
    }
    const $109d3f9af5469308$export$4213b25a1c4e877 = (propsObj, ...properties)=>{
        let propsMap = Object.entries(propsObj).filter(([key])=>!properties.includes(key));
        return propsMap.reduce((newProps, [key, val])=>{
            newProps[key] = val;
            return newProps;
        }, {});
    };
});
parcelRegister("eZFhY", function(module, exports) {
    $parcel$export(module.exports, "css", ()=>$728816cb4150b6bc$export$dbf350e5966cf602);
    $parcel$export(module.exports, "default", ()=>$728816cb4150b6bc$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    const $728816cb4150b6bc$export$dbf350e5966cf602 = ({ isDisabled: isDisabled, isFocused: isFocused, theme: { colors: colors, borderRadius: borderRadius, spacing: spacing } }, unstyled)=>({
            label: 'control',
            alignItems: 'center',
            cursor: 'default',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            minHeight: spacing.controlHeight,
            outline: '0 !important',
            position: 'relative',
            transition: 'all 100ms',
            ...unstyled ? {} : {
                backgroundColor: isDisabled ? colors.neutral5 : colors.neutral0,
                borderColor: isDisabled ? colors.neutral10 : isFocused ? colors.primary : colors.neutral20,
                borderRadius: borderRadius,
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: isFocused ? `0 0 0 1px ${colors.primary}` : undefined,
                '&:hover': {
                    borderColor: isFocused ? colors.primary : colors.neutral30
                }
            }
        });
    const $728816cb4150b6bc$var$Control = (props)=>{
        const { children: children, isDisabled: isDisabled, isFocused: isFocused, innerRef: innerRef, innerProps: innerProps, menuIsOpen: menuIsOpen } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ref: innerRef,
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'control', {
                control: true,
                'control--is-disabled': isDisabled,
                'control--is-focused': isFocused,
                'control--menu-is-open': menuIsOpen
            }),
            ...innerProps,
            "aria-disabled": isDisabled || undefined
        }, children);
    };
    var $728816cb4150b6bc$export$2e2bcd8739ae039 = $728816cb4150b6bc$var$Control;
});
parcelRegister("i48X9", function(module, exports) {
    $parcel$export(module.exports, "groupCSS", ()=>$902e86f4b4fe337b$export$b6794329c1460634);
    $parcel$export(module.exports, "groupHeadingCSS", ()=>$902e86f4b4fe337b$export$e5a7fbb542efcb7d);
    $parcel$export(module.exports, "GroupHeading", ()=>$902e86f4b4fe337b$export$85c8b41a3cd87edc);
    $parcel$export(module.exports, "default", ()=>$902e86f4b4fe337b$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    const $902e86f4b4fe337b$export$b6794329c1460634 = ({ theme: { spacing: spacing } }, unstyled)=>unstyled ? {} : {
            paddingBottom: spacing.baseUnit * 2,
            paddingTop: spacing.baseUnit * 2
        };
    const $902e86f4b4fe337b$var$Group = (props)=>{
        const { children: children, cx: cx, getStyles: getStyles, getClassNames: getClassNames, Heading: Heading, headingProps: headingProps, innerProps: innerProps, label: label, theme: theme, selectProps: selectProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'group', {
                group: true
            }),
            ...innerProps
        }, (0, (0, parcelRequire("f8Sb3")).jsx)(Heading, {
            ...headingProps,
            selectProps: selectProps,
            theme: theme,
            getStyles: getStyles,
            getClassNames: getClassNames,
            cx: cx
        }, label), (0, (0, parcelRequire("f8Sb3")).jsx)("div", null, children));
    };
    const $902e86f4b4fe337b$export$e5a7fbb542efcb7d = ({ theme: { colors: colors, spacing: spacing } }, unstyled)=>({
            label: 'group',
            cursor: 'default',
            display: 'block',
            ...unstyled ? {} : {
                color: colors.neutral40,
                fontSize: '75%',
                fontWeight: 500,
                marginBottom: '0.25em',
                paddingLeft: spacing.baseUnit * 3,
                paddingRight: spacing.baseUnit * 3,
                textTransform: 'uppercase'
            }
        });
    const $902e86f4b4fe337b$export$85c8b41a3cd87edc = (props)=>{
        const { data: data, ...innerProps } = (0, (0, parcelRequire("gyjU7")).cleanCommonProps)(props);
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'groupHeading', {
                'group-heading': true
            }),
            ...innerProps
        });
    };
    var $902e86f4b4fe337b$export$2e2bcd8739ae039 = $902e86f4b4fe337b$var$Group;
});
parcelRegister("95iuW", function(module, exports) {
    $parcel$export(module.exports, "CrossIcon", ()=>$13f090f36c42825d$export$3b621d86b1566b2);
    $parcel$export(module.exports, "DownChevron", ()=>$13f090f36c42825d$export$c5cbe1656ba71e6c);
    $parcel$export(module.exports, "dropdownIndicatorCSS", ()=>$13f090f36c42825d$export$e1687335805e1230);
    $parcel$export(module.exports, "DropdownIndicator", ()=>$13f090f36c42825d$export$ec98719d3db7f2e4);
    $parcel$export(module.exports, "clearIndicatorCSS", ()=>$13f090f36c42825d$export$9c0419fd944c1def);
    $parcel$export(module.exports, "ClearIndicator", ()=>$13f090f36c42825d$export$ecf9cf59142dd54a);
    $parcel$export(module.exports, "indicatorSeparatorCSS", ()=>$13f090f36c42825d$export$ec39646155d07fdd);
    $parcel$export(module.exports, "IndicatorSeparator", ()=>$13f090f36c42825d$export$bd638c2ba7915668);
    $parcel$export(module.exports, "loadingIndicatorCSS", ()=>$13f090f36c42825d$export$1d3f24cde248e6b5);
    $parcel$export(module.exports, "LoadingIndicator", ()=>$13f090f36c42825d$export$7c20be4c1d342248);
    var $f8Sb3;
    var $gyjU7;
    function $13f090f36c42825d$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $13f090f36c42825d$var$styles = {
        name: "8mmkcg",
        styles: "display:inline-block;fill:currentColor;line-height:1;stroke:currentColor;stroke-width:0"
    };
    const $13f090f36c42825d$var$Svg = ({ size: size, ...props })=>(0, (0, parcelRequire("f8Sb3")).jsx)("svg", {
            height: size,
            width: size,
            viewBox: "0 0 20 20",
            "aria-hidden": "true",
            focusable: "false",
            css: $13f090f36c42825d$var$styles,
            ...props
        });
    const $13f090f36c42825d$export$3b621d86b1566b2 = (props)=>(0, (0, parcelRequire("f8Sb3")).jsx)($13f090f36c42825d$var$Svg, {
            size: 20,
            ...props
        }, (0, (0, parcelRequire("f8Sb3")).jsx)("path", {
            d: "M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
        }));
    const $13f090f36c42825d$export$c5cbe1656ba71e6c = (props)=>(0, (0, parcelRequire("f8Sb3")).jsx)($13f090f36c42825d$var$Svg, {
            size: 20,
            ...props
        }, (0, (0, parcelRequire("f8Sb3")).jsx)("path", {
            d: "M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
        }));
    const $13f090f36c42825d$var$baseCSS = ({ isFocused: isFocused, theme: { spacing: { baseUnit: baseUnit }, colors: colors } }, unstyled)=>({
            label: 'indicatorContainer',
            display: 'flex',
            transition: 'color 150ms',
            ...unstyled ? {} : {
                color: isFocused ? colors.neutral60 : colors.neutral20,
                padding: baseUnit * 2,
                ':hover': {
                    color: isFocused ? colors.neutral80 : colors.neutral40
                }
            }
        });
    const $13f090f36c42825d$export$e1687335805e1230 = $13f090f36c42825d$var$baseCSS;
    const $13f090f36c42825d$export$ec98719d3db7f2e4 = (props)=>{
        const { children: children, innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'dropdownIndicator', {
                indicator: true,
                'dropdown-indicator': true
            }),
            ...innerProps
        }, children || (0, (0, parcelRequire("f8Sb3")).jsx)($13f090f36c42825d$export$c5cbe1656ba71e6c, null));
    };
    const $13f090f36c42825d$export$9c0419fd944c1def = $13f090f36c42825d$var$baseCSS;
    const $13f090f36c42825d$export$ecf9cf59142dd54a = (props)=>{
        const { children: children, innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'clearIndicator', {
                indicator: true,
                'clear-indicator': true
            }),
            ...innerProps
        }, children || (0, (0, parcelRequire("f8Sb3")).jsx)($13f090f36c42825d$export$3b621d86b1566b2, null));
    };
    const $13f090f36c42825d$export$ec39646155d07fdd = ({ isDisabled: isDisabled, theme: { spacing: { baseUnit: baseUnit }, colors: colors } }, unstyled)=>({
            label: 'indicatorSeparator',
            alignSelf: 'stretch',
            width: 1,
            ...unstyled ? {} : {
                backgroundColor: isDisabled ? colors.neutral10 : colors.neutral20,
                marginBottom: baseUnit * 2,
                marginTop: baseUnit * 2
            }
        });
    const $13f090f36c42825d$export$bd638c2ba7915668 = (props)=>{
        const { innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            ...innerProps,
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'indicatorSeparator', {
                'indicator-separator': true
            })
        });
    };
    const $13f090f36c42825d$var$loadingDotAnimations = (0, (0, parcelRequire("f8Sb3")).keyframes)({
        '0%, 80%, 100%': {
            opacity: 0
        },
        '40%': {
            opacity: 1
        }
    });
    const $13f090f36c42825d$export$1d3f24cde248e6b5 = ({ isFocused: isFocused, size: size, theme: { colors: colors, spacing: { baseUnit: baseUnit } } }, unstyled)=>({
            label: 'loadingIndicator',
            display: 'flex',
            transition: 'color 150ms',
            alignSelf: 'center',
            fontSize: size,
            lineHeight: 1,
            marginRight: size,
            textAlign: 'center',
            verticalAlign: 'middle',
            ...unstyled ? {} : {
                color: isFocused ? colors.neutral60 : colors.neutral20,
                padding: baseUnit * 2
            }
        });
    const $13f090f36c42825d$var$LoadingDot = ({ delay: delay, offset: offset })=>(0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            css: (0, (0, parcelRequire("f8Sb3")).css)({
                animation: `${$13f090f36c42825d$var$loadingDotAnimations} 1s ease-in-out ${delay}ms infinite;`,
                backgroundColor: 'currentColor',
                borderRadius: '1em',
                display: 'inline-block',
                marginLeft: offset ? '1em' : undefined,
                height: '1em',
                verticalAlign: 'top',
                width: '1em'
            })
        });
    const $13f090f36c42825d$export$7c20be4c1d342248 = ({ innerProps: innerProps, isRtl: isRtl, size: size = 4, ...restProps })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)({
                ...restProps,
                innerProps: innerProps,
                isRtl: isRtl,
                size: size
            }, 'loadingIndicator', {
                indicator: true,
                'loading-indicator': true
            }),
            ...innerProps
        }, (0, (0, parcelRequire("f8Sb3")).jsx)($13f090f36c42825d$var$LoadingDot, {
            delay: 0,
            offset: isRtl
        }), (0, (0, parcelRequire("f8Sb3")).jsx)($13f090f36c42825d$var$LoadingDot, {
            delay: 160,
            offset: true
        }), (0, (0, parcelRequire("f8Sb3")).jsx)($13f090f36c42825d$var$LoadingDot, {
            delay: 320,
            offset: !isRtl
        }));
    };
});
parcelRegister("dS5G5", function(module, exports) {
    $parcel$export(module.exports, "inputCSS", ()=>$1c949ac6f5985b40$export$14a7781d0ca7abea);
    $parcel$export(module.exports, "default", ()=>$1c949ac6f5985b40$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    const $1c949ac6f5985b40$export$14a7781d0ca7abea = ({ isDisabled: isDisabled, value: value, theme: { spacing: spacing, colors: colors } }, unstyled)=>({
            visibility: isDisabled ? 'hidden' : 'visible',
            transform: value ? 'translateZ(0)' : '',
            ...$1c949ac6f5985b40$var$containerStyle,
            ...unstyled ? {} : {
                margin: spacing.baseUnit / 2,
                paddingBottom: spacing.baseUnit / 2,
                paddingTop: spacing.baseUnit / 2,
                color: colors.neutral80
            }
        });
    const $1c949ac6f5985b40$var$spacingStyle = {
        gridArea: '1 / 2',
        font: 'inherit',
        minWidth: '2px',
        border: 0,
        margin: 0,
        outline: 0,
        padding: 0
    };
    const $1c949ac6f5985b40$var$containerStyle = {
        flex: '1 1 auto',
        display: 'inline-grid',
        gridArea: '1 / 1 / 2 / 3',
        gridTemplateColumns: '0 min-content',
        '&:after': {
            content: 'attr(data-value) " "',
            visibility: 'hidden',
            whiteSpace: 'pre',
            ...$1c949ac6f5985b40$var$spacingStyle
        }
    };
    const $1c949ac6f5985b40$var$inputStyle = (isHidden)=>({
            label: 'input',
            color: 'inherit',
            background: 0,
            opacity: isHidden ? 0 : 1,
            width: '100%',
            ...$1c949ac6f5985b40$var$spacingStyle
        });
    const $1c949ac6f5985b40$var$Input = (props)=>{
        const { cx: cx, value: value } = props;
        const { innerRef: innerRef, isDisabled: isDisabled, isHidden: isHidden, inputClassName: inputClassName, ...innerProps } = (0, (0, parcelRequire("gyjU7")).cleanCommonProps)(props);
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'input', {
                'input-container': true
            }),
            "data-value": value || ''
        }, (0, (0, parcelRequire("f8Sb3")).jsx)("input", {
            className: cx({
                input: true
            }, inputClassName),
            ref: innerRef,
            style: $1c949ac6f5985b40$var$inputStyle(isHidden),
            disabled: isDisabled,
            ...innerProps
        }));
    };
    var $1c949ac6f5985b40$export$2e2bcd8739ae039 = $1c949ac6f5985b40$var$Input;
});
parcelRegister("aA8TC", function(module, exports) {
    $parcel$export(module.exports, "menuCSS", ()=>$1fde8cb844828ae2$export$a228922d5ca42a79);
    $parcel$export(module.exports, "MenuPlacer", ()=>$1fde8cb844828ae2$export$f32337f3a7617699);
    $parcel$export(module.exports, "default", ()=>$1fde8cb844828ae2$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "menuListCSS", ()=>$1fde8cb844828ae2$export$abafed69cff95d5e);
    $parcel$export(module.exports, "MenuList", ()=>$1fde8cb844828ae2$export$d4c4e98c5044dc8);
    $parcel$export(module.exports, "noOptionsMessageCSS", ()=>$1fde8cb844828ae2$export$cf0e22534d8b0074);
    $parcel$export(module.exports, "loadingMessageCSS", ()=>$1fde8cb844828ae2$export$98ec46397a8cb3cd);
    $parcel$export(module.exports, "NoOptionsMessage", ()=>$1fde8cb844828ae2$export$d58b72b7d3c4072c);
    $parcel$export(module.exports, "LoadingMessage", ()=>$1fde8cb844828ae2$export$e928a71d039d3102);
    $parcel$export(module.exports, "menuPortalCSS", ()=>$1fde8cb844828ae2$export$1dbbeb9648d1fdc9);
    $parcel$export(module.exports, "MenuPortal", ()=>$1fde8cb844828ae2$export$793392f970497feb);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $g9RrE;
    var $7nsd3 = parcelRequire("7nsd3");
    var $hrqx3 = parcelRequire("hrqx3");
    var $gyjU7;
    function $1fde8cb844828ae2$export$bd649b913ec78e4a({ maxHeight: preferredMaxHeight, menuEl: menuEl, minHeight: minHeight, placement: preferredPlacement, shouldScroll: shouldScroll, isFixedPosition: isFixedPosition, controlHeight: controlHeight }) {
        const scrollParent = (0, (0, parcelRequire("gyjU7")).getScrollParent)(menuEl);
        const defaultState = {
            placement: 'bottom',
            maxHeight: preferredMaxHeight
        };
        if (!menuEl || !menuEl.offsetParent) return defaultState;
        const { height: scrollHeight } = scrollParent.getBoundingClientRect();
        const { bottom: menuBottom, height: menuHeight, top: menuTop } = menuEl.getBoundingClientRect();
        const { top: containerTop } = menuEl.offsetParent.getBoundingClientRect();
        const viewHeight = isFixedPosition ? window.innerHeight : (0, (0, parcelRequire("gyjU7")).normalizedHeight)(scrollParent);
        const scrollTop = (0, (0, parcelRequire("gyjU7")).getScrollTop)(scrollParent);
        const marginBottom = parseInt(getComputedStyle(menuEl).marginBottom, 10);
        const marginTop = parseInt(getComputedStyle(menuEl).marginTop, 10);
        const viewSpaceAbove = containerTop - marginTop;
        const viewSpaceBelow = viewHeight - menuTop;
        const scrollSpaceAbove = viewSpaceAbove + scrollTop;
        const scrollSpaceBelow = scrollHeight - scrollTop - menuTop;
        const scrollDown = menuBottom - viewHeight + scrollTop + marginBottom;
        const scrollUp = scrollTop + menuTop - marginTop;
        const scrollDuration = 160;
        switch(preferredPlacement){
            case 'auto':
            case 'bottom':
                if (viewSpaceBelow >= menuHeight) return {
                    placement: 'bottom',
                    maxHeight: preferredMaxHeight
                };
                if (scrollSpaceBelow >= menuHeight && !isFixedPosition) {
                    if (shouldScroll) (0, (0, parcelRequire("gyjU7")).animatedScrollTo)(scrollParent, scrollDown, scrollDuration);
                    return {
                        placement: 'bottom',
                        maxHeight: preferredMaxHeight
                    };
                }
                if (!isFixedPosition && scrollSpaceBelow >= minHeight || isFixedPosition && viewSpaceBelow >= minHeight) {
                    if (shouldScroll) (0, (0, parcelRequire("gyjU7")).animatedScrollTo)(scrollParent, scrollDown, scrollDuration);
                    const constrainedHeight = isFixedPosition ? viewSpaceBelow - marginBottom : scrollSpaceBelow - marginBottom;
                    return {
                        placement: 'bottom',
                        maxHeight: constrainedHeight
                    };
                }
                if (preferredPlacement === 'auto' || isFixedPosition) {
                    let constrainedHeight = preferredMaxHeight;
                    const spaceAbove = isFixedPosition ? viewSpaceAbove : scrollSpaceAbove;
                    if (spaceAbove >= minHeight) constrainedHeight = Math.min(spaceAbove - marginBottom - controlHeight, preferredMaxHeight);
                    return {
                        placement: 'top',
                        maxHeight: constrainedHeight
                    };
                }
                if (preferredPlacement === 'bottom') {
                    if (shouldScroll) (0, (0, parcelRequire("gyjU7")).scrollTo)(scrollParent, scrollDown);
                    return {
                        placement: 'bottom',
                        maxHeight: preferredMaxHeight
                    };
                }
                break;
            case 'top':
                if (viewSpaceAbove >= menuHeight) return {
                    placement: 'top',
                    maxHeight: preferredMaxHeight
                };
                if (scrollSpaceAbove >= menuHeight && !isFixedPosition) {
                    if (shouldScroll) (0, (0, parcelRequire("gyjU7")).animatedScrollTo)(scrollParent, scrollUp, scrollDuration);
                    return {
                        placement: 'top',
                        maxHeight: preferredMaxHeight
                    };
                }
                if (!isFixedPosition && scrollSpaceAbove >= minHeight || isFixedPosition && viewSpaceAbove >= minHeight) {
                    let constrainedHeight = preferredMaxHeight;
                    if (!isFixedPosition && scrollSpaceAbove >= minHeight || isFixedPosition && viewSpaceAbove >= minHeight) constrainedHeight = isFixedPosition ? viewSpaceAbove - marginTop : scrollSpaceAbove - marginTop;
                    if (shouldScroll) (0, (0, parcelRequire("gyjU7")).animatedScrollTo)(scrollParent, scrollUp, scrollDuration);
                    return {
                        placement: 'top',
                        maxHeight: constrainedHeight
                    };
                }
                return {
                    placement: 'bottom',
                    maxHeight: preferredMaxHeight
                };
            default:
                throw new Error(`Invalid placement provided "${preferredPlacement}".`);
        }
        return defaultState;
    }
    function $1fde8cb844828ae2$var$alignToControl(placement) {
        const placementToCSSProp = {
            bottom: 'top',
            top: 'bottom'
        };
        return placement ? placementToCSSProp[placement] : 'bottom';
    }
    const $1fde8cb844828ae2$var$coercePlacement = (p)=>p === 'auto' ? 'bottom' : p;
    const $1fde8cb844828ae2$export$a228922d5ca42a79 = ({ placement: placement, theme: { borderRadius: borderRadius, spacing: spacing, colors: colors } }, unstyled)=>({
            label: 'menu',
            [$1fde8cb844828ae2$var$alignToControl(placement)]: '100%',
            position: 'absolute',
            width: '100%',
            zIndex: 1,
            ...unstyled ? {} : {
                backgroundColor: colors.neutral0,
                borderRadius: borderRadius,
                boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
                marginBottom: spacing.menuGutter,
                marginTop: spacing.menuGutter
            }
        });
    const $1fde8cb844828ae2$var$PortalPlacementContext = (0, $5uXOq.createContext)(null);
    const $1fde8cb844828ae2$export$f32337f3a7617699 = (props)=>{
        const { children: children, minMenuHeight: minMenuHeight, maxMenuHeight: maxMenuHeight, menuPlacement: menuPlacement, menuPosition: menuPosition, menuShouldScrollIntoView: menuShouldScrollIntoView, theme: theme } = props;
        const { setPortalPlacement: setPortalPlacement } = (0, $5uXOq.useContext)($1fde8cb844828ae2$var$PortalPlacementContext) || {};
        const ref = (0, $5uXOq.useRef)(null);
        const [maxHeight, setMaxHeight] = (0, $5uXOq.useState)(maxMenuHeight);
        const [placement, setPlacement] = (0, $5uXOq.useState)(null);
        const { controlHeight: controlHeight } = theme.spacing;
        (0, $hrqx3.default)(()=>{
            const menuEl = ref.current;
            if (!menuEl) return;
            const isFixedPosition = menuPosition === 'fixed';
            const shouldScroll = menuShouldScrollIntoView && !isFixedPosition;
            const state = $1fde8cb844828ae2$export$bd649b913ec78e4a({
                maxHeight: maxMenuHeight,
                menuEl: menuEl,
                minHeight: minMenuHeight,
                placement: menuPlacement,
                shouldScroll: shouldScroll,
                isFixedPosition: isFixedPosition,
                controlHeight: controlHeight
            });
            setMaxHeight(state.maxHeight);
            setPlacement(state.placement);
            setPortalPlacement?.(state.placement);
        }, [
            maxMenuHeight,
            menuPlacement,
            menuPosition,
            menuShouldScrollIntoView,
            minMenuHeight,
            setPortalPlacement,
            controlHeight
        ]);
        return children({
            ref: ref,
            placerProps: {
                ...props,
                placement: placement || $1fde8cb844828ae2$var$coercePlacement(menuPlacement),
                maxHeight: maxHeight
            }
        });
    };
    const $1fde8cb844828ae2$var$Menu = (props)=>{
        const { children: children, innerRef: innerRef, innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'menu', {
                menu: true
            }),
            ref: innerRef,
            ...innerProps
        }, children);
    };
    var $1fde8cb844828ae2$export$2e2bcd8739ae039 = $1fde8cb844828ae2$var$Menu;
    const $1fde8cb844828ae2$export$abafed69cff95d5e = ({ maxHeight: maxHeight, theme: { spacing: { baseUnit: baseUnit } } }, unstyled)=>({
            maxHeight: maxHeight,
            overflowY: 'auto',
            position: 'relative',
            WebkitOverflowScrolling: 'touch',
            ...unstyled ? {} : {
                paddingBottom: baseUnit,
                paddingTop: baseUnit
            }
        });
    const $1fde8cb844828ae2$export$d4c4e98c5044dc8 = (props)=>{
        const { children: children, innerProps: innerProps, innerRef: innerRef, isMulti: isMulti } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'menuList', {
                'menu-list': true,
                'menu-list--is-multi': isMulti
            }),
            ref: innerRef,
            ...innerProps,
            tabIndex: -1
        }, children);
    };
    const $1fde8cb844828ae2$var$noticeCSS = ({ theme: { spacing: { baseUnit: baseUnit }, colors: colors } }, unstyled)=>({
            textAlign: 'center',
            ...unstyled ? {} : {
                color: colors.neutral40,
                padding: `${baseUnit * 2}px ${baseUnit * 3}px`
            }
        });
    const $1fde8cb844828ae2$export$cf0e22534d8b0074 = $1fde8cb844828ae2$var$noticeCSS;
    const $1fde8cb844828ae2$export$98ec46397a8cb3cd = $1fde8cb844828ae2$var$noticeCSS;
    const $1fde8cb844828ae2$export$d58b72b7d3c4072c = ({ children: children = 'No options', innerProps: innerProps, ...restProps })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)({
                ...restProps,
                children: children,
                innerProps: innerProps
            }, 'noOptionsMessage', {
                'menu-notice': true,
                'menu-notice--no-options': true
            }),
            role: "option",
            ...innerProps
        }, children);
    };
    const $1fde8cb844828ae2$export$e928a71d039d3102 = ({ children: children = 'Loading...', innerProps: innerProps, ...restProps })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)({
                ...restProps,
                children: children,
                innerProps: innerProps
            }, 'loadingMessage', {
                'menu-notice': true,
                'menu-notice--loading': true
            }),
            ...innerProps,
            role: "option"
        }, children);
    };
    const $1fde8cb844828ae2$export$1dbbeb9648d1fdc9 = ({ rect: rect, offset: offset, position: position })=>({
            left: rect.left,
            position: position,
            top: offset,
            width: rect.width,
            zIndex: 1
        });
    const $1fde8cb844828ae2$export$793392f970497feb = (props)=>{
        const { appendTo: appendTo, children: children, controlElement: controlElement, innerProps: innerProps, menuPlacement: menuPlacement, menuPosition: menuPosition } = props;
        const menuPortalRef = (0, $5uXOq.useRef)(null);
        const cleanupRef = (0, $5uXOq.useRef)(null);
        const [placement, setPortalPlacement] = (0, $5uXOq.useState)($1fde8cb844828ae2$var$coercePlacement(menuPlacement));
        const portalPlacementContext = (0, $5uXOq.useMemo)(()=>({
                setPortalPlacement: setPortalPlacement
            }), []);
        const [computedPosition, setComputedPosition] = (0, $5uXOq.useState)(null);
        const updateComputedPosition = (0, $5uXOq.useCallback)(()=>{
            if (!controlElement) return;
            const rect = (0, (0, parcelRequire("gyjU7")).getBoundingClientObj)(controlElement);
            const scrollDistance = menuPosition === 'fixed' ? 0 : window.pageYOffset;
            const offset = rect[placement] + scrollDistance;
            if (offset !== computedPosition?.offset || rect.left !== computedPosition?.rect.left || rect.width !== computedPosition?.rect.width) setComputedPosition({
                offset: offset,
                rect: rect
            });
        }, [
            controlElement,
            menuPosition,
            placement,
            computedPosition?.offset,
            computedPosition?.rect.left,
            computedPosition?.rect.width
        ]);
        (0, $hrqx3.default)(()=>{
            updateComputedPosition();
        }, [
            updateComputedPosition
        ]);
        const runAutoUpdate = (0, $5uXOq.useCallback)(()=>{
            if (typeof cleanupRef.current === 'function') {
                cleanupRef.current();
                cleanupRef.current = null;
            }
            if (controlElement && menuPortalRef.current) cleanupRef.current = (0, (0, parcelRequire("g9RrE")).autoUpdate)(controlElement, menuPortalRef.current, updateComputedPosition, {
                elementResize: 'ResizeObserver' in window
            });
        }, [
            controlElement,
            updateComputedPosition
        ]);
        (0, $hrqx3.default)(()=>{
            runAutoUpdate();
        }, [
            runAutoUpdate
        ]);
        const setMenuPortalElement = (0, $5uXOq.useCallback)((menuPortalElement)=>{
            menuPortalRef.current = menuPortalElement;
            runAutoUpdate();
        }, [
            runAutoUpdate
        ]);
        if (!appendTo && menuPosition !== 'fixed' || !computedPosition) return null;
        const menuWrapper = (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ref: setMenuPortalElement,
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)({
                ...props,
                offset: computedPosition.offset,
                position: menuPosition,
                rect: computedPosition.rect
            }, 'menuPortal', {
                'menu-portal': true
            }),
            ...innerProps
        }, children);
        return (0, (0, parcelRequire("f8Sb3")).jsx)($1fde8cb844828ae2$var$PortalPlacementContext.Provider, {
            value: portalPlacementContext
        }, appendTo ? (0, $7nsd3.createPortal)(menuWrapper, appendTo) : menuWrapper);
    };
});
parcelRegister("g9RrE", function(module, exports) {
    $parcel$export(module.exports, "autoUpdate", ()=>$bc36f1aefc070984$export$deee3a793edee05f);
    var $gSfPF;
    var $cVo0M;
    var $cVo0M;
    var $f8LYQ;
    function $bc36f1aefc070984$var$getCssDimensions(element) {
        const css = (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(element);
        let width = parseFloat(css.width) || 0;
        let height = parseFloat(css.height) || 0;
        const hasOffset = (0, (0, parcelRequire("f8LYQ")).isHTMLElement)(element);
        const offsetWidth = hasOffset ? element.offsetWidth : width;
        const offsetHeight = hasOffset ? element.offsetHeight : height;
        const shouldFallback = (0, (0, parcelRequire("cVo0M")).round)(width) !== offsetWidth || (0, (0, parcelRequire("cVo0M")).round)(height) !== offsetHeight;
        if (shouldFallback) {
            width = offsetWidth;
            height = offsetHeight;
        }
        return {
            width: width,
            height: height,
            $: shouldFallback
        };
    }
    function $bc36f1aefc070984$var$unwrapElement(element) {
        return !(0, (0, parcelRequire("f8LYQ")).isElement)(element) ? element.contextElement : element;
    }
    function $bc36f1aefc070984$var$getScale(element) {
        const domElement = $bc36f1aefc070984$var$unwrapElement(element);
        if (!(0, (0, parcelRequire("f8LYQ")).isHTMLElement)(domElement)) return (0, (0, parcelRequire("cVo0M")).createCoords)(1);
        const rect = domElement.getBoundingClientRect();
        const { width: width, height: height, $: $ } = $bc36f1aefc070984$var$getCssDimensions(domElement);
        let x = ($ ? (0, (0, parcelRequire("cVo0M")).round)(rect.width) : rect.width) / width;
        let y = ($ ? (0, (0, parcelRequire("cVo0M")).round)(rect.height) : rect.height) / height;
        if (!x || !Number.isFinite(x)) x = 1;
        if (!y || !Number.isFinite(y)) y = 1;
        return {
            x: x,
            y: y
        };
    }
    const $bc36f1aefc070984$var$noOffsets = (0, (0, parcelRequire("cVo0M")).createCoords)(0);
    function $bc36f1aefc070984$var$getVisualOffsets(element) {
        const win = (0, (0, parcelRequire("f8LYQ")).getWindow)(element);
        if (!(0, (0, parcelRequire("f8LYQ")).isWebKit)() || !win.visualViewport) return $bc36f1aefc070984$var$noOffsets;
        return {
            x: win.visualViewport.offsetLeft,
            y: win.visualViewport.offsetTop
        };
    }
    function $bc36f1aefc070984$var$shouldAddVisualOffsets(element, isFixed, floatingOffsetParent) {
        if (isFixed === void 0) isFixed = false;
        if (!floatingOffsetParent || isFixed && floatingOffsetParent !== (0, (0, parcelRequire("f8LYQ")).getWindow)(element)) return false;
        return isFixed;
    }
    function $bc36f1aefc070984$var$getBoundingClientRect(element, includeScale, isFixedStrategy, offsetParent) {
        if (includeScale === void 0) includeScale = false;
        if (isFixedStrategy === void 0) isFixedStrategy = false;
        const clientRect = element.getBoundingClientRect();
        const domElement = $bc36f1aefc070984$var$unwrapElement(element);
        let scale = (0, (0, parcelRequire("cVo0M")).createCoords)(1);
        if (includeScale) {
            if (offsetParent) {
                if ((0, (0, parcelRequire("f8LYQ")).isElement)(offsetParent)) scale = $bc36f1aefc070984$var$getScale(offsetParent);
            } else scale = $bc36f1aefc070984$var$getScale(element);
        }
        const visualOffsets = $bc36f1aefc070984$var$shouldAddVisualOffsets(domElement, isFixedStrategy, offsetParent) ? $bc36f1aefc070984$var$getVisualOffsets(domElement) : (0, (0, parcelRequire("cVo0M")).createCoords)(0);
        let x = (clientRect.left + visualOffsets.x) / scale.x;
        let y = (clientRect.top + visualOffsets.y) / scale.y;
        let width = clientRect.width / scale.x;
        let height = clientRect.height / scale.y;
        if (domElement) {
            const win = (0, (0, parcelRequire("f8LYQ")).getWindow)(domElement);
            const offsetWin = offsetParent && (0, (0, parcelRequire("f8LYQ")).isElement)(offsetParent) ? (0, (0, parcelRequire("f8LYQ")).getWindow)(offsetParent) : offsetParent;
            let currentWin = win;
            let currentIFrame = (0, (0, parcelRequire("f8LYQ")).getFrameElement)(currentWin);
            while(currentIFrame && offsetParent && offsetWin !== currentWin){
                const iframeScale = $bc36f1aefc070984$var$getScale(currentIFrame);
                const iframeRect = currentIFrame.getBoundingClientRect();
                const css = (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(currentIFrame);
                const left = iframeRect.left + (currentIFrame.clientLeft + parseFloat(css.paddingLeft)) * iframeScale.x;
                const top = iframeRect.top + (currentIFrame.clientTop + parseFloat(css.paddingTop)) * iframeScale.y;
                x *= iframeScale.x;
                y *= iframeScale.y;
                width *= iframeScale.x;
                height *= iframeScale.y;
                x += left;
                y += top;
                currentWin = (0, (0, parcelRequire("f8LYQ")).getWindow)(currentIFrame);
                currentIFrame = (0, (0, parcelRequire("f8LYQ")).getFrameElement)(currentWin);
            }
        }
        return (0, (0, parcelRequire("cVo0M")).rectToClientRect)({
            width: width,
            height: height,
            x: x,
            y: y
        });
    }
    function $bc36f1aefc070984$var$getWindowScrollBarX(element, rect) {
        const leftScroll = (0, (0, parcelRequire("f8LYQ")).getNodeScroll)(element).scrollLeft;
        if (!rect) return $bc36f1aefc070984$var$getBoundingClientRect((0, (0, parcelRequire("f8LYQ")).getDocumentElement)(element)).left + leftScroll;
        return rect.left + leftScroll;
    }
    function $bc36f1aefc070984$var$getHTMLOffset(documentElement, scroll, ignoreScrollbarX) {
        if (ignoreScrollbarX === void 0) ignoreScrollbarX = false;
        const htmlRect = documentElement.getBoundingClientRect();
        const x = htmlRect.left + scroll.scrollLeft - (ignoreScrollbarX ? 0 : $bc36f1aefc070984$var$getWindowScrollBarX(documentElement, htmlRect));
        const y = htmlRect.top + scroll.scrollTop;
        return {
            x: x,
            y: y
        };
    }
    function $bc36f1aefc070984$var$convertOffsetParentRelativeRectToViewportRelativeRect(_ref) {
        let { elements: elements, rect: rect, offsetParent: offsetParent, strategy: strategy } = _ref;
        const isFixed = strategy === 'fixed';
        const documentElement = (0, (0, parcelRequire("f8LYQ")).getDocumentElement)(offsetParent);
        const topLayer = elements ? (0, (0, parcelRequire("f8LYQ")).isTopLayer)(elements.floating) : false;
        if (offsetParent === documentElement || topLayer && isFixed) return rect;
        let scroll = {
            scrollLeft: 0,
            scrollTop: 0
        };
        let scale = (0, (0, parcelRequire("cVo0M")).createCoords)(1);
        const offsets = (0, (0, parcelRequire("cVo0M")).createCoords)(0);
        const isOffsetParentAnElement = (0, (0, parcelRequire("f8LYQ")).isHTMLElement)(offsetParent);
        if (isOffsetParentAnElement || !isOffsetParentAnElement && !isFixed) {
            if ((0, (0, parcelRequire("f8LYQ")).getNodeName)(offsetParent) !== 'body' || (0, (0, parcelRequire("f8LYQ")).isOverflowElement)(documentElement)) scroll = (0, (0, parcelRequire("f8LYQ")).getNodeScroll)(offsetParent);
            if ((0, (0, parcelRequire("f8LYQ")).isHTMLElement)(offsetParent)) {
                const offsetRect = $bc36f1aefc070984$var$getBoundingClientRect(offsetParent);
                scale = $bc36f1aefc070984$var$getScale(offsetParent);
                offsets.x = offsetRect.x + offsetParent.clientLeft;
                offsets.y = offsetRect.y + offsetParent.clientTop;
            }
        }
        const htmlOffset = documentElement && !isOffsetParentAnElement && !isFixed ? $bc36f1aefc070984$var$getHTMLOffset(documentElement, scroll, true) : (0, (0, parcelRequire("cVo0M")).createCoords)(0);
        return {
            width: rect.width * scale.x,
            height: rect.height * scale.y,
            x: rect.x * scale.x - scroll.scrollLeft * scale.x + offsets.x + htmlOffset.x,
            y: rect.y * scale.y - scroll.scrollTop * scale.y + offsets.y + htmlOffset.y
        };
    }
    function $bc36f1aefc070984$var$getClientRects(element) {
        return Array.from(element.getClientRects());
    }
    function $bc36f1aefc070984$var$getDocumentRect(element) {
        const html = (0, (0, parcelRequire("f8LYQ")).getDocumentElement)(element);
        const scroll = (0, (0, parcelRequire("f8LYQ")).getNodeScroll)(element);
        const body = element.ownerDocument.body;
        const width = (0, (0, parcelRequire("cVo0M")).max)(html.scrollWidth, html.clientWidth, body.scrollWidth, body.clientWidth);
        const height = (0, (0, parcelRequire("cVo0M")).max)(html.scrollHeight, html.clientHeight, body.scrollHeight, body.clientHeight);
        let x = -scroll.scrollLeft + $bc36f1aefc070984$var$getWindowScrollBarX(element);
        const y = -scroll.scrollTop;
        if ((0, (0, parcelRequire("f8LYQ")).getComputedStyle)(body).direction === 'rtl') x += (0, (0, parcelRequire("cVo0M")).max)(html.clientWidth, body.clientWidth) - width;
        return {
            width: width,
            height: height,
            x: x,
            y: y
        };
    }
    function $bc36f1aefc070984$var$getViewportRect(element, strategy) {
        const win = (0, (0, parcelRequire("f8LYQ")).getWindow)(element);
        const html = (0, (0, parcelRequire("f8LYQ")).getDocumentElement)(element);
        const visualViewport = win.visualViewport;
        let width = html.clientWidth;
        let height = html.clientHeight;
        let x = 0;
        let y = 0;
        if (visualViewport) {
            width = visualViewport.width;
            height = visualViewport.height;
            const visualViewportBased = (0, (0, parcelRequire("f8LYQ")).isWebKit)();
            if (!visualViewportBased || visualViewportBased && strategy === 'fixed') {
                x = visualViewport.offsetLeft;
                y = visualViewport.offsetTop;
            }
        }
        return {
            width: width,
            height: height,
            x: x,
            y: y
        };
    }
    function $bc36f1aefc070984$var$getInnerBoundingClientRect(element, strategy) {
        const clientRect = $bc36f1aefc070984$var$getBoundingClientRect(element, true, strategy === 'fixed');
        const top = clientRect.top + element.clientTop;
        const left = clientRect.left + element.clientLeft;
        const scale = (0, (0, parcelRequire("f8LYQ")).isHTMLElement)(element) ? $bc36f1aefc070984$var$getScale(element) : (0, (0, parcelRequire("cVo0M")).createCoords)(1);
        const width = element.clientWidth * scale.x;
        const height = element.clientHeight * scale.y;
        const x = left * scale.x;
        const y = top * scale.y;
        return {
            width: width,
            height: height,
            x: x,
            y: y
        };
    }
    function $bc36f1aefc070984$var$getClientRectFromClippingAncestor(element, clippingAncestor, strategy) {
        let rect;
        if (clippingAncestor === 'viewport') rect = $bc36f1aefc070984$var$getViewportRect(element, strategy);
        else if (clippingAncestor === 'document') rect = $bc36f1aefc070984$var$getDocumentRect((0, (0, parcelRequire("f8LYQ")).getDocumentElement)(element));
        else if ((0, (0, parcelRequire("f8LYQ")).isElement)(clippingAncestor)) rect = $bc36f1aefc070984$var$getInnerBoundingClientRect(clippingAncestor, strategy);
        else {
            const visualOffsets = $bc36f1aefc070984$var$getVisualOffsets(element);
            rect = {
                x: clippingAncestor.x - visualOffsets.x,
                y: clippingAncestor.y - visualOffsets.y,
                width: clippingAncestor.width,
                height: clippingAncestor.height
            };
        }
        return (0, (0, parcelRequire("cVo0M")).rectToClientRect)(rect);
    }
    function $bc36f1aefc070984$var$hasFixedPositionAncestor(element, stopNode) {
        const parentNode = (0, (0, parcelRequire("f8LYQ")).getParentNode)(element);
        if (parentNode === stopNode || !(0, (0, parcelRequire("f8LYQ")).isElement)(parentNode) || (0, (0, parcelRequire("f8LYQ")).isLastTraversableNode)(parentNode)) return false;
        return (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(parentNode).position === 'fixed' || $bc36f1aefc070984$var$hasFixedPositionAncestor(parentNode, stopNode);
    }
    function $bc36f1aefc070984$var$getClippingElementAncestors(element, cache) {
        const cachedResult = cache.get(element);
        if (cachedResult) return cachedResult;
        let result = (0, (0, parcelRequire("f8LYQ")).getOverflowAncestors)(element, [], false).filter((el)=>(0, (0, parcelRequire("f8LYQ")).isElement)(el) && (0, (0, parcelRequire("f8LYQ")).getNodeName)(el) !== 'body');
        let currentContainingBlockComputedStyle = null;
        const elementIsFixed = (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(element).position === 'fixed';
        let currentNode = elementIsFixed ? (0, (0, parcelRequire("f8LYQ")).getParentNode)(element) : element;
        while((0, (0, parcelRequire("f8LYQ")).isElement)(currentNode) && !(0, (0, parcelRequire("f8LYQ")).isLastTraversableNode)(currentNode)){
            const computedStyle = (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(currentNode);
            const currentNodeIsContaining = (0, (0, parcelRequire("f8LYQ")).isContainingBlock)(currentNode);
            if (!currentNodeIsContaining && computedStyle.position === 'fixed') currentContainingBlockComputedStyle = null;
            const shouldDropCurrentNode = elementIsFixed ? !currentNodeIsContaining && !currentContainingBlockComputedStyle : !currentNodeIsContaining && computedStyle.position === 'static' && !!currentContainingBlockComputedStyle && [
                'absolute',
                'fixed'
            ].includes(currentContainingBlockComputedStyle.position) || (0, (0, parcelRequire("f8LYQ")).isOverflowElement)(currentNode) && !currentNodeIsContaining && $bc36f1aefc070984$var$hasFixedPositionAncestor(element, currentNode);
            if (shouldDropCurrentNode) result = result.filter((ancestor)=>ancestor !== currentNode);
            else currentContainingBlockComputedStyle = computedStyle;
            currentNode = (0, (0, parcelRequire("f8LYQ")).getParentNode)(currentNode);
        }
        cache.set(element, result);
        return result;
    }
    function $bc36f1aefc070984$var$getClippingRect(_ref) {
        let { element: element, boundary: boundary, rootBoundary: rootBoundary, strategy: strategy } = _ref;
        const elementClippingAncestors = boundary === 'clippingAncestors' ? (0, (0, parcelRequire("f8LYQ")).isTopLayer)(element) ? [] : $bc36f1aefc070984$var$getClippingElementAncestors(element, this._c) : [].concat(boundary);
        const clippingAncestors = [
            ...elementClippingAncestors,
            rootBoundary
        ];
        const firstClippingAncestor = clippingAncestors[0];
        const clippingRect = clippingAncestors.reduce((accRect, clippingAncestor)=>{
            const rect = $bc36f1aefc070984$var$getClientRectFromClippingAncestor(element, clippingAncestor, strategy);
            accRect.top = (0, (0, parcelRequire("cVo0M")).max)(rect.top, accRect.top);
            accRect.right = (0, (0, parcelRequire("cVo0M")).min)(rect.right, accRect.right);
            accRect.bottom = (0, (0, parcelRequire("cVo0M")).min)(rect.bottom, accRect.bottom);
            accRect.left = (0, (0, parcelRequire("cVo0M")).max)(rect.left, accRect.left);
            return accRect;
        }, $bc36f1aefc070984$var$getClientRectFromClippingAncestor(element, firstClippingAncestor, strategy));
        return {
            width: clippingRect.right - clippingRect.left,
            height: clippingRect.bottom - clippingRect.top,
            x: clippingRect.left,
            y: clippingRect.top
        };
    }
    function $bc36f1aefc070984$var$getDimensions(element) {
        const { width: width, height: height } = $bc36f1aefc070984$var$getCssDimensions(element);
        return {
            width: width,
            height: height
        };
    }
    function $bc36f1aefc070984$var$getRectRelativeToOffsetParent(element, offsetParent, strategy) {
        const isOffsetParentAnElement = (0, (0, parcelRequire("f8LYQ")).isHTMLElement)(offsetParent);
        const documentElement = (0, (0, parcelRequire("f8LYQ")).getDocumentElement)(offsetParent);
        const isFixed = strategy === 'fixed';
        const rect = $bc36f1aefc070984$var$getBoundingClientRect(element, true, isFixed, offsetParent);
        let scroll = {
            scrollLeft: 0,
            scrollTop: 0
        };
        const offsets = (0, (0, parcelRequire("cVo0M")).createCoords)(0);
        if (isOffsetParentAnElement || !isOffsetParentAnElement && !isFixed) {
            if ((0, (0, parcelRequire("f8LYQ")).getNodeName)(offsetParent) !== 'body' || (0, (0, parcelRequire("f8LYQ")).isOverflowElement)(documentElement)) scroll = (0, (0, parcelRequire("f8LYQ")).getNodeScroll)(offsetParent);
            if (isOffsetParentAnElement) {
                const offsetRect = $bc36f1aefc070984$var$getBoundingClientRect(offsetParent, true, isFixed, offsetParent);
                offsets.x = offsetRect.x + offsetParent.clientLeft;
                offsets.y = offsetRect.y + offsetParent.clientTop;
            } else if (documentElement) offsets.x = $bc36f1aefc070984$var$getWindowScrollBarX(documentElement);
        }
        const htmlOffset = documentElement && !isOffsetParentAnElement && !isFixed ? $bc36f1aefc070984$var$getHTMLOffset(documentElement, scroll) : (0, (0, parcelRequire("cVo0M")).createCoords)(0);
        const x = rect.left + scroll.scrollLeft - offsets.x - htmlOffset.x;
        const y = rect.top + scroll.scrollTop - offsets.y - htmlOffset.y;
        return {
            x: x,
            y: y,
            width: rect.width,
            height: rect.height
        };
    }
    function $bc36f1aefc070984$var$isStaticPositioned(element) {
        return (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(element).position === 'static';
    }
    function $bc36f1aefc070984$var$getTrueOffsetParent(element, polyfill) {
        if (!(0, (0, parcelRequire("f8LYQ")).isHTMLElement)(element) || (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(element).position === 'fixed') return null;
        if (polyfill) return polyfill(element);
        let rawOffsetParent = element.offsetParent;
        if ((0, (0, parcelRequire("f8LYQ")).getDocumentElement)(element) === rawOffsetParent) rawOffsetParent = rawOffsetParent.ownerDocument.body;
        return rawOffsetParent;
    }
    function $bc36f1aefc070984$var$getOffsetParent(element, polyfill) {
        const win = (0, (0, parcelRequire("f8LYQ")).getWindow)(element);
        if ((0, (0, parcelRequire("f8LYQ")).isTopLayer)(element)) return win;
        if (!(0, (0, parcelRequire("f8LYQ")).isHTMLElement)(element)) {
            let svgOffsetParent = (0, (0, parcelRequire("f8LYQ")).getParentNode)(element);
            while(svgOffsetParent && !(0, (0, parcelRequire("f8LYQ")).isLastTraversableNode)(svgOffsetParent)){
                if ((0, (0, parcelRequire("f8LYQ")).isElement)(svgOffsetParent) && !$bc36f1aefc070984$var$isStaticPositioned(svgOffsetParent)) return svgOffsetParent;
                svgOffsetParent = (0, (0, parcelRequire("f8LYQ")).getParentNode)(svgOffsetParent);
            }
            return win;
        }
        let offsetParent = $bc36f1aefc070984$var$getTrueOffsetParent(element, polyfill);
        while(offsetParent && (0, (0, parcelRequire("f8LYQ")).isTableElement)(offsetParent) && $bc36f1aefc070984$var$isStaticPositioned(offsetParent))offsetParent = $bc36f1aefc070984$var$getTrueOffsetParent(offsetParent, polyfill);
        if (offsetParent && (0, (0, parcelRequire("f8LYQ")).isLastTraversableNode)(offsetParent) && $bc36f1aefc070984$var$isStaticPositioned(offsetParent) && !(0, (0, parcelRequire("f8LYQ")).isContainingBlock)(offsetParent)) return win;
        return offsetParent || (0, (0, parcelRequire("f8LYQ")).getContainingBlock)(element) || win;
    }
    const $bc36f1aefc070984$var$getElementRects = async function(data) {
        const getOffsetParentFn = this.getOffsetParent || $bc36f1aefc070984$var$getOffsetParent;
        const getDimensionsFn = this.getDimensions;
        const floatingDimensions = await getDimensionsFn(data.floating);
        return {
            reference: $bc36f1aefc070984$var$getRectRelativeToOffsetParent(data.reference, await getOffsetParentFn(data.floating), data.strategy),
            floating: {
                x: 0,
                y: 0,
                width: floatingDimensions.width,
                height: floatingDimensions.height
            }
        };
    };
    function $bc36f1aefc070984$var$isRTL(element) {
        return (0, (0, parcelRequire("f8LYQ")).getComputedStyle)(element).direction === 'rtl';
    }
    const $bc36f1aefc070984$export$722a64dea1b767dc = {
        convertOffsetParentRelativeRectToViewportRelativeRect: $bc36f1aefc070984$var$convertOffsetParentRelativeRectToViewportRelativeRect,
        getDocumentElement: (0, parcelRequire("f8LYQ")).getDocumentElement,
        getClippingRect: $bc36f1aefc070984$var$getClippingRect,
        getOffsetParent: $bc36f1aefc070984$var$getOffsetParent,
        getElementRects: $bc36f1aefc070984$var$getElementRects,
        getClientRects: $bc36f1aefc070984$var$getClientRects,
        getDimensions: $bc36f1aefc070984$var$getDimensions,
        getScale: $bc36f1aefc070984$var$getScale,
        isElement: (0, parcelRequire("f8LYQ")).isElement,
        isRTL: $bc36f1aefc070984$var$isRTL
    };
    function $bc36f1aefc070984$var$observeMove(element, onMove) {
        let io = null;
        let timeoutId;
        const root = (0, (0, parcelRequire("f8LYQ")).getDocumentElement)(element);
        function cleanup() {
            var _io;
            clearTimeout(timeoutId);
            (_io = io) == null || _io.disconnect();
            io = null;
        }
        function refresh(skip, threshold) {
            if (skip === void 0) skip = false;
            if (threshold === void 0) threshold = 1;
            cleanup();
            const { left: left, top: top, width: width, height: height } = element.getBoundingClientRect();
            if (!skip) onMove();
            if (!width || !height) return;
            const insetTop = (0, (0, parcelRequire("cVo0M")).floor)(top);
            const insetRight = (0, (0, parcelRequire("cVo0M")).floor)(root.clientWidth - (left + width));
            const insetBottom = (0, (0, parcelRequire("cVo0M")).floor)(root.clientHeight - (top + height));
            const insetLeft = (0, (0, parcelRequire("cVo0M")).floor)(left);
            const rootMargin = -insetTop + "px " + -insetRight + "px " + -insetBottom + "px " + -insetLeft + "px";
            const options = {
                rootMargin: rootMargin,
                threshold: (0, (0, parcelRequire("cVo0M")).max)(0, (0, (0, parcelRequire("cVo0M")).min)(1, threshold)) || 1
            };
            let isFirstUpdate = true;
            function handleObserve(entries) {
                const ratio = entries[0].intersectionRatio;
                if (ratio !== threshold) {
                    if (!isFirstUpdate) return refresh();
                    if (!ratio) timeoutId = setTimeout(()=>{
                        refresh(false, 1e-7);
                    }, 1000);
                    else refresh(false, ratio);
                }
                isFirstUpdate = false;
            }
            try {
                io = new IntersectionObserver(handleObserve, {
                    ...options,
                    root: root.ownerDocument
                });
            } catch (e) {
                io = new IntersectionObserver(handleObserve, options);
            }
            io.observe(element);
        }
        refresh(true);
        return cleanup;
    }
    function $bc36f1aefc070984$export$deee3a793edee05f(reference, floating, update, options) {
        if (options === void 0) options = {};
        const { ancestorScroll: ancestorScroll = true, ancestorResize: ancestorResize = true, elementResize: elementResize = typeof ResizeObserver === 'function', layoutShift: layoutShift = typeof IntersectionObserver === 'function', animationFrame: animationFrame = false } = options;
        const referenceEl = $bc36f1aefc070984$var$unwrapElement(reference);
        const ancestors = ancestorScroll || ancestorResize ? [
            ...referenceEl ? (0, (0, parcelRequire("f8LYQ")).getOverflowAncestors)(referenceEl) : [],
            ...(0, (0, parcelRequire("f8LYQ")).getOverflowAncestors)(floating)
        ] : [];
        ancestors.forEach((ancestor)=>{
            ancestorScroll && ancestor.addEventListener('scroll', update, {
                passive: true
            });
            ancestorResize && ancestor.addEventListener('resize', update);
        });
        const cleanupIo = referenceEl && layoutShift ? $bc36f1aefc070984$var$observeMove(referenceEl, update) : null;
        let reobserveFrame = -1;
        let resizeObserver = null;
        if (elementResize) {
            resizeObserver = new ResizeObserver((_ref)=>{
                let [firstEntry] = _ref;
                if (firstEntry && firstEntry.target === referenceEl && resizeObserver) {
                    resizeObserver.unobserve(floating);
                    cancelAnimationFrame(reobserveFrame);
                    reobserveFrame = requestAnimationFrame(()=>{
                        var _resizeObserver;
                        (_resizeObserver = resizeObserver) == null || _resizeObserver.observe(floating);
                    });
                }
                update();
            });
            if (referenceEl && !animationFrame) resizeObserver.observe(referenceEl);
            resizeObserver.observe(floating);
        }
        let frameId;
        let prevRefRect = animationFrame ? $bc36f1aefc070984$var$getBoundingClientRect(reference) : null;
        if (animationFrame) frameLoop();
        function frameLoop() {
            const nextRefRect = $bc36f1aefc070984$var$getBoundingClientRect(reference);
            if (prevRefRect && (nextRefRect.x !== prevRefRect.x || nextRefRect.y !== prevRefRect.y || nextRefRect.width !== prevRefRect.width || nextRefRect.height !== prevRefRect.height)) update();
            prevRefRect = nextRefRect;
            frameId = requestAnimationFrame(frameLoop);
        }
        update();
        return ()=>{
            var _resizeObserver2;
            ancestors.forEach((ancestor)=>{
                ancestorScroll && ancestor.removeEventListener('scroll', update);
                ancestorResize && ancestor.removeEventListener('resize', update);
            });
            cleanupIo == null || cleanupIo();
            (_resizeObserver2 = resizeObserver) == null || _resizeObserver2.disconnect();
            resizeObserver = null;
            if (animationFrame) cancelAnimationFrame(frameId);
        };
    }
    const $bc36f1aefc070984$export$37b6bde19b108ecc = (0, (0, parcelRequire("gSfPF")).detectOverflow);
    const $bc36f1aefc070984$export$cc800923e997bb8 = (0, (0, parcelRequire("gSfPF")).offset);
    const $bc36f1aefc070984$export$91fb6ecbd551914 = (0, (0, parcelRequire("gSfPF")).autoPlacement);
    const $bc36f1aefc070984$export$fba63a578e423eb = (0, (0, parcelRequire("gSfPF")).shift);
    const $bc36f1aefc070984$export$8a83211c878a3f1f = (0, (0, parcelRequire("gSfPF")).flip);
    const $bc36f1aefc070984$export$346677f925de839c = (0, (0, parcelRequire("gSfPF")).size);
    const $bc36f1aefc070984$export$fe8985bb6374093c = (0, (0, parcelRequire("gSfPF")).hide);
    const $bc36f1aefc070984$export$f2120bbfa5450bd2 = (0, (0, parcelRequire("gSfPF")).arrow);
    const $bc36f1aefc070984$export$18c8ad048e32c7d5 = (0, (0, parcelRequire("gSfPF")).inline);
    const $bc36f1aefc070984$export$7bf07e38f3dc4b69 = (0, (0, parcelRequire("gSfPF")).limitShift);
    const $bc36f1aefc070984$export$48a53dcb22e581d0 = (reference, floating, options)=>{
        const cache = new Map();
        const mergedOptions = {
            platform: $bc36f1aefc070984$export$722a64dea1b767dc,
            ...options
        };
        const platformWithCache = {
            ...mergedOptions.platform,
            _c: cache
        };
        return (0, (0, parcelRequire("gSfPF")).computePosition)(reference, floating, {
            ...mergedOptions,
            platform: platformWithCache
        });
    };
});
parcelRegister("gSfPF", function(module, exports) {
    $parcel$export(module.exports, "computePosition", ()=>$c48df878d8118962$export$48a53dcb22e581d0);
    $parcel$export(module.exports, "detectOverflow", ()=>$c48df878d8118962$export$37b6bde19b108ecc);
    $parcel$export(module.exports, "arrow", ()=>$c48df878d8118962$export$f2120bbfa5450bd2);
    $parcel$export(module.exports, "autoPlacement", ()=>$c48df878d8118962$export$91fb6ecbd551914);
    $parcel$export(module.exports, "flip", ()=>$c48df878d8118962$export$8a83211c878a3f1f);
    $parcel$export(module.exports, "hide", ()=>$c48df878d8118962$export$fe8985bb6374093c);
    $parcel$export(module.exports, "inline", ()=>$c48df878d8118962$export$18c8ad048e32c7d5);
    $parcel$export(module.exports, "offset", ()=>$c48df878d8118962$export$cc800923e997bb8);
    $parcel$export(module.exports, "shift", ()=>$c48df878d8118962$export$fba63a578e423eb);
    $parcel$export(module.exports, "limitShift", ()=>$c48df878d8118962$export$7bf07e38f3dc4b69);
    $parcel$export(module.exports, "size", ()=>$c48df878d8118962$export$346677f925de839c);
    $parcel$export(module.exports, "rectToClientRect", ()=>(parcelRequire("cVo0M")).rectToClientRect);
    var $cVo0M;
    function $c48df878d8118962$var$computeCoordsFromPlacement(_ref, placement, rtl) {
        let { reference: reference, floating: floating } = _ref;
        const sideAxis = (0, (0, parcelRequire("cVo0M")).getSideAxis)(placement);
        const alignmentAxis = (0, (0, parcelRequire("cVo0M")).getAlignmentAxis)(placement);
        const alignLength = (0, (0, parcelRequire("cVo0M")).getAxisLength)(alignmentAxis);
        const side = (0, (0, parcelRequire("cVo0M")).getSide)(placement);
        const isVertical = sideAxis === 'y';
        const commonX = reference.x + reference.width / 2 - floating.width / 2;
        const commonY = reference.y + reference.height / 2 - floating.height / 2;
        const commonAlign = reference[alignLength] / 2 - floating[alignLength] / 2;
        let coords;
        switch(side){
            case 'top':
                coords = {
                    x: commonX,
                    y: reference.y - floating.height
                };
                break;
            case 'bottom':
                coords = {
                    x: commonX,
                    y: reference.y + reference.height
                };
                break;
            case 'right':
                coords = {
                    x: reference.x + reference.width,
                    y: commonY
                };
                break;
            case 'left':
                coords = {
                    x: reference.x - floating.width,
                    y: commonY
                };
                break;
            default:
                coords = {
                    x: reference.x,
                    y: reference.y
                };
        }
        switch((0, (0, parcelRequire("cVo0M")).getAlignment)(placement)){
            case 'start':
                coords[alignmentAxis] -= commonAlign * (rtl && isVertical ? -1 : 1);
                break;
            case 'end':
                coords[alignmentAxis] += commonAlign * (rtl && isVertical ? -1 : 1);
                break;
        }
        return coords;
    }
    const $c48df878d8118962$export$48a53dcb22e581d0 = async (reference, floating, config)=>{
        const { placement: placement = 'bottom', strategy: strategy = 'absolute', middleware: middleware = [], platform: platform } = config;
        const validMiddleware = middleware.filter(Boolean);
        const rtl = await (platform.isRTL == null ? void 0 : platform.isRTL(floating));
        let rects = await platform.getElementRects({
            reference: reference,
            floating: floating,
            strategy: strategy
        });
        let { x: x, y: y } = $c48df878d8118962$var$computeCoordsFromPlacement(rects, placement, rtl);
        let statefulPlacement = placement;
        let middlewareData = {};
        let resetCount = 0;
        for(let i = 0; i < validMiddleware.length; i++){
            const { name: name, fn: fn } = validMiddleware[i];
            const { x: nextX, y: nextY, data: data, reset: reset } = await fn({
                x: x,
                y: y,
                initialPlacement: placement,
                placement: statefulPlacement,
                strategy: strategy,
                middlewareData: middlewareData,
                rects: rects,
                platform: platform,
                elements: {
                    reference: reference,
                    floating: floating
                }
            });
            x = nextX != null ? nextX : x;
            y = nextY != null ? nextY : y;
            middlewareData = {
                ...middlewareData,
                [name]: {
                    ...middlewareData[name],
                    ...data
                }
            };
            if (reset && resetCount <= 50) {
                resetCount++;
                if (typeof reset === 'object') {
                    if (reset.placement) statefulPlacement = reset.placement;
                    if (reset.rects) rects = reset.rects === true ? await platform.getElementRects({
                        reference: reference,
                        floating: floating,
                        strategy: strategy
                    }) : reset.rects;
                    ({ x: x, y: y } = $c48df878d8118962$var$computeCoordsFromPlacement(rects, statefulPlacement, rtl));
                }
                i = -1;
            }
        }
        return {
            x: x,
            y: y,
            placement: statefulPlacement,
            strategy: strategy,
            middlewareData: middlewareData
        };
    };
    async function $c48df878d8118962$export$37b6bde19b108ecc(state, options) {
        var _await$platform$isEle;
        if (options === void 0) options = {};
        const { x: x, y: y, platform: platform, rects: rects, elements: elements, strategy: strategy } = state;
        const { boundary: boundary = 'clippingAncestors', rootBoundary: rootBoundary = 'viewport', elementContext: elementContext = 'floating', altBoundary: altBoundary = false, padding: padding = 0 } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
        const paddingObject = (0, (0, parcelRequire("cVo0M")).getPaddingObject)(padding);
        const altContext = elementContext === 'floating' ? 'reference' : 'floating';
        const element = elements[altBoundary ? altContext : elementContext];
        const clippingClientRect = (0, (0, parcelRequire("cVo0M")).rectToClientRect)(await platform.getClippingRect({
            element: ((_await$platform$isEle = await (platform.isElement == null ? void 0 : platform.isElement(element))) != null ? _await$platform$isEle : true) ? element : element.contextElement || await (platform.getDocumentElement == null ? void 0 : platform.getDocumentElement(elements.floating)),
            boundary: boundary,
            rootBoundary: rootBoundary,
            strategy: strategy
        }));
        const rect = elementContext === 'floating' ? {
            x: x,
            y: y,
            width: rects.floating.width,
            height: rects.floating.height
        } : rects.reference;
        const offsetParent = await (platform.getOffsetParent == null ? void 0 : platform.getOffsetParent(elements.floating));
        const offsetScale = await (platform.isElement == null ? void 0 : platform.isElement(offsetParent)) ? await (platform.getScale == null ? void 0 : platform.getScale(offsetParent)) || {
            x: 1,
            y: 1
        } : {
            x: 1,
            y: 1
        };
        const elementClientRect = (0, (0, parcelRequire("cVo0M")).rectToClientRect)(platform.convertOffsetParentRelativeRectToViewportRelativeRect ? await platform.convertOffsetParentRelativeRectToViewportRelativeRect({
            elements: elements,
            rect: rect,
            offsetParent: offsetParent,
            strategy: strategy
        }) : rect);
        return {
            top: (clippingClientRect.top - elementClientRect.top + paddingObject.top) / offsetScale.y,
            bottom: (elementClientRect.bottom - clippingClientRect.bottom + paddingObject.bottom) / offsetScale.y,
            left: (clippingClientRect.left - elementClientRect.left + paddingObject.left) / offsetScale.x,
            right: (elementClientRect.right - clippingClientRect.right + paddingObject.right) / offsetScale.x
        };
    }
    const $c48df878d8118962$export$f2120bbfa5450bd2 = (options)=>({
            name: 'arrow',
            options: options,
            async fn (state) {
                const { x: x, y: y, placement: placement, rects: rects, platform: platform, elements: elements, middlewareData: middlewareData } = state;
                const { element: element, padding: padding = 0 } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state) || {};
                if (element == null) return {};
                const paddingObject = (0, (0, parcelRequire("cVo0M")).getPaddingObject)(padding);
                const coords = {
                    x: x,
                    y: y
                };
                const axis = (0, (0, parcelRequire("cVo0M")).getAlignmentAxis)(placement);
                const length = (0, (0, parcelRequire("cVo0M")).getAxisLength)(axis);
                const arrowDimensions = await platform.getDimensions(element);
                const isYAxis = axis === 'y';
                const minProp = isYAxis ? 'top' : 'left';
                const maxProp = isYAxis ? 'bottom' : 'right';
                const clientProp = isYAxis ? 'clientHeight' : 'clientWidth';
                const endDiff = rects.reference[length] + rects.reference[axis] - coords[axis] - rects.floating[length];
                const startDiff = coords[axis] - rects.reference[axis];
                const arrowOffsetParent = await (platform.getOffsetParent == null ? void 0 : platform.getOffsetParent(element));
                let clientSize = arrowOffsetParent ? arrowOffsetParent[clientProp] : 0;
                if (!clientSize || !await (platform.isElement == null ? void 0 : platform.isElement(arrowOffsetParent))) clientSize = elements.floating[clientProp] || rects.floating[length];
                const centerToReference = endDiff / 2 - startDiff / 2;
                const largestPossiblePadding = clientSize / 2 - arrowDimensions[length] / 2 - 1;
                const minPadding = (0, (0, parcelRequire("cVo0M")).min)(paddingObject[minProp], largestPossiblePadding);
                const maxPadding = (0, (0, parcelRequire("cVo0M")).min)(paddingObject[maxProp], largestPossiblePadding);
                const min$1 = minPadding;
                const max = clientSize - arrowDimensions[length] - maxPadding;
                const center = clientSize / 2 - arrowDimensions[length] / 2 + centerToReference;
                const offset = (0, (0, parcelRequire("cVo0M")).clamp)(min$1, center, max);
                const shouldAddOffset = !middlewareData.arrow && (0, (0, parcelRequire("cVo0M")).getAlignment)(placement) != null && center !== offset && rects.reference[length] / 2 - (center < min$1 ? minPadding : maxPadding) - arrowDimensions[length] / 2 < 0;
                const alignmentOffset = shouldAddOffset ? center < min$1 ? center - min$1 : center - max : 0;
                return {
                    [axis]: coords[axis] + alignmentOffset,
                    data: {
                        [axis]: offset,
                        centerOffset: center - offset - alignmentOffset,
                        ...shouldAddOffset && {
                            alignmentOffset: alignmentOffset
                        }
                    },
                    reset: shouldAddOffset
                };
            }
        });
    function $c48df878d8118962$var$getPlacementList(alignment, autoAlignment, allowedPlacements) {
        const allowedPlacementsSortedByAlignment = alignment ? [
            ...allowedPlacements.filter((placement)=>(0, (0, parcelRequire("cVo0M")).getAlignment)(placement) === alignment),
            ...allowedPlacements.filter((placement)=>(0, (0, parcelRequire("cVo0M")).getAlignment)(placement) !== alignment)
        ] : allowedPlacements.filter((placement)=>(0, (0, parcelRequire("cVo0M")).getSide)(placement) === placement);
        return allowedPlacementsSortedByAlignment.filter((placement)=>{
            if (alignment) return (0, (0, parcelRequire("cVo0M")).getAlignment)(placement) === alignment || (autoAlignment ? (0, (0, parcelRequire("cVo0M")).getOppositeAlignmentPlacement)(placement) !== placement : false);
            return true;
        });
    }
    const $c48df878d8118962$export$91fb6ecbd551914 = function(options) {
        if (options === void 0) options = {};
        return {
            name: 'autoPlacement',
            options: options,
            async fn (state) {
                var _middlewareData$autoP, _middlewareData$autoP2, _placementsThatFitOnE;
                const { rects: rects, middlewareData: middlewareData, placement: placement, platform: platform, elements: elements } = state;
                const { crossAxis: crossAxis = false, alignment: alignment, allowedPlacements: allowedPlacements = (0, (0, parcelRequire("cVo0M")).placements), autoAlignment: autoAlignment = true, ...detectOverflowOptions } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
                const placements$1 = alignment !== undefined || allowedPlacements === (0, (0, parcelRequire("cVo0M")).placements) ? $c48df878d8118962$var$getPlacementList(alignment || null, autoAlignment, allowedPlacements) : allowedPlacements;
                const overflow = await $c48df878d8118962$export$37b6bde19b108ecc(state, detectOverflowOptions);
                const currentIndex = ((_middlewareData$autoP = middlewareData.autoPlacement) == null ? void 0 : _middlewareData$autoP.index) || 0;
                const currentPlacement = placements$1[currentIndex];
                if (currentPlacement == null) return {};
                const alignmentSides = (0, (0, parcelRequire("cVo0M")).getAlignmentSides)(currentPlacement, rects, await (platform.isRTL == null ? void 0 : platform.isRTL(elements.floating)));
                if (placement !== currentPlacement) return {
                    reset: {
                        placement: placements$1[0]
                    }
                };
                const currentOverflows = [
                    overflow[(0, (0, parcelRequire("cVo0M")).getSide)(currentPlacement)],
                    overflow[alignmentSides[0]],
                    overflow[alignmentSides[1]]
                ];
                const allOverflows = [
                    ...((_middlewareData$autoP2 = middlewareData.autoPlacement) == null ? void 0 : _middlewareData$autoP2.overflows) || [],
                    {
                        placement: currentPlacement,
                        overflows: currentOverflows
                    }
                ];
                const nextPlacement = placements$1[currentIndex + 1];
                if (nextPlacement) return {
                    data: {
                        index: currentIndex + 1,
                        overflows: allOverflows
                    },
                    reset: {
                        placement: nextPlacement
                    }
                };
                const placementsSortedByMostSpace = allOverflows.map((d)=>{
                    const alignment = (0, (0, parcelRequire("cVo0M")).getAlignment)(d.placement);
                    return [
                        d.placement,
                        alignment && crossAxis ? d.overflows.slice(0, 2).reduce((acc, v)=>acc + v, 0) : d.overflows[0],
                        d.overflows
                    ];
                }).sort((a, b)=>a[1] - b[1]);
                const placementsThatFitOnEachSide = placementsSortedByMostSpace.filter((d)=>d[2].slice(0, (0, (0, parcelRequire("cVo0M")).getAlignment)(d[0]) ? 2 : 3).every((v)=>v <= 0));
                const resetPlacement = ((_placementsThatFitOnE = placementsThatFitOnEachSide[0]) == null ? void 0 : _placementsThatFitOnE[0]) || placementsSortedByMostSpace[0][0];
                if (resetPlacement !== placement) return {
                    data: {
                        index: currentIndex + 1,
                        overflows: allOverflows
                    },
                    reset: {
                        placement: resetPlacement
                    }
                };
                return {};
            }
        };
    };
    const $c48df878d8118962$export$8a83211c878a3f1f = function(options) {
        if (options === void 0) options = {};
        return {
            name: 'flip',
            options: options,
            async fn (state) {
                var _middlewareData$arrow, _middlewareData$flip;
                const { placement: placement, middlewareData: middlewareData, rects: rects, initialPlacement: initialPlacement, platform: platform, elements: elements } = state;
                const { mainAxis: checkMainAxis = true, crossAxis: checkCrossAxis = true, fallbackPlacements: specifiedFallbackPlacements, fallbackStrategy: fallbackStrategy = 'bestFit', fallbackAxisSideDirection: fallbackAxisSideDirection = 'none', flipAlignment: flipAlignment = true, ...detectOverflowOptions } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
                if ((_middlewareData$arrow = middlewareData.arrow) != null && _middlewareData$arrow.alignmentOffset) return {};
                const side = (0, (0, parcelRequire("cVo0M")).getSide)(placement);
                const initialSideAxis = (0, (0, parcelRequire("cVo0M")).getSideAxis)(initialPlacement);
                const isBasePlacement = (0, (0, parcelRequire("cVo0M")).getSide)(initialPlacement) === initialPlacement;
                const rtl = await (platform.isRTL == null ? void 0 : platform.isRTL(elements.floating));
                const fallbackPlacements = specifiedFallbackPlacements || (isBasePlacement || !flipAlignment ? [
                    (0, (0, parcelRequire("cVo0M")).getOppositePlacement)(initialPlacement)
                ] : (0, (0, parcelRequire("cVo0M")).getExpandedPlacements)(initialPlacement));
                const hasFallbackAxisSideDirection = fallbackAxisSideDirection !== 'none';
                if (!specifiedFallbackPlacements && hasFallbackAxisSideDirection) fallbackPlacements.push(...(0, (0, parcelRequire("cVo0M")).getOppositeAxisPlacements)(initialPlacement, flipAlignment, fallbackAxisSideDirection, rtl));
                const placements = [
                    initialPlacement,
                    ...fallbackPlacements
                ];
                const overflow = await $c48df878d8118962$export$37b6bde19b108ecc(state, detectOverflowOptions);
                const overflows = [];
                let overflowsData = ((_middlewareData$flip = middlewareData.flip) == null ? void 0 : _middlewareData$flip.overflows) || [];
                if (checkMainAxis) overflows.push(overflow[side]);
                if (checkCrossAxis) {
                    const sides = (0, (0, parcelRequire("cVo0M")).getAlignmentSides)(placement, rects, rtl);
                    overflows.push(overflow[sides[0]], overflow[sides[1]]);
                }
                overflowsData = [
                    ...overflowsData,
                    {
                        placement: placement,
                        overflows: overflows
                    }
                ];
                if (!overflows.every((side)=>side <= 0)) {
                    var _middlewareData$flip2, _overflowsData$filter;
                    const nextIndex = (((_middlewareData$flip2 = middlewareData.flip) == null ? void 0 : _middlewareData$flip2.index) || 0) + 1;
                    const nextPlacement = placements[nextIndex];
                    if (nextPlacement) return {
                        data: {
                            index: nextIndex,
                            overflows: overflowsData
                        },
                        reset: {
                            placement: nextPlacement
                        }
                    };
                    let resetPlacement = (_overflowsData$filter = overflowsData.filter((d)=>d.overflows[0] <= 0).sort((a, b)=>a.overflows[1] - b.overflows[1])[0]) == null ? void 0 : _overflowsData$filter.placement;
                    if (!resetPlacement) switch(fallbackStrategy){
                        case 'bestFit':
                            {
                                var _overflowsData$filter2;
                                const placement = (_overflowsData$filter2 = overflowsData.filter((d)=>{
                                    if (hasFallbackAxisSideDirection) {
                                        const currentSideAxis = (0, (0, parcelRequire("cVo0M")).getSideAxis)(d.placement);
                                        return currentSideAxis === initialSideAxis || currentSideAxis === 'y';
                                    }
                                    return true;
                                }).map((d)=>[
                                        d.placement,
                                        d.overflows.filter((overflow)=>overflow > 0).reduce((acc, overflow)=>acc + overflow, 0)
                                    ]).sort((a, b)=>a[1] - b[1])[0]) == null ? void 0 : _overflowsData$filter2[0];
                                if (placement) resetPlacement = placement;
                                break;
                            }
                        case 'initialPlacement':
                            resetPlacement = initialPlacement;
                            break;
                    }
                    if (placement !== resetPlacement) return {
                        reset: {
                            placement: resetPlacement
                        }
                    };
                }
                return {};
            }
        };
    };
    function $c48df878d8118962$var$getSideOffsets(overflow, rect) {
        return {
            top: overflow.top - rect.height,
            right: overflow.right - rect.width,
            bottom: overflow.bottom - rect.height,
            left: overflow.left - rect.width
        };
    }
    function $c48df878d8118962$var$isAnySideFullyClipped(overflow) {
        return (0, (0, parcelRequire("cVo0M")).sides).some((side)=>overflow[side] >= 0);
    }
    const $c48df878d8118962$export$fe8985bb6374093c = function(options) {
        if (options === void 0) options = {};
        return {
            name: 'hide',
            options: options,
            async fn (state) {
                const { rects: rects } = state;
                const { strategy: strategy = 'referenceHidden', ...detectOverflowOptions } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
                switch(strategy){
                    case 'referenceHidden':
                        {
                            const overflow = await $c48df878d8118962$export$37b6bde19b108ecc(state, {
                                ...detectOverflowOptions,
                                elementContext: 'reference'
                            });
                            const offsets = $c48df878d8118962$var$getSideOffsets(overflow, rects.reference);
                            return {
                                data: {
                                    referenceHiddenOffsets: offsets,
                                    referenceHidden: $c48df878d8118962$var$isAnySideFullyClipped(offsets)
                                }
                            };
                        }
                    case 'escaped':
                        {
                            const overflow = await $c48df878d8118962$export$37b6bde19b108ecc(state, {
                                ...detectOverflowOptions,
                                altBoundary: true
                            });
                            const offsets = $c48df878d8118962$var$getSideOffsets(overflow, rects.floating);
                            return {
                                data: {
                                    escapedOffsets: offsets,
                                    escaped: $c48df878d8118962$var$isAnySideFullyClipped(offsets)
                                }
                            };
                        }
                    default:
                        return {};
                }
            }
        };
    };
    function $c48df878d8118962$var$getBoundingRect(rects) {
        const minX = (0, (0, parcelRequire("cVo0M")).min)(...rects.map((rect)=>rect.left));
        const minY = (0, (0, parcelRequire("cVo0M")).min)(...rects.map((rect)=>rect.top));
        const maxX = (0, (0, parcelRequire("cVo0M")).max)(...rects.map((rect)=>rect.right));
        const maxY = (0, (0, parcelRequire("cVo0M")).max)(...rects.map((rect)=>rect.bottom));
        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY
        };
    }
    function $c48df878d8118962$var$getRectsByLine(rects) {
        const sortedRects = rects.slice().sort((a, b)=>a.y - b.y);
        const groups = [];
        let prevRect = null;
        for(let i = 0; i < sortedRects.length; i++){
            const rect = sortedRects[i];
            if (!prevRect || rect.y - prevRect.y > prevRect.height / 2) groups.push([
                rect
            ]);
            else groups[groups.length - 1].push(rect);
            prevRect = rect;
        }
        return groups.map((rect)=>(0, (0, parcelRequire("cVo0M")).rectToClientRect)($c48df878d8118962$var$getBoundingRect(rect)));
    }
    const $c48df878d8118962$export$18c8ad048e32c7d5 = function(options) {
        if (options === void 0) options = {};
        return {
            name: 'inline',
            options: options,
            async fn (state) {
                const { placement: placement, elements: elements, rects: rects, platform: platform, strategy: strategy } = state;
                const { padding: padding = 2, x: x, y: y } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
                const nativeClientRects = Array.from(await (platform.getClientRects == null ? void 0 : platform.getClientRects(elements.reference)) || []);
                const clientRects = $c48df878d8118962$var$getRectsByLine(nativeClientRects);
                const fallback = (0, (0, parcelRequire("cVo0M")).rectToClientRect)($c48df878d8118962$var$getBoundingRect(nativeClientRects));
                const paddingObject = (0, (0, parcelRequire("cVo0M")).getPaddingObject)(padding);
                function getBoundingClientRect() {
                    if (clientRects.length === 2 && clientRects[0].left > clientRects[1].right && x != null && y != null) return clientRects.find((rect)=>x > rect.left - paddingObject.left && x < rect.right + paddingObject.right && y > rect.top - paddingObject.top && y < rect.bottom + paddingObject.bottom) || fallback;
                    if (clientRects.length >= 2) {
                        if ((0, (0, parcelRequire("cVo0M")).getSideAxis)(placement) === 'y') {
                            const firstRect = clientRects[0];
                            const lastRect = clientRects[clientRects.length - 1];
                            const isTop = (0, (0, parcelRequire("cVo0M")).getSide)(placement) === 'top';
                            const top = firstRect.top;
                            const bottom = lastRect.bottom;
                            const left = isTop ? firstRect.left : lastRect.left;
                            const right = isTop ? firstRect.right : lastRect.right;
                            const width = right - left;
                            const height = bottom - top;
                            return {
                                top: top,
                                bottom: bottom,
                                left: left,
                                right: right,
                                width: width,
                                height: height,
                                x: left,
                                y: top
                            };
                        }
                        const isLeftSide = (0, (0, parcelRequire("cVo0M")).getSide)(placement) === 'left';
                        const maxRight = (0, (0, parcelRequire("cVo0M")).max)(...clientRects.map((rect)=>rect.right));
                        const minLeft = (0, (0, parcelRequire("cVo0M")).min)(...clientRects.map((rect)=>rect.left));
                        const measureRects = clientRects.filter((rect)=>isLeftSide ? rect.left === minLeft : rect.right === maxRight);
                        const top = measureRects[0].top;
                        const bottom = measureRects[measureRects.length - 1].bottom;
                        const left = minLeft;
                        const right = maxRight;
                        const width = right - left;
                        const height = bottom - top;
                        return {
                            top: top,
                            bottom: bottom,
                            left: left,
                            right: right,
                            width: width,
                            height: height,
                            x: left,
                            y: top
                        };
                    }
                    return fallback;
                }
                const resetRects = await platform.getElementRects({
                    reference: {
                        getBoundingClientRect: getBoundingClientRect
                    },
                    floating: elements.floating,
                    strategy: strategy
                });
                if (rects.reference.x !== resetRects.reference.x || rects.reference.y !== resetRects.reference.y || rects.reference.width !== resetRects.reference.width || rects.reference.height !== resetRects.reference.height) return {
                    reset: {
                        rects: resetRects
                    }
                };
                return {};
            }
        };
    };
    async function $c48df878d8118962$var$convertValueToCoords(state, options) {
        const { placement: placement, platform: platform, elements: elements } = state;
        const rtl = await (platform.isRTL == null ? void 0 : platform.isRTL(elements.floating));
        const side = (0, (0, parcelRequire("cVo0M")).getSide)(placement);
        const alignment = (0, (0, parcelRequire("cVo0M")).getAlignment)(placement);
        const isVertical = (0, (0, parcelRequire("cVo0M")).getSideAxis)(placement) === 'y';
        const mainAxisMulti = [
            'left',
            'top'
        ].includes(side) ? -1 : 1;
        const crossAxisMulti = rtl && isVertical ? -1 : 1;
        const rawValue = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
        let { mainAxis: mainAxis, crossAxis: crossAxis, alignmentAxis: alignmentAxis } = typeof rawValue === 'number' ? {
            mainAxis: rawValue,
            crossAxis: 0,
            alignmentAxis: null
        } : {
            mainAxis: rawValue.mainAxis || 0,
            crossAxis: rawValue.crossAxis || 0,
            alignmentAxis: rawValue.alignmentAxis
        };
        if (alignment && typeof alignmentAxis === 'number') crossAxis = alignment === 'end' ? alignmentAxis * -1 : alignmentAxis;
        return isVertical ? {
            x: crossAxis * crossAxisMulti,
            y: mainAxis * mainAxisMulti
        } : {
            x: mainAxis * mainAxisMulti,
            y: crossAxis * crossAxisMulti
        };
    }
    const $c48df878d8118962$export$cc800923e997bb8 = function(options) {
        if (options === void 0) options = 0;
        return {
            name: 'offset',
            options: options,
            async fn (state) {
                var _middlewareData$offse, _middlewareData$arrow;
                const { x: x, y: y, placement: placement, middlewareData: middlewareData } = state;
                const diffCoords = await $c48df878d8118962$var$convertValueToCoords(state, options);
                if (placement === ((_middlewareData$offse = middlewareData.offset) == null ? void 0 : _middlewareData$offse.placement) && (_middlewareData$arrow = middlewareData.arrow) != null && _middlewareData$arrow.alignmentOffset) return {};
                return {
                    x: x + diffCoords.x,
                    y: y + diffCoords.y,
                    data: {
                        ...diffCoords,
                        placement: placement
                    }
                };
            }
        };
    };
    const $c48df878d8118962$export$fba63a578e423eb = function(options) {
        if (options === void 0) options = {};
        return {
            name: 'shift',
            options: options,
            async fn (state) {
                const { x: x, y: y, placement: placement } = state;
                const { mainAxis: checkMainAxis = true, crossAxis: checkCrossAxis = false, limiter: limiter = {
                    fn: (_ref)=>{
                        let { x: x, y: y } = _ref;
                        return {
                            x: x,
                            y: y
                        };
                    }
                }, ...detectOverflowOptions } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
                const coords = {
                    x: x,
                    y: y
                };
                const overflow = await $c48df878d8118962$export$37b6bde19b108ecc(state, detectOverflowOptions);
                const crossAxis = (0, (0, parcelRequire("cVo0M")).getSideAxis)((0, (0, parcelRequire("cVo0M")).getSide)(placement));
                const mainAxis = (0, (0, parcelRequire("cVo0M")).getOppositeAxis)(crossAxis);
                let mainAxisCoord = coords[mainAxis];
                let crossAxisCoord = coords[crossAxis];
                if (checkMainAxis) {
                    const minSide = mainAxis === 'y' ? 'top' : 'left';
                    const maxSide = mainAxis === 'y' ? 'bottom' : 'right';
                    const min = mainAxisCoord + overflow[minSide];
                    const max = mainAxisCoord - overflow[maxSide];
                    mainAxisCoord = (0, (0, parcelRequire("cVo0M")).clamp)(min, mainAxisCoord, max);
                }
                if (checkCrossAxis) {
                    const minSide = crossAxis === 'y' ? 'top' : 'left';
                    const maxSide = crossAxis === 'y' ? 'bottom' : 'right';
                    const min = crossAxisCoord + overflow[minSide];
                    const max = crossAxisCoord - overflow[maxSide];
                    crossAxisCoord = (0, (0, parcelRequire("cVo0M")).clamp)(min, crossAxisCoord, max);
                }
                const limitedCoords = limiter.fn({
                    ...state,
                    [mainAxis]: mainAxisCoord,
                    [crossAxis]: crossAxisCoord
                });
                return {
                    ...limitedCoords,
                    data: {
                        x: limitedCoords.x - x,
                        y: limitedCoords.y - y,
                        enabled: {
                            [mainAxis]: checkMainAxis,
                            [crossAxis]: checkCrossAxis
                        }
                    }
                };
            }
        };
    };
    const $c48df878d8118962$export$7bf07e38f3dc4b69 = function(options) {
        if (options === void 0) options = {};
        return {
            options: options,
            fn (state) {
                const { x: x, y: y, placement: placement, rects: rects, middlewareData: middlewareData } = state;
                const { offset: offset = 0, mainAxis: checkMainAxis = true, crossAxis: checkCrossAxis = true } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
                const coords = {
                    x: x,
                    y: y
                };
                const crossAxis = (0, (0, parcelRequire("cVo0M")).getSideAxis)(placement);
                const mainAxis = (0, (0, parcelRequire("cVo0M")).getOppositeAxis)(crossAxis);
                let mainAxisCoord = coords[mainAxis];
                let crossAxisCoord = coords[crossAxis];
                const rawOffset = (0, (0, parcelRequire("cVo0M")).evaluate)(offset, state);
                const computedOffset = typeof rawOffset === 'number' ? {
                    mainAxis: rawOffset,
                    crossAxis: 0
                } : {
                    mainAxis: 0,
                    crossAxis: 0,
                    ...rawOffset
                };
                if (checkMainAxis) {
                    const len = mainAxis === 'y' ? 'height' : 'width';
                    const limitMin = rects.reference[mainAxis] - rects.floating[len] + computedOffset.mainAxis;
                    const limitMax = rects.reference[mainAxis] + rects.reference[len] - computedOffset.mainAxis;
                    if (mainAxisCoord < limitMin) mainAxisCoord = limitMin;
                    else if (mainAxisCoord > limitMax) mainAxisCoord = limitMax;
                }
                if (checkCrossAxis) {
                    var _middlewareData$offse, _middlewareData$offse2;
                    const len = mainAxis === 'y' ? 'width' : 'height';
                    const isOriginSide = [
                        'top',
                        'left'
                    ].includes((0, (0, parcelRequire("cVo0M")).getSide)(placement));
                    const limitMin = rects.reference[crossAxis] - rects.floating[len] + (isOriginSide ? ((_middlewareData$offse = middlewareData.offset) == null ? void 0 : _middlewareData$offse[crossAxis]) || 0 : 0) + (isOriginSide ? 0 : computedOffset.crossAxis);
                    const limitMax = rects.reference[crossAxis] + rects.reference[len] + (isOriginSide ? 0 : ((_middlewareData$offse2 = middlewareData.offset) == null ? void 0 : _middlewareData$offse2[crossAxis]) || 0) - (isOriginSide ? computedOffset.crossAxis : 0);
                    if (crossAxisCoord < limitMin) crossAxisCoord = limitMin;
                    else if (crossAxisCoord > limitMax) crossAxisCoord = limitMax;
                }
                return {
                    [mainAxis]: mainAxisCoord,
                    [crossAxis]: crossAxisCoord
                };
            }
        };
    };
    const $c48df878d8118962$export$346677f925de839c = function(options) {
        if (options === void 0) options = {};
        return {
            name: 'size',
            options: options,
            async fn (state) {
                var _state$middlewareData, _state$middlewareData2;
                const { placement: placement, rects: rects, platform: platform, elements: elements } = state;
                const { apply: apply = ()=>{}, ...detectOverflowOptions } = (0, (0, parcelRequire("cVo0M")).evaluate)(options, state);
                const overflow = await $c48df878d8118962$export$37b6bde19b108ecc(state, detectOverflowOptions);
                const side = (0, (0, parcelRequire("cVo0M")).getSide)(placement);
                const alignment = (0, (0, parcelRequire("cVo0M")).getAlignment)(placement);
                const isYAxis = (0, (0, parcelRequire("cVo0M")).getSideAxis)(placement) === 'y';
                const { width: width, height: height } = rects.floating;
                let heightSide;
                let widthSide;
                if (side === 'top' || side === 'bottom') {
                    heightSide = side;
                    widthSide = alignment === (await (platform.isRTL == null ? void 0 : platform.isRTL(elements.floating)) ? 'start' : 'end') ? 'left' : 'right';
                } else {
                    widthSide = side;
                    heightSide = alignment === 'end' ? 'top' : 'bottom';
                }
                const maximumClippingHeight = height - overflow.top - overflow.bottom;
                const maximumClippingWidth = width - overflow.left - overflow.right;
                const overflowAvailableHeight = (0, (0, parcelRequire("cVo0M")).min)(height - overflow[heightSide], maximumClippingHeight);
                const overflowAvailableWidth = (0, (0, parcelRequire("cVo0M")).min)(width - overflow[widthSide], maximumClippingWidth);
                const noShift = !state.middlewareData.shift;
                let availableHeight = overflowAvailableHeight;
                let availableWidth = overflowAvailableWidth;
                if ((_state$middlewareData = state.middlewareData.shift) != null && _state$middlewareData.enabled.x) availableWidth = maximumClippingWidth;
                if ((_state$middlewareData2 = state.middlewareData.shift) != null && _state$middlewareData2.enabled.y) availableHeight = maximumClippingHeight;
                if (noShift && !alignment) {
                    const xMin = (0, (0, parcelRequire("cVo0M")).max)(overflow.left, 0);
                    const xMax = (0, (0, parcelRequire("cVo0M")).max)(overflow.right, 0);
                    const yMin = (0, (0, parcelRequire("cVo0M")).max)(overflow.top, 0);
                    const yMax = (0, (0, parcelRequire("cVo0M")).max)(overflow.bottom, 0);
                    if (isYAxis) availableWidth = width - 2 * (xMin !== 0 || xMax !== 0 ? xMin + xMax : (0, (0, parcelRequire("cVo0M")).max)(overflow.left, overflow.right));
                    else availableHeight = height - 2 * (yMin !== 0 || yMax !== 0 ? yMin + yMax : (0, (0, parcelRequire("cVo0M")).max)(overflow.top, overflow.bottom));
                }
                await apply({
                    ...state,
                    availableWidth: availableWidth,
                    availableHeight: availableHeight
                });
                const nextDimensions = await platform.getDimensions(elements.floating);
                if (width !== nextDimensions.width || height !== nextDimensions.height) return {
                    reset: {
                        rects: true
                    }
                };
                return {};
            }
        };
    };
});
parcelRegister("cVo0M", function(module, exports) {
    $parcel$export(module.exports, "sides", ()=>$968d6f1d7e429938$export$832b6fa47fa053d2);
    $parcel$export(module.exports, "placements", ()=>$968d6f1d7e429938$export$803cd8101b6c182b);
    $parcel$export(module.exports, "min", ()=>$968d6f1d7e429938$export$96ec731ed4dcb222);
    $parcel$export(module.exports, "max", ()=>$968d6f1d7e429938$export$8960430cfd85939f);
    $parcel$export(module.exports, "round", ()=>$968d6f1d7e429938$export$2077e0241d6afd3c);
    $parcel$export(module.exports, "floor", ()=>$968d6f1d7e429938$export$a3fe094919f356fd);
    $parcel$export(module.exports, "createCoords", ()=>$968d6f1d7e429938$export$a397704b5e280835);
    $parcel$export(module.exports, "clamp", ()=>$968d6f1d7e429938$export$7d15b64cf5a3a4c4);
    $parcel$export(module.exports, "evaluate", ()=>$968d6f1d7e429938$export$fef61f332f2c0afc);
    $parcel$export(module.exports, "getSide", ()=>$968d6f1d7e429938$export$3c915306cb7fc97a);
    $parcel$export(module.exports, "getAlignment", ()=>$968d6f1d7e429938$export$f0989780f32bfcda);
    $parcel$export(module.exports, "getOppositeAxis", ()=>$968d6f1d7e429938$export$8a000a963141ac32);
    $parcel$export(module.exports, "getAxisLength", ()=>$968d6f1d7e429938$export$320bd9d53520c68a);
    $parcel$export(module.exports, "getSideAxis", ()=>$968d6f1d7e429938$export$f28e8f882010718a);
    $parcel$export(module.exports, "getAlignmentAxis", ()=>$968d6f1d7e429938$export$2b57e0052a6d6d4c);
    $parcel$export(module.exports, "getAlignmentSides", ()=>$968d6f1d7e429938$export$be42861551f17911);
    $parcel$export(module.exports, "getOppositePlacement", ()=>$968d6f1d7e429938$export$9c5e28bad23365fa);
    $parcel$export(module.exports, "getExpandedPlacements", ()=>$968d6f1d7e429938$export$496432a3699e50b1);
    $parcel$export(module.exports, "getOppositeAlignmentPlacement", ()=>$968d6f1d7e429938$export$2b182c584985588b);
    $parcel$export(module.exports, "getOppositeAxisPlacements", ()=>$968d6f1d7e429938$export$a9b6fb18fd92c3ec);
    $parcel$export(module.exports, "getPaddingObject", ()=>$968d6f1d7e429938$export$598c291c29bc2e71);
    $parcel$export(module.exports, "rectToClientRect", ()=>$968d6f1d7e429938$export$ee05aea0aeecbad4);
    const $968d6f1d7e429938$export$832b6fa47fa053d2 = [
        'top',
        'right',
        'bottom',
        'left'
    ];
    const $968d6f1d7e429938$export$27b07d8a310ed8b5 = [
        'start',
        'end'
    ];
    const $968d6f1d7e429938$export$803cd8101b6c182b = $968d6f1d7e429938$export$832b6fa47fa053d2.reduce((acc, side)=>acc.concat(side, side + "-" + $968d6f1d7e429938$export$27b07d8a310ed8b5[0], side + "-" + $968d6f1d7e429938$export$27b07d8a310ed8b5[1]), []);
    const $968d6f1d7e429938$export$96ec731ed4dcb222 = Math.min;
    const $968d6f1d7e429938$export$8960430cfd85939f = Math.max;
    const $968d6f1d7e429938$export$2077e0241d6afd3c = Math.round;
    const $968d6f1d7e429938$export$a3fe094919f356fd = Math.floor;
    const $968d6f1d7e429938$export$a397704b5e280835 = (v)=>({
            x: v,
            y: v
        });
    const $968d6f1d7e429938$var$oppositeSideMap = {
        left: 'right',
        right: 'left',
        bottom: 'top',
        top: 'bottom'
    };
    const $968d6f1d7e429938$var$oppositeAlignmentMap = {
        start: 'end',
        end: 'start'
    };
    function $968d6f1d7e429938$export$7d15b64cf5a3a4c4(start, value, end) {
        return $968d6f1d7e429938$export$8960430cfd85939f(start, $968d6f1d7e429938$export$96ec731ed4dcb222(value, end));
    }
    function $968d6f1d7e429938$export$fef61f332f2c0afc(value, param) {
        return typeof value === 'function' ? value(param) : value;
    }
    function $968d6f1d7e429938$export$3c915306cb7fc97a(placement) {
        return placement.split('-')[0];
    }
    function $968d6f1d7e429938$export$f0989780f32bfcda(placement) {
        return placement.split('-')[1];
    }
    function $968d6f1d7e429938$export$8a000a963141ac32(axis) {
        return axis === 'x' ? 'y' : 'x';
    }
    function $968d6f1d7e429938$export$320bd9d53520c68a(axis) {
        return axis === 'y' ? 'height' : 'width';
    }
    function $968d6f1d7e429938$export$f28e8f882010718a(placement) {
        return [
            'top',
            'bottom'
        ].includes($968d6f1d7e429938$export$3c915306cb7fc97a(placement)) ? 'y' : 'x';
    }
    function $968d6f1d7e429938$export$2b57e0052a6d6d4c(placement) {
        return $968d6f1d7e429938$export$8a000a963141ac32($968d6f1d7e429938$export$f28e8f882010718a(placement));
    }
    function $968d6f1d7e429938$export$be42861551f17911(placement, rects, rtl) {
        if (rtl === void 0) rtl = false;
        const alignment = $968d6f1d7e429938$export$f0989780f32bfcda(placement);
        const alignmentAxis = $968d6f1d7e429938$export$2b57e0052a6d6d4c(placement);
        const length = $968d6f1d7e429938$export$320bd9d53520c68a(alignmentAxis);
        let mainAlignmentSide = alignmentAxis === 'x' ? alignment === (rtl ? 'end' : 'start') ? 'right' : 'left' : alignment === 'start' ? 'bottom' : 'top';
        if (rects.reference[length] > rects.floating[length]) mainAlignmentSide = $968d6f1d7e429938$export$9c5e28bad23365fa(mainAlignmentSide);
        return [
            mainAlignmentSide,
            $968d6f1d7e429938$export$9c5e28bad23365fa(mainAlignmentSide)
        ];
    }
    function $968d6f1d7e429938$export$496432a3699e50b1(placement) {
        const oppositePlacement = $968d6f1d7e429938$export$9c5e28bad23365fa(placement);
        return [
            $968d6f1d7e429938$export$2b182c584985588b(placement),
            oppositePlacement,
            $968d6f1d7e429938$export$2b182c584985588b(oppositePlacement)
        ];
    }
    function $968d6f1d7e429938$export$2b182c584985588b(placement) {
        return placement.replace(/start|end/g, (alignment)=>$968d6f1d7e429938$var$oppositeAlignmentMap[alignment]);
    }
    function $968d6f1d7e429938$var$getSideList(side, isStart, rtl) {
        const lr = [
            'left',
            'right'
        ];
        const rl = [
            'right',
            'left'
        ];
        const tb = [
            'top',
            'bottom'
        ];
        const bt = [
            'bottom',
            'top'
        ];
        switch(side){
            case 'top':
            case 'bottom':
                if (rtl) return isStart ? rl : lr;
                return isStart ? lr : rl;
            case 'left':
            case 'right':
                return isStart ? tb : bt;
            default:
                return [];
        }
    }
    function $968d6f1d7e429938$export$a9b6fb18fd92c3ec(placement, flipAlignment, direction, rtl) {
        const alignment = $968d6f1d7e429938$export$f0989780f32bfcda(placement);
        let list = $968d6f1d7e429938$var$getSideList($968d6f1d7e429938$export$3c915306cb7fc97a(placement), direction === 'start', rtl);
        if (alignment) {
            list = list.map((side)=>side + "-" + alignment);
            if (flipAlignment) list = list.concat(list.map($968d6f1d7e429938$export$2b182c584985588b));
        }
        return list;
    }
    function $968d6f1d7e429938$export$9c5e28bad23365fa(placement) {
        return placement.replace(/left|right|bottom|top/g, (side)=>$968d6f1d7e429938$var$oppositeSideMap[side]);
    }
    function $968d6f1d7e429938$export$1c66dbf1a9e34223(padding) {
        return {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            ...padding
        };
    }
    function $968d6f1d7e429938$export$598c291c29bc2e71(padding) {
        return typeof padding !== 'number' ? $968d6f1d7e429938$export$1c66dbf1a9e34223(padding) : {
            top: padding,
            right: padding,
            bottom: padding,
            left: padding
        };
    }
    function $968d6f1d7e429938$export$ee05aea0aeecbad4(rect) {
        const { x: x, y: y, width: width, height: height } = rect;
        return {
            width: width,
            height: height,
            top: y,
            left: x,
            right: x + width,
            bottom: y + height,
            x: x,
            y: y
        };
    }
});
parcelRegister("f8LYQ", function(module, exports) {
    $parcel$export(module.exports, "getNodeName", ()=>$b05cd24baa7455b6$export$651544f548703224);
    $parcel$export(module.exports, "getWindow", ()=>$b05cd24baa7455b6$export$38b2d434cce3ea22);
    $parcel$export(module.exports, "getDocumentElement", ()=>$b05cd24baa7455b6$export$e495491855dc5418);
    $parcel$export(module.exports, "isElement", ()=>$b05cd24baa7455b6$export$45a5e7f76e0caa8d);
    $parcel$export(module.exports, "isHTMLElement", ()=>$b05cd24baa7455b6$export$1b3bfaa9684536aa);
    $parcel$export(module.exports, "isOverflowElement", ()=>$b05cd24baa7455b6$export$989e911fa9af580a);
    $parcel$export(module.exports, "getComputedStyle", ()=>$b05cd24baa7455b6$export$3735103072e4a80);
    $parcel$export(module.exports, "isTableElement", ()=>$b05cd24baa7455b6$export$71535ffaa902797f);
    $parcel$export(module.exports, "isTopLayer", ()=>$b05cd24baa7455b6$export$89e651af02346cd4);
    $parcel$export(module.exports, "isContainingBlock", ()=>$b05cd24baa7455b6$export$d6c4e8150c35fed1);
    $parcel$export(module.exports, "isWebKit", ()=>$b05cd24baa7455b6$export$78551043582a6a98);
    $parcel$export(module.exports, "getContainingBlock", ()=>$b05cd24baa7455b6$export$940d8225183e1404);
    $parcel$export(module.exports, "getParentNode", ()=>$b05cd24baa7455b6$export$4e12058fc4d51d56);
    $parcel$export(module.exports, "isLastTraversableNode", ()=>$b05cd24baa7455b6$export$d1162fb0b6d4cd51);
    $parcel$export(module.exports, "getNodeScroll", ()=>$b05cd24baa7455b6$export$dc8fc79fa2800137);
    $parcel$export(module.exports, "getOverflowAncestors", ()=>$b05cd24baa7455b6$export$3b57c1601291186b);
    $parcel$export(module.exports, "getFrameElement", ()=>$b05cd24baa7455b6$export$7bf1f8740e3e34af);
    function $b05cd24baa7455b6$var$hasWindow() {
        return typeof window !== 'undefined';
    }
    function $b05cd24baa7455b6$export$651544f548703224(node) {
        if ($b05cd24baa7455b6$export$8ee0fc9ee280b4ee(node)) return (node.nodeName || '').toLowerCase();
        return '#document';
    }
    function $b05cd24baa7455b6$export$38b2d434cce3ea22(node) {
        var _node$ownerDocument;
        return (node == null || (_node$ownerDocument = node.ownerDocument) == null ? void 0 : _node$ownerDocument.defaultView) || window;
    }
    function $b05cd24baa7455b6$export$e495491855dc5418(node) {
        var _ref;
        return (_ref = ($b05cd24baa7455b6$export$8ee0fc9ee280b4ee(node) ? node.ownerDocument : node.document) || window.document) == null ? void 0 : _ref.documentElement;
    }
    function $b05cd24baa7455b6$export$8ee0fc9ee280b4ee(value) {
        if (!$b05cd24baa7455b6$var$hasWindow()) return false;
        return value instanceof Node || value instanceof $b05cd24baa7455b6$export$38b2d434cce3ea22(value).Node;
    }
    function $b05cd24baa7455b6$export$45a5e7f76e0caa8d(value) {
        if (!$b05cd24baa7455b6$var$hasWindow()) return false;
        return value instanceof Element || value instanceof $b05cd24baa7455b6$export$38b2d434cce3ea22(value).Element;
    }
    function $b05cd24baa7455b6$export$1b3bfaa9684536aa(value) {
        if (!$b05cd24baa7455b6$var$hasWindow()) return false;
        return value instanceof HTMLElement || value instanceof $b05cd24baa7455b6$export$38b2d434cce3ea22(value).HTMLElement;
    }
    function $b05cd24baa7455b6$export$af51f0f06c0f328a(value) {
        if (!$b05cd24baa7455b6$var$hasWindow() || typeof ShadowRoot === 'undefined') return false;
        return value instanceof ShadowRoot || value instanceof $b05cd24baa7455b6$export$38b2d434cce3ea22(value).ShadowRoot;
    }
    function $b05cd24baa7455b6$export$989e911fa9af580a(element) {
        const { overflow: overflow, overflowX: overflowX, overflowY: overflowY, display: display } = $b05cd24baa7455b6$export$3735103072e4a80(element);
        return /auto|scroll|overlay|hidden|clip/.test(overflow + overflowY + overflowX) && ![
            'inline',
            'contents'
        ].includes(display);
    }
    function $b05cd24baa7455b6$export$71535ffaa902797f(element) {
        return [
            'table',
            'td',
            'th'
        ].includes($b05cd24baa7455b6$export$651544f548703224(element));
    }
    function $b05cd24baa7455b6$export$89e651af02346cd4(element) {
        return [
            ':popover-open',
            ':modal'
        ].some((selector)=>{
            try {
                return element.matches(selector);
            } catch (e) {
                return false;
            }
        });
    }
    function $b05cd24baa7455b6$export$d6c4e8150c35fed1(elementOrCss) {
        const webkit = $b05cd24baa7455b6$export$78551043582a6a98();
        const css = $b05cd24baa7455b6$export$45a5e7f76e0caa8d(elementOrCss) ? $b05cd24baa7455b6$export$3735103072e4a80(elementOrCss) : elementOrCss;
        return css.transform !== 'none' || css.perspective !== 'none' || (css.containerType ? css.containerType !== 'normal' : false) || !webkit && (css.backdropFilter ? css.backdropFilter !== 'none' : false) || !webkit && (css.filter ? css.filter !== 'none' : false) || [
            'transform',
            'perspective',
            'filter'
        ].some((value)=>(css.willChange || '').includes(value)) || [
            'paint',
            'layout',
            'strict',
            'content'
        ].some((value)=>(css.contain || '').includes(value));
    }
    function $b05cd24baa7455b6$export$940d8225183e1404(element) {
        let currentNode = $b05cd24baa7455b6$export$4e12058fc4d51d56(element);
        while($b05cd24baa7455b6$export$1b3bfaa9684536aa(currentNode) && !$b05cd24baa7455b6$export$d1162fb0b6d4cd51(currentNode)){
            if ($b05cd24baa7455b6$export$d6c4e8150c35fed1(currentNode)) return currentNode;
            else if ($b05cd24baa7455b6$export$89e651af02346cd4(currentNode)) return null;
            currentNode = $b05cd24baa7455b6$export$4e12058fc4d51d56(currentNode);
        }
        return null;
    }
    function $b05cd24baa7455b6$export$78551043582a6a98() {
        if (typeof CSS === 'undefined' || !CSS.supports) return false;
        return CSS.supports('-webkit-backdrop-filter', 'none');
    }
    function $b05cd24baa7455b6$export$d1162fb0b6d4cd51(node) {
        return [
            'html',
            'body',
            '#document'
        ].includes($b05cd24baa7455b6$export$651544f548703224(node));
    }
    function $b05cd24baa7455b6$export$3735103072e4a80(element) {
        return $b05cd24baa7455b6$export$38b2d434cce3ea22(element).getComputedStyle(element);
    }
    function $b05cd24baa7455b6$export$dc8fc79fa2800137(element) {
        if ($b05cd24baa7455b6$export$45a5e7f76e0caa8d(element)) return {
            scrollLeft: element.scrollLeft,
            scrollTop: element.scrollTop
        };
        return {
            scrollLeft: element.scrollX,
            scrollTop: element.scrollY
        };
    }
    function $b05cd24baa7455b6$export$4e12058fc4d51d56(node) {
        if ($b05cd24baa7455b6$export$651544f548703224(node) === 'html') return node;
        const result = node.assignedSlot || node.parentNode || $b05cd24baa7455b6$export$af51f0f06c0f328a(node) && node.host || $b05cd24baa7455b6$export$e495491855dc5418(node);
        return $b05cd24baa7455b6$export$af51f0f06c0f328a(result) ? result.host : result;
    }
    function $b05cd24baa7455b6$export$7cd01a57c8ca906c(node) {
        const parentNode = $b05cd24baa7455b6$export$4e12058fc4d51d56(node);
        if ($b05cd24baa7455b6$export$d1162fb0b6d4cd51(parentNode)) return node.ownerDocument ? node.ownerDocument.body : node.body;
        if ($b05cd24baa7455b6$export$1b3bfaa9684536aa(parentNode) && $b05cd24baa7455b6$export$989e911fa9af580a(parentNode)) return parentNode;
        return $b05cd24baa7455b6$export$7cd01a57c8ca906c(parentNode);
    }
    function $b05cd24baa7455b6$export$3b57c1601291186b(node, list, traverseIframes) {
        var _node$ownerDocument2;
        if (list === void 0) list = [];
        if (traverseIframes === void 0) traverseIframes = true;
        const scrollableAncestor = $b05cd24baa7455b6$export$7cd01a57c8ca906c(node);
        const isBody = scrollableAncestor === ((_node$ownerDocument2 = node.ownerDocument) == null ? void 0 : _node$ownerDocument2.body);
        const win = $b05cd24baa7455b6$export$38b2d434cce3ea22(scrollableAncestor);
        if (isBody) {
            const frameElement = $b05cd24baa7455b6$export$7bf1f8740e3e34af(win);
            return list.concat(win, win.visualViewport || [], $b05cd24baa7455b6$export$989e911fa9af580a(scrollableAncestor) ? scrollableAncestor : [], frameElement && traverseIframes ? $b05cd24baa7455b6$export$3b57c1601291186b(frameElement) : []);
        }
        return list.concat(scrollableAncestor, $b05cd24baa7455b6$export$3b57c1601291186b(scrollableAncestor, [], traverseIframes));
    }
    function $b05cd24baa7455b6$export$7bf1f8740e3e34af(win) {
        return win.parent && Object.getPrototypeOf(win.parent) ? win.frameElement : null;
    }
});
parcelRegister("hrqx3", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$cb2989fd9beaabfa$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $cb2989fd9beaabfa$var$index = (0, $5uXOq.useLayoutEffect);
    var $cb2989fd9beaabfa$export$2e2bcd8739ae039 = $cb2989fd9beaabfa$var$index;
});
parcelRegister("hqcPo", function(module, exports) {
    $parcel$export(module.exports, "multiValueCSS", ()=>$20933ad7b05a0c37$export$23c213f7717186af);
    $parcel$export(module.exports, "multiValueLabelCSS", ()=>$20933ad7b05a0c37$export$f40612612e0af964);
    $parcel$export(module.exports, "multiValueRemoveCSS", ()=>$20933ad7b05a0c37$export$283467f6c4ac142b);
    $parcel$export(module.exports, "MultiValueContainer", ()=>$20933ad7b05a0c37$export$cf9cf2a30a59265c);
    $parcel$export(module.exports, "MultiValueLabel", ()=>$20933ad7b05a0c37$export$544aeea2a5ce08ee);
    $parcel$export(module.exports, "MultiValueRemove", ()=>$20933ad7b05a0c37$export$8e1fd1a40e7a2f4a);
    $parcel$export(module.exports, "default", ()=>$20933ad7b05a0c37$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    var $95iuW;
    const $20933ad7b05a0c37$export$23c213f7717186af = ({ theme: { spacing: spacing, borderRadius: borderRadius, colors: colors } }, unstyled)=>({
            label: 'multiValue',
            display: 'flex',
            minWidth: 0,
            ...unstyled ? {} : {
                backgroundColor: colors.neutral10,
                borderRadius: borderRadius / 2,
                margin: spacing.baseUnit / 2
            }
        });
    const $20933ad7b05a0c37$export$f40612612e0af964 = ({ theme: { borderRadius: borderRadius, colors: colors }, cropWithEllipsis: cropWithEllipsis }, unstyled)=>({
            overflow: 'hidden',
            textOverflow: cropWithEllipsis || cropWithEllipsis === undefined ? 'ellipsis' : undefined,
            whiteSpace: 'nowrap',
            ...unstyled ? {} : {
                borderRadius: borderRadius / 2,
                color: colors.neutral80,
                fontSize: '85%',
                padding: 3,
                paddingLeft: 6
            }
        });
    const $20933ad7b05a0c37$export$283467f6c4ac142b = ({ theme: { spacing: spacing, borderRadius: borderRadius, colors: colors }, isFocused: isFocused }, unstyled)=>({
            alignItems: 'center',
            display: 'flex',
            ...unstyled ? {} : {
                borderRadius: borderRadius / 2,
                backgroundColor: isFocused ? colors.dangerLight : undefined,
                paddingLeft: spacing.baseUnit,
                paddingRight: spacing.baseUnit,
                ':hover': {
                    backgroundColor: colors.dangerLight,
                    color: colors.danger
                }
            }
        });
    const $20933ad7b05a0c37$export$e1de505c1242dc1a = ({ children: children, innerProps: innerProps })=>(0, (0, parcelRequire("f8Sb3")).jsx)("div", innerProps, children);
    const $20933ad7b05a0c37$export$cf9cf2a30a59265c = $20933ad7b05a0c37$export$e1de505c1242dc1a;
    const $20933ad7b05a0c37$export$544aeea2a5ce08ee = $20933ad7b05a0c37$export$e1de505c1242dc1a;
    function $20933ad7b05a0c37$export$8e1fd1a40e7a2f4a({ children: children, innerProps: innerProps }) {
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            role: "button",
            ...innerProps
        }, children || (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("95iuW")).CrossIcon), {
            size: 14
        }));
    }
    const $20933ad7b05a0c37$var$MultiValue = (props)=>{
        const { children: children, components: components, data: data, innerProps: innerProps, isDisabled: isDisabled, removeProps: removeProps, selectProps: selectProps } = props;
        const { Container: Container, Label: Label, Remove: Remove } = components;
        return (0, (0, parcelRequire("f8Sb3")).jsx)(Container, {
            data: data,
            innerProps: {
                ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'multiValue', {
                    'multi-value': true,
                    'multi-value--is-disabled': isDisabled
                }),
                ...innerProps
            },
            selectProps: selectProps
        }, (0, (0, parcelRequire("f8Sb3")).jsx)(Label, {
            data: data,
            innerProps: {
                ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'multiValueLabel', {
                    'multi-value__label': true
                })
            },
            selectProps: selectProps
        }, children), (0, (0, parcelRequire("f8Sb3")).jsx)(Remove, {
            data: data,
            innerProps: {
                ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'multiValueRemove', {
                    'multi-value__remove': true
                }),
                'aria-label': `Remove ${children || 'option'}`,
                ...removeProps
            },
            selectProps: selectProps
        }));
    };
    var $20933ad7b05a0c37$export$2e2bcd8739ae039 = $20933ad7b05a0c37$var$MultiValue;
});
parcelRegister("8l2RA", function(module, exports) {
    $parcel$export(module.exports, "optionCSS", ()=>$6e6d67a90b457dc1$export$ef897119b7e920ba);
    $parcel$export(module.exports, "default", ()=>$6e6d67a90b457dc1$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    const $6e6d67a90b457dc1$export$ef897119b7e920ba = ({ isDisabled: isDisabled, isFocused: isFocused, isSelected: isSelected, theme: { spacing: spacing, colors: colors } }, unstyled)=>({
            label: 'option',
            cursor: 'default',
            display: 'block',
            fontSize: 'inherit',
            width: '100%',
            userSelect: 'none',
            WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
            ...unstyled ? {} : {
                backgroundColor: isSelected ? colors.primary : isFocused ? colors.primary25 : 'transparent',
                color: isDisabled ? colors.neutral20 : isSelected ? colors.neutral0 : 'inherit',
                padding: `${spacing.baseUnit * 2}px ${spacing.baseUnit * 3}px`,
                ':active': {
                    backgroundColor: !isDisabled ? isSelected ? colors.primary : colors.primary50 : undefined
                }
            }
        });
    const $6e6d67a90b457dc1$var$Option = (props)=>{
        const { children: children, isDisabled: isDisabled, isFocused: isFocused, isSelected: isSelected, innerRef: innerRef, innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'option', {
                option: true,
                'option--is-disabled': isDisabled,
                'option--is-focused': isFocused,
                'option--is-selected': isSelected
            }),
            ref: innerRef,
            "aria-disabled": isDisabled,
            ...innerProps,
            tabIndex: -1
        }, children);
    };
    var $6e6d67a90b457dc1$export$2e2bcd8739ae039 = $6e6d67a90b457dc1$var$Option;
});
parcelRegister("kKm82", function(module, exports) {
    $parcel$export(module.exports, "placeholderCSS", ()=>$889d652a9d0dc097$export$5f3ffacd6594d90f);
    $parcel$export(module.exports, "default", ()=>$889d652a9d0dc097$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    const $889d652a9d0dc097$export$5f3ffacd6594d90f = ({ theme: { spacing: spacing, colors: colors } }, unstyled)=>({
            label: 'placeholder',
            gridArea: '1 / 1 / 2 / 3',
            ...unstyled ? {} : {
                color: colors.neutral50,
                marginLeft: spacing.baseUnit / 2,
                marginRight: spacing.baseUnit / 2
            }
        });
    const $889d652a9d0dc097$var$Placeholder = (props)=>{
        const { children: children, innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'placeholder', {
                placeholder: true
            }),
            ...innerProps
        }, children);
    };
    var $889d652a9d0dc097$export$2e2bcd8739ae039 = $889d652a9d0dc097$var$Placeholder;
});
parcelRegister("hpcbi", function(module, exports) {
    $parcel$export(module.exports, "css", ()=>$aff94346c62a222f$export$dbf350e5966cf602);
    $parcel$export(module.exports, "default", ()=>$aff94346c62a222f$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    const $aff94346c62a222f$export$dbf350e5966cf602 = ({ isDisabled: isDisabled, theme: { spacing: spacing, colors: colors } }, unstyled)=>({
            label: 'singleValue',
            gridArea: '1 / 1 / 2 / 3',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...unstyled ? {} : {
                color: isDisabled ? colors.neutral40 : colors.neutral80,
                marginLeft: spacing.baseUnit / 2,
                marginRight: spacing.baseUnit / 2
            }
        });
    const $aff94346c62a222f$var$SingleValue = (props)=>{
        const { children: children, isDisabled: isDisabled, innerProps: innerProps } = props;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            ...(0, (0, parcelRequire("gyjU7")).getStyleProps)(props, 'singleValue', {
                'single-value': true,
                'single-value--is-disabled': isDisabled
            }),
            ...innerProps
        }, children);
    };
    var $aff94346c62a222f$export$2e2bcd8739ae039 = $aff94346c62a222f$var$SingleValue;
});
parcelRegister("jtVgE", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$afe53037961824c2$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $gyjU7;
    const $afe53037961824c2$var$dummyInputStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        width: 1,
        padding: 0,
        position: 'relative',
        background: 0,
        border: 0,
        caretColor: 'transparent',
        color: 'transparent',
        fontSize: 'inherit',
        gridArea: '1 / 1 / 2 / 3',
        insetInlineStart: -100,
        label: 'dummyInput',
        opacity: 0,
        outline: 0,
        transform: 'scale(.01)'
    });
    function $afe53037961824c2$export$2e2bcd8739ae039({ innerRef: innerRef, ...props }) {
        const filteredProps = (0, (0, parcelRequire("gyjU7")).removeProps)(props, 'onExited', 'in', 'enter', 'exit', 'appear');
        return (0, (0, parcelRequire("f8Sb3")).jsx)("input", {
            ref: innerRef,
            ...filteredProps,
            css: $afe53037961824c2$var$dummyInputStyles
        });
    }
});
parcelRegister("9cRAr", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a388fa48a9e96271$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $eGmKJ;
    function $a388fa48a9e96271$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $a388fa48a9e96271$var$styles = {
        name: "1fj9brw-requiredInput",
        styles: "width:100%;position:absolute;inset-block-end:0;inset-inline-end:0;inset-inline-start:0;label:requiredInput;opacity:0;pointer-events:none"
    };
    const $a388fa48a9e96271$var$RequiredInput = ({ name: name, onFocus: onFocus })=>(0, (0, parcelRequire("f8Sb3")).jsx)("input", {
            required: true,
            name: name,
            tabIndex: -1,
            "aria-hidden": "true",
            onFocus: onFocus,
            css: $a388fa48a9e96271$var$styles,
            value: "",
            onChange: (0, (0, parcelRequire("eGmKJ")).default)
        });
    var $a388fa48a9e96271$export$2e2bcd8739ae039 = $a388fa48a9e96271$var$RequiredInput;
});
parcelRegister("galqx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e15a49c0b3452f71$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $59IMp;
    var $3jzRI;
    function $e15a49c0b3452f71$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $e15a49c0b3452f71$var$styles = {
        name: "qzxev8",
        styles: "position:fixed;inset-block-end:0;inset-block-start:0;inset-inline-end:0;inset-inline-start:0"
    };
    const $e15a49c0b3452f71$var$blurSelectInput = (event)=>{
        const element = event.target;
        return element.ownerDocument.activeElement && element.ownerDocument.activeElement.blur();
    };
    function $e15a49c0b3452f71$export$2e2bcd8739ae039({ children: children, lockEnabled: lockEnabled, captureEnabled: captureEnabled = true, onBottomArrive: onBottomArrive, onBottomLeave: onBottomLeave, onTopArrive: onTopArrive, onTopLeave: onTopLeave }) {
        const setScrollCaptureTarget = (0, (0, parcelRequire("59IMp")).default)({
            isEnabled: captureEnabled,
            onBottomArrive: onBottomArrive,
            onBottomLeave: onBottomLeave,
            onTopArrive: onTopArrive,
            onTopLeave: onTopLeave
        });
        const setScrollLockTarget = (0, (0, parcelRequire("3jzRI")).default)({
            isEnabled: lockEnabled
        });
        const targetRef = (element)=>{
            setScrollCaptureTarget(element);
            setScrollLockTarget(element);
        };
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, $5uXOq.Fragment), null, lockEnabled && (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            onClick: $e15a49c0b3452f71$var$blurSelectInput,
            css: $e15a49c0b3452f71$var$styles
        }), children(targetRef));
    }
});
parcelRegister("59IMp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$96c08116303d9e3a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gyjU7;
    const $96c08116303d9e3a$var$cancelScroll = (event)=>{
        if (event.cancelable) event.preventDefault();
        event.stopPropagation();
    };
    function $96c08116303d9e3a$export$2e2bcd8739ae039({ isEnabled: isEnabled, onBottomArrive: onBottomArrive, onBottomLeave: onBottomLeave, onTopArrive: onTopArrive, onTopLeave: onTopLeave }) {
        const isBottom = (0, $5uXOq.useRef)(false);
        const isTop = (0, $5uXOq.useRef)(false);
        const touchStart = (0, $5uXOq.useRef)(0);
        const scrollTarget = (0, $5uXOq.useRef)(null);
        const handleEventDelta = (0, $5uXOq.useCallback)((event, delta)=>{
            if (scrollTarget.current === null) return;
            const { scrollTop: scrollTop, scrollHeight: scrollHeight, clientHeight: clientHeight } = scrollTarget.current;
            const target = scrollTarget.current;
            const isDeltaPositive = delta > 0;
            const availableScroll = scrollHeight - clientHeight - scrollTop;
            let shouldCancelScroll = false;
            if (availableScroll > delta && isBottom.current) {
                if (onBottomLeave) onBottomLeave(event);
                isBottom.current = false;
            }
            if (isDeltaPositive && isTop.current) {
                if (onTopLeave) onTopLeave(event);
                isTop.current = false;
            }
            if (isDeltaPositive && delta > availableScroll) {
                if (onBottomArrive && !isBottom.current) onBottomArrive(event);
                target.scrollTop = scrollHeight;
                shouldCancelScroll = true;
                isBottom.current = true;
            } else if (!isDeltaPositive && -delta > scrollTop) {
                if (onTopArrive && !isTop.current) onTopArrive(event);
                target.scrollTop = 0;
                shouldCancelScroll = true;
                isTop.current = true;
            }
            if (shouldCancelScroll) $96c08116303d9e3a$var$cancelScroll(event);
        }, [
            onBottomArrive,
            onBottomLeave,
            onTopArrive,
            onTopLeave
        ]);
        const onWheel = (0, $5uXOq.useCallback)((event)=>{
            handleEventDelta(event, event.deltaY);
        }, [
            handleEventDelta
        ]);
        const onTouchStart = (0, $5uXOq.useCallback)((event)=>{
            touchStart.current = event.changedTouches[0].clientY;
        }, []);
        const onTouchMove = (0, $5uXOq.useCallback)((event)=>{
            const deltaY = touchStart.current - event.changedTouches[0].clientY;
            handleEventDelta(event, deltaY);
        }, [
            handleEventDelta
        ]);
        const startListening = (0, $5uXOq.useCallback)((el)=>{
            if (!el) return;
            const notPassive = (0, (0, parcelRequire("gyjU7")).supportsPassiveEvents) ? {
                passive: false
            } : false;
            el.addEventListener('wheel', onWheel, notPassive);
            el.addEventListener('touchstart', onTouchStart, notPassive);
            el.addEventListener('touchmove', onTouchMove, notPassive);
        }, [
            onTouchMove,
            onTouchStart,
            onWheel
        ]);
        const stopListening = (0, $5uXOq.useCallback)((el)=>{
            if (!el) return;
            el.removeEventListener('wheel', onWheel, false);
            el.removeEventListener('touchstart', onTouchStart, false);
            el.removeEventListener('touchmove', onTouchMove, false);
        }, [
            onTouchMove,
            onTouchStart,
            onWheel
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!isEnabled) return;
            const element = scrollTarget.current;
            startListening(element);
            return ()=>{
                stopListening(element);
            };
        }, [
            isEnabled,
            startListening,
            stopListening
        ]);
        return (element)=>{
            scrollTarget.current = element;
        };
    }
});
parcelRegister("3jzRI", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$840302f0f43020c5$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    const $840302f0f43020c5$var$STYLE_KEYS = [
        'boxSizing',
        'height',
        'overflow',
        'paddingRight',
        'position'
    ];
    const $840302f0f43020c5$var$LOCK_STYLES = {
        boxSizing: 'border-box',
        overflow: 'hidden',
        position: 'relative',
        height: '100%'
    };
    function $840302f0f43020c5$var$preventTouchMove(e) {
        e.preventDefault();
    }
    function $840302f0f43020c5$var$allowTouchMove(e) {
        e.stopPropagation();
    }
    function $840302f0f43020c5$var$preventInertiaScroll() {
        const top = this.scrollTop;
        const totalScroll = this.scrollHeight;
        const currentScroll = top + this.offsetHeight;
        if (top === 0) this.scrollTop = 1;
        else if (currentScroll === totalScroll) this.scrollTop = top - 1;
    }
    function $840302f0f43020c5$var$isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;
    }
    const $840302f0f43020c5$var$canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    let $840302f0f43020c5$var$activeScrollLocks = 0;
    const $840302f0f43020c5$var$listenerOptions = {
        capture: false,
        passive: false
    };
    function $840302f0f43020c5$export$2e2bcd8739ae039({ isEnabled: isEnabled, accountForScrollbars: accountForScrollbars = true }) {
        const originalStyles = (0, $5uXOq.useRef)({});
        const scrollTarget = (0, $5uXOq.useRef)(null);
        const addScrollLock = (0, $5uXOq.useCallback)((touchScrollTarget)=>{
            if (!$840302f0f43020c5$var$canUseDOM) return;
            const target = document.body;
            const targetStyle = target && target.style;
            if (accountForScrollbars) $840302f0f43020c5$var$STYLE_KEYS.forEach((key)=>{
                const val = targetStyle && targetStyle[key];
                originalStyles.current[key] = val;
            });
            if (accountForScrollbars && $840302f0f43020c5$var$activeScrollLocks < 1) {
                const currentPadding = parseInt(originalStyles.current.paddingRight, 10) || 0;
                const clientWidth = document.body ? document.body.clientWidth : 0;
                const adjustedPadding = window.innerWidth - clientWidth + currentPadding || 0;
                Object.keys($840302f0f43020c5$var$LOCK_STYLES).forEach((key)=>{
                    const val = $840302f0f43020c5$var$LOCK_STYLES[key];
                    if (targetStyle) targetStyle[key] = val;
                });
                if (targetStyle) targetStyle.paddingRight = `${adjustedPadding}px`;
            }
            if (target && $840302f0f43020c5$var$isTouchDevice()) {
                target.addEventListener('touchmove', $840302f0f43020c5$var$preventTouchMove, $840302f0f43020c5$var$listenerOptions);
                if (touchScrollTarget) {
                    touchScrollTarget.addEventListener('touchstart', $840302f0f43020c5$var$preventInertiaScroll, $840302f0f43020c5$var$listenerOptions);
                    touchScrollTarget.addEventListener('touchmove', $840302f0f43020c5$var$allowTouchMove, $840302f0f43020c5$var$listenerOptions);
                }
            }
            $840302f0f43020c5$var$activeScrollLocks += 1;
        }, [
            accountForScrollbars
        ]);
        const removeScrollLock = (0, $5uXOq.useCallback)((touchScrollTarget)=>{
            if (!$840302f0f43020c5$var$canUseDOM) return;
            const target = document.body;
            const targetStyle = target && target.style;
            $840302f0f43020c5$var$activeScrollLocks = Math.max($840302f0f43020c5$var$activeScrollLocks - 1, 0);
            if (accountForScrollbars && $840302f0f43020c5$var$activeScrollLocks < 1) $840302f0f43020c5$var$STYLE_KEYS.forEach((key)=>{
                const val = originalStyles.current[key];
                if (targetStyle) targetStyle[key] = val;
            });
            if (target && $840302f0f43020c5$var$isTouchDevice()) {
                target.removeEventListener('touchmove', $840302f0f43020c5$var$preventTouchMove, $840302f0f43020c5$var$listenerOptions);
                if (touchScrollTarget) {
                    touchScrollTarget.removeEventListener('touchstart', $840302f0f43020c5$var$preventInertiaScroll, $840302f0f43020c5$var$listenerOptions);
                    touchScrollTarget.removeEventListener('touchmove', $840302f0f43020c5$var$allowTouchMove, $840302f0f43020c5$var$listenerOptions);
                }
            }
        }, [
            accountForScrollbars
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!isEnabled) return;
            const element = scrollTarget.current;
            addScrollLock(element);
            return ()=>{
                removeScrollLock(element);
            };
        }, [
            isEnabled,
            addScrollLock,
            removeScrollLock
        ]);
        return (element)=>{
            scrollTarget.current = element;
        };
    }
});
parcelRegister("81i5b", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a3ccfcdca5695e58$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $VSDnB;
    var $dQlbw;
    var $bEd7d;
    const $a3ccfcdca5695e58$var$LiveRegion = (props)=>{
        const { ariaSelection: ariaSelection, focusedOption: focusedOption, focusedValue: focusedValue, focusableOptions: focusableOptions, isFocused: isFocused, selectValue: selectValue, selectProps: selectProps, id: id, isAppleDevice: isAppleDevice } = props;
        const { ariaLiveMessages: ariaLiveMessages, getOptionLabel: getOptionLabel, inputValue: inputValue, isMulti: isMulti, isOptionDisabled: isOptionDisabled, isSearchable: isSearchable, label: label, menuIsOpen: menuIsOpen, options: options, screenReaderStatus: screenReaderStatus, tabSelectsValue: tabSelectsValue, isLoading: isLoading } = selectProps;
        const ariaLabel = selectProps['aria-label'] || label;
        const ariaLive = selectProps['aria-live'];
        const isA11yImprovementEnabled = (0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') && !isAppleDevice;
        const messages = (0, $5uXOq.useMemo)(()=>({
                ...(0, (0, parcelRequire("dQlbw")).defaultAriaLiveMessages),
                ...ariaLiveMessages || {}
            }), [
            ariaLiveMessages
        ]);
        const ariaSelected = (0, $5uXOq.useMemo)(()=>{
            let message = '';
            if (isA11yImprovementEnabled && menuIsOpen) return '';
            if (ariaSelection && messages.onChange) {
                const { option: option, options: selectedOptions, removedValue: removedValue, removedValues: removedValues, value: value } = ariaSelection;
                const asOption = (val)=>!Array.isArray(val) ? val : null;
                const selected = removedValue || option || asOption(value);
                const label = selected ? getOptionLabel(selected) : '';
                const multiSelected = selectedOptions || removedValues || undefined;
                const labels = multiSelected ? multiSelected.map(getOptionLabel) : [];
                if (isA11yImprovementEnabled && !label && !labels.length) return '';
                const onChangeProps = {
                    isDisabled: selected && isOptionDisabled(selected, selectValue),
                    label: label,
                    labels: labels,
                    ...ariaSelection
                };
                message = messages.onChange(onChangeProps);
            }
            return message;
        }, [
            ariaSelection,
            messages,
            isOptionDisabled,
            selectValue,
            getOptionLabel,
            isA11yImprovementEnabled,
            menuIsOpen
        ]);
        const prevInputValue = (0, $5uXOq.useRef)('');
        const ariaFocused = (0, $5uXOq.useMemo)(()=>{
            let focusMsg = '';
            const focused = focusedOption || focusedValue;
            const isSelected = !!(focusedOption && selectValue && selectValue.includes(focusedOption));
            if (inputValue === prevInputValue.current && isA11yImprovementEnabled) return '';
            if (focused && messages.onFocus) {
                const onFocusProps = {
                    focused: focused,
                    label: getOptionLabel(focused),
                    isDisabled: isOptionDisabled(focused, selectValue),
                    isSelected: isSelected,
                    options: focusableOptions,
                    context: focused === focusedOption ? 'menu' : 'value',
                    selectValue: selectValue,
                    isMulti: isMulti
                };
                focusMsg = messages.onFocus(onFocusProps);
            }
            prevInputValue.current = inputValue;
            return focusMsg;
        }, [
            inputValue,
            focusedOption,
            focusedValue,
            getOptionLabel,
            isOptionDisabled,
            messages,
            focusableOptions,
            selectValue,
            isA11yImprovementEnabled,
            isMulti
        ]);
        const ariaResults = (0, $5uXOq.useMemo)(()=>{
            let resultsMsg = '';
            if (menuIsOpen && options.length && !isLoading && messages.onFilter) {
                const shouldAnnounceAvailableResults = !focusableOptions.length;
                if (shouldAnnounceAvailableResults && (0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') || !(0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement')) {
                    const resultsMessage = screenReaderStatus({
                        count: focusableOptions.length
                    });
                    resultsMsg = messages.onFilter({
                        inputValue: inputValue,
                        resultsMessage: resultsMessage
                    });
                }
            }
            return resultsMsg;
        }, [
            focusableOptions,
            inputValue,
            menuIsOpen,
            messages,
            options,
            screenReaderStatus,
            isLoading
        ]);
        const isInitialFocus = ariaSelection?.action === 'initial-input-focus';
        const ariaGuidance = (0, $5uXOq.useMemo)(()=>{
            if ((0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement')) return '';
            let guidanceMsg = '';
            if (messages.guidance) {
                const context = focusedValue ? 'value' : menuIsOpen ? 'menu' : 'input';
                guidanceMsg = messages.guidance({
                    'aria-label': ariaLabel,
                    context: context,
                    isDisabled: focusedOption && isOptionDisabled(focusedOption, selectValue),
                    isMulti: isMulti,
                    isSearchable: isSearchable,
                    tabSelectsValue: tabSelectsValue,
                    isInitialFocus: isInitialFocus
                });
            }
            return guidanceMsg;
        }, [
            ariaLabel,
            focusedOption,
            focusedValue,
            isMulti,
            isOptionDisabled,
            isSearchable,
            menuIsOpen,
            messages,
            selectValue,
            tabSelectsValue,
            isInitialFocus
        ]);
        const ScreenReaderText = (0, (0, parcelRequire("f8Sb3")).jsx)((0, $5uXOq.Fragment), null, (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            id: "aria-selection"
        }, ariaSelected), (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            id: "aria-focused"
        }, ariaFocused), (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            id: "aria-results"
        }, ariaResults), !(0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') && (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            id: "aria-guidance"
        }, ariaGuidance));
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, $5uXOq.Fragment), null, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("bEd7d")).default), {
            id: id
        }, isInitialFocus && ScreenReaderText), (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("bEd7d")).default), {
            "aria-live": isA11yImprovementEnabled ? 'polite' : ariaLive,
            role: isA11yImprovementEnabled ? 'status' : 'log'
        }, isFocused && !isInitialFocus && ScreenReaderText));
    };
    var $a3ccfcdca5695e58$export$2e2bcd8739ae039 = $a3ccfcdca5695e58$var$LiveRegion;
});
parcelRegister("dQlbw", function(module, exports) {
    $parcel$export(module.exports, "defaultAriaLiveMessages", ()=>$d3258ad64818881e$export$a7265767c78f9bae);
    var $VSDnB;
    const $d3258ad64818881e$export$a7265767c78f9bae = {
        guidance: (props)=>{
            const { isSearchable: isSearchable, isMulti: isMulti, tabSelectsValue: tabSelectsValue, context: context, isInitialFocus: isInitialFocus } = props;
            switch(context){
                case 'menu':
                    return `Use Up and Down to choose options, press Enter to select the currently focused option, press Escape to exit the menu${tabSelectsValue ? ', press Tab to select the option and exit the menu' : ''}.`;
                case 'input':
                    return isInitialFocus ? `${props['aria-label'] || 'Select'} is focused ${isSearchable ? ',type to refine list' : ''}, press Down to open the menu, ${isMulti ? ' press left to focus selected values' : ''}` : '';
                case 'value':
                    return 'Use left and right to toggle between focused values, press Backspace to remove the currently focused value';
                default:
                    return '';
            }
        },
        onChange: (props)=>{
            const { action: action, label: label = '', labels: labels, isDisabled: isDisabled } = props;
            switch(action){
                case 'deselect-option':
                case 'pop-value':
                case 'remove-value':
                    return `option ${label}, deselected.`;
                case 'clear':
                    return 'All selected options have been cleared.';
                case 'initial-input-focus':
                    return `option${labels.length > 1 ? 's' : ''} ${labels.join(',')}, selected.`;
                case 'select-option':
                    return isDisabled ? `option ${label} is disabled. Select another option.` : `option ${label}, selected.`;
                default:
                    return '';
            }
        },
        onFocus: (props)=>{
            const { context: context, focused: focused, options: options, label: label = '', selectValue: selectValue, isMulti: isMulti, isDisabled: isDisabled, isSelected: isSelected } = props;
            const getArrayIndex = (arr, item)=>arr && arr.length ? `(${arr.indexOf(item) + 1} of ${arr.length})` : '';
            if (context === 'value' && selectValue) return `value ${label} focused, ${getArrayIndex(selectValue, focused)}.`;
            if (context === 'menu') {
                const disabled = isDisabled ? ' disabled' : '';
                const notSelectedStatus = !isMulti && (0, (0, parcelRequire("VSDnB")).fg)('design_system_select-a11y-improvement') ? '' : ' not selected';
                const status = `${isSelected ? ' selected' : notSelectedStatus}${disabled}`;
                return `${label}${status}, ${getArrayIndex(options, focused)}, completion selected`;
            }
            return '';
        },
        onFilter: (props)=>{
            const { inputValue: inputValue, resultsMessage: resultsMessage } = props;
            return `${resultsMessage}${inputValue ? ' for search term ' + inputValue : ''}.`;
        }
    };
});
parcelRegister("bEd7d", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7cc43afa82d3c33b$export$2e2bcd8739ae039);
    var $f8Sb3;
    function $7cc43afa82d3c33b$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $7cc43afa82d3c33b$var$styles = {
        name: "1j8j4w4-a11yText",
        styles: "width:1px;height:1px;padding:0;position:absolute;z-index:9999;border:0;clip:rect(1px, 1px, 1px, 1px);label:a11yText;overflow:hidden;white-space:nowrap"
    };
    const $7cc43afa82d3c33b$var$A11yText = (props)=>(0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            css: $7cc43afa82d3c33b$var$styles,
            ...props
        });
    var $7cc43afa82d3c33b$export$2e2bcd8739ae039 = $7cc43afa82d3c33b$var$A11yText;
});
parcelRegister("5JAlL", function(module, exports) {
    $parcel$export(module.exports, "createFilter", ()=>$2c9d8148d5bbcfb3$export$5d81789ff4aeccdf);
    var $8QpSo;
    var $bXu2O;
    const $2c9d8148d5bbcfb3$var$memoizedStripDiacriticsForInput = (0, (0, parcelRequire("8QpSo")).default)((0, (0, parcelRequire("bXu2O")).stripDiacritics));
    const $2c9d8148d5bbcfb3$var$trimString = (str)=>str.replace(/^\s+|\s+$/g, '');
    const $2c9d8148d5bbcfb3$var$defaultStringify = (option)=>`${option.label} ${option.value}`;
    const $2c9d8148d5bbcfb3$export$5d81789ff4aeccdf = (config)=>(option, rawInput)=>{
            if (option.data.__isNew__) return true;
            const { ignoreCase: ignoreCase, ignoreAccents: ignoreAccents, stringify: stringify, trim: trim, matchFrom: matchFrom } = {
                ignoreCase: true,
                ignoreAccents: true,
                stringify: $2c9d8148d5bbcfb3$var$defaultStringify,
                trim: true,
                matchFrom: 'any',
                ...config
            };
            let input = trim ? $2c9d8148d5bbcfb3$var$trimString(rawInput) : rawInput;
            let candidate = trim ? $2c9d8148d5bbcfb3$var$trimString(stringify(option)) : stringify(option);
            if (ignoreCase) {
                input = input.toLowerCase();
                candidate = candidate.toLowerCase();
            }
            if (ignoreAccents) {
                input = $2c9d8148d5bbcfb3$var$memoizedStripDiacriticsForInput(input);
                candidate = (0, (0, parcelRequire("bXu2O")).stripDiacritics)(candidate);
            }
            return matchFrom === 'start' ? candidate.substr(0, input.length) === input : candidate.indexOf(input) > -1;
        };
});
parcelRegister("bXu2O", function(module, exports) {
    $parcel$export(module.exports, "stripDiacritics", ()=>$df93339199dda18e$export$b5bee4b27048330b);
    const $df93339199dda18e$var$diacritics = [
        {
            base: 'A',
            letters: '\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F'
        },
        {
            base: 'AA',
            letters: '\uA732'
        },
        {
            base: 'AE',
            letters: '\u00C6\u01FC\u01E2'
        },
        {
            base: 'AO',
            letters: '\uA734'
        },
        {
            base: 'AU',
            letters: '\uA736'
        },
        {
            base: 'AV',
            letters: '\uA738\uA73A'
        },
        {
            base: 'AY',
            letters: '\uA73C'
        },
        {
            base: 'B',
            letters: '\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181'
        },
        {
            base: 'C',
            letters: '\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E'
        },
        {
            base: 'D',
            letters: '\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779'
        },
        {
            base: 'DZ',
            letters: '\u01F1\u01C4'
        },
        {
            base: 'Dz',
            letters: '\u01F2\u01C5'
        },
        {
            base: 'E',
            letters: '\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E'
        },
        {
            base: 'F',
            letters: '\u0046\u24BB\uFF26\u1E1E\u0191\uA77B'
        },
        {
            base: 'G',
            letters: '\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E'
        },
        {
            base: 'H',
            letters: '\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D'
        },
        {
            base: 'I',
            letters: '\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197'
        },
        {
            base: 'J',
            letters: '\u004A\u24BF\uFF2A\u0134\u0248'
        },
        {
            base: 'K',
            letters: '\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2'
        },
        {
            base: 'L',
            letters: '\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780'
        },
        {
            base: 'LJ',
            letters: '\u01C7'
        },
        {
            base: 'Lj',
            letters: '\u01C8'
        },
        {
            base: 'M',
            letters: '\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C'
        },
        {
            base: 'N',
            letters: '\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4'
        },
        {
            base: 'NJ',
            letters: '\u01CA'
        },
        {
            base: 'Nj',
            letters: '\u01CB'
        },
        {
            base: 'O',
            letters: '\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C'
        },
        {
            base: 'OI',
            letters: '\u01A2'
        },
        {
            base: 'OO',
            letters: '\uA74E'
        },
        {
            base: 'OU',
            letters: '\u0222'
        },
        {
            base: 'P',
            letters: '\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754'
        },
        {
            base: 'Q',
            letters: '\u0051\u24C6\uFF31\uA756\uA758\u024A'
        },
        {
            base: 'R',
            letters: '\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782'
        },
        {
            base: 'S',
            letters: '\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784'
        },
        {
            base: 'T',
            letters: '\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786'
        },
        {
            base: 'TZ',
            letters: '\uA728'
        },
        {
            base: 'U',
            letters: '\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244'
        },
        {
            base: 'V',
            letters: '\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245'
        },
        {
            base: 'VY',
            letters: '\uA760'
        },
        {
            base: 'W',
            letters: '\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72'
        },
        {
            base: 'X',
            letters: '\u0058\u24CD\uFF38\u1E8A\u1E8C'
        },
        {
            base: 'Y',
            letters: '\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE'
        },
        {
            base: 'Z',
            letters: '\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762'
        },
        {
            base: 'a',
            letters: '\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250'
        },
        {
            base: 'aa',
            letters: '\uA733'
        },
        {
            base: 'ae',
            letters: '\u00E6\u01FD\u01E3'
        },
        {
            base: 'ao',
            letters: '\uA735'
        },
        {
            base: 'au',
            letters: '\uA737'
        },
        {
            base: 'av',
            letters: '\uA739\uA73B'
        },
        {
            base: 'ay',
            letters: '\uA73D'
        },
        {
            base: 'b',
            letters: '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253'
        },
        {
            base: 'c',
            letters: '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184'
        },
        {
            base: 'd',
            letters: '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A'
        },
        {
            base: 'dz',
            letters: '\u01F3\u01C6'
        },
        {
            base: 'e',
            letters: '\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD'
        },
        {
            base: 'f',
            letters: '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C'
        },
        {
            base: 'g',
            letters: '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F'
        },
        {
            base: 'h',
            letters: '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265'
        },
        {
            base: 'hv',
            letters: '\u0195'
        },
        {
            base: 'i',
            letters: '\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131'
        },
        {
            base: 'j',
            letters: '\u006A\u24D9\uFF4A\u0135\u01F0\u0249'
        },
        {
            base: 'k',
            letters: '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3'
        },
        {
            base: 'l',
            letters: '\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747'
        },
        {
            base: 'lj',
            letters: '\u01C9'
        },
        {
            base: 'm',
            letters: '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F'
        },
        {
            base: 'n',
            letters: '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5'
        },
        {
            base: 'nj',
            letters: '\u01CC'
        },
        {
            base: 'o',
            letters: '\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275'
        },
        {
            base: 'oi',
            letters: '\u01A3'
        },
        {
            base: 'ou',
            letters: '\u0223'
        },
        {
            base: 'oo',
            letters: '\uA74F'
        },
        {
            base: 'p',
            letters: '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755'
        },
        {
            base: 'q',
            letters: '\u0071\u24E0\uFF51\u024B\uA757\uA759'
        },
        {
            base: 'r',
            letters: '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783'
        },
        {
            base: 's',
            letters: '\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B'
        },
        {
            base: 't',
            letters: '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787'
        },
        {
            base: 'tz',
            letters: '\uA729'
        },
        {
            base: 'u',
            letters: '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289'
        },
        {
            base: 'v',
            letters: '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C'
        },
        {
            base: 'vy',
            letters: '\uA761'
        },
        {
            base: 'w',
            letters: '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73'
        },
        {
            base: 'x',
            letters: '\u0078\u24E7\uFF58\u1E8B\u1E8D'
        },
        {
            base: 'y',
            letters: '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF'
        },
        {
            base: 'z',
            letters: '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763'
        }
    ];
    const $df93339199dda18e$var$anyDiacritic = new RegExp('[' + $df93339199dda18e$var$diacritics.map((d)=>d.letters).join('') + ']', 'g');
    const $df93339199dda18e$var$diacriticToBase = {};
    for(let i = 0; i < $df93339199dda18e$var$diacritics.length; i++){
        let diacritic = $df93339199dda18e$var$diacritics[i];
        for(let j = 0; j < diacritic.letters.length; j++)$df93339199dda18e$var$diacriticToBase[diacritic.letters[j]] = diacritic.base;
    }
    const $df93339199dda18e$export$b5bee4b27048330b = (str)=>{
        return str.replace($df93339199dda18e$var$anyDiacritic, (match)=>$df93339199dda18e$var$diacriticToBase[match]);
    };
});
parcelRegister("7VXAu", function(module, exports) {
    $parcel$export(module.exports, "defaultStyles", ()=>$11d0fd648d1f51c0$export$214b055320e0bf32);
    $parcel$export(module.exports, "mergeStyles", ()=>$11d0fd648d1f51c0$export$e618dc39ac9ad607);
    var $h1QWG;
    var $eZFhY;
    var $i48X9;
    var $95iuW;
    var $dS5G5;
    var $aA8TC;
    var $hqcPo;
    var $8l2RA;
    var $kKm82;
    var $hpcbi;
    const $11d0fd648d1f51c0$export$214b055320e0bf32 = {
        clearIndicator: (0, (0, parcelRequire("95iuW")).clearIndicatorCSS),
        container: (0, (0, parcelRequire("h1QWG")).containerCSS),
        control: (0, (0, parcelRequire("eZFhY")).css),
        dropdownIndicator: (0, (0, parcelRequire("95iuW")).dropdownIndicatorCSS),
        group: (0, (0, parcelRequire("i48X9")).groupCSS),
        groupHeading: (0, (0, parcelRequire("i48X9")).groupHeadingCSS),
        indicatorsContainer: (0, (0, parcelRequire("h1QWG")).indicatorsContainerCSS),
        indicatorSeparator: (0, (0, parcelRequire("95iuW")).indicatorSeparatorCSS),
        input: (0, (0, parcelRequire("dS5G5")).inputCSS),
        loadingIndicator: (0, (0, parcelRequire("95iuW")).loadingIndicatorCSS),
        loadingMessage: (0, (0, parcelRequire("aA8TC")).loadingMessageCSS),
        menu: (0, (0, parcelRequire("aA8TC")).menuCSS),
        menuList: (0, (0, parcelRequire("aA8TC")).menuListCSS),
        menuPortal: (0, (0, parcelRequire("aA8TC")).menuPortalCSS),
        multiValue: (0, (0, parcelRequire("hqcPo")).multiValueCSS),
        multiValueLabel: (0, (0, parcelRequire("hqcPo")).multiValueLabelCSS),
        multiValueRemove: (0, (0, parcelRequire("hqcPo")).multiValueRemoveCSS),
        noOptionsMessage: (0, (0, parcelRequire("aA8TC")).noOptionsMessageCSS),
        option: (0, (0, parcelRequire("8l2RA")).optionCSS),
        placeholder: (0, (0, parcelRequire("kKm82")).placeholderCSS),
        singleValue: (0, (0, parcelRequire("hpcbi")).css),
        valueContainer: (0, (0, parcelRequire("h1QWG")).valueContainerCSS)
    };
    function $11d0fd648d1f51c0$export$e618dc39ac9ad607(source, target = {}) {
        const styles = {
            ...source
        };
        Object.keys(target).forEach((keyAsString)=>{
            const key = keyAsString;
            if (source[key]) styles[key] = (rsCss, props)=>{
                return target[key](source[key](rsCss, props), props);
            };
            else styles[key] = target[key];
        });
        return styles;
    }
});
parcelRegister("5HBcW", function(module, exports) {
    $parcel$export(module.exports, "defaultTheme", ()=>$f64b5780144c9fe8$export$164de7ab8df77ef0);
    const $f64b5780144c9fe8$export$8f45430ccf837300 = {
        primary: '#2684FF',
        primary75: '#4C9AFF',
        primary50: '#B2D4FF',
        primary25: '#DEEBFF',
        danger: '#DE350B',
        dangerLight: '#FFBDAD',
        neutral0: 'hsl(0, 0%, 100%)',
        neutral5: 'hsl(0, 0%, 95%)',
        neutral10: 'hsl(0, 0%, 90%)',
        neutral20: 'hsl(0, 0%, 80%)',
        neutral30: 'hsl(0, 0%, 70%)',
        neutral40: 'hsl(0, 0%, 60%)',
        neutral50: 'hsl(0, 0%, 50%)',
        neutral60: 'hsl(0, 0%, 40%)',
        neutral70: 'hsl(0, 0%, 30%)',
        neutral80: 'hsl(0, 0%, 20%)',
        neutral90: 'hsl(0, 0%, 10%)'
    };
    const $f64b5780144c9fe8$var$borderRadius = 4;
    const $f64b5780144c9fe8$var$baseUnit = 4;
    const $f64b5780144c9fe8$var$controlHeight = 38;
    const $f64b5780144c9fe8$var$menuGutter = $f64b5780144c9fe8$var$baseUnit * 2;
    const $f64b5780144c9fe8$export$5458b64234d1333c = {
        baseUnit: $f64b5780144c9fe8$var$baseUnit,
        controlHeight: $f64b5780144c9fe8$var$controlHeight,
        menuGutter: $f64b5780144c9fe8$var$menuGutter
    };
    const $f64b5780144c9fe8$export$164de7ab8df77ef0 = {
        borderRadius: $f64b5780144c9fe8$var$borderRadius,
        colors: $f64b5780144c9fe8$export$8f45430ccf837300,
        spacing: $f64b5780144c9fe8$export$5458b64234d1333c
    };
});
parcelRegister("iaInK", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7e85c90096c52fe4$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gyjU7;
    function $7e85c90096c52fe4$export$2e2bcd8739ae039({ defaultOptions: propsDefaultOptions = false, cacheOptions: cacheOptions = false, loadOptions: propsLoadOptions, options: propsOptions, isLoading: propsIsLoading = false, onInputChange: propsOnInputChange, filterOption: filterOption = null, ...restSelectProps }) {
        const { inputValue: propsInputValue } = restSelectProps;
        const lastRequest = (0, $5uXOq.useRef)(undefined);
        const mounted = (0, $5uXOq.useRef)(false);
        const [defaultOptions, setDefaultOptions] = (0, $5uXOq.useState)(Array.isArray(propsDefaultOptions) ? propsDefaultOptions : undefined);
        const [stateInputValue, setStateInputValue] = (0, $5uXOq.useState)(typeof propsInputValue !== 'undefined' ? propsInputValue : '');
        const [isLoading, setIsLoading] = (0, $5uXOq.useState)(propsDefaultOptions === true);
        const [loadedInputValue, setLoadedInputValue] = (0, $5uXOq.useState)(undefined);
        const [loadedOptions, setLoadedOptions] = (0, $5uXOq.useState)([]);
        const [passEmptyOptions, setPassEmptyOptions] = (0, $5uXOq.useState)(false);
        const [optionsCache, setOptionsCache] = (0, $5uXOq.useState)({});
        const [prevDefaultOptions, setPrevDefaultOptions] = (0, $5uXOq.useState)(undefined);
        const [prevCacheOptions, setPrevCacheOptions] = (0, $5uXOq.useState)(undefined);
        if (cacheOptions !== prevCacheOptions) {
            setOptionsCache({});
            setPrevCacheOptions(cacheOptions);
        }
        if (propsDefaultOptions !== prevDefaultOptions) {
            setDefaultOptions(Array.isArray(propsDefaultOptions) ? propsDefaultOptions : undefined);
            setPrevDefaultOptions(propsDefaultOptions);
        }
        (0, $5uXOq.useEffect)(()=>{
            mounted.current = true;
            return ()=>{
                mounted.current = false;
            };
        }, []);
        const loadOptions = (0, $5uXOq.useCallback)((inputValue, callback)=>{
            if (!propsLoadOptions) return callback();
            const loader = propsLoadOptions(inputValue, callback);
            if (loader && typeof loader.then === 'function') loader.then(callback, ()=>callback());
        }, [
            propsLoadOptions
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (propsDefaultOptions === true) loadOptions(stateInputValue, (options)=>{
                if (!mounted.current) return;
                setDefaultOptions(options || []);
                setIsLoading(!!lastRequest.current);
            });
        }, []);
        const onInputChange = (0, $5uXOq.useCallback)((newValue, actionMeta)=>{
            const inputValue = (0, (0, parcelRequire("gyjU7")).handleInputChange)(newValue, actionMeta, propsOnInputChange);
            if (!inputValue) {
                lastRequest.current = undefined;
                setStateInputValue('');
                setLoadedInputValue('');
                setLoadedOptions([]);
                setIsLoading(false);
                setPassEmptyOptions(false);
                return;
            }
            if (cacheOptions && optionsCache[inputValue]) {
                setStateInputValue(inputValue);
                setLoadedInputValue(inputValue);
                setLoadedOptions(optionsCache[inputValue]);
                setIsLoading(false);
                setPassEmptyOptions(false);
            } else {
                const request = lastRequest.current = {};
                setStateInputValue(inputValue);
                setIsLoading(true);
                setPassEmptyOptions(!loadedInputValue);
                loadOptions(inputValue, (options)=>{
                    if (!mounted) return;
                    if (request !== lastRequest.current) return;
                    lastRequest.current = undefined;
                    setIsLoading(false);
                    setLoadedInputValue(inputValue);
                    setLoadedOptions(options || []);
                    setPassEmptyOptions(false);
                    setOptionsCache(options ? {
                        ...optionsCache,
                        [inputValue]: options
                    } : optionsCache);
                });
            }
        }, [
            cacheOptions,
            loadOptions,
            loadedInputValue,
            optionsCache,
            propsOnInputChange
        ]);
        const options = passEmptyOptions ? [] : stateInputValue && loadedInputValue ? loadedOptions : defaultOptions || [];
        return {
            ...restSelectProps,
            options: options,
            isLoading: isLoading || propsIsLoading,
            onInputChange: onInputChange,
            filterOption: filterOption
        };
    }
});
parcelRegister("9uJ4X", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$72b6d9d338d10de1$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    function $72b6d9d338d10de1$export$2e2bcd8739ae039({ defaultInputValue: defaultInputValue = '', defaultMenuIsOpen: defaultMenuIsOpen = false, defaultValue: defaultValue = null, inputValue: propsInputValue, menuIsOpen: propsMenuIsOpen, onChange: propsOnChange, onInputChange: propsOnInputChange, onMenuClose: propsOnMenuClose, onMenuOpen: propsOnMenuOpen, value: propsValue, ...restSelectProps }) {
        const [stateInputValue, setStateInputValue] = (0, $5uXOq.useState)(propsInputValue !== undefined ? propsInputValue : defaultInputValue);
        const [stateMenuIsOpen, setStateMenuIsOpen] = (0, $5uXOq.useState)(propsMenuIsOpen !== undefined ? propsMenuIsOpen : defaultMenuIsOpen);
        const [stateValue, setStateValue] = (0, $5uXOq.useState)(propsValue !== undefined ? propsValue : defaultValue);
        const onChange = (0, $5uXOq.useCallback)((value, actionMeta)=>{
            if (typeof propsOnChange === 'function') propsOnChange(value, actionMeta);
            setStateValue(value);
        }, [
            propsOnChange
        ]);
        const onInputChange = (0, $5uXOq.useCallback)((value, actionMeta)=>{
            let newValue;
            if (typeof propsOnInputChange === 'function') newValue = propsOnInputChange(value, actionMeta);
            setStateInputValue(newValue !== undefined ? newValue : value);
        }, [
            propsOnInputChange
        ]);
        const onMenuOpen = (0, $5uXOq.useCallback)(()=>{
            if (typeof propsOnMenuOpen === 'function') propsOnMenuOpen();
            setStateMenuIsOpen(true);
        }, [
            propsOnMenuOpen
        ]);
        const onMenuClose = (0, $5uXOq.useCallback)(()=>{
            if (typeof propsOnMenuClose === 'function') propsOnMenuClose();
            setStateMenuIsOpen(false);
        }, [
            propsOnMenuClose
        ]);
        const inputValue = propsInputValue !== undefined ? propsInputValue : stateInputValue;
        const menuIsOpen = propsMenuIsOpen !== undefined ? propsMenuIsOpen : stateMenuIsOpen;
        const value = propsValue !== undefined ? propsValue : stateValue;
        return {
            ...restSelectProps,
            inputValue: inputValue,
            menuIsOpen: menuIsOpen,
            onChange: onChange,
            onInputChange: onInputChange,
            onMenuClose: onMenuClose,
            onMenuOpen: onMenuOpen,
            value: value
        };
    }
});
parcelRegister("3c98u", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$628a0199069e875a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $7VXAu;
    var $l4W7r;
    var $1k2at;
    var $j1GDZ;
    var $bA56e;
    var $eaXSR;
    var $1DATb;
    function $628a0199069e875a$export$2e2bcd8739ae039(WrappedComponent) {
        const AtlaskitSelect = (0, $5uXOq.forwardRef)(function AtlaskitSelect(props, forwardedRef) {
            const { appearance: appearance, ariaLiveMessages: ariaLiveMessages, components: componentsProp, isInvalid: isInvalid, onClickPreventDefault: onClickPreventDefault = true, spacing: spacing = 'default', styles: styles = {}, tabSelectsValue: tabSelectsValue = false, validationState: validationState = 'default', ...restProps } = props;
            const internalSelectRef = (0, $5uXOq.useRef)(null);
            const components = (0, $5uXOq.useMemo)(()=>({
                    ClearIndicator: (0, parcelRequire("l4W7r")).ClearIndicator,
                    DropdownIndicator: (0, parcelRequire("l4W7r")).DropdownIndicator,
                    LoadingIndicator: (0, parcelRequire("l4W7r")).LoadingIndicator,
                    MultiValueRemove: (0, parcelRequire("1k2at")).MultiValueRemove,
                    IndicatorSeparator: (0, parcelRequire("1k2at")).IndicatorSeparator,
                    Input: (0, parcelRequire("j1GDZ")).Input,
                    NoOptionsMessage: (0, parcelRequire("bA56e")).NoOptionsMessage,
                    ...componentsProp
                }), [
                componentsProp
            ]);
            const descriptionId = props['aria-describedby'];
            const isSearchable = props.isSearchable;
            (0, $5uXOq.useEffect)(()=>{
                if (!isSearchable && descriptionId) {
                    const input = internalSelectRef.current?.inputRef;
                    const ariaDescribedby = input?.getAttribute('aria-describedby');
                    if (!ariaDescribedby?.includes(descriptionId)) input?.setAttribute('aria-describedby', `${ariaDescribedby} ${descriptionId}`);
                }
            }, [
                descriptionId,
                isSearchable
            ]);
            const isCompact = spacing === 'compact';
            (0, $5uXOq.useImperativeHandle)(forwardedRef, ()=>({
                    select: internalSelectRef.current,
                    focus: ()=>internalSelectRef.current?.focus(),
                    blur: ()=>internalSelectRef.current?.blur()
                }), []);
            return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, {
                ref: internalSelectRef,
                "aria-live": "assertive",
                ariaLiveMessages: (0, (0, parcelRequire("1DATb")).isOptionsGrouped)(props.options) ? {
                    onFocus: (data)=>(0, (0, parcelRequire("1DATb")).onFocus)(data, props.options),
                    ...ariaLiveMessages
                } : {
                    ...ariaLiveMessages
                },
                tabSelectsValue: tabSelectsValue,
                onClickPreventDefault: onClickPreventDefault,
                isInvalid: isInvalid,
                ...restProps,
                components: components,
                styles: (0, (0, parcelRequire("7VXAu")).mergeStyles)((0, (0, parcelRequire("eaXSR")).default)(typeof isInvalid !== 'undefined' ? isInvalid ? 'error' : 'default' : validationState, isCompact, appearance || 'default'), styles),
                isAsync: true
            });
        });
        AtlaskitSelect.displayName = 'AtlaskitSelect';
        return AtlaskitSelect;
    }
});
parcelRegister("l4W7r", function(module, exports) {
    $parcel$export(module.exports, "ClearIndicator", ()=>$9145857df3efc9da$export$ecf9cf59142dd54a);
    $parcel$export(module.exports, "DropdownIndicator", ()=>$9145857df3efc9da$export$ec98719d3db7f2e4);
    $parcel$export(module.exports, "LoadingIndicator", ()=>$9145857df3efc9da$export$7c20be4c1d342248);
    var $f8Sb3;
    var $c8Oei;
    var $cQz2U;
    var $f5TWP;
    var $5ILhZ;
    var $8CUq3;
    var $bChGW;
    var $eZJjh;
    const $9145857df3efc9da$var$iconContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        all: 'unset',
        outline: 'revert',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 'space.025'
    });
    const $9145857df3efc9da$var$dropdownWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        padding: 'space.075'
    });
    const $9145857df3efc9da$export$ecf9cf59142dd54a = ({ clearControlLabel: clearControlLabel = 'clear', ...props })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("bChGW")).components).ClearIndicator, {
            ...props,
            innerProps: {
                ...props.innerProps,
                'aria-hidden': 'false'
            }
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("5ILhZ")).default), {
            xcss: $9145857df3efc9da$var$iconContainerStyles,
            tabIndex: -1,
            "aria-label": clearControlLabel
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, ($parcel$interopDefault((0, parcelRequire("cQz2U"))))), {
            label: "",
            color: "currentColor",
            LEGACY_size: "small",
            LEGACY_margin: "var(--ds-space-negative-025, -0.125rem)"
        })));
    };
    const $9145857df3efc9da$export$ec98719d3db7f2e4 = (props)=>(0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("bChGW")).components).DropdownIndicator, props, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("f5TWP")).default), {
            as: "span",
            xcss: $9145857df3efc9da$var$dropdownWrapperStyles
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, ($parcel$interopDefault((0, parcelRequire("c8Oei"))))), {
            color: "currentColor",
            label: "open",
            LEGACY_margin: "var(--ds-space-negative-075, -0.375rem)"
        })));
    const $9145857df3efc9da$export$7c20be4c1d342248 = (props)=>{
        const loadingStyles = (0, (0, parcelRequire("f8Sb3")).css)(props.getStyles('loadingIndicator', props));
        return ((0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: loadingStyles,
            ...props.innerProps
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("eZJjh")).default), {
            size: "small"
        })));
    };
});
parcelRegister("cQz2U", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $95a586bfe482ffec$var$_react = $95a586bfe482ffec$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $95a586bfe482ffec$var$_crossCircle = $95a586bfe482ffec$var$_interopRequireDefault((parcelRequire("47etd")));
    var $95a586bfe482ffec$var$_selectClear = $95a586bfe482ffec$var$_interopRequireDefault((parcelRequire("673MF")));
    function $95a586bfe482ffec$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $95a586bfe482ffec$var$CrossCircleIcon = (props)=>$95a586bfe482ffec$var$_react.default.createElement($95a586bfe482ffec$var$_crossCircle.default, Object.assign({
            LEGACY_fallbackIcon: $95a586bfe482ffec$var$_selectClear.default
        }, props));
    $95a586bfe482ffec$var$CrossCircleIcon.Name = 'CrossCircleIconMigration';
    var $95a586bfe482ffec$var$_default = module.exports.default = $95a586bfe482ffec$var$CrossCircleIcon;
});
parcelRegister("47etd", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $2ff30a5d9a7d32b6$var$_react = $2ff30a5d9a7d32b6$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $2ff30a5d9a7d32b6$var$_UNSAFE_baseNew = $2ff30a5d9a7d32b6$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $2ff30a5d9a7d32b6$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $2ff30a5d9a7d32b6$var$CrossCircleIcon = (props)=>$2ff30a5d9a7d32b6$var$_react.default.createElement($2ff30a5d9a7d32b6$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path fill="currentcolor" fill-rule="evenodd" d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12m3.28-8.22L7.06 6l2.22 2.22-1.06 1.06L6 7.06 3.78 9.28 2.72 8.22 4.94 6 2.72 3.78l1.06-1.06L6 4.94l2.22-2.22z" clip-rule="evenodd"/>`,
            type: 'utility'
        }, props));
    $2ff30a5d9a7d32b6$var$CrossCircleIcon.displayName = 'CrossCircleIcon';
    var $2ff30a5d9a7d32b6$var$_default = module.exports.default = $2ff30a5d9a7d32b6$var$CrossCircleIcon;
});
parcelRegister("673MF", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $473654f205f53fc4$var$_react = $473654f205f53fc4$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $473654f205f53fc4$var$_crossCircle = $473654f205f53fc4$var$_interopRequireDefault((parcelRequire("hI6SY")));
    function $473654f205f53fc4$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $473654f205f53fc4$var$SelectClearIcon = (props)=>$473654f205f53fc4$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill-rule="evenodd"><circle cx="12" cy="12" r="9" fill="currentcolor"/><path fill="inherit" d="M16.155 14.493a1.174 1.174 0 1 1-1.662 1.663L12 13.662l-2.494 2.494a1.17 1.17 0 0 1-1.662 0 1.176 1.176 0 0 1 0-1.663L10.337 12 7.844 9.507a1.176 1.176 0 0 1 1.662-1.662L12 10.338l2.493-2.493a1.174 1.174 0 1 1 1.662 1.662L13.662 12z"/></g></svg>`
        }, props, {
            newIcon: $473654f205f53fc4$var$_crossCircle.default
        }));
    $473654f205f53fc4$var$SelectClearIcon.displayName = 'SelectClearIcon';
    var $473654f205f53fc4$var$_default = module.exports.default = $473654f205f53fc4$var$SelectClearIcon;
});
parcelRegister("hI6SY", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $ce4be313f95bc8ed$var$_react = $ce4be313f95bc8ed$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $ce4be313f95bc8ed$var$_UNSAFE_baseNew = $ce4be313f95bc8ed$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $ce4be313f95bc8ed$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $ce4be313f95bc8ed$var$CrossCircleIcon = (props)=>$ce4be313f95bc8ed$var$_react.default.createElement($ce4be313f95bc8ed$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path fill="currentcolor" fill-rule="evenodd" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m4.03 5.03L9.06 8l2.97 2.97-1.06 1.06L8 9.06l-2.97 2.97-1.06-1.06L6.94 8 3.97 5.03l1.06-1.06L8 6.94l2.97-2.97z" clip-rule="evenodd"/>`
        }, props));
    $ce4be313f95bc8ed$var$CrossCircleIcon.displayName = 'CrossCircleIcon';
    var $ce4be313f95bc8ed$var$_default = module.exports.default = $ce4be313f95bc8ed$var$CrossCircleIcon;
});
parcelRegister("1k2at", function(module, exports) {
    $parcel$export(module.exports, "MultiValueRemove", ()=>$5fdfa3c8145f4e13$export$8e1fd1a40e7a2f4a);
    $parcel$export(module.exports, "IndicatorSeparator", ()=>$5fdfa3c8145f4e13$export$bd638c2ba7915668);
    var $f8Sb3;
    var $673MF;
    var $bjGSs;
    var $VSDnB;
    var $f5TWP;
    var $8CUq3;
    var $bChGW;
    var $l4W7r;
    function $5fdfa3c8145f4e13$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $5fdfa3c8145f4e13$var$disabledStyles = {
        name: "eivff4",
        styles: "display:none"
    };
    const $5fdfa3c8145f4e13$var$enabledStyles = {
        name: "ck88l6",
        styles: "display:inherit"
    };
    const $5fdfa3c8145f4e13$var$iconWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        padding: 'space.025'
    });
    const $5fdfa3c8145f4e13$export$8e1fd1a40e7a2f4a = (props)=>{
        const { isDisabled: isDisabled } = props.selectProps;
        const renderIcon = ()=>{
            if ((0, (0, parcelRequire("VSDnB")).fg)('platform-component-visual-refresh')) return (0, (0, parcelRequire("f8Sb3")).jsx)((0, ($parcel$interopDefault((0, parcelRequire("bjGSs"))))), {
                label: "Clear",
                color: "currentColor"
            });
            if ((0, (0, parcelRequire("VSDnB")).fg)('platform-visual-refresh-icons-legacy-facade')) return (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("f5TWP")).default), {
                xcss: $5fdfa3c8145f4e13$var$iconWrapperStyles
            }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, ($parcel$interopDefault((0, parcelRequire("bjGSs"))))), {
                label: "Clear",
                color: "currentColor"
            }));
            return ((0, (0, parcelRequire("f8Sb3")).jsx)((0, ($parcel$interopDefault((0, parcelRequire("673MF"))))), {
                label: "Clear",
                primaryColor: "transparent",
                size: "small",
                secondaryColor: "inherit"
            }));
        };
        return ((0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("bChGW")).components).MultiValueRemove, props, (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: isDisabled ? $5fdfa3c8145f4e13$var$disabledStyles : $5fdfa3c8145f4e13$var$enabledStyles,
            "data-testid": isDisabled ? 'hide-clear-icon' : 'show-clear-icon'
        }, renderIcon())));
    };
    const $5fdfa3c8145f4e13$export$bd638c2ba7915668 = null;
});
parcelRegister("bjGSs", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $83d2ba975692c1a3$var$_react = $83d2ba975692c1a3$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $83d2ba975692c1a3$var$_UNSAFE_baseNew = $83d2ba975692c1a3$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $83d2ba975692c1a3$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $83d2ba975692c1a3$var$CrossIcon = (props)=>$83d2ba975692c1a3$var$_react.default.createElement($83d2ba975692c1a3$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="m2 10 4-4m4-4L6 6m0 0L2 2m4 4 4 4"/>`,
            type: 'utility'
        }, props));
    $83d2ba975692c1a3$var$CrossIcon.displayName = 'CrossIcon';
    var $83d2ba975692c1a3$var$_default = module.exports.default = $83d2ba975692c1a3$var$CrossIcon;
});
parcelRegister("j1GDZ", function(module, exports) {
    $parcel$export(module.exports, "Input", ()=>$4cb02513bf42b58e$export$f5b8910cec6cf069);
    var $5uXOq = parcelRequire("5uXOq");
    var $bChGW;
    function $4cb02513bf42b58e$export$f5b8910cec6cf069(props) {
        let ariaDescribedByAttribute = props['aria-describedby'];
        const passed_describedby = props.selectProps['aria-describedby'] || props.selectProps.descriptionId;
        if (passed_describedby && !ariaDescribedByAttribute?.includes(passed_describedby)) ariaDescribedByAttribute = props['aria-describedby'] + ' ' + passed_describedby;
        else ariaDescribedByAttribute = props['aria-describedby'] || passed_describedby;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bChGW")).components).Input, {
            ...props,
            "aria-describedby": ariaDescribedByAttribute
        });
    }
});
parcelRegister("bA56e", function(module, exports) {
    $parcel$export(module.exports, "NoOptionsMessage", ()=>$eeac7d0aa054dce2$export$d58b72b7d3c4072c);
    var $5uXOq = parcelRequire("5uXOq");
    var $cgL5K;
    var $bChGW;
    const $eeac7d0aa054dce2$export$d58b72b7d3c4072c = (props)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bChGW")).components).NoOptionsMessage, props, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            color: "color.text.subtle"
        }, props.children || `No options`));
    };
});
parcelRegister("eaXSR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b7eb841defe611e3$export$2e2bcd8739ae039);
    var $VSDnB;
    function $b7eb841defe611e3$export$2e2bcd8739ae039(validationState, isCompact = false, appearance) {
        return {
            container: (css, { isDisabled: isDisabled })=>({
                    ...css,
                    font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
                    pointerEvents: 'all',
                    cursor: isDisabled ? 'not-allowed' : css.cursor
                }),
            input: (css)=>({
                    ...css,
                    color: "var(--ds-text, hsl(0, 0%, 20%))"
                }),
            control: (css, { isFocused: isFocused, isDisabled: isDisabled })=>{
                let borderColor = isFocused ? `var(--ds-border-focused, ${0, $829f609a65d26a98$export$ecfc1dff386a3e7a})` : `var(--ds-border-input, ${0, $829f609a65d26a98$export$d6447c357493214f})`;
                let backgroundColor = isFocused ? `var(--ds-background-input-pressed, ${0, $829f609a65d26a98$export$29a4f05edc054374})` : `var(--ds-background-input, ${0, $829f609a65d26a98$export$9fdfb749e33cdf97})`;
                let backgroundColorHover = isFocused ? `var(--ds-background-input-pressed, ${0, $829f609a65d26a98$export$29a4f05edc054374})` : `var(--ds-background-input-hovered, ${0, $829f609a65d26a98$export$3e51e8f1440314ba})`;
                if (isDisabled) {
                    backgroundColor = `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$9fdfb749e33cdf97})`;
                    borderColor = `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$9fdfb749e33cdf97})`;
                }
                if (validationState === 'error') borderColor = `var(--ds-border-danger, ${0, $829f609a65d26a98$export$2010d0fb4295b346})`;
                if (validationState === 'success') borderColor = `var(--ds-border-success, ${0, $829f609a65d26a98$export$a49394138f7b8e85})`;
                let borderColorHover = isFocused ? `var(--ds-border-focused, ${0, $829f609a65d26a98$export$ecfc1dff386a3e7a})` : `var(--ds-border-input, ${0, $829f609a65d26a98$export$d6447c357493214f})`;
                if (validationState === 'error') borderColorHover = `var(--ds-border-danger, ${0, $829f609a65d26a98$export$2010d0fb4295b346})`;
                if (validationState === 'success') borderColorHover = `var(--ds-border-success, ${0, $829f609a65d26a98$export$a49394138f7b8e85})`;
                const transitionDuration = '200ms';
                if (appearance === 'subtle') {
                    borderColor = isFocused ? `var(--ds-border-focused, ${0, $829f609a65d26a98$export$ecfc1dff386a3e7a})` : 'transparent';
                    backgroundColor = isFocused ? `var(--ds-surface, ${0, $829f609a65d26a98$export$29a4f05edc054374})` : 'transparent';
                    backgroundColorHover = isFocused ? `var(--ds-background-input-pressed, ${0, $829f609a65d26a98$export$29a4f05edc054374})` : `var(--ds-background-input-hovered, ${0, $829f609a65d26a98$export$3e51e8f1440314ba})`;
                }
                if (appearance === 'none') {
                    borderColor = 'transparent';
                    backgroundColor = 'transparent';
                    backgroundColorHover = 'transparent';
                    borderColorHover = 'transparent';
                }
                return {
                    ...css,
                    pointerEvents: isDisabled ? 'none' : undefined,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderStyle: 'solid',
                    borderRadius: "var(--ds-border-radius-100, 3px)",
                    borderWidth: "var(--ds-border-width, 1px)",
                    boxShadow: validationState === 'error' ? `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${borderColor}` : 'none',
                    '&:focus-within': {
                        boxShadow: `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${borderColor}`
                    },
                    minHeight: isCompact ? 32 : 40,
                    padding: 0,
                    transition: `background-color ${transitionDuration} ease-in-out,
        border-color ${transitionDuration} ease-in-out`,
                    '::-webkit-scrollbar': {
                        height: 8,
                        width: 8
                    },
                    '::-webkit-scrollbar-corner': {
                        display: 'none'
                    },
                    ':hover': {
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0.2)'
                        },
                        cursor: 'pointer',
                        backgroundColor: backgroundColorHover,
                        borderColor: borderColorHover
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: 'rgba(0,0,0,0.4)'
                    }
                };
            },
            valueContainer: (css)=>({
                    ...css,
                    paddingLeft: "var(--ds-space-075, 6px)",
                    paddingRight: "var(--ds-space-075, 6px)",
                    paddingBottom: isCompact ? 0 : 2,
                    paddingTop: isCompact ? 0 : 2
                }),
            clearIndicator: (css)=>({
                    ...css,
                    color: `var(--ds-text-subtlest, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`,
                    paddingLeft: "var(--ds-space-025, 2px)",
                    paddingRight: "var(--ds-space-025, 2px)",
                    paddingBottom: isCompact ? 0 : 6,
                    paddingTop: isCompact ? 0 : 6,
                    ':hover': {
                        color: `var(--ds-text-subtle, ${0, $829f609a65d26a98$export$c39c0fe0f26207ae})`
                    }
                }),
            loadingIndicator: (css)=>({
                    ...css,
                    paddingBottom: isCompact ? 0 : 6,
                    paddingTop: isCompact ? 0 : 6
                }),
            dropdownIndicator: (css, { isDisabled: isDisabled })=>{
                let color = `var(--ds-text-subtle, ${(0, $829f609a65d26a98$export$c39c0fe0f26207ae)})`;
                if (isDisabled) color = `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`;
                return {
                    ...css,
                    color: color,
                    paddingLeft: "var(--ds-space-025, 2px)",
                    paddingRight: "var(--ds-space-025, 2px)",
                    paddingBottom: isCompact ? 0 : 6,
                    paddingTop: isCompact ? 0 : 6,
                    ':hover': {
                        color: `var(--ds-text-subtle, ${0, $829f609a65d26a98$export$f563ee71e9ee74cd})`
                    }
                };
            },
            indicatorsContainer: (css)=>({
                    ...css,
                    paddingRight: "var(--ds-space-050, 4px)"
                }),
            option: (css, { isFocused: isFocused, isSelected: isSelected, isDisabled: isDisabled })=>{
                let color = `var(--ds-text, ${(0, $829f609a65d26a98$export$7d61b15e0da132af)})`;
                if (isDisabled) color = `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`;
                else if (isSelected) color = `var(--ds-text-selected, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
                let boxShadow;
                let backgroundColor;
                if (isDisabled) backgroundColor = undefined;
                else if (isSelected && isFocused) backgroundColor = `var(--ds-background-selected-hovered, ${0, $829f609a65d26a98$export$226de0355ed012f8})`;
                else if (isSelected) backgroundColor = `var(--ds-background-selected, ${0, $829f609a65d26a98$export$226de0355ed012f8})`;
                else if (isFocused) backgroundColor = `var(--ds-background-neutral-subtle-hovered, ${0, $829f609a65d26a98$export$9fdfb749e33cdf97})`;
                if (!isDisabled && (isFocused || isSelected)) boxShadow = `inset 2px 0px 0px ${`var(--ds-border-selected, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`}`;
                const cursor = isDisabled ? 'not-allowed' : css.cursor;
                return {
                    ...css,
                    padding: '6px 12px',
                    backgroundColor: backgroundColor,
                    color: color,
                    cursor: cursor,
                    boxShadow: boxShadow,
                    ':active': {
                        backgroundColor: !isDisabled ? isSelected ? `var(--ds-background-selected-pressed, ${0, $829f609a65d26a98$export$9fdfb749e33cdf97})` : `var(--ds-background-neutral-subtle-pressed, ${0, $829f609a65d26a98$export$3e51e8f1440314ba})` : undefined
                    },
                    '@media screen and (-ms-high-contrast: active)': {
                        borderLeft: !isDisabled && (isFocused || isSelected) ? '2px solid transparent' : ''
                    }
                };
            },
            placeholder: (css, { isDisabled: isDisabled })=>({
                    ...css,
                    color: isDisabled ? `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$e2ef2124cf4024b1})` : `var(--ds-text-subtlest, ${0, $829f609a65d26a98$export$f563ee71e9ee74cd})`
                }),
            singleValue: (css, { isDisabled: isDisabled })=>({
                    ...css,
                    color: isDisabled ? `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})` : `var(--ds-text, ${0, $829f609a65d26a98$export$7d61b15e0da132af})`
                }),
            menu: (css)=>({
                    ...css,
                    backgroundColor: "var(--ds-surface-overlay, white)",
                    boxShadow: "var(--ds-shadow-overlay, 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%))"
                }),
            menuList: (css)=>({
                    ...css,
                    paddingTop: "var(--ds-space-100, 8px)",
                    paddingBottom: "var(--ds-space-100, 8px)"
                }),
            multiValue: (css, { isDisabled: isDisabled, isFocused: isFocused })=>{
                let backgroundColor;
                let color;
                if (isDisabled) {
                    backgroundColor = `var(--ds-background-neutral, ${0, $829f609a65d26a98$export$1ce2d1575d444ceb})`;
                    color = `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`;
                } else if (isFocused) {
                    backgroundColor = `var(--ds-background-selected, ${0, $829f609a65d26a98$export$1ce2d1575d444ceb})`;
                    color = "var(--ds-text-selected, hsl(0, 0%, 20%))";
                } else {
                    backgroundColor = (0, (0, parcelRequire("VSDnB")).fg)('platform-component-visual-refresh') ? "var(--ds-background-neutral-subtle-hovered, #091E420F)" : `var(--ds-background-neutral, ${0, $829f609a65d26a98$export$1ce2d1575d444ceb})`;
                    color = "var(--ds-text, hsl(0, 0%, 20%))";
                }
                return {
                    ...css,
                    borderRadius: "var(--ds-border-radius-050, 2px)",
                    backgroundColor: backgroundColor,
                    boxShadow: isFocused ? `0 0 0 2px ${"var(--ds-surface, transparent)"}, 0 0 0 4px ${"var(--ds-border-focused, transparent)"}` : 'none',
                    maxWidth: '100%',
                    '@media screen and (-ms-high-contrast: active)': {
                        border: isFocused ? '1px solid transparent' : 'none'
                    },
                    color: color,
                    ...(0, (0, parcelRequire("VSDnB")).fg)('platform-component-visual-refresh') && {
                        borderRadius: "var(--ds-border-radius-100, 4px)",
                        borderColor: '#B7B9BE',
                        borderWidth: "var(--ds-border-width, 1px)",
                        borderStyle: 'solid',
                        backgroundColor: "var(--ds-background-input, #FFFFFF)"
                    }
                };
            },
            multiValueLabel: (css, { isDisabled: isDisabled })=>({
                    ...css,
                    font: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
                    padding: "var(--ds-space-025, 2px)",
                    color: isDisabled ? `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})` : 'inherit',
                    paddingRight: "var(--ds-space-025, 2px)",
                    ...(0, (0, parcelRequire("VSDnB")).fg)('platform-component-visual-refresh') && {
                        font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: "var(--ds-space-050, 4px)"
                    }
                }),
            multiValueRemove: (css, { isFocused: isFocused })=>({
                    ...css,
                    backgroundColor: isFocused ? `var(--ds-UNSAFE-transparent, ${0, $829f609a65d26a98$export$b34b069eff244586})` : undefined,
                    fill: isFocused ? "var(--ds-text-selected, #000)" : "var(--ds-text, #000)",
                    paddingLeft: "var(--ds-space-025, 2px)",
                    paddingRight: "var(--ds-space-025, 2px)",
                    borderRadius: '0px 2px 2px 0px',
                    ':hover': {
                        backgroundColor: `var(--ds-background-danger-hovered, ${0, $829f609a65d26a98$export$b34b069eff244586})`,
                        fill: "var(--ds-text-danger, #000)"
                    },
                    ':active': {
                        backgroundColor: `var(--ds-background-danger-pressed, ${0, $829f609a65d26a98$export$b34b069eff244586})`,
                        fill: "var(--ds-text-danger, #000)"
                    },
                    ...(0, (0, parcelRequire("VSDnB")).fg)('platform-component-visual-refresh') && {
                        backgroundColor: "var(--ds-background-neutral-subtle, #00000000)",
                        border: 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        appearance: 'none',
                        borderRadius: "var(--ds-border-radius, 4px)",
                        color: "var(--ds-text, #172B4D)",
                        padding: "var(--ds-space-025, 2px)",
                        marginRight: "var(--ds-space-025, 2px)",
                        ':focus-visible': {
                            outlineOffset: "var(--ds-space-negative-025, -2px)"
                        },
                        ':hover': {
                            backgroundColor: "var(--ds-background-neutral-subtle-hovered, #091E420F)"
                        },
                        ':active': {
                            backgroundColor: "var(--ds-background-neutral-subtle-pressed, #091E4224)"
                        }
                    }
                }),
            groupHeading: (css)=>({
                    ...css,
                    font: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
                    color: `var(--ds-text-subtle, ${0, $829f609a65d26a98$export$f563ee71e9ee74cd})`,
                    fontWeight: `${"var(--ds-font-weight-bold, 700)"} !important`,
                    textTransform: 'none'
                })
        };
    }
});
var $829f609a65d26a98$exports = {};
$parcel$export($829f609a65d26a98$exports, "R50", ()=>$829f609a65d26a98$export$4b84fd0e2c617bd);
$parcel$export($829f609a65d26a98$exports, "R75", ()=>$829f609a65d26a98$export$b34b069eff244586);
$parcel$export($829f609a65d26a98$exports, "R100", ()=>$829f609a65d26a98$export$be802dd0d8b57f35);
$parcel$export($829f609a65d26a98$exports, "R200", ()=>$829f609a65d26a98$export$60847d42f9da92a6);
$parcel$export($829f609a65d26a98$exports, "R300", ()=>$829f609a65d26a98$export$4a7404f376229999);
$parcel$export($829f609a65d26a98$exports, "R400", ()=>$829f609a65d26a98$export$2010d0fb4295b346);
$parcel$export($829f609a65d26a98$exports, "R500", ()=>$829f609a65d26a98$export$2837b5a1a71d5c02);
$parcel$export($829f609a65d26a98$exports, "Y50", ()=>$829f609a65d26a98$export$8d9596b529bbb9cf);
$parcel$export($829f609a65d26a98$exports, "Y75", ()=>$829f609a65d26a98$export$41060a744ab0b7ae);
$parcel$export($829f609a65d26a98$exports, "Y100", ()=>$829f609a65d26a98$export$1cc05bbbf340e559);
$parcel$export($829f609a65d26a98$exports, "Y200", ()=>$829f609a65d26a98$export$5aae31392d72caaa);
$parcel$export($829f609a65d26a98$exports, "Y300", ()=>$829f609a65d26a98$export$c6930421db9fa514);
$parcel$export($829f609a65d26a98$exports, "Y400", ()=>$829f609a65d26a98$export$59619663aa6500fb);
$parcel$export($829f609a65d26a98$exports, "Y500", ()=>$829f609a65d26a98$export$874739ea9e379ab6);
$parcel$export($829f609a65d26a98$exports, "G50", ()=>$829f609a65d26a98$export$8a6341c32b6a3ee6);
$parcel$export($829f609a65d26a98$exports, "G75", ()=>$829f609a65d26a98$export$69a3bf21b9686c2b);
$parcel$export($829f609a65d26a98$exports, "G100", ()=>$829f609a65d26a98$export$afbce1382ec95f73);
$parcel$export($829f609a65d26a98$exports, "G200", ()=>$829f609a65d26a98$export$747d86715d4175de);
$parcel$export($829f609a65d26a98$exports, "G300", ()=>$829f609a65d26a98$export$940e1f69e873f202);
$parcel$export($829f609a65d26a98$exports, "G400", ()=>$829f609a65d26a98$export$a49394138f7b8e85);
$parcel$export($829f609a65d26a98$exports, "G500", ()=>$829f609a65d26a98$export$6fa04827ac68a4fb);
$parcel$export($829f609a65d26a98$exports, "B50", ()=>$829f609a65d26a98$export$226de0355ed012f8);
$parcel$export($829f609a65d26a98$exports, "B75", ()=>$829f609a65d26a98$export$8c13c2ee36917869);
$parcel$export($829f609a65d26a98$exports, "B100", ()=>$829f609a65d26a98$export$ecfc1dff386a3e7a);
$parcel$export($829f609a65d26a98$exports, "B200", ()=>$829f609a65d26a98$export$215b7063051591f1);
$parcel$export($829f609a65d26a98$exports, "B300", ()=>$829f609a65d26a98$export$e62e8919cca44969);
$parcel$export($829f609a65d26a98$exports, "B400", ()=>$829f609a65d26a98$export$b3de0ae08da2ce64);
$parcel$export($829f609a65d26a98$exports, "B500", ()=>$829f609a65d26a98$export$a6ee3d71bc067634);
$parcel$export($829f609a65d26a98$exports, "B600", ()=>$829f609a65d26a98$export$67f15409e87642a1);
$parcel$export($829f609a65d26a98$exports, "B700", ()=>$829f609a65d26a98$export$f0539dbc42dbb067);
$parcel$export($829f609a65d26a98$exports, "P50", ()=>$829f609a65d26a98$export$363e4475840015bb);
$parcel$export($829f609a65d26a98$exports, "P75", ()=>$829f609a65d26a98$export$4b0a4417ed59760f);
$parcel$export($829f609a65d26a98$exports, "P100", ()=>$829f609a65d26a98$export$6011520a64d9a187);
$parcel$export($829f609a65d26a98$exports, "P200", ()=>$829f609a65d26a98$export$a7175d4e9206bc80);
$parcel$export($829f609a65d26a98$exports, "P300", ()=>$829f609a65d26a98$export$b48405b95304b5a1);
$parcel$export($829f609a65d26a98$exports, "P400", ()=>$829f609a65d26a98$export$c71e4279dd54a18d);
$parcel$export($829f609a65d26a98$exports, "P500", ()=>$829f609a65d26a98$export$147899de772240ec);
$parcel$export($829f609a65d26a98$exports, "T50", ()=>$829f609a65d26a98$export$2cf711fe14c66f06);
$parcel$export($829f609a65d26a98$exports, "T75", ()=>$829f609a65d26a98$export$7a8f4d33f6f9772c);
$parcel$export($829f609a65d26a98$exports, "T100", ()=>$829f609a65d26a98$export$776c9c497ef08533);
$parcel$export($829f609a65d26a98$exports, "T200", ()=>$829f609a65d26a98$export$69cb3ca10c1e330e);
$parcel$export($829f609a65d26a98$exports, "T300", ()=>$829f609a65d26a98$export$9dc1ef0f3f48059b);
$parcel$export($829f609a65d26a98$exports, "T400", ()=>$829f609a65d26a98$export$7c835c78b6fcdef8);
$parcel$export($829f609a65d26a98$exports, "T500", ()=>$829f609a65d26a98$export$6036577f95c3877e);
$parcel$export($829f609a65d26a98$exports, "N0", ()=>$829f609a65d26a98$export$29a4f05edc054374);
$parcel$export($829f609a65d26a98$exports, "N10", ()=>$829f609a65d26a98$export$e655ef011c4aa1a2);
$parcel$export($829f609a65d26a98$exports, "N20", ()=>$829f609a65d26a98$export$9fdfb749e33cdf97);
$parcel$export($829f609a65d26a98$exports, "N30", ()=>$829f609a65d26a98$export$3e51e8f1440314ba);
$parcel$export($829f609a65d26a98$exports, "N40", ()=>$829f609a65d26a98$export$1ce2d1575d444ceb);
$parcel$export($829f609a65d26a98$exports, "N50", ()=>$829f609a65d26a98$export$8754fc7e6d8b3000);
$parcel$export($829f609a65d26a98$exports, "N60", ()=>$829f609a65d26a98$export$c2e91cbeada39b8b);
$parcel$export($829f609a65d26a98$exports, "N70", ()=>$829f609a65d26a98$export$5f71e19f3a7ad9de);
$parcel$export($829f609a65d26a98$exports, "N80", ()=>$829f609a65d26a98$export$eaa8c8a70ac22806);
$parcel$export($829f609a65d26a98$exports, "N90", ()=>$829f609a65d26a98$export$7c87d337fb91a97d);
$parcel$export($829f609a65d26a98$exports, "N100", ()=>$829f609a65d26a98$export$d6447c357493214f);
$parcel$export($829f609a65d26a98$exports, "N200", ()=>$829f609a65d26a98$export$f563ee71e9ee74cd);
$parcel$export($829f609a65d26a98$exports, "N300", ()=>$829f609a65d26a98$export$e2ef2124cf4024b1);
$parcel$export($829f609a65d26a98$exports, "N400", ()=>$829f609a65d26a98$export$eece5f492e429f08);
$parcel$export($829f609a65d26a98$exports, "N500", ()=>$829f609a65d26a98$export$c39c0fe0f26207ae);
$parcel$export($829f609a65d26a98$exports, "N600", ()=>$829f609a65d26a98$export$7090862dc3038a9f);
$parcel$export($829f609a65d26a98$exports, "N700", ()=>$829f609a65d26a98$export$ad0bacf983b7ddaa);
$parcel$export($829f609a65d26a98$exports, "N800", ()=>$829f609a65d26a98$export$7d61b15e0da132af);
$parcel$export($829f609a65d26a98$exports, "N900", ()=>$829f609a65d26a98$export$fbda843f0a27fe96);
$parcel$export($829f609a65d26a98$exports, "N10A", ()=>$829f609a65d26a98$export$791e57b27ca738ef);
$parcel$export($829f609a65d26a98$exports, "N20A", ()=>$829f609a65d26a98$export$8d43898f60b96a82);
$parcel$export($829f609a65d26a98$exports, "N30A", ()=>$829f609a65d26a98$export$920c0d5c49070351);
$parcel$export($829f609a65d26a98$exports, "N40A", ()=>$829f609a65d26a98$export$46987b49aab6d1a8);
$parcel$export($829f609a65d26a98$exports, "N50A", ()=>$829f609a65d26a98$export$7d3359664e8bb0e5);
$parcel$export($829f609a65d26a98$exports, "N60A", ()=>$829f609a65d26a98$export$d2d3bedfb276cac0);
$parcel$export($829f609a65d26a98$exports, "N70A", ()=>$829f609a65d26a98$export$6e5e7499ff054f6d);
$parcel$export($829f609a65d26a98$exports, "N80A", ()=>$829f609a65d26a98$export$59264b346c877fa7);
$parcel$export($829f609a65d26a98$exports, "N90A", ()=>$829f609a65d26a98$export$d092757d7c64925c);
$parcel$export($829f609a65d26a98$exports, "N100A", ()=>$829f609a65d26a98$export$486ab785e4bac3c6);
$parcel$export($829f609a65d26a98$exports, "N200A", ()=>$829f609a65d26a98$export$fba592bbc6419fea);
$parcel$export($829f609a65d26a98$exports, "N300A", ()=>$829f609a65d26a98$export$13609db0e6812ab8);
$parcel$export($829f609a65d26a98$exports, "N400A", ()=>$829f609a65d26a98$export$fbae4efaa892d883);
$parcel$export($829f609a65d26a98$exports, "N500A", ()=>$829f609a65d26a98$export$f13946d26f440ce6);
$parcel$export($829f609a65d26a98$exports, "N600A", ()=>$829f609a65d26a98$export$19f58225ac52a306);
$parcel$export($829f609a65d26a98$exports, "N700A", ()=>$829f609a65d26a98$export$6384f09da70e7b62);
$parcel$export($829f609a65d26a98$exports, "N800A", ()=>$829f609a65d26a98$export$d0dccc730683edc2);
$parcel$export($829f609a65d26a98$exports, "DN900", ()=>$829f609a65d26a98$export$2f6319cf7d736c2a);
$parcel$export($829f609a65d26a98$exports, "DN800", ()=>$829f609a65d26a98$export$95126bc8884d6634);
$parcel$export($829f609a65d26a98$exports, "DN700", ()=>$829f609a65d26a98$export$8338d8c35d44ff1);
$parcel$export($829f609a65d26a98$exports, "DN600", ()=>$829f609a65d26a98$export$5368d76ae4fbbda7);
$parcel$export($829f609a65d26a98$exports, "DN500", ()=>$829f609a65d26a98$export$9f0a771d16ec8d36);
$parcel$export($829f609a65d26a98$exports, "DN400", ()=>$829f609a65d26a98$export$abcf8c38338b4aa5);
$parcel$export($829f609a65d26a98$exports, "DN300", ()=>$829f609a65d26a98$export$97e81faa16a182c7);
$parcel$export($829f609a65d26a98$exports, "DN200", ()=>$829f609a65d26a98$export$bfe21c59123ecf33);
$parcel$export($829f609a65d26a98$exports, "DN100", ()=>$829f609a65d26a98$export$ee82aa2ea6a1558);
$parcel$export($829f609a65d26a98$exports, "DN90", ()=>$829f609a65d26a98$export$682927c6ed76be02);
$parcel$export($829f609a65d26a98$exports, "DN80", ()=>$829f609a65d26a98$export$91fd9994c5be9757);
$parcel$export($829f609a65d26a98$exports, "DN70", ()=>$829f609a65d26a98$export$d281fbfe3cff2df6);
$parcel$export($829f609a65d26a98$exports, "DN60", ()=>$829f609a65d26a98$export$3902c91d8b98a753);
$parcel$export($829f609a65d26a98$exports, "DN50", ()=>$829f609a65d26a98$export$8e8133c1ba5845b5);
$parcel$export($829f609a65d26a98$exports, "DN40", ()=>$829f609a65d26a98$export$33ff357f6620b39);
$parcel$export($829f609a65d26a98$exports, "DN30", ()=>$829f609a65d26a98$export$633847cf062b69e0);
$parcel$export($829f609a65d26a98$exports, "DN20", ()=>$829f609a65d26a98$export$9776a6e503bdbf76);
$parcel$export($829f609a65d26a98$exports, "DN10", ()=>$829f609a65d26a98$export$6c813d5160dff5db);
$parcel$export($829f609a65d26a98$exports, "DN0", ()=>$829f609a65d26a98$export$3d4f271a500072a);
$parcel$export($829f609a65d26a98$exports, "DN800A", ()=>$829f609a65d26a98$export$fe151285c22759ec);
$parcel$export($829f609a65d26a98$exports, "DN700A", ()=>$829f609a65d26a98$export$a101c6190d0da34);
$parcel$export($829f609a65d26a98$exports, "DN600A", ()=>$829f609a65d26a98$export$4b16f52932fda51);
$parcel$export($829f609a65d26a98$exports, "DN500A", ()=>$829f609a65d26a98$export$a74bcd7b9845b0d8);
$parcel$export($829f609a65d26a98$exports, "DN400A", ()=>$829f609a65d26a98$export$9209c179316d1751);
$parcel$export($829f609a65d26a98$exports, "DN300A", ()=>$829f609a65d26a98$export$88f55133f636c8dd);
$parcel$export($829f609a65d26a98$exports, "DN200A", ()=>$829f609a65d26a98$export$51126f85d1009512);
$parcel$export($829f609a65d26a98$exports, "DN100A", ()=>$829f609a65d26a98$export$a069b547bd23af78);
$parcel$export($829f609a65d26a98$exports, "DN90A", ()=>$829f609a65d26a98$export$40cce05af3b2600a);
$parcel$export($829f609a65d26a98$exports, "DN80A", ()=>$829f609a65d26a98$export$b78942185925a0d);
$parcel$export($829f609a65d26a98$exports, "DN70A", ()=>$829f609a65d26a98$export$e058fb44b8eaafca);
$parcel$export($829f609a65d26a98$exports, "DN60A", ()=>$829f609a65d26a98$export$92b0b8de26920c18);
$parcel$export($829f609a65d26a98$exports, "DN50A", ()=>$829f609a65d26a98$export$67564f7459d3fc9e);
$parcel$export($829f609a65d26a98$exports, "DN40A", ()=>$829f609a65d26a98$export$77868e1ca884ffa3);
$parcel$export($829f609a65d26a98$exports, "DN30A", ()=>$829f609a65d26a98$export$2afd71d7d3ea2cd5);
$parcel$export($829f609a65d26a98$exports, "DN20A", ()=>$829f609a65d26a98$export$f2a0acaab9972c70);
$parcel$export($829f609a65d26a98$exports, "DN10A", ()=>$829f609a65d26a98$export$9ab70e67d6475361);
$parcel$export($829f609a65d26a98$exports, "T30", ()=>$829f609a65d26a98$export$d2adc5dedf09fced);
$parcel$export($829f609a65d26a98$exports, "L50", ()=>$829f609a65d26a98$export$f51762073d2bb381);
$parcel$export($829f609a65d26a98$exports, "L75", ()=>$829f609a65d26a98$export$95e26d54def96891);
const $829f609a65d26a98$export$4b84fd0e2c617bd = '#FFEBE6';
const $829f609a65d26a98$export$b34b069eff244586 = '#FFBDAD';
const $829f609a65d26a98$export$be802dd0d8b57f35 = '#FF8F73';
const $829f609a65d26a98$export$60847d42f9da92a6 = '#FF7452';
const $829f609a65d26a98$export$4a7404f376229999 = '#FF5630';
const $829f609a65d26a98$export$2010d0fb4295b346 = '#DE350B';
const $829f609a65d26a98$export$2837b5a1a71d5c02 = '#BF2600';
const $829f609a65d26a98$export$8d9596b529bbb9cf = '#FFFAE6';
const $829f609a65d26a98$export$41060a744ab0b7ae = '#FFF0B3';
const $829f609a65d26a98$export$1cc05bbbf340e559 = '#FFE380';
const $829f609a65d26a98$export$5aae31392d72caaa = '#FFC400';
const $829f609a65d26a98$export$c6930421db9fa514 = '#FFAB00';
const $829f609a65d26a98$export$59619663aa6500fb = '#FF991F';
const $829f609a65d26a98$export$874739ea9e379ab6 = '#FF8B00';
const $829f609a65d26a98$export$8a6341c32b6a3ee6 = '#E3FCEF';
const $829f609a65d26a98$export$69a3bf21b9686c2b = '#ABF5D1';
const $829f609a65d26a98$export$afbce1382ec95f73 = '#79F2C0';
const $829f609a65d26a98$export$747d86715d4175de = '#57D9A3';
const $829f609a65d26a98$export$940e1f69e873f202 = '#36B37E';
const $829f609a65d26a98$export$a49394138f7b8e85 = '#00875A';
const $829f609a65d26a98$export$6fa04827ac68a4fb = '#006644';
const $829f609a65d26a98$export$226de0355ed012f8 = '#DEEBFF';
const $829f609a65d26a98$export$8c13c2ee36917869 = '#B3D4FF';
const $829f609a65d26a98$export$ecfc1dff386a3e7a = '#4C9AFF';
const $829f609a65d26a98$export$215b7063051591f1 = '#2684FF';
const $829f609a65d26a98$export$e62e8919cca44969 = '#0065FF';
const $829f609a65d26a98$export$b3de0ae08da2ce64 = '#0052CC';
const $829f609a65d26a98$export$a6ee3d71bc067634 = '#0747A6';
const $829f609a65d26a98$export$67f15409e87642a1 = '#669DF1';
const $829f609a65d26a98$export$f0539dbc42dbb067 = '#8FB8F6';
const $829f609a65d26a98$export$363e4475840015bb = '#EAE6FF';
const $829f609a65d26a98$export$4b0a4417ed59760f = '#C0B6F2';
const $829f609a65d26a98$export$6011520a64d9a187 = '#998DD9';
const $829f609a65d26a98$export$a7175d4e9206bc80 = '#8777D9';
const $829f609a65d26a98$export$b48405b95304b5a1 = '#6554C0';
const $829f609a65d26a98$export$c71e4279dd54a18d = '#5243AA';
const $829f609a65d26a98$export$147899de772240ec = '#403294';
const $829f609a65d26a98$export$2cf711fe14c66f06 = '#E6FCFF';
const $829f609a65d26a98$export$7a8f4d33f6f9772c = '#B3F5FF';
const $829f609a65d26a98$export$776c9c497ef08533 = '#79E2F2';
const $829f609a65d26a98$export$69cb3ca10c1e330e = '#00C7E6';
const $829f609a65d26a98$export$9dc1ef0f3f48059b = '#00B8D9';
const $829f609a65d26a98$export$7c835c78b6fcdef8 = '#00A3BF';
const $829f609a65d26a98$export$6036577f95c3877e = '#008DA6';
const $829f609a65d26a98$export$29a4f05edc054374 = '#FFFFFF';
const $829f609a65d26a98$export$e655ef011c4aa1a2 = '#FAFBFC';
const $829f609a65d26a98$export$9fdfb749e33cdf97 = '#F4F5F7';
const $829f609a65d26a98$export$3e51e8f1440314ba = '#EBECF0';
const $829f609a65d26a98$export$1ce2d1575d444ceb = '#DFE1E6';
const $829f609a65d26a98$export$8754fc7e6d8b3000 = '#C1C7D0';
const $829f609a65d26a98$export$c2e91cbeada39b8b = '#B3BAC5';
const $829f609a65d26a98$export$5f71e19f3a7ad9de = '#A5ADBA';
const $829f609a65d26a98$export$eaa8c8a70ac22806 = '#97A0AF';
const $829f609a65d26a98$export$7c87d337fb91a97d = '#8993A4';
const $829f609a65d26a98$export$d6447c357493214f = '#7A869A';
const $829f609a65d26a98$export$f563ee71e9ee74cd = '#6B778C';
const $829f609a65d26a98$export$e2ef2124cf4024b1 = '#5E6C84';
const $829f609a65d26a98$export$eece5f492e429f08 = '#505F79';
const $829f609a65d26a98$export$c39c0fe0f26207ae = '#42526E';
const $829f609a65d26a98$export$7090862dc3038a9f = '#344563';
const $829f609a65d26a98$export$ad0bacf983b7ddaa = '#253858';
const $829f609a65d26a98$export$7d61b15e0da132af = '#172B4D';
const $829f609a65d26a98$export$fbda843f0a27fe96 = '#091E42';
const $829f609a65d26a98$export$791e57b27ca738ef = 'rgba(9, 30, 66, 0.02)';
const $829f609a65d26a98$export$8d43898f60b96a82 = 'rgba(9, 30, 66, 0.04)';
const $829f609a65d26a98$export$920c0d5c49070351 = 'rgba(9, 30, 66, 0.08)';
const $829f609a65d26a98$export$46987b49aab6d1a8 = 'rgba(9, 30, 66, 0.13)';
const $829f609a65d26a98$export$7d3359664e8bb0e5 = 'rgba(9, 30, 66, 0.25)';
const $829f609a65d26a98$export$d2d3bedfb276cac0 = 'rgba(9, 30, 66, 0.31)';
const $829f609a65d26a98$export$6e5e7499ff054f6d = 'rgba(9, 30, 66, 0.36)';
const $829f609a65d26a98$export$59264b346c877fa7 = 'rgba(9, 30, 66, 0.42)';
const $829f609a65d26a98$export$d092757d7c64925c = 'rgba(9, 30, 66, 0.48)';
const $829f609a65d26a98$export$486ab785e4bac3c6 = 'rgba(9, 30, 66, 0.54)';
const $829f609a65d26a98$export$fba592bbc6419fea = 'rgba(9, 30, 66, 0.60)';
const $829f609a65d26a98$export$13609db0e6812ab8 = 'rgba(9, 30, 66, 0.66)';
const $829f609a65d26a98$export$fbae4efaa892d883 = 'rgba(9, 30, 66, 0.71)';
const $829f609a65d26a98$export$f13946d26f440ce6 = 'rgba(9, 30, 66, 0.77)';
const $829f609a65d26a98$export$19f58225ac52a306 = 'rgba(9, 30, 66, 0.82)';
const $829f609a65d26a98$export$6384f09da70e7b62 = 'rgba(9, 30, 66, 0.89)';
const $829f609a65d26a98$export$d0dccc730683edc2 = 'rgba(9, 30, 66, 0.95)';
const $829f609a65d26a98$export$2f6319cf7d736c2a = '#E6EDFA';
const $829f609a65d26a98$export$95126bc8884d6634 = '#DCE5F5';
const $829f609a65d26a98$export$8338d8c35d44ff1 = '#CED9EB';
const $829f609a65d26a98$export$5368d76ae4fbbda7 = '#B8C7E0';
const $829f609a65d26a98$export$9f0a771d16ec8d36 = '#ABBBD6';
const $829f609a65d26a98$export$abcf8c38338b4aa5 = '#9FB0CC';
const $829f609a65d26a98$export$97e81faa16a182c7 = '#8C9CB8';
const $829f609a65d26a98$export$bfe21c59123ecf33 = '#7988A3';
const $829f609a65d26a98$export$ee82aa2ea6a1558 = '#67758F';
const $829f609a65d26a98$export$682927c6ed76be02 = '#56637A';
const $829f609a65d26a98$export$91fd9994c5be9757 = '#455166';
const $829f609a65d26a98$export$d281fbfe3cff2df6 = '#3B475C';
const $829f609a65d26a98$export$3902c91d8b98a753 = '#313D52';
const $829f609a65d26a98$export$8e8133c1ba5845b5 = '#283447';
const $829f609a65d26a98$export$33ff357f6620b39 = '#202B3D';
const $829f609a65d26a98$export$633847cf062b69e0 = '#1B2638';
const $829f609a65d26a98$export$9776a6e503bdbf76 = '#121A29';
const $829f609a65d26a98$export$6c813d5160dff5db = '#0E1624';
const $829f609a65d26a98$export$3d4f271a500072a = '#0D1424';
const $829f609a65d26a98$export$fe151285c22759ec = 'rgba(13, 20, 36, 0.06)';
const $829f609a65d26a98$export$a101c6190d0da34 = 'rgba(13, 20, 36, 0.14)';
const $829f609a65d26a98$export$4b16f52932fda51 = 'rgba(13, 20, 36, 0.18)';
const $829f609a65d26a98$export$a74bcd7b9845b0d8 = 'rgba(13, 20, 36, 0.29)';
const $829f609a65d26a98$export$9209c179316d1751 = 'rgba(13, 20, 36, 0.36)';
const $829f609a65d26a98$export$88f55133f636c8dd = 'rgba(13, 20, 36, 0.40)';
const $829f609a65d26a98$export$51126f85d1009512 = 'rgba(13, 20, 36, 0.47)';
const $829f609a65d26a98$export$a069b547bd23af78 = 'rgba(13, 20, 36, 0.53)';
const $829f609a65d26a98$export$40cce05af3b2600a = 'rgba(13, 20, 36, 0.63)';
const $829f609a65d26a98$export$b78942185925a0d = 'rgba(13, 20, 36, 0.73)';
const $829f609a65d26a98$export$e058fb44b8eaafca = 'rgba(13, 20, 36, 0.78)';
const $829f609a65d26a98$export$92b0b8de26920c18 = 'rgba(13, 20, 36, 0.81)';
const $829f609a65d26a98$export$67564f7459d3fc9e = 'rgba(13, 20, 36, 0.85)';
const $829f609a65d26a98$export$77868e1ca884ffa3 = 'rgba(13, 20, 36, 0.89)';
const $829f609a65d26a98$export$2afd71d7d3ea2cd5 = 'rgba(13, 20, 36, 0.92)';
const $829f609a65d26a98$export$f2a0acaab9972c70 = 'rgba(13, 20, 36, 0.95)';
const $829f609a65d26a98$export$9ab70e67d6475361 = 'rgba(13, 20, 36, 0.97)';
const $829f609a65d26a98$export$d2adc5dedf09fced = '#292A2E';
const $829f609a65d26a98$export$f51762073d2bb381 = '#94C748';
const $829f609a65d26a98$export$95e26d54def96891 = '#B3DF72';
parcelRegister("1DATb", function(module, exports) {
    $parcel$export(module.exports, "onFocus", ()=>$a998ee34d66f3da7$export$ecf9bf35ad97eb74);
    $parcel$export(module.exports, "isOptionsGrouped", ()=>$a998ee34d66f3da7$export$d2756249f53f9f8);
    $parcel$export(module.exports, "countAllOptions", ()=>$a998ee34d66f3da7$export$8ee33f304118fce4);
    function $a998ee34d66f3da7$export$ecf9bf35ad97eb74(onFocusProps, defaultOptions) {
        const { focused: focused } = onFocusProps;
        const isOptionFocused = (option)=>{
            return option.label === focused.label;
        };
        const groupData = defaultOptions?.find((option)=>{
            return option.options?.some(isOptionFocused);
        });
        const groupOptionIndex = groupData?.options.findIndex(isOptionFocused) ?? 0;
        return `Option ${focused.label}, ${groupData?.label} group, item ${groupOptionIndex + 1} out of ${groupData?.options.length}. All in all `;
    }
    const $a998ee34d66f3da7$export$d2756249f53f9f8 = (arr)=>{
        return arr?.every((obj)=>obj.hasOwnProperty('options'));
    };
    const $a998ee34d66f3da7$export$8ee33f304118fce4 = (groupsArray)=>{
        const totalLength = groupsArray?.reduce((acc, currentGroup)=>{
            const group = currentGroup;
            acc += group?.options?.length;
            return acc;
        }, 0);
        return totalLength;
    };
});
parcelRegister("6psbp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3751d830a949ad13$export$2e2bcd8739ae039);
    var $7VHMR;
    var $3751d830a949ad13$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        placeholder: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-category-picker.placeholder",
            "defaultMessage": "All categories"
        },
        ariaLabel: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-category-picker.aria-label",
            "defaultMessage": "Select a project category"
        }
    });
});
parcelRegister("hJiWC", function(module, exports) {
    $parcel$export(module.exports, "ProjectTypePicker", ()=>$a0cc4f9ef4ac5cfd$export$964395abe5900b56);
    var $dcQdC;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $94bNw;
    var $bChGW;
    var $dZhZb;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $gkT9B;
    var $dPvGX;
    var $lv5Gx;
    const $a0cc4f9ef4ac5cfd$var$PROJECT_TYPE_LABELS = {
        software: (0, (0, parcelRequire("lv5Gx")).default).softwareSpork,
        business: (0, (0, parcelRequire("lv5Gx")).default).businessSpork,
        service_desk: (0, (0, parcelRequire("lv5Gx")).default).serviceDesk,
        customer_service: (0, (0, parcelRequire("lv5Gx")).default).customerService,
        product_discovery: (0, (0, parcelRequire("lv5Gx")).default).productDiscovery
    };
    const $a0cc4f9ef4ac5cfd$var$PROJECT_TYPE_ORDER = {
        software: 2,
        service_desk: 3,
        business: 1,
        product_discovery: 4
    };
    const $a0cc4f9ef4ac5cfd$var$Input = ({ children: children, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bChGW")).components).Input, {
            ...props,
            "aria-describedby": undefined
        }, children);
    const $a0cc4f9ef4ac5cfd$export$964395abe5900b56 = ({ dataRef: dataRef })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'changed',
            actionSubject: 'refinementBar'
        });
        const [value, { updateFilter: updateFilter }] = (0, (0, parcelRequire("dPvGX")).useScopedFilter)((0, (0, parcelRequire("gkT9B")).FilterFields).ProjectType);
        const data = (0, $8Uumt.useFragment)((0, (0, parcelRequire("dcQdC")).default), dataRef);
        const onChange = (0, $5uXOq.useCallback)((newValue)=>{
            updateFilter({
                [(0, (0, parcelRequire("gkT9B")).FilterFields).ProjectType]: (0, (0, parcelRequire("dZhZb")).serializeSelectOptions)(newValue),
                [(0, (0, parcelRequire("gkT9B")).FilterFields).ProjectTypeLabel]: (0, (0, parcelRequire("dZhZb")).serializeSelectOptionsLabel)(newValue)
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'ProjectTypePicker');
        }, [
            updateFilter,
            analyticsEvent
        ]);
        const options = (0, $5uXOq.useMemo)(()=>[
                ...data?.allJiraProjectTypes?.edges?.map((edge)=>{
                    const projectTypeKey = edge?.node?.key || '';
                    const labelMessage = projectTypeKey && $a0cc4f9ef4ac5cfd$var$PROJECT_TYPE_LABELS[projectTypeKey];
                    return {
                        value: projectTypeKey,
                        label: labelMessage ? formatMessage(labelMessage) : '',
                        order: $a0cc4f9ef4ac5cfd$var$PROJECT_TYPE_ORDER[projectTypeKey]
                    };
                }) || []
            ].sort((a, b)=>a.order - b.order), [
            data,
            formatMessage
        ]);
        const selectedValue = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dZhZb")).matchValueToSelectOptions)(value, options), [
            value,
            options
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($a0cc4f9ef4ac5cfd$var$SelectWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("94bNw")).default), {
            'aria-label': formatMessage((0, (0, parcelRequire("lv5Gx")).default).placeholderSpork),
            components: {
                Input: $a0cc4f9ef4ac5cfd$var$Input
            },
            styles: {
                menu: ({ width: width, ...css })=>({
                        ...css,
                        width: 'max-content',
                        minWidth: '100%'
                    })
            },
            placeholder: formatMessage((0, (0, parcelRequire("lv5Gx")).default).placeholderSpork),
            isMulti: true,
            name: (0, (0, parcelRequire("gkT9B")).FilterFields).ProjectType,
            value: selectedValue,
            onChange: onChange,
            options: options
        }));
    };
    const $a0cc4f9ef4ac5cfd$var$SelectWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1ul9uuw1",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("dcQdC", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ff2807597a572d32$export$2e2bcd8739ae039);
    const $ff2807597a572d32$var$node = {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "cloudId"
            }
        ],
        "kind": "Fragment",
        "name": "projectTypePicker_projectsDirectoryV3",
        "selections": [
            {
                "args": [
                    {
                        "kind": "Variable",
                        "name": "cloudId",
                        "variableName": "cloudId"
                    }
                ],
                "concreteType": "JiraProjectTypeDetailsConnection",
                "kind": "LinkedField",
                "name": "allJiraProjectTypes",
                "plural": false,
                "selections": [
                    {
                        "concreteType": "JiraProjectTypeDetailsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "concreteType": "JiraProjectTypeDetails",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "key"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "type": "JiraQuery"
    };
    $ff2807597a572d32$var$node.hash = "f75b948f55a1e98fe58f7b24532671ea";
    var $ff2807597a572d32$export$2e2bcd8739ae039 = $ff2807597a572d32$var$node;
});
parcelRegister("94bNw", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ebf832c571222d72$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $7K5PP;
    var $itpgN;
    const $ebf832c571222d72$var$CheckboxSelectInternal = ({ components: components, ...props })=>{
        const mergedComponents = (0, $5uXOq.useMemo)(()=>({
                ...components,
                Option: (0, (0, parcelRequire("itpgN")).CheckboxOption)
            }), [
            components
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7K5PP")).default), {
            closeMenuOnSelect: false,
            hideSelectedOptions: false,
            isMulti: true,
            ...props,
            components: mergedComponents
        });
    };
    const $ebf832c571222d72$var$CheckboxSelect = (0, ($parcel$interopDefault($5uXOq))).memo($ebf832c571222d72$var$CheckboxSelectInternal);
    var $ebf832c571222d72$export$2e2bcd8739ae039 = $ebf832c571222d72$var$CheckboxSelect;
});
parcelRegister("itpgN", function(module, exports) {
    $parcel$export(module.exports, "CheckboxOption", ()=>$f5875245cd0ad0c1$export$d40cc97ecda916f9);
    $parcel$export(module.exports, "RadioOption", ()=>$f5875245cd0ad0c1$export$82ab7168879267a7);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $6aY6N;
    var $iQHJo;
    function $f5875245cd0ad0c1$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $f5875245cd0ad0c1$var$getPrimitiveStyles = (props)=>{
        const { cx: cx, className: className, getStyles: getStyles, isDisabled: isDisabled, isFocused: isFocused, isSelected: isSelected } = props;
        const baseStyles = {
            alignItems: 'center',
            backgroundColor: isFocused ? `var(--ds-background-neutral-subtle-hovered, ${0, $829f609a65d26a98$export$9fdfb749e33cdf97})` : 'transparent',
            color: isDisabled ? "var(--ds-text-disabled, inherit)" : 'inherit',
            display: 'flex ',
            paddingBottom: "var(--ds-space-050, 4px)",
            paddingLeft: "var(--ds-space-200, 16px)",
            paddingTop: "var(--ds-space-050, 4px)",
            boxShadow: isFocused ? `inset 2px 0px 0px ${`var(--ds-border-focused, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`}` : 'none',
            ':active': {
                backgroundColor: `var(--ds-background-neutral-subtle-pressed, ${(0, $829f609a65d26a98$export$3e51e8f1440314ba)})`
            },
            '@media screen and (-ms-high-contrast: active)': {
                borderLeft: isFocused ? '2px solid transparent' : ''
            }
        };
        const augmentedStyles = (0, (0, parcelRequire("f8Sb3")).css)({
            ...getStyles('option', props),
            ...baseStyles
        });
        const bemClasses = {
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected
        };
        return [
            augmentedStyles,
            cx(bemClasses, className)
        ];
    };
    const $f5875245cd0ad0c1$var$getPrimaryColor = ({ isActive: isActive, isDisabled: isDisabled, isFocused: isFocused, isSelected: isSelected })=>{
        if (isDisabled && isSelected) return `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$8c13c2ee36917869})`;
        else if (isDisabled) return `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$8d43898f60b96a82})`;
        else if (isSelected && isActive) return `var(--ds-background-selected-bold-pressed, ${0, $829f609a65d26a98$export$8c13c2ee36917869})`;
        else if (isActive) return `var(--ds-background-selected-pressed, ${0, $829f609a65d26a98$export$8c13c2ee36917869})`;
        else if (isFocused && isSelected) return `var(--ds-background-selected-bold-hovered, ${0, $829f609a65d26a98$export$e62e8919cca44969})`;
        else if (isFocused) return `var(--ds-surface, ${0, $829f609a65d26a98$export$29a4f05edc054374})`;
        else if (isSelected) return `var(--ds-background-selected-bold, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
        return `var(--ds-background-neutral, ${0, $829f609a65d26a98$export$29a4f05edc054374})`;
    };
    const $f5875245cd0ad0c1$var$getSecondaryColor = ({ isActive: isActive, isDisabled: isDisabled, isSelected: isSelected })=>{
        if (isDisabled && isSelected) return `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`;
        else if (isActive && isSelected && !isDisabled) return `var(--ds-surface, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
        else if (!isSelected) return 'transparent';
        return `var(--ds-surface, ${0, $829f609a65d26a98$export$29a4f05edc054374})`;
    };
    const $f5875245cd0ad0c1$var$getBorderColor = ({ isActive: isActive, isDisabled: isDisabled, isFocused: isFocused, isSelected: isSelected })=>{
        if (isDisabled && isSelected) return `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
        else if (isDisabled) return `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$d6447c357493214f})`;
        else if (isSelected && isActive) return `var(--ds-background-selected-bold-pressed, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
        else if (isActive) return `var(--ds-background-selected-bold, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
        else if (isFocused && isSelected) return `var(--ds-background-selected-bold-hovered, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
        else if (isFocused) return `var(--ds-border-input, ${0, $829f609a65d26a98$export$d6447c357493214f})`;
        else if (isSelected) return `var(--ds-background-selected-bold, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
        return `var(--ds-border-input, ${0, $829f609a65d26a98$export$d6447c357493214f})`;
    };
    const $f5875245cd0ad0c1$var$baseIconStyles = {
        name: "35x0q3",
        styles: "display:flex ;align-items:center;flex-shrink:0;padding-inline-end:var(--ds-space-050, 4px);& svg rect, & svg circle:first-of-type{stroke-linejoin:round;stroke-width:var(--ds-border-width, 1px);}"
    };
    const $f5875245cd0ad0c1$var$baseOptionStyles = {
        name: "16e0ede",
        styles: "flex-grow:1;overflow-x:hidden;text-overflow:ellipsis;white-space:nowrap"
    };
    class $f5875245cd0ad0c1$var$ControlOption extends (0, $5uXOq.Component) {
        render() {
            const { getStyles: getStyles, Icon: Icon, children: children, innerProps: innerProps, innerRef: innerRef, ...rest } = this.props;
            const props = {
                ...innerProps,
                onMouseDown: this.onMouseDown,
                onMouseUp: this.onMouseUp,
                onMouseLeave: this.onMouseLeave
            };
            const [styles, classes] = $f5875245cd0ad0c1$var$getPrimitiveStyles({
                getStyles: getStyles,
                ...rest
            });
            return ((0, (0, parcelRequire("f8Sb3")).jsx)("div", {
                css: styles,
                className: classes,
                ref: innerRef,
                ...props
            }, (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
                css: [
                    $f5875245cd0ad0c1$var$baseIconStyles,
                    {
                        '& svg rect, & svg circle:first-of-type': {
                            stroke: $f5875245cd0ad0c1$var$getBorderColor({
                                ...this.props,
                                ...this.state
                            })
                        }
                    }
                ]
            }, !!Icon ? (0, (0, parcelRequire("f8Sb3")).jsx)(Icon, {
                label: "",
                primaryColor: $f5875245cd0ad0c1$var$getPrimaryColor({
                    ...this.props,
                    ...this.state
                }),
                secondaryColor: $f5875245cd0ad0c1$var$getSecondaryColor({
                    ...this.props,
                    ...this.state
                }),
                isFacadeDisabled: true
            }) : null), (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
                css: $f5875245cd0ad0c1$var$baseOptionStyles
            }, children)));
        }
        constructor(...args){
            super(...args);
            this.state = {
                isActive: false
            };
            this.onMouseDown = ()=>this.setState({
                    isActive: true
                });
            this.onMouseUp = ()=>this.setState({
                    isActive: false
                });
            this.onMouseLeave = ()=>this.setState({
                    isActive: false
                });
        }
    }
    const $f5875245cd0ad0c1$export$d40cc97ecda916f9 = (props)=>(0, (0, parcelRequire("f8Sb3")).jsx)($f5875245cd0ad0c1$var$ControlOption, {
            Icon: (0, ($parcel$interopDefault((0, parcelRequire("6aY6N"))))),
            ...props
        });
    const $f5875245cd0ad0c1$export$82ab7168879267a7 = (props)=>(0, (0, parcelRequire("f8Sb3")).jsx)($f5875245cd0ad0c1$var$ControlOption, {
            Icon: (0, ($parcel$interopDefault((0, parcelRequire("iQHJo"))))),
            ...props
        });
});
parcelRegister("6aY6N", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $47f24d9003b82760$var$_react = $47f24d9003b82760$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $47f24d9003b82760$var$_checkboxChecked = $47f24d9003b82760$var$_interopRequireDefault((parcelRequire("aoKYM")));
    function $47f24d9003b82760$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $47f24d9003b82760$var$CheckboxIcon = (props)=>$47f24d9003b82760$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill-rule="evenodd"><rect width="12" height="12" x="6" y="6" fill="currentcolor" rx="2"/><path fill="inherit" d="M9.707 11.293a1 1 0 1 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414L11 12.586z"/></g></svg>`
        }, props, {
            newIcon: $47f24d9003b82760$var$_checkboxChecked.default
        }));
    $47f24d9003b82760$var$CheckboxIcon.displayName = 'CheckboxIcon';
    var $47f24d9003b82760$var$_default = module.exports.default = $47f24d9003b82760$var$CheckboxIcon;
});
parcelRegister("aoKYM", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $79209663d32c0c44$var$_react = $79209663d32c0c44$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $79209663d32c0c44$var$_UNSAFE_baseNew = $79209663d32c0c44$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $79209663d32c0c44$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $79209663d32c0c44$var$CheckboxCheckedIcon = (props)=>$79209663d32c0c44$var$_react.default.createElement($79209663d32c0c44$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path fill="currentcolor" fill-rule="evenodd" d="M3 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm9.326 4.48-1.152-.96L6.75 9.828 4.826 7.52l-1.152.96 2.5 3a.75.75 0 0 0 1.152 0z" clip-rule="evenodd"/>`
        }, props));
    $79209663d32c0c44$var$CheckboxCheckedIcon.displayName = 'CheckboxCheckedIcon';
    var $79209663d32c0c44$var$_default = module.exports.default = $79209663d32c0c44$var$CheckboxCheckedIcon;
});
parcelRegister("iQHJo", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $db8f016a0af5fda9$var$_react = $db8f016a0af5fda9$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $db8f016a0af5fda9$var$_radioChecked = $db8f016a0af5fda9$var$_interopRequireDefault((parcelRequire("kqGQn")));
    function $db8f016a0af5fda9$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $db8f016a0af5fda9$var$RadioIcon = (props)=>$db8f016a0af5fda9$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill-rule="evenodd"><circle cx="12" cy="12" r="6" fill="currentcolor"/><circle cx="12" cy="12" r="2" fill="inherit"/></g></svg>`
        }, props, {
            newIcon: $db8f016a0af5fda9$var$_radioChecked.default
        }));
    $db8f016a0af5fda9$var$RadioIcon.displayName = 'RadioIcon';
    var $db8f016a0af5fda9$var$_default = module.exports.default = $db8f016a0af5fda9$var$RadioIcon;
});
parcelRegister("kqGQn", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $edf7783e719984ba$var$_react = $edf7783e719984ba$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $edf7783e719984ba$var$_UNSAFE_baseNew = $edf7783e719984ba$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $edf7783e719984ba$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $edf7783e719984ba$var$RadioCheckedIcon = (props)=>$edf7783e719984ba$var$_react.default.createElement($edf7783e719984ba$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path fill="currentcolor" fill-rule="evenodd" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m0 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6" clip-rule="evenodd"/>`
        }, props));
    $edf7783e719984ba$var$RadioCheckedIcon.displayName = 'RadioCheckedIcon';
    var $edf7783e719984ba$var$_default = module.exports.default = $edf7783e719984ba$var$RadioCheckedIcon;
});
parcelRegister("lv5Gx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$69246b2863575212$export$2e2bcd8739ae039);
    var $7VHMR;
    var $69246b2863575212$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        placeholderSpork: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-type-picker.placeholder-spork",
            "defaultMessage": "Filter by product"
        },
        productDiscovery: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-type-picker.product-discovery",
            "defaultMessage": "Jira Product Discovery"
        },
        softwareSpork: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-type-picker.software-spork",
            "defaultMessage": "Jira - software projects"
        },
        serviceDesk: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-type-picker.service-desk",
            "defaultMessage": "Jira Service Management"
        },
        customerService: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-type-picker.customer-service",
            "defaultMessage": "Jira Customer Service"
        },
        businessSpork: {
            "id": "projects-directory-v3.page-layout.refinement-bar.project-type-picker.business-spork",
            "defaultMessage": "Jira - business projects"
        }
    });
});
parcelRegister("lOzQH", function(module, exports) {
    $parcel$export(module.exports, "SearchControl", ()=>$f6f7425010dc3c75$export$e8511ccde7917fd2);
    var $5uXOq = parcelRequire("5uXOq");
    var $8WLYr;
    var $4R6GH;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $gkT9B;
    var $dCm1o;
    var $dPvGX;
    var $hz3am;
    const $f6f7425010dc3c75$export$e8511ccde7917fd2 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const analyticsEvent = createAnalyticsEvent({
            action: 'changed',
            actionSubject: 'refinementBar'
        });
        const [value, { updateFilter: updateFilter }] = (0, (0, parcelRequire("dPvGX")).useScopedFilter)((0, (0, parcelRequire("gkT9B")).FilterFields).Keyword);
        const onChange = (0, $5uXOq.useCallback)((newValue)=>{
            updateFilter((0, (0, parcelRequire("gkT9B")).FilterFields).Keyword, newValue);
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'FilterSearch');
        }, [
            analyticsEvent,
            updateFilter
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8WLYr")).SearchControl), {
            name: (0, (0, parcelRequire("gkT9B")).FilterFields).Keyword,
            value: value,
            onChange: onChange,
            placeholderAlwaysVisible: true,
            placeholder: formatMessage((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_eap_drop_1') ? (0, (0, parcelRequire("hz3am")).default).placeholder : (0, (0, parcelRequire("hz3am")).default).placeholderOld)
        });
    };
});
parcelRegister("8WLYr", function(module, exports) {
    $parcel$export(module.exports, "SearchControl", ()=>$34b91ff110207eec$export$e8511ccde7917fd2);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $4R6GH;
    var $jDrwg;
    var $2xSAL;
    var $dCm1o;
    const $34b91ff110207eec$export$e8511ccde7917fd2 = ({ name: name, value: value, onChange: onChange, placeholder: placeholder, placeholderAlwaysVisible: placeholderAlwaysVisible })=>{
        const [onInputChange] = (0, (0, parcelRequire("jDrwg")).default)((newValue)=>{
            onChange(newValue);
        }, 500);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($34b91ff110207eec$var$SearchControlWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2xSAL")).SearchField), {
            isCompact: true,
            id: name,
            shouldFitContainerWidth: true,
            value: value,
            onChange: onInputChange,
            isAutocompleteDisabled: true,
            placeholderAlwaysVisible: placeholderAlwaysVisible,
            placeholder: placeholder,
            showIconBeforeInput: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() && (0, (0, parcelRequire("4R6GH")).fg)('jira_nav4_eap_drop_1')
        }));
    };
    const $34b91ff110207eec$var$SearchControlWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1ul9194s _p12f194s",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("jDrwg", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$049f084810e3e7c4$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lFKmC = parcelRequire("lFKmC");
    function $049f084810e3e7c4$export$2e2bcd8739ae039(callback, delay, options) {
        const optionsRef = (0, $5uXOq.useRef)(options);
        const callbackRef = (0, $5uXOq.useRef)((...args)=>{
            callback(...args);
        });
        callbackRef.current = callback;
        const debouncedCallBack = (0, $5uXOq.useMemo)(()=>(0, ($parcel$interopDefault($lFKmC)))((...args)=>callbackRef.current(...args), delay, optionsRef.current), [
            delay
        ]);
        (0, $5uXOq.useEffect)(()=>debouncedCallBack.cancel, [
            debouncedCallBack
        ]);
        return [
            debouncedCallBack,
            debouncedCallBack.cancel
        ];
    }
});
parcelRegister("2xSAL", function(module, exports) {
    $parcel$export(module.exports, "SearchField", ()=>$f115db1c327fbbaa$export$b94867ecbd698f21);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $fW62l = parcelRequire("fW62l");
    var $2NMNM;
    var $kY99m;
    var $koVbs;
    var $dCm1o;
    var $cBssJ;
    var $hJBhl;
    var $5yLnO;
    const $f115db1c327fbbaa$var$className = 'ajs-dirty-warning-exempt';
    const $f115db1c327fbbaa$var$SearchFieldUnmemoized = ({ onEnter: onEnter, onChange: onChange, onFocus: onFocus, onBlur: onBlur, placeholder: placeholder = '', inputRef: inputRef, value: externalValue, autoFocus: autoFocus = false, isCompact: isCompact = false, isDisabled: isDisabled = false, shouldFitContainerWidth: shouldFitContainerWidth = false, isAutocompleteDisabled: isAutocompleteDisabled = false, placeholderAlwaysVisible: placeholderAlwaysVisible = false, searchIconVisible: searchIconVisible = true, isAlwaysExpanded: isAlwaysExpanded = false, increasedWidth: increasedWidth = false, maxLength: maxLength, describedById: describedById = '', ariaLabelText: ariaLabelText, id: id, renderCustomSearchField: renderCustomSearchField, searchIconPrimaryColor: searchIconPrimaryColor, testId: testId, inputProps: inputProps = {}, isResponsive: isResponsive = false, showIconBeforeInput: showIconBeforeInput = false })=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [isFocused, setIsFocused] = (0, $5uXOq.useState)(false);
        const [value, setValue] = (0, $5uXOq.useState)(externalValue || '');
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const textInput = (0, $5uXOq.useRef)(null);
        const previousValueRef = (0, $5uXOq.useRef)();
        const previousValue = previousValueRef.current;
        if (externalValue !== previousValue && externalValue !== value) setValue(externalValue);
        previousValueRef.current = externalValue;
        const handleOnFocus = (0, $5uXOq.useCallback)((event)=>{
            setIsFocused(true);
            onFocus && onFocus(event);
        }, [
            onFocus
        ]);
        const handleOnBlur = (0, $5uXOq.useCallback)((event)=>{
            onBlur && onBlur(event);
            setIsFocused(false);
        }, [
            onBlur
        ]);
        const handleOnChange = (0, $5uXOq.useCallback)(({ currentTarget: currentTarget })=>{
            const { value: val } = currentTarget;
            const analyticsEvent = createAnalyticsEvent({
                action: 'changed'
            });
            setValue(val);
            onChange(val, analyticsEvent);
        }, [
            createAnalyticsEvent,
            onChange
        ]);
        const handleKeyDown = (0, $5uXOq.useCallback)((event)=>{
            const analyticsEvent = createAnalyticsEvent({
                action: 'changed'
            });
            if (event.key === 'Escape') {
                setValue('');
                onChange('', analyticsEvent);
            }
            if (onEnter && event.keyCode === (0, ($parcel$interopDefault($fW62l)))('enter')) {
                event.preventDefault();
                onEnter(event.currentTarget && event.currentTarget.value);
            }
        }, [
            createAnalyticsEvent,
            onChange,
            onEnter
        ]);
        const handleOnClear = (0, $5uXOq.useCallback)(()=>{
            if (value !== '') {
                const analyticsEvent = createAnalyticsEvent({
                    action: 'changed'
                });
                setValue('');
                onChange('', analyticsEvent);
            }
            if (inputRef && inputRef.current !== null) inputRef.current.blur();
            if (textInput.current !== null) textInput.current.blur();
        }, [
            createAnalyticsEvent,
            inputRef,
            onChange,
            value
        ]);
        const isExpanded = isFocused || value !== '' || isAlwaysExpanded;
        const textFieldProps = {
            ...inputProps,
            'aria-label': ariaLabelText || placeholder || formatMessage((0, (0, parcelRequire("cBssJ")).messages).search),
            'aria-describedby': describedById,
            type: 'text',
            'data-test-id': 'searchfield',
            testId: testId ?? 'searchfield',
            name: 'search',
            className: $f115db1c327fbbaa$var$className,
            placeholder: placeholder,
            onFocus: handleOnFocus,
            onBlur: handleOnBlur,
            width: '120px',
            onChange: handleOnChange,
            onKeyDown: handleKeyDown,
            ref: inputRef || textInput,
            isCompact: isCompact,
            autoFocus: autoFocus,
            autoComplete: isAutocompleteDisabled ? 'off' : 'on',
            value: value,
            maxLength: maxLength,
            isDisabled: isDisabled
        };
        const searchIconDynamic = (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5yLnO")).SearchFieldIconDynamic), {
            onClear: handleOnClear,
            hasValue: value !== '',
            clearIconLabel: formatMessage((0, (0, parcelRequire("cBssJ")).messages).clear),
            searchIconVisible: searchIconVisible,
            primaryColor: (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? undefined : searchIconPrimaryColor,
            onlyIconSpacing: isResponsive && !isExpanded
        });
        const searchIcon = showIconBeforeInput ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5yLnO")).SearchFieldIcon), {
            searchIconVisible: searchIconVisible,
            onlyIconSpacing: isResponsive && !isExpanded
        }) : undefined;
        const clearIcon = showIconBeforeInput && value !== '' ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hJBhl")).ClearFieldIcon), {
            onClear: handleOnClear,
            clearIconLabel: formatMessage((0, (0, parcelRequire("cBssJ")).messages).clear)
        }) : undefined;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($f115db1c327fbbaa$var$Container, {
            shouldFitContainerWidth: shouldFitContainerWidth
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f115db1c327fbbaa$var$InputWrapper, {
            isExpanded: isExpanded,
            shouldFitContainerWidth: shouldFitContainerWidth,
            increasedWidth: increasedWidth,
            maxWidth: increasedWidth ? 328 : 184,
            placeholderAlwaysVisible: placeholderAlwaysVisible,
            isResponsive: isResponsive
        }, renderCustomSearchField !== undefined ? renderCustomSearchField({
            textFieldProps: textFieldProps,
            searchIcon: searchIconDynamic
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kY99m")).default), {
            ...textFieldProps,
            elemBeforeInput: searchIcon,
            elemAfterInput: !showIconBeforeInput ? searchIconDynamic : clearIcon,
            ...id !== undefined ? {
                id: id
            } : {}
        })));
    };
    $f115db1c327fbbaa$var$SearchFieldUnmemoized.displayName = 'Search';
    $f115db1c327fbbaa$var$SearchFieldUnmemoized.whyDidYouRender = true;
    const $f115db1c327fbbaa$export$b94867ecbd698f21 = (0, $5uXOq.memo)($f115db1c327fbbaa$var$SearchFieldUnmemoized);
    $f115db1c327fbbaa$export$b94867ecbd698f21.displayName = 'Search';
    const $f115db1c327fbbaa$var$Container = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { shouldFitContainerWidth: shouldFitContainerWidth, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw",
                __cmplp.shouldFitContainerWidth && "_1bsb1osq",
                __cmplp.className
            ])
        });
    });
    const $f115db1c327fbbaa$var$InputWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { placeholderAlwaysVisible: placeholderAlwaysVisible, isResponsive: isResponsive, maxWidth: maxWidth, isExpanded: isExpanded, shouldFitContainerWidth: shouldFitContainerWidth, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1et2rhb": (0, (0, parcelRequire("lD23V")).default)(__cmplp.maxWidth, "px"),
                "--_47juog": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isExpanded ? `${__cmplp.maxWidth}px` : '30px'),
                "--_5cv8rq": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isExpanded ? 'auto' : '32px'),
                "--_jy5l88": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isExpanded ? `${"var(--ds-space-050, 4px)"} ${"var(--ds-space-075, 6px)"}` : "var(--ds-space-0, 0px)"),
                "--_1skyi4s": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isExpanded ? 'auto' : 0),
                "--_1qenfck": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isExpanded ? 1 : 0),
                "--_1b7586u": (0, (0, parcelRequire("lD23V")).default)(__cmplp.isExpanded ? `${__cmplp.maxWidth}px` : '120px'),
                "--_1b72lxu": (0, (0, parcelRequire("lD23V")).default)(__cmplp.shouldFitContainerWidth ? '100%' : `${__cmplp.isExpanded ? __cmplp.maxWidth : 120}px`)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _v564zpk5 _166qglyw _101ad2bq _i2ybkb7n",
                __cmplp.placeholderAlwaysVisible ? "_lav0kb7n" : "_lav0idpf",
                __cmplp.isResponsive ? "_1bsb1ayu _1s4pflvl _1kneabdv _odci1sf5 _wngi16hp _5xde1wic _1hr27bcq" : "_1bsb1di6",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("fW62l", function(module, exports) {
    function keyCode(searchInput) {
        if (searchInput && 'object' === typeof searchInput) {
            var hasKeyCode = searchInput.which || searchInput.keyCode || searchInput.charCode;
            if (hasKeyCode) searchInput = hasKeyCode;
        }
        if ('number' === typeof searchInput) return names[searchInput];
        var search = String(searchInput);
        var foundNamedKey = codes[search.toLowerCase()];
        if (foundNamedKey) return foundNamedKey;
        var foundNamedKey = aliases[search.toLowerCase()];
        if (foundNamedKey) return foundNamedKey;
        if (search.length === 1) return search.charCodeAt(0);
        return undefined;
    }
    keyCode.isEventKey = function isEventKey(event, nameOrCode) {
        if (event && 'object' === typeof event) {
            var keyCode = event.which || event.keyCode || event.charCode;
            if (keyCode === null || keyCode === undefined) return false;
            if (typeof nameOrCode === 'string') {
                var foundNamedKey = codes[nameOrCode.toLowerCase()];
                if (foundNamedKey) return foundNamedKey === keyCode;
                var foundNamedKey = aliases[nameOrCode.toLowerCase()];
                if (foundNamedKey) return foundNamedKey === keyCode;
            } else if (typeof nameOrCode === 'number') return nameOrCode === keyCode;
            return false;
        }
    };
    exports = module.exports = keyCode;
    var codes = exports.code = exports.codes = {
        'backspace': 8,
        'tab': 9,
        'enter': 13,
        'shift': 16,
        'ctrl': 17,
        'alt': 18,
        'pause/break': 19,
        'caps lock': 20,
        'esc': 27,
        'space': 32,
        'page up': 33,
        'page down': 34,
        'end': 35,
        'home': 36,
        'left': 37,
        'up': 38,
        'right': 39,
        'down': 40,
        'insert': 45,
        'delete': 46,
        'command': 91,
        'left command': 91,
        'right command': 93,
        'numpad *': 106,
        'numpad +': 107,
        'numpad -': 109,
        'numpad .': 110,
        'numpad /': 111,
        'num lock': 144,
        'scroll lock': 145,
        'my computer': 182,
        'my calculator': 183,
        ';': 186,
        '=': 187,
        ',': 188,
        '-': 189,
        '.': 190,
        '/': 191,
        '`': 192,
        '[': 219,
        '\\': 220,
        ']': 221,
        "'": 222
    };
    var aliases = exports.aliases = {
        'windows': 91,
        "\u21E7": 16,
        "\u2325": 18,
        "\u2303": 17,
        "\u2318": 91,
        'ctl': 17,
        'control': 17,
        'option': 18,
        'pause': 19,
        'break': 19,
        'caps': 20,
        'return': 13,
        'escape': 27,
        'spc': 32,
        'spacebar': 32,
        'pgup': 33,
        'pgdn': 34,
        'ins': 45,
        'del': 46,
        'cmd': 91
    };
    for(i = 97; i < 123; i++)codes[String.fromCharCode(i)] = i - 32;
    for(var i = 48; i < 58; i++)codes[i - 48] = i;
    for(i = 1; i < 13; i++)codes['f' + i] = i + 111;
    for(i = 0; i < 10; i++)codes['numpad ' + i] = i + 96;
    var names = exports.names = exports.title = {};
    for(i in codes)names[codes[i]] = i;
    for(var alias in aliases)codes[alias] = aliases[alias];
});
parcelRegister("kY99m", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$509076ff0c6afa78$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $6QvkM;
    var $hbrbl;
    const $509076ff0c6afa78$var$analyticsParams = {
        componentName: 'textField',
        packageName: "platform-pkg",
        packageVersion: "0.0.0-use.local"
    };
    const $509076ff0c6afa78$var$Textfield = (0, $5uXOq.forwardRef)((props, ref)=>{
        const { appearance: appearance = 'standard', className: className, elemAfterInput: elemAfterInput, elemBeforeInput: elemBeforeInput, isCompact: isCompact = false, isDisabled: isDisabled = false, isInvalid: isInvalid = false, isMonospaced: isMonospaced = false, isReadOnly: isReadOnly = false, isRequired: isRequired = false, name: name, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onMouseDown: onMouseDown, placeholder: placeholder, testId: testId, width: width, ...spreadProps } = props;
        const inputRef = (0, $5uXOq.useRef)(null);
        const handleOnFocus = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: (event)=>{
                onFocus && onFocus(event);
            },
            action: 'focused',
            ...$509076ff0c6afa78$var$analyticsParams
        });
        const handleOnBlur = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: (event)=>{
                onBlur && onBlur(event);
            },
            action: 'blurred',
            ...$509076ff0c6afa78$var$analyticsParams
        });
        const handleOnMouseDown = (0, $5uXOq.useCallback)((event)=>{
            const target = event.target;
            if (target.tagName !== 'INPUT') event.preventDefault();
            if (inputRef && inputRef.current && !isDisabled && document.activeElement !== inputRef.current) inputRef.current.focus();
            onMouseDown && onMouseDown(event);
        }, [
            onMouseDown,
            isDisabled
        ]);
        const setInputRef = (0, $5uXOq.useCallback)((inputElement)=>{
            inputRef.current = inputElement;
            if (!ref) return;
            if (typeof ref === 'object') ref.current = inputElement;
            if (typeof ref === 'function') ref(inputElement);
        }, [
            ref
        ]);
        const containerStyles = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("hbrbl")).containerStyles)(appearance, width), [
            appearance,
            width
        ]);
        const inputStyle = (0, (0, parcelRequire("hbrbl")).inputStyles)();
        return ((0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            role: "presentation",
            "data-disabled": isDisabled ? isDisabled : undefined,
            "data-invalid": isInvalid ? isInvalid : undefined,
            "data-ds--text-field--container": true,
            "data-testid": testId && `${testId}-container`,
            onMouseDown: handleOnMouseDown,
            css: containerStyles,
            className: className
        }, elemBeforeInput, (0, (0, parcelRequire("f8Sb3")).jsx)("input", {
            ...spreadProps,
            "aria-invalid": isInvalid ? isInvalid : undefined,
            css: inputStyle,
            "data-compact": isCompact ? isCompact : undefined,
            "data-ds--text-field--input": true,
            "data-monospaced": isMonospaced ? isMonospaced : undefined,
            "data-testid": testId,
            disabled: isDisabled,
            name: name,
            onBlur: handleOnBlur,
            onChange: onChange,
            onFocus: handleOnFocus,
            placeholder: placeholder,
            readOnly: isReadOnly,
            ref: setInputRef,
            required: isRequired
        }), elemAfterInput));
    });
    var $509076ff0c6afa78$export$2e2bcd8739ae039 = $509076ff0c6afa78$var$Textfield;
});
parcelRegister("hbrbl", function(module, exports) {
    $parcel$export(module.exports, "containerStyles", ()=>$9b106cc21ace4b3b$export$5972a68b6e54f38f);
    $parcel$export(module.exports, "inputStyles", ()=>$9b106cc21ace4b3b$export$3eeb47dc8e6249d9);
    const $9b106cc21ace4b3b$var$backgroundColor = {
        standard: `var(--ds-background-input, ${(0, $829f609a65d26a98$export$e655ef011c4aa1a2)})`,
        subtle: 'transparent',
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$backgroundColorFocus = {
        standard: `var(--ds-background-input-pressed, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        subtle: `var(--ds-background-input-pressed, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$backgroundColorHover = {
        standard: `var(--ds-background-input-hovered, ${(0, $829f609a65d26a98$export$3e51e8f1440314ba)})`,
        subtle: `var(--ds-background-input-hovered, ${(0, $829f609a65d26a98$export$3e51e8f1440314ba)})`,
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$borderColor = {
        standard: `var(--ds-border-input, ${(0, $829f609a65d26a98$export$d6447c357493214f)})`,
        subtle: 'transparent',
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$borderColorFocus = {
        standard: `var(--ds-border-focused, ${(0, $829f609a65d26a98$export$215b7063051591f1)})`,
        subtle: `var(--ds-border-focused, ${(0, $829f609a65d26a98$export$215b7063051591f1)})`,
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$borderColorHover = {
        standard: `var(--ds-border-input, ${(0, $829f609a65d26a98$export$d6447c357493214f)})`,
        subtle: "var(--ds-border-input, transparent)",
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$getContainerTextBgAndBorderColor = (appearance)=>({
            backgroundColor: $9b106cc21ace4b3b$var$backgroundColor[appearance],
            borderColor: $9b106cc21ace4b3b$var$borderColor[appearance],
            color: `var(--ds-text, ${0, $829f609a65d26a98$export$fbda843f0a27fe96})`,
            cursor: 'text',
            '&:hover:not([data-disabled])': {
                backgroundColor: $9b106cc21ace4b3b$var$backgroundColorHover[appearance],
                borderColor: $9b106cc21ace4b3b$var$borderColorHover[appearance]
            },
            '&:focus-within:not([data-disabled])': {
                backgroundColor: $9b106cc21ace4b3b$var$backgroundColorFocus[appearance],
                borderColor: $9b106cc21ace4b3b$var$borderColorFocus[appearance],
                boxShadow: `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${$9b106cc21ace4b3b$var$borderColorFocus[appearance]}`
            },
            '&[data-disabled]': {
                color: `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`,
                cursor: 'not-allowed',
                ...appearance === 'standard' && {
                    backgroundColor: `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$e655ef011c4aa1a2})`,
                    borderColor: `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$e655ef011c4aa1a2})`
                }
            },
            '&[data-invalid], &[data-invalid]:hover': {
                borderColor: `var(--ds-border-danger, ${0, $829f609a65d26a98$export$2010d0fb4295b346})`,
                boxShadow: `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${`var(--ds-border-danger, ${0, $829f609a65d26a98$export$2010d0fb4295b346})`}`
            },
            '&[data-invalid]:focus-within': {
                backgroundColor: `var(--ds-background-input-pressed, ${0, $829f609a65d26a98$export$29a4f05edc054374})`,
                borderColor: `var(--ds-border-focused, ${0, $829f609a65d26a98$export$215b7063051591f1})`,
                boxShadow: `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${`var(--ds-border-focused, ${0, $829f609a65d26a98$export$215b7063051591f1})`}`
            },
            '@media screen and (-ms-high-contrast: active)': {
                '&[data-invalid]:focus-within': {
                    borderColor: 'Highlight'
                },
                '&:focus-within': {
                    borderColor: 'Highlight'
                },
                '&[data-disabled]': {
                    borderColor: 'GrayText'
                }
            }
        });
    const $9b106cc21ace4b3b$var$widthMap = {
        xsmall: 80,
        small: 160,
        medium: 240,
        large: 320,
        xlarge: 480
    };
    const $9b106cc21ace4b3b$var$getMaxWidth = (width)=>!width ? `100%` : width in $9b106cc21ace4b3b$var$widthMap ? $9b106cc21ace4b3b$var$widthMap[width] : +width;
    const $9b106cc21ace4b3b$export$5972a68b6e54f38f = (appearance, width)=>({
            alignItems: 'center',
            ...$9b106cc21ace4b3b$var$getContainerTextBgAndBorderColor(appearance),
            borderRadius: 3,
            borderWidth: "var(--ds-border-width, 1px)",
            ...appearance !== 'none' ? {
                padding: `${"var(--ds-border-width, 1px)"} 0`
            } : {},
            borderStyle: appearance === 'none' ? 'none' : 'solid',
            boxSizing: 'border-box',
            display: 'flex',
            flex: '1 1 100%',
            font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
            justifyContent: 'space-between',
            maxWidth: $9b106cc21ace4b3b$var$getMaxWidth(width),
            overflow: 'hidden',
            transition: `background-color 0.2s ease-in-out, border-color 0.2s ease-in-out`,
            wordWrap: 'break-word',
            verticalAlign: 'top',
            pointerEvents: 'auto'
        });
    const $9b106cc21ace4b3b$export$3eeb47dc8e6249d9 = ()=>({
            backgroundColor: 'transparent',
            border: 0,
            boxSizing: 'border-box',
            color: 'inherit',
            cursor: 'inherit',
            font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
            minWidth: '0',
            outline: 'none',
            width: '100%',
            '&[data-monospaced]': {
                fontFamily: "var(--ds-font-family-code, ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace)"
            },
            '&[data-compact]': {
                padding: `${"var(--ds-space-050, 4px)"} ${"var(--ds-space-075, 6px)"}`
            },
            '&:not([data-compact])': {
                padding: `${"var(--ds-space-100, 8px)"} ${"var(--ds-space-075, 6px)"}`
            },
            '&[disabled]': {
                WebkitTextFillColor: `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`
            },
            '&::-ms-clear': {
                display: 'none'
            },
            '&:invalid': {
                boxShadow: 'none'
            },
            '&:placeholder-shown': {
                textOverflow: 'ellipsis'
            },
            '&::placeholder': {
                color: `var(--ds-text-subtlest, ${0, $829f609a65d26a98$export$f563ee71e9ee74cd})`,
                '&:disabled': {
                    color: `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`
                }
            },
            '@media screen and (-ms-high-contrast: active)': {
                '&[disabled]': {
                    color: 'GrayText'
                }
            }
        });
});
parcelRegister("cBssJ", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$08273d4fc2a6d6da$export$defe85febe8b728c);
    var $7VHMR;
    const $08273d4fc2a6d6da$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        clear: {
            "id": "searchfield.clear",
            "defaultMessage": "Clear"
        },
        search: {
            "id": "searchfield.search",
            "defaultMessage": "Search"
        }
    });
});
parcelRegister("hJBhl", function(module, exports) {
    $parcel$export(module.exports, "ClearFieldIcon", ()=>$99940a7c159b00c0$export$84038b503874befa);
    var $5uXOq = parcelRequire("5uXOq");
    var $42CjB;
    var $i28qQ;
    var $dC5iT;
    var $h9kUS;
    var $8CUq3;
    const $99940a7c159b00c0$export$84038b503874befa = ({ onClear: onClear, clearIconLabel: clearIconLabel })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                $99940a7c159b00c0$var$wrapperStylesCloseIcon
            ]
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
            appearance: "subtle",
            testId: "searchfield.ui.clearfield-icon.clear-button",
            onClick: onClear,
            label: clearIconLabel,
            spacing: "compact",
            icon: (0, ($parcel$interopDefault((0, parcelRequire("i28qQ")))))
        }));
    const $99940a7c159b00c0$var$wrapperStylesCloseIcon = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        marginRight: 'space.025',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            marginRight: 'space.050'
        }
    });
});
parcelRegister("5yLnO", function(module, exports) {
    $parcel$export(module.exports, "SearchFieldIconDynamic", ()=>$71cc18983db63a0d$export$9eef03e91cdeba14);
    $parcel$export(module.exports, "SearchFieldIcon", ()=>$71cc18983db63a0d$export$8a82bff895521bd9);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $84THj;
    var $26g7Y;
    var $hyOJh;
    var $dC5iT;
    var $h9kUS;
    var $8CUq3;
    const $71cc18983db63a0d$export$9eef03e91cdeba14 = ({ onClear: onClear, hasValue: hasValue, searchIconVisible: searchIconVisible, clearIconLabel: clearIconLabel, primaryColor: primaryColor, onlyIconSpacing: onlyIconSpacing = false })=>{
        const akSearchIcon = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("26g7Y"))))), {
            LEGACY_size: "small",
            label: "",
            LEGACY_primaryColor: primaryColor,
            color: "currentColor"
        });
        const searchIcon = searchIconVisible ? akSearchIcon : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                $71cc18983db63a0d$var$wrapperStyles,
                onlyIconSpacing && $71cc18983db63a0d$var$spacingStyles
            ]
        }, hasValue ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "subtle",
            spacing: "none",
            "data-test-id": "searchfield.ui.searchfield-icon.clear-button",
            testId: "searchfield.ui.searchfield-icon.clear-button",
            onClick: onClear,
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("84THj"))))), {
                LEGACY_size: "small",
                label: clearIconLabel,
                LEGACY_primaryColor: primaryColor,
                color: "currentColor"
            })
        }) : searchIcon);
    };
    const $71cc18983db63a0d$export$8a82bff895521bd9 = ({ searchIconVisible: searchIconVisible, onlyIconSpacing: onlyIconSpacing = false })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                $71cc18983db63a0d$var$wrapperStylesBefore,
                onlyIconSpacing && $71cc18983db63a0d$var$spacingStylesBefore
            ]
        }, searchIconVisible ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("hyOJh"))))), {
            spacing: "none",
            label: "",
            color: "var(--ds-icon, #44546F)"
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null));
    const $71cc18983db63a0d$var$wrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text.accent.gray',
        marginTop: 'space.0',
        marginRight: 'space.100',
        marginBottom: 'space.0',
        marginLeft: 'space.0',
        height: '16px',
        display: 'flex'
    });
    const $71cc18983db63a0d$var$spacingStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginRight: 'space.075',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            marginRight: 'space.100'
        }
    });
    const $71cc18983db63a0d$var$wrapperStylesBefore = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        marginRight: 'space.0',
        marginLeft: 'space.075',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            marginLeft: 'space.100'
        }
    });
    const $71cc18983db63a0d$var$spacingStylesBefore = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginLeft: 'space.075',
        [(0, (0, parcelRequire("h9kUS")).media).above.md]: {
            marginLeft: 'space.100'
        }
    });
});
parcelRegister("hz3am", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d919549c7300ed1a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $d919549c7300ed1a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        placeholder: {
            "id": "projects-directory-v3.page-layout.refinement-bar.search-control.placeholder",
            "defaultMessage": "Search projects"
        },
        placeholderOld: {
            "id": "projects-directory-v3.page-layout.refinement-bar.search-control.placeholder-old",
            "defaultMessage": "Search Projects"
        }
    });
});
parcelRegister("857tP", function(module, exports) {
    $parcel$export(module.exports, "ShouldShowRightSidebar", ()=>$440c9abdefaf43ed$export$495cbc34397848e9);
    var $55q1Q;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $39xOx;
    var $7Hq8b;
    var $hsmeR;
    var $8zOmE;
    var $2NMNM;
    const $440c9abdefaf43ed$var$ShouldShowRightSidebar = ({ dataRef: dataRef })=>{
        const { openRightSidebar: openRightSidebar, closeRightSidebar: closeRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const { jira: { userPreferences: userPreferences, sidebarExperimentTemplates: sidebarExperimentTemplates, canCreateProject: canCreateProject } } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("55q1Q")).default), dataRef);
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        (0, $5uXOq.useEffect)(()=>{
            if (!(typeof sidebarExperimentTemplates?.totalCount === 'number') || !(sidebarExperimentTemplates.totalCount > 0) || !userPreferences?.projectListRightPanelState || !canCreateProject) {
                (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'sidebarEligibility failed');
                return;
            }
            if (userPreferences.projectListRightPanelState === 'OPEN') openRightSidebar((0, (0, parcelRequire("7Hq8b")).PANEL_ID), (0, (0, parcelRequire("7Hq8b")).SIDEBAR_WIDTH), false, true);
            if (userPreferences.projectListRightPanelState === 'CLOSED') closeRightSidebar((0, (0, parcelRequire("7Hq8b")).PANEL_ID));
        }, [
            openRightSidebar,
            closeRightSidebar,
            userPreferences?.projectListRightPanelState,
            sidebarExperimentTemplates?.totalCount,
            canCreateProject,
            createAnalyticsEvent
        ]);
        return null;
    };
    const $440c9abdefaf43ed$export$495cbc34397848e9 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "right-side-panel",
            packageName: "jiraProjectsDirectoryV3",
            fallback: "unmount"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($440c9abdefaf43ed$var$ShouldShowRightSidebar, props));
});
parcelRegister("55q1Q", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7babe9d018105977$export$2e2bcd8739ae039);
    const $7babe9d018105977$var$node = function() {
        var v0 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        };
        return {
            "argumentDefinitions": [
                {
                    "kind": "RootArgument",
                    "name": "cloudId"
                }
            ],
            "kind": "Fragment",
            "name": "rightPanel_projectsDirectoryV3_ShouldShowRightSidebar",
            "selections": [
                {
                    "kind": "RequiredField",
                    "field": {
                        "concreteType": "JiraQuery",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": [
                                    v0
                                ],
                                "concreteType": "JiraUserPreferences",
                                "kind": "LinkedField",
                                "name": "userPreferences",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "projectListRightPanelState"
                                    }
                                ]
                            },
                            {
                                "alias": "sidebarExperimentTemplates",
                                "args": [
                                    v0,
                                    {
                                        "kind": "Literal",
                                        "name": "experimentKey",
                                        "value": "sidebarExperiment"
                                    }
                                ],
                                "concreteType": "JiraProjectListViewTemplateConnection",
                                "kind": "LinkedField",
                                "name": "projectListViewTemplates",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "name": "totalCount"
                                    }
                                ]
                            },
                            {
                                "alias": "canCreateProject",
                                "args": [
                                    v0,
                                    {
                                        "kind": "Literal",
                                        "name": "type",
                                        "value": "CREATE_PROJECT"
                                    }
                                ],
                                "kind": "ScalarField",
                                "name": "canPerform"
                            }
                        ]
                    },
                    "action": "THROW",
                    "path": "jira"
                }
            ],
            "type": "Query"
        };
    }();
    $7babe9d018105977$var$node.hash = "41b719258d7b3108422166c24c418e1c";
    var $7babe9d018105977$export$2e2bcd8739ae039 = $7babe9d018105977$var$node;
});
parcelRegister("5qVrc", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$34104383eeaa69f9$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $iqr2y;
    var $bVDE5;
    const $34104383eeaa69f9$var$ErrorBoundaryWithExtraEventData = ({ children: children, id: id })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iqr2y")).ErrorBoundaryFlag), {
            id: "spa-apps.error-boundary"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bVDE5")).default), null, ({ transitionsCount: transitionsCount = 0, lastTransition: lastTransition = '', navigationStart: navigationStart = '' })=>{
            const extraEventData = {
                transitionsCount: transitionsCount,
                lastTransition: lastTransition,
                navigationStart: navigationStart
            };
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iqr2y")).ErrorBoundaryFlag), {
                id: id,
                extraEventData: extraEventData
            }, children);
        }));
    const $34104383eeaa69f9$var$SpaErrorBoundary = $34104383eeaa69f9$var$ErrorBoundaryWithExtraEventData;
    var $34104383eeaa69f9$export$2e2bcd8739ae039 = $34104383eeaa69f9$var$SpaErrorBoundary;
});
parcelRegister("hQFKP", function(module, exports) {
    $parcel$export(module.exports, "METRICS_CONFIG", ()=>$55fecdb370260ae8$export$ccca72d76a53907e);
    var $gkT9B;
    const $55fecdb370260ae8$export$ccca72d76a53907e = {
        default: {
            sourceName: 'projectsV3',
            experienceKey: (0, (0, parcelRequire("gkT9B")).PROJECTS_DIRECTORY_EXPERIENCE),
            experienceSource: 'projects-directory-v3',
            errorBoundary: 'spa-app-bootstrap.directories.projects-v3',
            appNameV2: 'directory.projects-v3',
            appNameV3: 'projectsDirectoryV3'
        },
        admin: {
            sourceName: 'projectsManageV3',
            experienceKey: (0, (0, parcelRequire("gkT9B")).MANAGE_PROJECTS_DIRECTORY_EXPERIENCE),
            experienceSource: 'projects-directory-manage-v3',
            errorBoundary: 'spa-app-bootstrap.directories.projects-manage-v3',
            appNameV2: 'directory.projects-manage-v3',
            appNameV3: 'projectsDirectoryManageV3'
        }
    };
});
const $e6a2e0952e4731b8$export$febebefae5a09065 = 'profile-card.loaded';
const $e6a2e0952e4731b8$export$3ca1936ee743bd18 = 'profile-card.view';
const $e6a2e0952e4731b8$export$25bd35f2cd9ae0ea = 'profile-card.reload';
const $e6a2e0952e4731b8$export$86aa8b9c379ebbbd = 'profile-card.error';
const $e6a2e0952e4731b8$export$4442e31da6ba4127 = 'profile-card.click';
const $e6a2e0952e4731b8$export$7ecd9c56ca354ac0 = 'profileCardNext';
const $e6a2e0952e4731b8$export$81319ffa9c0d18d7 = 'profileCardNext';
const $e6a2e0952e4731b8$export$3145fc6c544ad7bf = 'profileCardNextLegacy';
const $e6a2e0952e4731b8$export$50632e21ea714398 = 'profileCardNextLegacy';
const $e6a2e0952e4731b8$export$d053e144c034c8aa = 'teamProfileCardNextLegacy';
const $e6a2e0952e4731b8$export$2549357601611c61 = 'loadError';
const $e6a2e0952e4731b8$export$23cedd3261bd8015 = 'viewProfile';
const $e6a2e0952e4731b8$export$6bef782d14a92db8 = 'assignedIssues';
const $e6a2e0952e4731b8$export$6b36f20db7a18a86 = 'reportedIssues';
const $e6a2e0952e4731b8$export$56da0dd0b0aa67b9 = 'viewFilters';
