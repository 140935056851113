import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'projects-directory-v3.page-layout.refinement-bar.search-control.placeholder',
		defaultMessage: 'Search projects',
		description: 'Placeholder label for the Search projects field',
	},
	placeholderOld: {
		id: 'projects-directory-v3.page-layout.refinement-bar.search-control.placeholder-old',
		defaultMessage: 'Search Projects',
		description: 'Placeholder label for the Search Projects field',
	},
});
