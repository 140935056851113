/**
 * @generated SignedSource<<f341e321a1687a6141d269c751cf0a0f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type createProjectAction_projectsDirectoryV3_CreateButton$data = {
  readonly canCreateProject: boolean | null | undefined;
  readonly " $fragmentType": "createProjectAction_projectsDirectoryV3_CreateButton";
};
export type createProjectAction_projectsDirectoryV3_CreateButton$key = {
  readonly " $data"?: createProjectAction_projectsDirectoryV3_CreateButton$data;
  readonly " $fragmentSpreads": FragmentRefs<"createProjectAction_projectsDirectoryV3_CreateButton">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "createProjectAction_projectsDirectoryV3_CreateButton",
  "selections": [
    {
      "alias": "canCreateProject",
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        {
          "kind": "Literal",
          "name": "type",
          "value": "CREATE_PROJECT"
        }
      ],
      "kind": "ScalarField",
      "name": "canPerform"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "df6f22d032f8d63a59ac7ad12bb1116f";

export default node;
