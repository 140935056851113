import React, { useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import AkPagination from '@atlaskit/pagination';
import { useIntl } from '@atlassian/jira-intl';
import type { pagination_directoryBaseV3$key } from '@atlassian/jira-relay/src/__generated__/pagination_directoryBaseV3.graphql';
import messages from './messages.tsx';

export interface Props {
	dataRef: pagination_directoryBaseV3$key;
	itemsPerPage: number;
	currentPage: number;
	onChange: (pageNum: number) => void;
}

const PAGES_TO_DISPLAY = 10;

export const Pagination = ({ dataRef, itemsPerPage, currentPage, onChange }: Props) => {
	const { formatMessage } = useIntl();
	const { totalCount } = useFragment(
		graphql`
			fragment pagination_directoryBaseV3 on HasTotal {
				totalCount
			}
		`,
		dataRef,
	);

	const handlePageChange = useCallback(
		(_: React.SyntheticEvent<Element, Event>, page: number) => {
			onChange(page);
		},
		[onChange],
	);

	const pages = useMemo(() => {
		if (!totalCount) {
			return [];
		}

		const totalPages = Math.ceil(totalCount / itemsPerPage);
		const middle = [];
		const end = Math.min(currentPage + PAGES_TO_DISPLAY, totalPages);
		const start = Math.max(currentPage - PAGES_TO_DISPLAY, 1);

		for (let i = start; i < end; i += 1) {
			middle.push(i);
		}

		return Array.from(new Set([1, ...middle, totalPages]));
	}, [totalCount, itemsPerPage, currentPage]);

	const selectedIndex = useMemo(
		() => pages.findIndex((p) => p === currentPage),
		[pages, currentPage],
	);

	if (!totalCount) {
		return null;
	}

	return (
		<AkPagination<number>
			pages={pages}
			onChange={handlePageChange}
			selectedIndex={selectedIndex}
			nextLabel={formatMessage(messages.next)}
			previousLabel={formatMessage(messages.previous)}
		/>
	);
};
