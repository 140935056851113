import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipAscSortingDirectionContent: {
		id: 'simple-table.table-head.table-head-cell.tooltip-asc-sorting-direction-content',
		defaultMessage: 'A ➞ Z',
		description: 'Default ASC tooltip sorting direction content',
	},
	tooltipDescSortingDirectionContent: {
		id: 'simple-table.table-head.table-head-cell.tooltip-desc-sorting-direction-content',
		defaultMessage: 'Z ➞ A',
		description: 'Default DESC tooltip sorting direction content',
	},
	a11YSortButtonDescriptionLabel: {
		id: 'simple-table.table-head.table-head-cell.a-11-y-sort-button-description-label',
		defaultMessage: 'column sort button',
		description: 'Role description for a sort button',
	},
});
