/**
 * @generated SignedSource<<c0b2fed1c40da58e8adc6b850f137f9e>>
 * @relayHash d4a9710e84ebea84a643c6ab959e756e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 677bbcd9c25049b9a4296d20d4a52bcaa99e82e0d0f12414fac1ed3dbbfa1e31

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type favouriteCell_directoryBaseV3_Mutation$variables = {
  entityId: string;
  isFavourite: boolean;
};
export type favouriteCell_directoryBaseV3_Mutation$data = {
  readonly jira: {
    readonly setEntityIsFavourite: {
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type favouriteCell_directoryBaseV3_Mutation = {
  response: favouriteCell_directoryBaseV3_Mutation$data;
  variables: favouriteCell_directoryBaseV3_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isFavourite"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "entityId",
                "variableName": "entityId"
              },
              {
                "kind": "Variable",
                "name": "isFavourite",
                "variableName": "isFavourite"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "JiraSetIsFavouritePayload",
        "kind": "LinkedField",
        "name": "setEntityIsFavourite",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "favouriteCell_directoryBaseV3_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "favouriteCell_directoryBaseV3_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "677bbcd9c25049b9a4296d20d4a52bcaa99e82e0d0f12414fac1ed3dbbfa1e31",
    "metadata": {},
    "name": "favouriteCell_directoryBaseV3_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bd17a743d8447fa8141ce0ba8c8f1012";

export default node;
