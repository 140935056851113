import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import {
	CheckboxSelect,
	type OptionsType,
	type OptionType,
	components,
	type InputProps,
} from '@atlaskit/select';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	matchValueToSelectOptions,
	serializeSelectOptions,
	serializeSelectOptionsLabel,
} from '@atlassian/jira-directory-base-v3/src/utils/search-string/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { FilterFields } from '@atlassian/jira-projects-directory-v3-utils/src/constants.tsx';
import type { projectTypePicker_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/projectTypePicker_projectsDirectoryV3.graphql';
import { useScopedFilter } from '../../../../controllers/filter-state/index.tsx';
import messages from './messages.tsx';

type Props = {
	dataRef: projectTypePicker_projectsDirectoryV3$key;
};

const PROJECT_TYPE_LABELS: { [key: string]: MessageDescriptorV2 } = {
	software: messages.softwareSpork,
	business: messages.businessSpork,
	service_desk: messages.serviceDesk,
	customer_service: messages.customerService,
	product_discovery: messages.productDiscovery,
};
const PROJECT_TYPE_ORDER: { [key: string]: number } = {
	software: 2,
	service_desk: 3,
	business: 1,
	product_discovery: 4,
};
// JCA11Y-148 - Provide override Input component to remove aria-describedby attribute that attached by placeholder prop,
// since it is conflicted with A11Y comaptibility with aria-label screen announcement, see https://react-select.com/props#components
/* @ts-expect-error - TS2304 - Cannot find name 'SelectComponents' and 'GroupBase' */
const Input: Partial<SelectComponents<OptionType, true, GroupBase<OptionType>>> | undefined = ({
	children,
	...props
}: InputProps) => (
	<components.Input {...props} aria-describedby={undefined}>
		{children}
	</components.Input>
);

export const ProjectTypePicker = ({ dataRef }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'changed',
		actionSubject: 'refinementBar',
	});

	const [value, { updateFilter }] = useScopedFilter(FilterFields.ProjectType);

	const data = useFragment<projectTypePicker_projectsDirectoryV3$key>(
		graphql`
			fragment projectTypePicker_projectsDirectoryV3 on JiraQuery {
				allJiraProjectTypes(cloudId: $cloudId) {
					edges {
						node {
							key
						}
					}
				}
			}
		`,
		dataRef,
	);

	const onChange = useCallback(
		(newValue: OptionsType<OptionType>) => {
			updateFilter({
				[FilterFields.ProjectType]: serializeSelectOptions(newValue),
				[FilterFields.ProjectTypeLabel]: serializeSelectOptionsLabel(newValue),
			});
			fireUIAnalytics(analyticsEvent, 'ProjectTypePicker');
		},
		[updateFilter, analyticsEvent],
	);

	const options: OptionsType<OptionType> = useMemo(
		() =>
			[
				...(data?.allJiraProjectTypes?.edges?.map((edge) => {
					const projectTypeKey = edge?.node?.key || '';
					const labelMessage = projectTypeKey && PROJECT_TYPE_LABELS[projectTypeKey];
					return {
						value: projectTypeKey,
						label: labelMessage ? formatMessage(labelMessage) : '',
						order: PROJECT_TYPE_ORDER[projectTypeKey],
					};
				}) || []),
			].sort((a, b) => a.order - b.order),
		[data, formatMessage],
	);

	const selectedValue = useMemo(() => matchValueToSelectOptions(value, options), [value, options]);

	return (
		<SelectWrapper>
			<CheckboxSelect
				{...{
					'aria-label': formatMessage(messages.placeholderSpork),
					components: { Input },
					styles: {
						menu: ({ width, ...css }) => ({
							...css,
							width: 'max-content',
							minWidth: '100%',
						}),
					},
				}}
				placeholder={formatMessage(messages.placeholderSpork)}
				isMulti
				name={FilterFields.ProjectType}
				value={selectedValue}
				onChange={onChange}
				options={options}
			/>
		</SelectWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 25}px`,
});
