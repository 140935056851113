import type { RefObject } from 'react';

export type ReturnFocusTo = RefObject<HTMLElement>;

export type State = {
	returnFocusTo: ReturnFocusTo | null;
};

export const initialState: State = {
	returnFocusTo: null,
};
