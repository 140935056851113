import React from 'react';
import get from 'lodash/get';
import { graphql, useFragment } from 'react-relay';
import { CellWrapper } from '@atlassian/jira-directory-base-v3/src/ui/table-cells/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { projectTypeCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/projectTypeCell_projectsDirectoryV3.graphql';
import messages from './messages.tsx';

type Props = {
	project: projectTypeCell_projectsDirectoryV3$key;
};

const projectTypeLabels = {
	COMPANY_MANAGED_PROJECT: {
		BUSINESS: messages.companyManagedBusiness,
		SOFTWARE: messages.companyManagedSoftware,
		SERVICE_DESK: messages.companyManagedServiceProject,
		PRODUCT_DISCOVERY: messages.productDiscoveryProject,
		CUSTOMER_SERVICE: messages.customerServiceProjectNonFinal,
	},
	TEAM_MANAGED_PROJECT: {
		BUSINESS: messages.teamManagedBusiness,
		SOFTWARE: messages.teamManagedSoftware,
		SERVICE_DESK: messages.teamManagedServiceProject,
		PRODUCT_DISCOVERY: messages.productDiscoveryProject,
		CUSTOMER_SERVICE: messages.customerServiceProjectNonFinal,
	},
};

export const ProjectTypeCell = ({ project }: Props) => {
	const { formatMessage } = useIntl();

	const { projectType, projectStyle } = useFragment(
		graphql`
			fragment projectTypeCell_projectsDirectoryV3 on JiraProject {
				projectStyle
				projectType
			}
		`,
		project,
	);

	const projectStyleKey = projectStyle || 'TEAM_MANAGED_PROJECT';
	const label = get(projectTypeLabels, [projectStyleKey, projectType || '']);

	return <CellWrapper>{label ? formatMessage(label) : projectType}</CellWrapper>;
};
