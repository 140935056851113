/**
 * @generated SignedSource<<1c7c638034af1606cfc66ad7c0873876>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectsList_projectsDirectoryV3_projectsRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"pagination_projectsDirectoryV3" | "resultsTable_projectsDirectoryV3">;
  readonly " $fragmentType": "projectsList_projectsDirectoryV3_projectsRef";
};
export type projectsList_projectsDirectoryV3_projectsRef$key = {
  readonly " $data"?: projectsList_projectsDirectoryV3_projectsRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectsList_projectsDirectoryV3_projectsRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAdminSettingsContext"
    }
  ],
  "kind": "Fragment",
  "name": "projectsList_projectsDirectoryV3_projectsRef",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isAdminSettingsContext",
          "variableName": "isAdminSettingsContext"
        }
      ],
      "kind": "FragmentSpread",
      "name": "resultsTable_projectsDirectoryV3"
    },
    {
      "kind": "FragmentSpread",
      "name": "pagination_projectsDirectoryV3"
    }
  ],
  "type": "JiraProjectConnection"
};

(node as any).hash = "a2ac4e53b4aeb7f1a30eaf9175bfdeae";

export default node;
