import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Flex } from '@atlaskit/primitives';
import { Pagination as BasePagination } from '@atlassian/jira-directory-base-v3/src/ui/pagination/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	DEFAULT_LIMIT,
	FilterFields,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants.tsx';
import type { pagination_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/pagination_projectsDirectoryV3.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useScopedFilter } from '../../../../controllers/filter-state/index.tsx';

type Props = {
	projectsRef: pagination_projectsDirectoryV3$key;
};

export const Pagination = ({ projectsRef }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'changed',
		actionSubject: 'resultsTable',
	});
	const [value, { updateFilter }] = useScopedFilter(FilterFields.Page);
	const currentPage = parseInt(value, 10);

	const dataRef = useFragment<pagination_projectsDirectoryV3$key>(
		graphql`
			fragment pagination_projectsDirectoryV3 on JiraProjectConnection {
				...pagination_directoryBaseV3
			}
		`,
		projectsRef,
	);

	const handleChange = useCallback(
		(page: number) => {
			updateFilter(FilterFields.Page, page.toString());
			fireUIAnalytics(analyticsEvent, 'PageNumber');
		},
		[analyticsEvent, updateFilter],
	);

	if (isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')) {
		return (
			<Flex alignItems="center" justifyContent="center">
				<BasePagination
					dataRef={dataRef}
					itemsPerPage={DEFAULT_LIMIT}
					currentPage={currentPage}
					onChange={handleChange}
				/>
			</Flex>
		);
	}

	return (
		<BasePagination
			dataRef={dataRef}
			itemsPerPage={DEFAULT_LIMIT}
			currentPage={currentPage}
			onChange={handleChange}
		/>
	);
};
