/**
 * @generated SignedSource<<17cbac72bd869ab640a0ccd5a3afce47>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type lastIssueUpdateCell_projectsDirectoryV3$data = {
  readonly lastUpdatedDisplayDate: string | null | undefined;
  readonly lastUpdatedToolTipDate: string | null | undefined;
  readonly " $fragmentType": "lastIssueUpdateCell_projectsDirectoryV3";
};
export type lastIssueUpdateCell_projectsDirectoryV3$key = {
  readonly " $data"?: lastIssueUpdateCell_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"lastIssueUpdateCell_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "lastIssueUpdateCell_projectsDirectoryV3",
  "selections": [
    {
      "alias": "lastUpdatedToolTipDate",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "COMPLETE_DATETIME_FORMAT"
        }
      ],
      "kind": "ScalarField",
      "name": "lastUpdatedFormatted",
      "storageKey": "lastUpdatedFormatted(format:\"COMPLETE_DATETIME_FORMAT\")"
    },
    {
      "alias": "lastUpdatedDisplayDate",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "RELATIVE"
        }
      ],
      "kind": "ScalarField",
      "name": "lastUpdatedFormatted",
      "storageKey": "lastUpdatedFormatted(format:\"RELATIVE\")"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "be249fa705b1ccb8ba2a516f14f08647";

export default node;
