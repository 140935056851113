import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	archiveTrigger: {
		id: 'project-archive-trigger.archive-trigger',
		defaultMessage: 'Archive',
		description: 'Dropdown item text for archiving project through archive trigger',
	},
	archiveTriggerServiceManagement: {
		id: 'project-archive-trigger.archive-trigger-service-management',
		defaultMessage: 'Move to archive',
		description:
			'Dropdown item text for archiving service management project through archive trigger',
	},
	premiumLozenge: {
		id: 'project-archive-trigger.premium-lozenge',
		defaultMessage: 'Premium',
		description:
			'Text for the Lozenge to announce that this menu item is only available for Premium',
	},
});
