import type { AriaAttributes, ReactNode } from 'react';

export const SortOrder = {
	ASC: 'ASC',
	DESC: 'DESC',
} as const;

export type SortOrder = (typeof SortOrder)[keyof typeof SortOrder];

export type AriaSortType = AriaAttributes['aria-sort'];
export type AriaLabelType = { 'aria-label'?: AriaAttributes['aria-label'] };
export const SortOrderLabel: { [key: string]: AriaSortType } = {
	ASC: 'ascending',
	DESC: 'descending',
};

export type OnSort = ({ key, sortOrder }: { key: string; sortOrder: SortOrder }) => void;

export interface SortProps {
	sortKey?: string;
	sortOrder?: SortOrder;
	onSort: OnSort;
}

export type RowCellType = {
	content: ReactNode;
	key: string;
};

export type RowType = {
	cells: RowCellType[];
};

export type HeadCellType = {
	isSortable?: boolean;
	width?: number;
	testId?: string;
	tooltipContent?: string;
	element?: 'th' | 'td';
} & RowCellType;

export type HeadType = {
	cells: HeadCellType[];
};
