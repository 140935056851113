/**
 * @generated SignedSource<<6e47b16dcd2a4c0090f75b99be9dfb50>>
 * @relayHash f80461cb9c20e5817d234cd6193aff07
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a9daa742fffdffb3be75a50f4d433a30c07a6212c4a9d23a43b3664323d1faa0

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiArchiveProjectModal_getProjectQuery } from './uiArchiveProjectModal_getProjectQuery.graphql';

const node: PreloadableConcreteRequest<uiArchiveProjectModal_getProjectQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a9daa742fffdffb3be75a50f4d433a30c07a6212c4a9d23a43b3664323d1faa0",
    "metadata": {},
    "name": "uiArchiveProjectModal_getProjectQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
