/**
 * @generated SignedSource<<d6375b0f75c09e09474a3ff052c13200>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type urlCell_projectsDirectoryV3$data = {
  readonly projectUrl: string | null | undefined;
  readonly " $fragmentType": "urlCell_projectsDirectoryV3";
};
export type urlCell_projectsDirectoryV3$key = {
  readonly " $data"?: urlCell_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlCell_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "urlCell_projectsDirectoryV3",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "projectUrl"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "bc3c770944d0003a95b4fc28334d594a";

export default node;
