/**
 * @generated SignedSource<<364088419a35537f43370e010e4d245d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type actionsCell_projectsDirectoryV3_ActionsCellDropdown_project$data = {
  readonly id: string;
  readonly jiraProjectName: string;
  readonly jiraProjectType: JiraProjectType | null | undefined;
  readonly key: string;
  readonly projectStyle: JiraProjectStyle | null | undefined;
  readonly " $fragmentType": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_project";
};
export type actionsCell_projectsDirectoryV3_ActionsCellDropdown_project$key = {
  readonly " $data"?: actionsCell_projectsDirectoryV3_ActionsCellDropdown_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_ActionsCellDropdown_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_project",
  "selections": [
    {
      "alias": "jiraProjectType",
      "kind": "ScalarField",
      "name": "projectType"
    },
    {
      "kind": "ScalarField",
      "name": "projectStyle"
    },
    {
      "alias": "jiraProjectName",
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "c940602017bcd3fd11260c66a06307d5";

export default node;
