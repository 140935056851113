import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tableCaptionAllProjects: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-caption-all-projects',
		defaultMessage: 'Table showing all projects',
		description: 'Projects table caption text',
	},
	tableCaptionAllProjectsAndAllCategories: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-caption-all-projects-and-all-categories',
		defaultMessage: 'Table showing all projects for all categories.',
		description: 'Projects table caption text for all categories',
	},
	tableCaptionSpecificProduct: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-product',
		defaultMessage: 'Table showing {product} projects.',
		description: 'Projects table caption text for specific products',
	},
	tableCaptionSpecificCategory: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-category',
		defaultMessage: 'Table showing all projects for {category} category',
		description: 'Projects table caption text for specific products',
	},
	tableCaptionSpecificProductAllCategories: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-product-all-categories',
		defaultMessage: 'Table showing {product} projects for all categories',
		description: 'Projects table caption text for specific products for all categories',
	},
	tableCaptionSpecificProductSpecificCategories: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-caption-specific-product-specific-categories',
		defaultMessage: 'Table showing {product} projects for {category} category',
		description: 'Projects table caption text for specific products for all categories',
	},
});
