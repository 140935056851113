import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { CellWrapper } from '@atlassian/jira-directory-base-v3/src/ui/table-cells/styled.tsx';
import type { projectCategoryCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/projectCategoryCell_projectsDirectoryV3.graphql';

type Props = {
	project: projectCategoryCell_projectsDirectoryV3$key;
};

export const ProjectCategoryCell = ({ project }: Props) => {
	const data = useFragment(
		graphql`
			fragment projectCategoryCell_projectsDirectoryV3 on JiraProject {
				category {
					name
				}
			}
		`,
		project,
	);

	return <CellWrapper>{data?.category?.name}</CellWrapper>;
};
