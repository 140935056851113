/**
 * @generated SignedSource<<00f1382389e8ddfb70ece3b8fdc2d637>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectsList_projectsDirectoryV3_rootQueryRef$data = {
  readonly jira: {
    readonly allJiraProjectCategories?: {
      readonly edges: ReadonlyArray<{
        readonly __typename: "JiraProjectCategoryEdge";
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef">;
  } | null | undefined;
  readonly " $fragmentType": "projectsList_projectsDirectoryV3_rootQueryRef";
};
export type projectsList_projectsDirectoryV3_rootQueryRef$key = {
  readonly " $data"?: projectsList_projectsDirectoryV3_rootQueryRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectsList_projectsDirectoryV3_rootQueryRef">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAnonymous"
    }
  ],
  "kind": "Fragment",
  "name": "projectsList_projectsDirectoryV3_rootQueryRef",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "condition": "isAnonymous",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "args": (v0/*: any*/),
              "concreteType": "JiraProjectCategoryConnection",
              "kind": "LinkedField",
              "name": "allJiraProjectCategories",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraProjectCategoryEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "17861eb1411077bef688cc2747a1321b";

export default node;
