import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	documentTitle: {
		id: 'projects-directory-v3.page-layout.document-title',
		defaultMessage: 'Projects',
		description: 'Title for Projects Directory page that is shown in browser tab',
	},
	adminDocumentTitle: {
		id: 'projects-directory-v3.page-layout.admin-document-title',
		defaultMessage: 'Manage Projects',
		description: 'Title for Admin Projects Directory page that is shown in browser tab',
	},
	filterResultsAnnouncement: {
		id: 'projects-directory-v3.page-layout.filter-results-announcement',
		defaultMessage:
			'{itemCount, plural, =0 {No projects were found that match your search} one {{itemCount} project found} other {{itemCount} projects found}}',
		description:
			'Message for screen reader users to announce how many projects were found after filtering',
	},
});
